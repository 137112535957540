// Models
import { SupportedReferenceTypes } from "./ITaskAttachmentType";
import { TaskAttachmentTypeBuilder } from "./TaskAttachmentTypeBuilder";
/** TaskAttachmentType specifies the type of a task attachment */ export class TaskAttachmentType {
    /** Builder for TaskAttachmentType objects */ static get builder() {
        return new TaskAttachmentTypeBuilder();
    }
    /**
     * Get the task attachment type from a string
     * @param fileType The type as a string we want to convert to SupportedReferenceTypes
     */ static getTaskAttachmentTypeFromString(fileType) {
        return SupportedReferenceTypes[fileType] ? SupportedReferenceTypes[fileType] : SupportedReferenceTypes.Other;
    }
    /**
     * @param taskAttachmentTypeBuilder Builder with the initialization data
     */ constructor(taskAttachmentTypeBuilder){
        this.taskAttachmentType = taskAttachmentTypeBuilder.taskAttachmentType;
        this.specificType = taskAttachmentTypeBuilder.specificType;
        this.videoUrlParseResult = taskAttachmentTypeBuilder.videoUrlParseResult;
    }
}
