// Models
// Utilities
import { getBreadcrumbString } from "@ms/uno-utilities/lib/local/StringUtilities";
/**
 * External Source info for a task
 */ export class ExternalTaskSource {
    /**
     * Creates a new ExternalSource instance from a given Graph service resource
     * @param resource The service model
     */ static fromGraphResource(resource) {
        return new ExternalTaskSource(resource.externalObjectId, resource.externalContextId, resource.contextScenarioId ?? "", resource.externalObjectVersion, resource.webUrl, resource.displayLinkType, resource.displayNameSegments, /* Graph does not return ownerAppId field */ "");
    }
    /**
     * Takes the display names segments array from the external source and returns a display ready string
     */ getSyncContextDisplayText() {
        return getBreadcrumbString(this.displayNameSegments);
    }
    /**
     * Create a new instance of an ExternalSource
     * @param externalObjectId The id of the entity that an external service associates with a task. Ex myProjectTaskId
     * @param externalContextId The id of the containing entity of the external task entity. Ex myProjectId
     * @param contextScenarioId An identifier for the scenario associated with this externally sourced task Ex com.microsoft.project
     * @param externalObjectVersion The external ItemVersion for the object
     * @param webUrl A deep link to the external application that created the task
     * @param displayAs How to display the task external task source
     * @param displayNameSegments An list of segments that the planner UI will use to show the external source to the user
     * @param ownerAppId The Id of the app where the task was created. Used for AuthZ
     */ constructor(externalObjectId, externalContextId, contextScenarioId, externalObjectVersion, webUrl, displayAs, displayNameSegments, ownerAppId){
        this.externalObjectId = externalObjectId;
        this.externalContextId = externalContextId;
        this.contextScenarioId = contextScenarioId;
        this.externalObjectVersion = externalObjectVersion;
        this.webUrl = webUrl;
        this.displayAs = displayAs;
        this.displayNameSegments = displayNameSegments;
        this.ownerAppId = ownerAppId;
    }
}
