// Models
import { DailyRecurrenceCalculator } from "../calculator/DailyRecurrenceCalculator";
import { MonthlyRecurrenceCalculator } from "../calculator/MonthlyRecurrenceCalculator";
import { RecurrencePatternType } from "../../../../../service/graph-legacy/task/recurrence/RecurrencePatternType";
import { WeeklyRecurrenceCalculator } from "../calculator/WeeklyRecurrenceCalculator";
import { YearlyRecurrenceCalculator } from "../calculator/YearlyRecurrenceCalculator";
/**
 * Get the Recurrence Calculator representing the recurrence pattern on a recurring task.
 * @param task A task to create the calculator from.
 * @param nextOccurrenceDate Recurrence start date.
 * @returns The calculator needed to represent the pattern.
 *
 * @remarks If the {@link task} is not recurring null will be returned.
 */ export function getRecurrenceCalculatorForTask(task) {
    if (task.recurrence?.isActive()) {
        /*
         * Date used by the calculator should be nextOccurrenceDate. The calculator bases all logic around this
         * assumption. However, nextOccurrenceDate may not exist for optimistically created recurrences. We need
         * to calculate the nextOccurrenceDate. To calculate this we can use the dueDate on the current occurrence
         * which satisfies the recurrence pattern. This assumption that dueDate satisifies the recurrence pattern
         * is false for all but optimistically created recurrences. This is because when we create a recurrence on
         * a task we calculate the first date that satisfies the recurrence and set that date as the dueDate.
         * After the recurrence is created on the service, the nextOccurrenceDate will be supplied. After this
         * the dueDate may not satisfy the recurrence as they are not tightly coupled.
         */ const nextOccurrenceDate = task.recurrence.nextOccurrenceDate ?? calculateNextOccurrenceDateFromSchedule(task.recurrence.schedule, task.recurrence.schedule?.range.startDate);
        return getRecurrenceCalculator(task.recurrence.schedule.pattern, nextOccurrenceDate);
    }
    return null;
}
/**
 * Calculate the first date on or after the {@link minDueDate} that fulfills the {@link schedule}.
 * @param schedule Schedule of the recurrence.
 * @param minDueDate Minimum due date for the schedule.
 * @returns The first date on or after the {@link minDueDate} that satisifies the {@link schedule}.
 *
 * @remarks If the {@link schedule} is null, the nextOccurrenceDate will be null.
 *
 * @description
 * This differs from {@link calculateNextOccurrenceDateFromSchedule} in that the first date is going to follow
 * the {@link schedule} and up to at most 1 period later.
 */ export function calculateDueDateFromSchedule(schedule, minDueDate) {
    if (schedule == null) {
        return null;
    }
    return getRecurrenceCalculator(schedule.pattern, minDueDate).calculatePatternStartDate();
}
/**
 * Calculate the next date after the {@link startingDate} that fulfills the {@link schedule}.
 *
 * @param schedule Schedule of the recurrence.
 * @param startingDate Starting date for the schedule.
 * @returns The next date following the {@link startingDate} that satisifies the {@link schedule}.
 *
 * @remarks If the {@link schedule} is null, the nextOccurrenceDate will be null.
 *
 * @remarks
 * If the {@link startingDate} is null and the {@link schedule} exists, a starting date will be calculated for
 * the {@link schedule} using the schedules range start date.
 *
 * @description
 * This differs from {@link calculateDueDateFromSchedule} in that the next date is going to follow the
 * {@link schedule} and the schedules pattern interval periods later.
 */ export function calculateNextOccurrenceDateFromSchedule(schedule, startingDate) {
    if (schedule == null) {
        return null;
    }
    // If the startingDate is unknown calculate the first date that will satisfy the pattern starting with the range start date.
    if (startingDate == null) {
        startingDate = calculateDueDateFromSchedule(schedule, schedule.range.startDate);
    }
    return getRecurrenceCalculator(schedule.pattern, startingDate).calculateNextOccurrenceDate();
}
/**
 * Get the Recurrence Calculator representing the recurrence {@link pattern} and the {@link nextOccurrenceDate}.
 * @param pattern Recurrence pattern.
 * @param nextOccurrenceDate Date of the next occurrence represented by the pattern.
 * @returns The calculator needed to represent the pattern.
 */ function getRecurrenceCalculator(pattern, nextOccurrenceDate) {
    switch(pattern.recurrencePatternType){
        case RecurrencePatternType.Weekly:
            return new WeeklyRecurrenceCalculator(pattern, nextOccurrenceDate);
        case RecurrencePatternType.AbsoluteMonthly:
        case RecurrencePatternType.RelativeMonthly:
            return new MonthlyRecurrenceCalculator(pattern, nextOccurrenceDate);
        case RecurrencePatternType.AbsoluteYearly:
        case RecurrencePatternType.RelativeYearly:
            return new YearlyRecurrenceCalculator(pattern, nextOccurrenceDate);
        case RecurrencePatternType.Daily:
        default:
            return new DailyRecurrenceCalculator(pattern, nextOccurrenceDate);
    }
}
