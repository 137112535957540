// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
// Constants
import { ClientType, Environment } from "@ms/uno-constants/lib/local/AppConstants";
import { StoreEntityStatus } from "@ms/uno-constants/lib/local/StoreConstants";
// Stores
import { EventPublishingStore } from "@ms/uno-fluxcore/lib/local/stores/EventPublishingStore";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
import { logUnreachableAction } from "../../utilities/LoggingUtilities";
import isEqual from "lodash/isEqual";
/**
 * Store for app level context data
 */ export class AppContextStore extends EventPublishingStore {
    get name() {
        return "AppContextStore";
    }
    getAppContext() {
        return this.appContext;
    }
    getUserExtraFields() {
        return {
            data: this.userExtraFields,
            status: this.userExtraFieldsEntityStatus
        };
    }
    getAppInstanceId() {
        return this.uuid;
    }
    // This method should be in sync with the one in pex/AppContext isCopilotEnabled
    isCopilotEnabled() {
        return this.appConfiguration.flights.CopilotSidecar && !this.isGovEnvironment();
    }
    isFullFrameMode() {
        return this.appConfiguration.sessionMetaData.appMetadata.clientType === ClientType.Web;
    }
    getTodoSettings() {
        return {
            data: this.todoSettings,
            status: this.todoSettingsEntityStatus
        };
    }
    handleAction(action) {
        let storeChanged = false;
        switch(action.type){
            case ActionType.FetchAppContextSucceeded:
                storeChanged = this.handleFetchAppContext(action);
                break;
            case ActionType.UpdateAppThemeAction:
                storeChanged = this.handleUpdateAppTheme(action);
                break;
            case ActionType.FetchUserExtraFieldsAction:
                storeChanged = this.handleFetchExtraUserFieldsAction();
                break;
            case ActionType.FetchUserExtraFieldsSucceededAction:
                storeChanged = this.handleFetchExtraUserFieldsSucceeded(action);
                break;
            case ActionType.FetchUserExtraFieldsFailedAction:
                storeChanged = this.handleFetchExtraUserFieldsFailed(action);
                break;
            case ActionType.FetchTodoSettingsAction:
                storeChanged = this.handleFetchTodoSettings(action);
                break;
            case ActionType.FetchTodoSettingsFailedAction:
                storeChanged = this.handleFetchTodoSettingsFailed(action);
                break;
            case ActionType.FetchTodoSettingsSucceededAction:
                storeChanged = this.handleFetchTodoSettingsSucceeded(action);
                break;
            default:
                logUnreachableAction(this, this.loggers, action);
                break;
        }
        if (storeChanged) {
            this.publishStoreChangedEvent();
        }
    }
    handleFetchExtraUserFieldsAction() {
        if (this.userExtraFieldsEntityStatus !== StoreEntityStatus.Fetching) {
            this.userExtraFieldsEntityStatus = StoreEntityStatus.Fetching;
            return true;
        }
        return false;
    }
    handleFetchExtraUserFieldsSucceeded(action) {
        this.userExtraFieldsEntityStatus = StoreEntityStatus.Present;
        this.userExtraFields = action.userExtraFields;
        return true;
    }
    handleFetchExtraUserFieldsFailed(action) {
        this.userExtraFieldsEntityStatus = StoreEntityStatus.UnableToFetch;
        return true;
    }
    handleFetchAppContext(action) {
        if (!isEqual(this.appContext, action.appContext)) {
            this.appContext = action.appContext;
            return true;
        }
        return false;
    }
    handleUpdateAppTheme(action) {
        if (this.appContext.theme !== action.theme) {
            this.appContext = {
                ...this.appContext,
                theme: action.theme
            };
            return true;
        }
        return false;
    }
    handleFetchTodoSettings(action) {
        if (this.todoSettingsEntityStatus !== StoreEntityStatus.Fetching) {
            this.todoSettingsEntityStatus = StoreEntityStatus.Fetching;
            return true;
        }
        return false;
    }
    handleFetchTodoSettingsFailed(action) {
        this.todoSettingsEntityStatus = StoreEntityStatus.UnableToFetch;
        return true;
    }
    handleFetchTodoSettingsSucceeded(action) {
        this.todoSettings = action.settings;
        this.todoSettingsEntityStatus = StoreEntityStatus.Present;
        return true;
    }
    isGovEnvironment() {
        return this.appConfiguration.sessionMetaData.environment === Environment.Gcc || this.appConfiguration.sessionMetaData.environment === Environment.Gcch || this.appConfiguration.sessionMetaData.environment === Environment.Dod || this.appConfiguration.sessionMetaData.environment === Environment.Agc;
    }
    constructor(initialContext, appConfiguration, loggers){
        super(), this.loggers = loggers, this.userExtraFields = null, this.todoSettings = null, this.userExtraFieldsEntityStatus = StoreEntityStatus.Unfetched, this.todoSettingsEntityStatus = StoreEntityStatus.Unfetched;
        this.appContext = initialContext;
        this.appConfiguration = appConfiguration;
        this.uuid = generateGuid();
    }
}
