// Models
import { CompletionRequirements } from "./CompletionRequirements";
import { ChecklistCompletionRequirement } from "./ChecklistCompletionRequirement";
import { FormsCompletionRequirement } from "./FormsCompletionRequirement";
import { ApprovalCompletionRequirement } from "./ApprovalCompletionRequirement";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
export class CompletionRequirementsBuilder {
    /**
     * Build out the completion requirements
     */ build() {
        return new CompletionRequirements(this);
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.checklistRequirement = propertyBag.checklistRequirement ?? this.checklistRequirement;
        this.formsRequirement = propertyBag.formsRequirement ?? this.formsRequirement;
        this.approvalRequirement = propertyBag.approvalRequirement ?? this.approvalRequirement;
        return this;
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(completionRequirements) {
        return this.withPropertyBag(cloneDeep(completionRequirements));
    }
    getDiffDataFromWebResource(update) {
        // ToDo: Handle DiffSync as a part of WI: 7738329
        return {};
    }
    fromGraphResource(resource) {
        const isFormsRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryForms;
        const isApprovalRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryApprovals;
        return this.withPropertyBag({
            checklistRequirement: resource.checklistRequirement ? ChecklistCompletionRequirement.fromResource(resource.checklistRequirement) : null,
            formsRequirement: resource.formsRequirement && isFormsRequirementEnabled ? FormsCompletionRequirement.fromResource(resource.formsRequirement) : null,
            approvalRequirement: resource.approvalRequirement && isApprovalRequirementEnabled ? ApprovalCompletionRequirement.fromResource(resource.approvalRequirement) : null
        });
    }
    getDiffDataFromGraphResource(update) {
        // ToDo: Handle DiffSync as a part of WI: 7738329
        return {};
    }
    /**
     * Return completion requirement builder object with with the given list of checklist items .
     * @param requiredChecklistIds list of checklist items
     */ withChecklistRequirement(checklistRequirements) {
        this.checklistRequirement = checklistRequirements;
        return this;
    }
    /**
     * Return completion requirement builder object with with the given forms requirement object .
     * @param formsRequirement forms requirement object
     */ withFormsRequirement(formsRequirement) {
        this.formsRequirement = formsRequirement;
        return this;
    }
    /**
     * Return completion requirement builder object with with the given approval requirement object .
     * @param approvalRequirement approval requirement object
     */ withApprovalRequirement(approvalRequirement) {
        this.approvalRequirement = approvalRequirement;
        return this;
    }
    constructor(){
        this.checklistRequirement = null;
        this.formsRequirement = null;
        this.approvalRequirement = null;
    }
}
