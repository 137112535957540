// Models
import { DayOrDate } from "./DayOrDate";
// Utilities
import { WeekIndex, getMomentDayIndexFromDayOfWeek } from "@ms/uno-utilities/lib/local/DateUtilities";
/**
 * Represents a floating day of a recurrence.
 * @example Monday of a week.
 * @example Fourth Thursday of November.
 */ export class FloatingDay extends DayOrDate {
    getDateForPeriod(period) {
        const month = this.getMonthForPeriod(period);
        const firstPossibleDayNumber = this.getFirstDateOfWeekInMonth(period);
        const firstDateOfDesiredWeek = period.clone().month(month).date(firstPossibleDayNumber);
        return this.getFirstDayMatchingTargetDayOfWeek(firstDateOfDesiredWeek);
    }
    /**
     * Get the first possible date given a weekIndex.
     * @param date Date representing the period
     * @returns The first possible date that the weekIndex can fall on.
     * @example Having the weekIndex set to second. Given the period July,
     * get the first possible date for that weekIndex, which is 8.
     * @example Having the weekIndex set to last. Given the period June,
     * get the first possible date for that weekIndex, which is 24 (30 - 6).
     */ getFirstDateOfWeekInMonth(date) {
        switch(this.weekIndex){
            case WeekIndex.Second:
                return 8;
            case WeekIndex.Third:
                return 15;
            case WeekIndex.Fourth:
                return 22;
            case WeekIndex.Last:
                return date.daysInMonth() - 6;
            case WeekIndex.First:
                return 1;
            default:
                return 1;
        }
    }
    /**
     * Get the first day matching the target day within a given period.
     * @param period Date representing the period
     * @returns The date representing the first matching target day within the period.
     * @example Having the Fourth Thursday. Given the period starting on the Tuesday the 22 day of the month, see above.
     * Get the Thursday that falls after the 22 for that period. (7 + 4 - 2) % 7 = 9 % 7 = 2. Add 2 days to the Tuesday 22,
     * which is Thursday the 24.
     */ getFirstDayMatchingTargetDayOfWeek(period) {
        // calculate the number of days to increment to get to the target day of week. Could be zero.
        const adder = (7 + getMomentDayIndexFromDayOfWeek(this.dayOfWeek) - period.day()) % 7;
        return period.clone().add(adder, "day");
    }
    constructor(weekIndex, dayOfWeek){
        super(), this.dayOfWeek = dayOfWeek;
        this.weekIndex = weekIndex;
    }
}
