// ActionCreators
import { ActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/ActionCreator";
// Factories
import { BaseActionCreatorFactory } from "../../base/factories/BaseActionCreatorFactory";
/** Factory class for action creator providers in Teams app */
export class TeamsActionCreatorFactory extends BaseActionCreatorFactory {
    constructor(loader, dispatcher, modules, stores, loggers, configProvider, teamsAdaptor) {
        super(loader, dispatcher, modules, stores, configProvider, loggers);
        this.teamsAdaptor = teamsAdaptor;
        this.loadCreateTaskFromTeamsMessageAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.CreateTaskFromTeamsMessage), () => import(
        /* webpackChunkName: "uno-createtaskfromteamsmessageac" */ "@ms/uno-actioncreators/lib/local/actioncreators/createTaskFromTeamsMessage/CreateTaskFromTeamsMessageActionCreator"), (bundle) => new bundle.CreateTaskFromTeamsMessageActionCreator(this.dispatcher, this.modules, this.loggers));
        this.loadTeamsTabAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.TeamsTab), () => import(
        /* webpackChunkName: "uno-teamstabac" */ "@ms/uno-actioncreators/lib/local/actioncreators/teamsTab/TeamsTabActionCreator"), (bundle) => new bundle.TeamsTabActionCreator(this.modules, this.stores, this.dispatcher, this.loggers, this.teamsAdaptor, this.configProvider));
        this.loadCreateTeamsTabViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.CreateTeamsTabView), () => import(
        /* webpackChunkName: "uno-createteamstabviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreateTeamsTabViewActionCreator"), (bundle) => new bundle.CreateTeamsTabViewActionCreator(this.dispatcher, this.modules, this.loggers, this.teamsAdaptor));
    }
}
