// Models
import { RecurrenceOccurrence } from "./RecurrenceOccurrence";
import { RecurrenceSchedule } from "./RecurrenceSchedule";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { DeltaTranslationMap } from "../../deltaSync/DeltaTranslationMap";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Configuration and building of recurrence occurrence info objects
 */ export class RecurrenceOccurrenceBuilder {
    get seriesId() {
        return this._seriesId;
    }
    get occurrenceIndex() {
        return this._occurrenceIndex;
    }
    get previousInSeriesTaskId() {
        return this._previousInSeriesTaskId;
    }
    get nextInSeriesTaskId() {
        return this._nextInSeriesTaskId;
    }
    get recurrenceStartDate() {
        return this._recurrenceStartDate;
    }
    get schedule() {
        return this._schedule;
    }
    get nextOccurrenceDate() {
        return this._nextOccurrenceDate;
    }
    /**
     * Build out the recurrence occurrence info
     */ build() {
        return new RecurrenceOccurrence(this);
    }
    fromGraphResource(resource) {
        return this.withPropertyBag({
            seriesId: resource.seriesId,
            occurrenceIndex: resource.occurrenceId,
            previousInSeriesTaskId: resource.previousInSeriesTaskId ?? undefined,
            nextInSeriesTaskId: resource.nextInSeriesTaskId ?? undefined,
            recurrenceStartDate: moment(resource.recurrenceStartDateTime),
            schedule: resource.schedule != null ? RecurrenceSchedule.builder.fromGraphResource(resource.schedule).build() : null,
            nextOccurrenceDate: resource.schedule != null ? moment(resource.schedule.nextOccurrenceDateTime) : null
        });
    }
    getDiffDataFromGraphResource(update, storeCopy) {
        if (!("recurrence" in update)) {
            return {};
        }
        const recurrenceUpdate = update["recurrence"];
        const translateReqDateString = (dateValue)=>dateValue != null ? moment(dateValue) : moment();
        // Property name translation Map
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("seriesId", "seriesId");
        graphTranslationMap.addMapping("occurrenceId", "occurrenceIndex");
        graphTranslationMap.addMapping("previousInSeriesTaskId", "previousInSeriesTaskId");
        graphTranslationMap.addMapping("nextInSeriesTaskId", "nextInSeriesTaskId");
        graphTranslationMap.addMapping("recurrenceStartDateTime", "recurrenceStartDate", translateReqDateString);
        // Translate mapped values
        const translatedRecurrence = graphTranslationMap.translate(recurrenceUpdate);
        // Translate schedule
        if ("schedule" in recurrenceUpdate) {
            if (recurrenceUpdate["schedule"] === null) {
                // Termination
                translatedRecurrence["schedule"] = null;
                translatedRecurrence["nextOccurrenceDate"] = null;
            } else {
                translatedRecurrence["schedule"] = RecurrenceSchedule.builder.getDiffDataFromGraphResource(update);
                const scheduleUpdate = recurrenceUpdate["schedule"];
                if ("nextOccurrenceDateTime" in scheduleUpdate && scheduleUpdate["nextOccurrenceDateTime"] != null) {
                    translatedRecurrence["nextOccurrenceDate"] = moment(scheduleUpdate["nextOccurrenceDateTime"]);
                }
            }
        }
        return translatedRecurrence;
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(recurrence) {
        return this.withPropertyBag(cloneDeep(recurrence));
    }
    /**
     * Return updated recurrence occurrence builder object with new recurrence schedule set.
     * @param schedule New recurrence schedule info
     */ withRecurrenceSchedule(schedule) {
        return this.withPropertyBag({
            schedule
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this._seriesId = propertyBag.seriesId ?? this.seriesId;
        this._occurrenceIndex = propertyBag.occurrenceIndex ?? this.occurrenceIndex; // The occurrenceIndex is always > 0
        this._previousInSeriesTaskId = propertyBag.previousInSeriesTaskId ?? this.previousInSeriesTaskId;
        this._nextInSeriesTaskId = propertyBag.nextInSeriesTaskId ?? this.nextInSeriesTaskId;
        this._recurrenceStartDate = propertyBag.recurrenceStartDate ?? this.recurrenceStartDate;
        // Doing triple check as certain recurrence schedule should allow for the removal.
        // Three possible values can be sent as params. value | null | undefined.
        // If is null then remove the property. If is undefined then used old value.
        this._schedule = propertyBag.schedule !== undefined ? propertyBag.schedule : this.schedule;
        this._nextOccurrenceDate = propertyBag.nextOccurrenceDate !== undefined ? propertyBag.nextOccurrenceDate : this.nextOccurrenceDate;
        return this;
    }
    /**
     * Initialize an instance of the recurrence occurrence builder
     * @remarks
     * Equivalent to optimistic recurrence with default daily schedule with all dates set to today with no end (see below)
     * {
     *      seriesId to PlexTempId
     *      occurrenceIndex to undefined
     *      previousInSeriesTaskId to undefined
     *      nextInSeriesTaskId to undefined
     *      recurrenceStartDate to undefined
     *      schedule to {
     *          pattern to {
     *              dayOfMonth to null
     *              daysOfWeek to null
     *              firstDayOfWeek to null
     *              interval to 1
     *              month to null
     *              recurrencePatternType to Daily
     *              weekIndex to null
     *          }
     *          range to {
     *              startDate to today
     *              recurrenceRangeType to no end
     *              endDate to null
     *              numberOfOccurrences to null
     *          }
     *      }
     *      nextOccurrenceDate to undefined
     * }
     */ constructor(){
        this._seriesId = LocalIdGenerator.generate();
        this._schedule = RecurrenceSchedule.builder.build();
    }
}
