// Models
import { ExternalPlanSource } from "./ExternalPlanSource";
/**
 * Creation source information for the plan
 */ export class PlanCreationSource {
    toGraphSerializable() {
        const resource = {};
        if (this.external) {
            resource.external = this.external.toGraphSerializable();
        }
        return resource;
    }
    /**
     * Initialize an instance of the creation source information for a plan
     * @param creationSource creation source information from the service
     */ constructor(creationSource){
        if (creationSource.external != null) {
            this.external = ExternalPlanSource.fromResource(creationSource.external);
        }
    }
}
