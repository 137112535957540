/**
 * Represents the container type
 */ export var ContainerType;
(function(ContainerType) {
    /** Invalid container type */ ContainerType["Invalid"] = "invalid";
    /** Planner User owned */ ContainerType["PlannerUser"] = "plannerUser";
    /** Group/Team owned  */ ContainerType["Group"] = "group";
    /** TFL owned */ ContainerType["TeamsConversation"] = "teamsConversation";
    /** DriveItem owned */ ContainerType["DriveItem"] = "driveItem";
    /** Roster owned */ ContainerType["Roster"] = "roster";
    /** Project owned */ ContainerType["Project"] = "project";
    /** User container */ ContainerType["User"] = "user";
    /** Todo container */ ContainerType["ToDo"] = "todo";
})(ContainerType || (ContainerType = {}));
