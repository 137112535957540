/**
 * Enum representing the extension of mru document items
 * Reference: https://www.owiki.ms/wiki/ICE_Services/Recent_Documents/MRU_V2_API#Mru_Item_Request_Properties
 */ export var MruExtension;
(function(MruExtension) {
    /**
     * Project document extension
     */ MruExtension["Project"] = "mpp";
    /**
     * Planner document extension
     */ MruExtension["Planner"] = "plan";
    /**
     * Todo document extension
     */ MruExtension["Todo"] = "tasklist";
    /**
     * Portfolio document extension
     */ MruExtension["Portfolio"] = "portfolio";
})(MruExtension || (MruExtension = {}));
