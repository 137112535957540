// Builders
import { TaskEntitySetBuilder } from "./TaskEntitySetBuilder";
// Models
import { PreviewType as ALPreviewType } from "@augloop-types/planner-file-recommendations";
import { PreviewType } from "../previewType/PreviewType";
// Utilities
import compact from "lodash/compact";
import find from "lodash/find";
import forEach from "lodash/forEach";
import keys from "lodash/keys";
import { ArgumentNullError } from "@ms/uno-errors/lib/local/errors/ArgumentNullError";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
/** Group of entities related to the task */ export class TaskEntitySet {
    /** Returns builder for Task entity set */ static builder() {
        return new TaskEntitySetBuilder();
    }
    /**
     * Converts a given task entity set to the AugmentationLoop task model for file suggestions
     * @param taskEntitySet The task entity set to convert
     * @param planTitle The title of the plan the task exists in
     * @param appliedCategoryStrings The list of category names that are applied to the current task
     * @param resolvedUsers The list of resolved users that are needed to convert the task entity
     * @param [bucketTitle] The title of the bucket the task is in
     */ static toAugmentationLoopTaskModel(taskEntitySet, planTitle, appliedCategoryStrings, resolvedUsers, bucketTitle) {
        let alPreviewType;
        switch(taskEntitySet.task.previewType){
            case PreviewType.CheckList:
                alPreviewType = ALPreviewType.Checklist;
                break;
            case PreviewType.Description:
                alPreviewType = ALPreviewType.Description;
                break;
            case PreviewType.NoPreview:
                alPreviewType = ALPreviewType.NoPreview;
                break;
            case PreviewType.Reference:
                alPreviewType = ALPreviewType.Reference;
                break;
            case PreviewType.Automatic:
            default:
                alPreviewType = ALPreviewType.Automatic;
        }
        const createdByUserPrincipalName = find(resolvedUsers, {
            id: taskEntitySet.task.createdBy
        })?.userPrincipalName ?? "";
        // The typing for userContentLastModifiedBy is for newer tasks. Old tasks that haven't had changes since this was added won't have this populated
        const lastModifiedByUserPrincipalName = find(resolvedUsers, {
            id: taskEntitySet.task.userContentLastModifiedBy
        })?.userPrincipalName ?? "";
        const completedByUserPrincipalName = taskEntitySet.task.completedBy ? find(resolvedUsers, {
            id: taskEntitySet.task.completedBy
        })?.userPrincipalName : undefined;
        const deletedByUserPrincipalName = taskEntitySet.task.deletedBy ? find(resolvedUsers, {
            id: taskEntitySet.task.deletedBy
        })?.userPrincipalName : undefined;
        const assignmentPrincipalNames = compact(keys(taskEntitySet.task.assignments).map((userId)=>find(resolvedUsers, {
                id: userId
            })?.userPrincipalName));
        return {
            id: taskEntitySet.id,
            appliedCategories: appliedCategoryStrings,
            bucketTitle: bucketTitle,
            completedBy: completedByUserPrincipalName,
            completedDate: taskEntitySet.task.completedDate?.toISOString() ?? undefined,
            createdBy: createdByUserPrincipalName,
            createdDate: taskEntitySet.task.createdDate.toISOString(),
            deletedBy: deletedByUserPrincipalName,
            dueDate: taskEntitySet.task.dueDate?.toISOString() ?? undefined,
            hasDescription: taskEntitySet.task.hasDescription,
            numberOfActiveChecklistItems: taskEntitySet.task.numberOfActiveChecklistItems,
            numberOfChecklistItems: taskEntitySet.task.numberOfChecklistItems,
            numberOfReferences: taskEntitySet.task.numberOfReferences,
            orderHint: taskEntitySet.task.orderHint,
            percentComplete: taskEntitySet.task.percentComplete,
            planTitle: planTitle,
            previewType: alPreviewType,
            priority: taskEntitySet.task.priority,
            startDate: taskEntitySet.task.startDate?.toISOString() ?? undefined,
            title: taskEntitySet.task.title,
            userContentLastModifiedBy: lastModifiedByUserPrincipalName,
            userContentLastModifiedDate: taskEntitySet.task.userContentLastModifiedDate?.toISOString() ?? undefined,
            kind: 1,
            assigneeIds: assignmentPrincipalNames,
            plannerTaskDetails: {
                id: taskEntitySet.id,
                description: taskEntitySet.taskDetails?.description ?? "",
                previewType: alPreviewType
            },
            logicalId: generateGuid()
        };
    }
    /**
     * Checks if the task and task details content has modified
     * Task - title, bucket, progress, startDate, dueDate, labels, assignments (add)
     * Task details - reference (add, update), checklist (add, update, checked/unchecked), description
     * @param originalTaskEntitySet Original task entity set to compare with
     * @param updatedTaskEntitySet Updated task entity set to compare with
     *
     */ static hasContentBeenModified(originalTaskEntitySet, updatedTaskEntitySet) {
        let hasTaskContentChanged = false;
        // Same ids, or task was created in service
        if (originalTaskEntitySet.id === updatedTaskEntitySet.id || LocalIdGenerator.isLocalTempId(originalTaskEntitySet.id) && !LocalIdGenerator.isLocalTempId(updatedTaskEntitySet.id)) {
            // Check if any of the properties ("title", "bucketId", "percentComplete", "startDate", "dueDate", "conversationThreadId", "appliedCategories") have changed in Task entity
            const diffKeys = keys(updatedTaskEntitySet.task.getDiff(originalTaskEntitySet.task));
            const taskProperties = [
                "title",
                "bucketId",
                "percentComplete",
                "startDate",
                "dueDate",
                "conversationThreadId",
                "appliedCategories"
            ];
            hasTaskContentChanged = diffKeys.some((r)=>taskProperties.indexOf(r) >= 0);
            // If not then check assignments and labels
            if (!hasTaskContentChanged) {
                keys(updatedTaskEntitySet.task.assignments).forEach((assignee)=>{
                    if (originalTaskEntitySet.task.assignments[assignee] == null) {
                        hasTaskContentChanged = true;
                        return false;
                    }
                });
            }
            // If task entity has not changed then check task details entity changes
            if (!hasTaskContentChanged && originalTaskEntitySet.taskDetails != null && updatedTaskEntitySet.taskDetails != null) {
                let hasReferenceChanged = false;
                const originalReferences = originalTaskEntitySet.taskDetails.references;
                forEach(updatedTaskEntitySet.taskDetails.references, (attachment)=>{
                    if (originalReferences[attachment.url] == null || attachment.alias !== originalReferences[attachment.url].alias) {
                        hasReferenceChanged = true;
                        return false;
                    }
                });
                let hasChecklistChanged = false;
                const originalChecklist = originalTaskEntitySet.taskDetails.checklist;
                if (!hasReferenceChanged) {
                    forEach(updatedTaskEntitySet.taskDetails.checklist, (checklistItem)=>{
                        if (originalChecklist[checklistItem.id] == null || checklistItem.title !== originalChecklist[checklistItem.id].title || checklistItem.isChecked !== originalChecklist[checklistItem.id].isChecked) {
                            hasChecklistChanged = true;
                            return false;
                        }
                    });
                }
                hasTaskContentChanged = updatedTaskEntitySet.taskDetails.description !== originalTaskEntitySet.taskDetails.description || updatedTaskEntitySet.taskDetails.notes?.content !== originalTaskEntitySet.taskDetails.notes?.content || hasReferenceChanged || hasChecklistChanged;
            }
            return hasTaskContentChanged;
        } else {
            throw new Error("Task ids doesn't match");
        }
    }
    get id() {
        return this.task.id;
    }
    toGraphSerializable() {
        const task = this.task.toGraphSerializable();
        task.details = this.taskDetails?.toGraphSerializable();
        task.assignedToTaskBoardFormat = this.taskFormatData?.assignmentBoardFormat?.toGraphSerializable();
        task.bucketTaskBoardFormat = this.taskFormatData?.bucketBoardFormat?.toGraphSerializable();
        task.progressTaskBoardFormat = this.taskFormatData?.progressBoardFormat?.toGraphSerializable();
        return task;
    }
    getClone() {
        return this.getCloneBuilder().build();
    }
    /**
     * Return a copy of task entity set with a new set of formatting applied to it especially used for move task
     * @param properties Task move clone options
     * @param formattingCollection Taskboard task formatting colloction options
     */ getCloneForMove(properties, formattingCollection) {
        return TaskEntitySet.builder().withTask(this.task.getCloneForMove(properties)).withFormatData(formattingCollection).build();
    }
    /**
     * Returns a builder for cloning this task entity set
     */ getCloneBuilder() {
        return TaskEntitySet.builder().forClone(this);
    }
    /**
     * Initialize a new instance of the task entity set
     */ constructor(builder){
        if (builder.task == null) {
            throw new ArgumentNullError("builder.task");
        }
        if (builder.taskDetails && builder.task && builder.taskDetails.id !== builder.task.id) {
            throw new Error("ArgumentException: Mismatch between task.id and taskDetails.id");
        }
        if (builder.taskFormatData?.assignmentBoardFormat && builder.taskFormatData.assignmentBoardFormat.id !== builder.task.id) {
            throw new Error("ArgumentException: Mismatch between task.id and taskFormatData.assignmentBoardFormat.id");
        }
        if (builder.taskFormatData?.bucketBoardFormat && builder.taskFormatData.bucketBoardFormat.id !== builder.task.id) {
            throw new Error("ArgumentException: Mismatch between task.id and taskFormatData.bucketBoardFormat.id");
        }
        if (builder.taskFormatData?.progressBoardFormat && builder.taskFormatData.progressBoardFormat.id !== builder.task.id) {
            throw new Error("ArgumentException: Mismatch between task.id and taskFormatData.progressBoardFormat.id");
        }
        this.task = builder.task;
        this.taskDetails = builder.taskDetails;
        this.taskFormatData = builder.taskFormatData;
    }
}
