// Constants
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { TaskServiceEntityType } from "../../ITaskServiceEntity";
import { TaskboardType } from "../../taskboardType/TaskboardType";
import { TaskServiceEntity } from "../../TaskServiceEntity";
import { AssignedToTaskboardTaskFormattingBuilder } from "./AssignedToTaskboardTaskFormattingBuilder";
import { GraphPlannerODataType } from "../../../../service/graph/planner/GraphPlannerODataType";
// Utilities
import clone from "lodash/clone";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
/**
 * Represents a formatting of the task in the "assignment" task board.
 */ export class AssignedToTaskboardTaskFormatting extends TaskServiceEntity {
    /**
     * Returns a builder of objects of type AssignedToTaskboardTaskFormatting
     */ static get builder() {
        return new AssignedToTaskboardTaskFormattingBuilder();
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (key === "orderHintsByAssignee") {
            if (isEqual(target, DeltaApiConstants.CollectionCleanupValue)) {
                // Unassign all workaround
                return {};
            }
            return target;
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    get taskboardType() {
        return TaskboardType.AssignedTo;
    }
    get entityType() {
        return TaskServiceEntityType.TaskboardAssignedToTaskFormatting;
    }
    /**
     * @param key Name of property on the AssignedToTaskboardTaskFormatting object
     * @param value Value to set on the property
     */ setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (!(key === "orderHintsByAssignee" || key === "unassignedOrderHint")) {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    getClone() {
        return this.getCloneBuilder().build();
    }
    /**
     * Returns a builder for cloning this instance
     */ getCloneBuilder() {
        return AssignedToTaskboardTaskFormatting.builder.forClone(this);
    }
    toGraphSerializable() {
        return {
            "@odata.type": GraphPlannerODataType.TaskboardAssignedToTaskFormatting,
            id: this.id,
            "@odata.etag": !isEmpty(this.itemVersion) ? this.itemVersion : undefined,
            orderHintsByAssignee: clone(this.orderHintsByAssignee || {}),
            unassignedOrderHint: this.unassignedOrderHint
        };
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === "orderHintsByAssignee") {
                if (!isEqual(source, target)) {
                    if (isEqual(target, {})) {
                        // Unassigned from all, workaround if {} is returned it treats it as no diff
                        return DeltaApiConstants.CollectionCleanupValue;
                    }
                    return target;
                } else {
                    // Equal
                    return {};
                }
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(AssignedToTaskboardTaskFormatting.builder.build(), this, ...diffs, AssignedToTaskboardTaskFormatting.mergeCustomizer);
        }
        return this;
    }
    applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this, this.entityType);
        return this.applyDiffs(diffData);
    }
    /**
     * Initializes a new instance of the AssignedToTaskBoardTaskFormatting class
     * @param builder Builder to create this instance
     */ constructor(builder){
        super(builder.id, builder.version);
        this.orderHintsByAssignee = builder.orderHintsByAssignee;
        this.unassignedOrderHint = builder.unassignedOrderHint;
    }
}
