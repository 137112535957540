// Configuration
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
// Models
import { CompletionRequirementsBuilder } from "./CompletionRequirementsBuilder";
export class CompletionRequirements {
    /**
     * Builder for CompletionRequirements objects
     */ static get builder() {
        return new CompletionRequirementsBuilder();
    }
    toGraphSerializable() {
        const isFormsRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryForms;
        const isApprovalRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryApprovals;
        const completionRequirementsPayload = {
            checklistRequirement: this.checklistRequirement
        };
        if (isFormsRequirementEnabled) {
            completionRequirementsPayload.formsRequirement = this.formsRequirement;
        }
        if (isApprovalRequirementEnabled) {
            completionRequirementsPayload.approvalRequirement = this.approvalRequirement;
        }
        return completionRequirementsPayload;
    }
    setProperty(key, value) {
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    getClone() {
        return CompletionRequirements.builder.forClone(this).build();
    }
    /**
     * Initializes a new instance of the "CompletionRequirements" entity.
     * @param builder Builder with the initialization data
     */ constructor(builder){
        this.checklistRequirement = builder.checklistRequirement;
        this.formsRequirement = builder.formsRequirement;
        this.approvalRequirement = builder.approvalRequirement;
    }
}
