// Models
import { MultipleDaysOrDatesRecurrenceCalculator } from "./MultipleDaysOrDatesRecurrenceCalculator";
/**
 * Represents a monthly recurrence pattern calculator.
 * @example 2nd of every other month. (MonthlyFixed)
 * @example Last Tuesday of every 4th month. (MonthlyFloating)
 */ export class MonthlyRecurrenceCalculator extends MultipleDaysOrDatesRecurrenceCalculator {
    isSameOrBefore(start, end) {
        // Set to beginning of the month then check. This is because we iterate through the months
        return start.clone().date(1).isSameOrBefore(end.clone().date(1));
    }
    constructor(pattern, patternStartDate){
        super(pattern, patternStartDate, "month");
    }
}
