// Models
import { RecurrencePattern } from "./RecurrencePattern";
import { RecurrencePatternType } from "../../../../service/graph-legacy/task/recurrence/RecurrencePatternType";
// Utilities
import moment from "moment";
import { getDayOfWeekFromMomentIndex, WeekIndex } from "@ms/uno-utilities/lib/local/DateUtilities";
/**
 * Configuration and building of recurrence pattern objects
 */ export class RecurrencePatternBuilder {
    get dayOfMonth() {
        return this._dayOfMonth;
    }
    get daysOfWeek() {
        return this._daysOfWeek;
    }
    get firstDayOfWeek() {
        return this._firstDayOfWeek;
    }
    get interval() {
        return this._interval;
    }
    get month() {
        return this._month;
    }
    get recurrencePatternType() {
        return this._recurrencePatternType;
    }
    get weekIndex() {
        return this._weekIndex;
    }
    /**
     * Build out the recurrence pattern
     * @throws ArgumentException if the recurrence pattern is malformed
     */ build() {
        return new RecurrencePattern(this);
    }
    fromGraphResource(resource) {
        return this.withPropertyBag(this.convertGraphToClient(resource));
    }
    getDiffDataFromGraphResource(update, storeCopy) {
        // Recurrence update
        if (!("recurrence" in update)) {
            return {};
        }
        const recurrenceUpdate = update["recurrence"];
        // Schedule update
        if (!("schedule" in recurrenceUpdate)) {
            return {};
        }
        const scheduleUpdate = recurrenceUpdate["schedule"];
        // Pattern update
        if (!("pattern" in scheduleUpdate)) {
            return {};
        }
        // Pattern is immutable and should set all data coming in through diff. However, we need to convert defaults sent from graph to nulls stored on client.
        return {
            ...this.fromGraphResource(scheduleUpdate["pattern"]).build()
        };
    }
    /**
     * Set recurrence pattern to daily every {@link interval}.
     * @param interval The interval applied to the cadence, values greater than 1 mean that a period will be skipped with no occurrences.
     */ withDaily(interval = 1) {
        return this.withPropertyBag({
            dayOfMonth: null,
            daysOfWeek: null,
            firstDayOfWeek: null,
            interval,
            month: null,
            recurrencePatternType: RecurrencePatternType.Daily,
            weekIndex: null
        });
    }
    /**
     * Set recurrence pattern to weekly every {@link interval} on {@link daysOfWeek}.
     * @param daysOfWeek A collection of the days of the week on which the event occurs.
     * @param interval The interval applied to the cadence, values greater than 1 mean that a period will be skipped with no occurrences.
     * @param firstDayOfWeek First day of the week.
     */ withWeekly(daysOfWeek, interval = 1, firstDayOfWeek = getDayOfWeekFromMomentIndex(moment.localeData().firstDayOfWeek())) {
        return this.withPropertyBag({
            dayOfMonth: null,
            daysOfWeek,
            firstDayOfWeek,
            interval,
            month: null,
            recurrencePatternType: RecurrencePatternType.Weekly,
            weekIndex: null
        });
    }
    /**
     * Set recurrence pattern to absoluteMonthly every {@link interval} on {@link dayOfMonth}.
     * @param dayOfMonth The day of the month on which the event occurs
     * @param interval The interval applied to the cadence, values greater than 1 mean that a period will be skipped with no occurrences.
     */ withAbsoluteMonthly(dayOfMonth, interval = 1) {
        return this.withPropertyBag({
            dayOfMonth,
            daysOfWeek: null,
            firstDayOfWeek: null,
            interval,
            month: null,
            recurrencePatternType: RecurrencePatternType.AbsoluteMonthly,
            weekIndex: null
        });
    }
    /**
     * Set recurrence pattern to relativeMonthly every {@link interval} on the {@link weekIndex} {@link dayOfWeek}.
     * @param dayOfWeek The day of the week on which the event occurs.
     * @param weekIndex The week of the month the event occurs. Defaults to first week.
     * @param interval The interval applied to the cadence, values greater than 1 mean that a period will be skipped with no occurrences.
     */ withRelativeMonthly(dayOfWeek, weekIndex = WeekIndex.First, interval = 1) {
        return this.withPropertyBag({
            dayOfMonth: null,
            daysOfWeek: [
                dayOfWeek
            ],
            firstDayOfWeek: null,
            interval,
            month: null,
            recurrencePatternType: RecurrencePatternType.RelativeMonthly,
            weekIndex
        });
    }
    /**
     * Set recurrence pattern to absoluteYearly every {@link interval} on {@link dayOfMonth} of {@link month}.
     * @param dayOfMonth The day of the month on which the event occurs
     * @param month The month on which the event occurs
     * @param interval The interval applied to the cadence, values greater than 1 mean that a period will be skipped with no occurrences.
     */ withAbsoluteYearly(dayOfMonth, month, interval = 1) {
        return this.withPropertyBag({
            dayOfMonth,
            daysOfWeek: null,
            firstDayOfWeek: null,
            interval,
            month,
            recurrencePatternType: RecurrencePatternType.AbsoluteYearly,
            weekIndex: null
        });
    }
    /**
     * Set recurrence pattern to relativeYearly every {@link interval} on the {@link weekIndex} {@link dayOfWeek} of {@link month}.
     * @param dayOfWeek The day of the week on which the event occurs.
     * @param weekIndex The week of the month the event occurs. Defaults to first week.
     * @param month The month on which the event occurs
     * @param interval The interval applied to the cadence, values greater than 1 mean that a period will be skipped with no occurrences.
     */ withRelativeYearly(dayOfWeek, month, weekIndex = WeekIndex.First, interval = 1) {
        return this.withPropertyBag({
            dayOfMonth: null,
            daysOfWeek: [
                dayOfWeek
            ],
            firstDayOfWeek: null,
            interval,
            month,
            recurrencePatternType: RecurrencePatternType.RelativeYearly,
            weekIndex
        });
    }
    /**
     * Build from provided property bag. All data is required which will reduce malformed patterns.
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this._dayOfMonth = propertyBag.dayOfMonth;
        this._daysOfWeek = propertyBag.daysOfWeek;
        this._firstDayOfWeek = propertyBag.firstDayOfWeek;
        this._interval = propertyBag.interval;
        this._month = propertyBag.month;
        this._recurrencePatternType = propertyBag.recurrencePatternType;
        this._weekIndex = propertyBag.weekIndex;
        return this;
    }
    /**
     * Convert Graph pattern representation to a property bag. Graph sends default values for properties not used per pattern type. We need to set only the
     * properties that are needed for the type and null out others.
     * @param resource Graph representation of a Recurrence Pattern
     * @returns Property bag representing the Recurrence Pattern
     */ convertGraphToClient(resource) {
        // Graph sends defaults for unused properties per type. We store nulls, thus we need to start with null and set per type.
        const propertyBag = {
            dayOfMonth: null,
            daysOfWeek: null,
            firstDayOfWeek: null,
            interval: resource.interval,
            month: null,
            recurrencePatternType: resource.type,
            weekIndex: null
        };
        switch(resource.type){
            case RecurrencePatternType.AbsoluteYearly:
                propertyBag.month = resource.month;
            case RecurrencePatternType.AbsoluteMonthly:
                // Intentional Fallthrough
                propertyBag.dayOfMonth = resource.dayOfMonth;
                break;
            case RecurrencePatternType.RelativeYearly:
                propertyBag.month = resource.month;
            case RecurrencePatternType.RelativeMonthly:
                // Intentional Fallthrough
                propertyBag.weekIndex = resource.index;
            case RecurrencePatternType.Weekly:
                // Intentional Fallthrough
                propertyBag.daysOfWeek = resource.daysOfWeek;
                // Since we are falling through if it is in fact weekly set firstDayOfWeek property
                if (resource.type === RecurrencePatternType.Weekly) {
                    propertyBag.firstDayOfWeek = resource.firstDayOfWeek;
                }
                break;
            case RecurrencePatternType.Daily:
            default:
                break;
        }
        return propertyBag;
    }
    /**
     * Initialize an instance of the recurrence pattern builder.
     * @remarks
     * Equivalent to daily (see below)
     * {
     *      dayOfMonth to null
     *      daysOfWeek to null
     *      firstDayOfWeek to null
     *      interval to 1
     *      month to null
     *      recurrencePatternType to Daily
     *      weekIndex to null
     * }
     */ constructor(){
        this._dayOfMonth = null;
        this._daysOfWeek = null;
        this._firstDayOfWeek = null;
        this._interval = 1;
        this._month = null;
        this._recurrencePatternType = RecurrencePatternType.Daily;
        this._weekIndex = null;
    }
}
