// Models
import { ExternalTaskSource } from "../creationSource/ExternalTaskSource";
import { Publication } from "./Publication";
// Resource
import { isGraphExternalTaskSourceResource } from "../../../../service/graph-legacy/task/IGraphExternalTaskSourceResource";
import { isGraphTeamsPublicationInfoResource } from "../../../../service/graph-legacy/task/IGraphTeamsPublicationInfoResource";
/**
 * Creation source information for the task
 */ export class TaskCreationSource {
    /**
     * Initialize an instance of the creation source from a Graph service resource
     * @param resource creation source information from the service
     */ static fromGraphResource(resource) {
        if (isGraphTeamsPublicationInfoResource(resource)) {
            return new TaskCreationSource(Publication.fromGraphResource(resource));
        } else if (isGraphExternalTaskSourceResource(resource)) {
            return new TaskCreationSource(undefined, undefined, ExternalTaskSource.fromGraphResource(resource));
        }
        throw new Error("Unexpected creationSourceKind");
    }
    /**
     * Initialize an instance of the creation source information for a task
     * @param publication Publication source information for the task
     * @param document Document related sync info for a task
     * @param external Information for tasks created from an external source
     */ constructor(publication, document, external){
        if (publication != null) {
            this.publication = publication;
        }
        if (document != null) {
            this.document = document;
        }
        if (external != null) {
            this.external = external;
        }
    }
}
