/**
 * Represents the type of the content. Possible values are Text and HTML.
 */ export var ContentType;
(function(ContentType) {
    /**
     * Text content
     */ ContentType["Text"] = "text";
    /**
     * HTML content
     */ ContentType["HTML"] = "html";
})(ContentType || (ContentType = {}));
