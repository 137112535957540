/**
 * Enum representing the category of mru data storage type
 * e.g. document, place url
 * Reference: https://www.owiki.ms/wiki/ICE_Services/Recent_Documents/MRU_V2_API#MRU_Applications
 */ export var MruCategory;
(function(MruCategory) {
    /**
     * Document category signlifying the mru data is a document url
     */ MruCategory["Document"] = "Document";
    /**
     * Place category signlifying the mru data is a link to a place
     */ MruCategory["Place"] = "Place";
})(MruCategory || (MruCategory = {}));
