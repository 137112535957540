/**
 * Enumeration of all the MRU apps that we care about
 * Reference: https://www.owiki.ms/wiki/ICE_Services/Recent_Documents/MRU_V2_API#MRU_Applications
 */ export var MruApp;
(function(MruApp) {
    /**
     * The project app in MRU.
     */ MruApp["Project"] = "Project";
    /**
     * The portfolio app in MRU.
     */ MruApp["Portfolio"] = "Portfolio";
    /**
     * The planner app in MRU.
     * TODO: We need to confirm this value once the MRU integration for planner is complete
     */ MruApp["Planner"] = "Planner";
    /**
     * The todo app's MRU.
     */ MruApp["ToDo"] = "ToDo";
    /**
     * The Word app's MRU.
     */ MruApp["Word"] = "Word";
    /**
     * The Excel app's MRU.
     */ MruApp["Excel"] = "Excel";
    /**
     * The Powerpoint app's MRU.
     */ MruApp["PowerPoint"] = "PowerPoint";
    /**
     * The PDFViewer app's MRU.
     */ MruApp["PdfViewer"] = "PdfViewer";
})(MruApp || (MruApp = {}));
