// Authentication
import { BrowserAuthError, BrowserAuthErrorCodes, BrowserCacheLocation, EventMessageUtils, EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType, PublicClientApplication } from "@azure/msal-browser-1p";
import { Logger, PromptValue } from "@azure/msal-common-uno";
// Constants
import { Environment, TenantRegion } from "@ms/uno-constants/lib/local/AppConstants";
import { AuthTokenClaims, GuestUserAcctClaimValue, TenantRegionSubScope } from "@ms/uno-constants/lib/local/AuthConstants";
// Errors
import { NoAccountAuthError } from "./NoAccountAuthError";
// Telemetry
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
import { retry } from "../../RetryUtilities";
import { BaseMsalHelper } from "./BaseMsalHelper";
export var MsalCacheLocation;
(function(MsalCacheLocation) {
    /**
     * Local storage on browser. Share login across browser tabs.
     */ MsalCacheLocation[MsalCacheLocation["LocalStorage"] = 0] = "LocalStorage";
    /**
     * Session storage on browser.
     */ MsalCacheLocation[MsalCacheLocation["SessionStorage"] = 1] = "SessionStorage";
    /**
     * Memomry storage. Not shared across browser tabs and sessions.
     */ MsalCacheLocation[MsalCacheLocation["MemoryStorage"] = 2] = "MemoryStorage";
})(MsalCacheLocation || (MsalCacheLocation = {}));
var PromptType;
/**
 * Helper for authentication using msal.js
 */ export class MsalHelper extends BaseMsalHelper {
    /**
     * Initialize Msal Helper
     * @param msalConfigSettings The partial configuration settings to construct the MSAL object
     * @param msalHelperOptions Options for the MsalHelper
     * @param [loggers] logger to send logs
     */ static async initialize(msalConfigSettings, msalHelperOptions, loggers) {
        if (MsalHelper._instance != null) {
            throw new Error("MsalHelper instance is not null. Don't initialize more than once.");
        }
        const msalHelper = new MsalHelper(msalConfigSettings, msalHelperOptions, loggers);
        await msalHelper.initializeMsal(msalConfigSettings);
        // create a MSALHelper Instance
        MsalHelper._instance = msalHelper;
        // Handle redirect promise during app initialization
        await msalHelper.handleAuthRedirect();
    }
    async createMsalClient(authConfig) {
        try {
            const msalConfig = this.createMsalConfig(authConfig);
            const matsConfiguration = this.createMatsConfiguration(authConfig);
            return await PublicClientApplication.createPublicClientApplication(msalConfig, matsConfiguration, authConfig.correlationId);
        } catch (error) {
            this.loggers.traceLogger.logTrace(0x1e28f21b /* tag_4kpi1 */ , TraceLevel.Error, this.constructErrorMessage({
                error,
                hasClaims: false
            }));
            throw error;
        }
    }
    /**
     * Create the MATs configuration so we log to MATs telemetry
     * @param msalConfigSettings The partial configuration settings to construct the MSAL object
     */ createMatsConfiguration(msalConfigSettings) {
        const { clientName, clientVersion } = msalConfigSettings;
        if (clientName === "" || clientVersion === "") {
            const errorMessage = "MsalHelper: clientName and clientVersion must be provided in msalConfigSettings";
            this.loggers.traceLogger.logTrace(0x1e2df707 /* tag_4l52h */ , TraceLevel.Error, errorMessage);
            throw new Error(errorMessage);
        }
        return {
            appName: clientName,
            appVersion: clientVersion,
            enableMATS: true
        };
    }
    /**
     * Creates the Msal configuration object
     * @param msalConfigSettings The partial configuration settings to construct the MSAL object
     * @returns Msal Configuration object for the app
     */ createMsalConfig(msalConfigSettings) {
        const { clientId, msalCacheLocation, postLogoutRedirectUrl, correlationId } = msalConfigSettings;
        const postLogoutRedirectUri = postLogoutRedirectUrl.toString();
        const cacheLocation = this.getCacheLocationName(msalCacheLocation);
        // cp1 is the capability for CAE
        const clientCapabilities = [
            "cp1"
        ];
        return {
            auth: {
                clientId,
                postLogoutRedirectUri,
                clientCapabilities
            },
            cache: {
                cacheLocation
            },
            system: {
                loggerOptions: this.createLoggerOptions(correlationId)
            }
        };
    }
    /**
     * Returns the cacheLocation name for the MsalCacheLocation
     * @param cacheLocation The MsalCacheLocation
     */ getCacheLocationName(cacheLocation) {
        switch(cacheLocation){
            case 0:
                return BrowserCacheLocation.LocalStorage;
            case 1:
                return BrowserCacheLocation.SessionStorage;
            case 2:
                return BrowserCacheLocation.MemoryStorage;
            default:
                ErrorUtilities.unreachableCase(cacheLocation, (log)=>{
                    this.loggers.traceLogger.logTrace(0x1e311613 /* tag_4mryt */ , TraceLevel.Warning, log);
                });
                return BrowserCacheLocation.LocalStorage;
        }
    }
    /**
     * Get the instance or throw if it doesn't exist yet
     * @returns Instance of MsalHelper or throws if it hasn't been initialized with initializeMsal
     */ static get instance() {
        if (MsalHelper._instance == null) {
            throw new Error("Cannot retrieve instance as Msal helper is uninitialized");
        }
        return MsalHelper._instance;
    }
    /**
     * Handle redirect response and set the active account.
     */ async handleAuthRedirect() {
        try {
            const response = await this.msalObject.handleRedirectPromise();
            if (response) {
                this.loggers.traceLogger.logTrace(0x1e28774d /* tag_4kh3n */ , TraceLevel.Info, "Received response from handleRedirectPromise.");
                const responseAccount = response.account;
                if (responseAccount) {
                    this.loggers.traceLogger.logTrace(0x1e287723 /* tag_4kh29 */ , TraceLevel.Info, "Received account from handleRedirectPromise.");
                    this.msalObject.setActiveAccount(responseAccount);
                } else {
                    this.loggers.traceLogger.logTrace(0x1e28805e /* tag_4kib4 */ , TraceLevel.Warning, "Received null account for handleRedirectPromise.");
                    throw new NoAccountAuthError(response.requestId);
                }
            }
        } catch (error) {
            this.loggers.traceLogger.logTrace(0x1e28805d /* tag_4kib3 */ , TraceLevel.Error, this.constructErrorMessage({
                error,
                hasClaims: false
            }));
            throw error;
        }
    }
    /**
     * Checks if the logged in account matches the expected account and logs in if not
     * @param params Parameters to login the user
     */ async checkActiveAccountAndLoginIfRequired(params) {
        const { forceLogin, userId, tenantId, loginHint, ensureActiveAccount, claimsChallenge } = params || {};
        const forceLoginOrClaimsChallenge = forceLogin || claimsChallenge;
        // When forceLogin is true or there is a claims challenge, we skip using current cached user and force the user to login
        if (!forceLoginOrClaimsChallenge) {
            if (!ensureActiveAccount && this.hasActiveAccount()) {
                return;
            }
            if (ensureActiveAccount && userId && tenantId && this.validateActiveAccount(userId, tenantId)) {
                // If there is an cached active account then we know the user is already logged-in and no further action is needed.
                return;
            }
        }
        await this.attemptLogin(forceLogin, loginHint, tenantId, userId, claimsChallenge);
    }
    /**
     * Evaluates if there is an active account present
     * @returns True if there is an active account
     */ hasActiveAccount() {
        const account = this.msalObject.getActiveAccount();
        return account !== null;
    }
    /**
     * Validate that an active account exists and/or the specified userId/tenantId
     * @param userId Expected userId
     * @param tenantId Expected tenantId
     * @returns True if specified user is logged in
     */ validateActiveAccount(userId, tenantId) {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            return false;
        }
        if (userId && tenantId && userId === account.localAccountId && tenantId === account.tenantId) {
            return true;
        }
        return false;
    }
    // #region ensure logged in utilities
    /**
     * Ensure user is logged with with extended properties.
     * 1. We check if we are able to log the user in using loginHint if it is specified.
     * 2. If the user has never logged into uno web, we try sso.
     * 3. If userId/tenantId is specified we filter using them. If an account exists, use it to login.
     * 4. If there is more than 1 account show the account selector, otherwise show the login username page.
     * @param forceLogin Skips using current cached user and forces the user to login
     * @param loginHint Param to be used as login_hint. It is usually the user's upn/email.
     * @param tenantId The tenantId to be used for login
     * @param userId The expected userId trying to login, used to validate account after login
     * @param claimsChallenge Claims for getting the token
     */ async attemptLogin(forceLogin, loginHint, tenantId, userId, claimsChallenge) {
        if (forceLogin || claimsChallenge) {
            await this.login({
                promptType: 0,
                tenantId,
                loginHint,
                claimsChallenge
            });
            return;
        }
        const accounts = this.msalObject.getAllAccounts();
        if (loginHint) {
            // Login hint is usually the user's upn. If we have loginHint, we use the loginRedirect
            // with loginHint specified. This ensures login succeeds on Chrome when SSO extension is not enabled.
            await this.login({
                promptType: forceLogin ? 0 : 2,
                tenantId,
                loginHint
            });
        } else if (!accounts || accounts.length === 0) {
            const ssoRequest = {
                scopes: this.getDefaultAppScopes(),
                authority: this.getAuthorityUrl(tenantId),
                redirectUri: this.silentRedirectUri
            };
            const ssoResponse = await this.msalObject.ssoSilent(ssoRequest);
            return this.handleSilentLoginSuccess(ssoResponse);
        } else {
            let account = this.msalObject.getActiveAccount();
            // If we are signed in to a different tenant, sign out and sign in to the correct tenant
            if (account && tenantId && account.tenantId !== tenantId) {
                await this.logout();
                return;
            }
            if (userId || tenantId || loginHint) {
                // Check if an account already exists for the tenantId or userId if it is provided
                account = this.msalObject.getAccount({
                    tenantId: tenantId,
                    localAccountId: userId,
                    loginHint
                });
            }
            if (account) {
                await this.attemptSilentLogin(this.getDefaultAppScopes(), account);
            } else {
                await this.login({
                    promptType: accounts.length > 1 ? 1 : forceLogin ? 0 : 2,
                    tenantId
                });
            }
        }
    }
    /**
     * Set the active account after a successful slient login
     * @param response The response from a silent login
     */ handleSilentLoginSuccess(response) {
        if (response) {
            this.msalObject.setActiveAccount(response.account);
        } else {
            this.loggers.traceLogger.logTrace(0x1e31761b /* tag_4mxy1 */ , TraceLevel.Warning, "Received null response for silent login.");
            const error = new InteractionRequiredAuthError("NoActiveAccountAfterSilentLogin", "No active account found after silent login.");
            throw error;
        }
    }
    // #region ensure logged in utilities
    /**
     * Function which ensures the user is either logged in or initiates the
     * login flow in other cases.
     * @param params ILoginParameters object
     */ async ensureLoggedIn(params) {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
        // Note: If you are calling loginRedirect or acquireTokenRedirect from a page that is not your redirectUri you will need to ensure
        // handleRedirectPromise is called and awaited on both the redirectUri page as well as the page that you initiated the redirect from.
        // This is because the redirectUri page will initiate a redirect back to the page that originally invoked loginRedirect and that page
        // will process the token response.
        try {
            await this.checkActiveAccountAndLoginIfRequired(params);
            if (!this.hasActiveAccount()) {
                throw new InteractionRequiredAuthError("NoActiveAccountAfterLogin", "No active account found after login.");
            }
            const msalActiveAccount = this.msalObject.getActiveAccount();
            const activeAccount = this.getActiveAccount();
            const { tenant_region_scope, tenant_region_sub_scope, xms_tdbr } = msalActiveAccount?.idTokenClaims ?? {};
            this.loggers.traceLogger.logTrace(0x1e2e204d /* tag_4l8bn */ , TraceLevel.Info, `ensureLoggedIn done [isGuest=${activeAccount?.isGuest}][telemetryRegion=${activeAccount?.telemetryRegion}][accountEnvironment=${activeAccount?.accountEnvironment}][tenantRegion=${tenant_region_scope}][tenantRegionSubScope=${tenant_region_sub_scope}][telemetryDataBoundary=${xms_tdbr}]`);
        } catch (error) {
            const hasClaims = params.claimsChallenge ? true : false;
            this.loggers.traceLogger.logTrace(0x1e2e004f /* tag_4l6bp */ , TraceLevel.Warning, this.constructErrorMessage({
                error,
                hasClaims
            }));
            throw error;
        }
    }
    // #endregion
    /**
     * Gets the active account from MSAL Cache
     * @returns { IUserAccount | null } IUserAccount if there is an active account, null otherwise
     */ getActiveAccount() {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            return null;
        }
        let isGuest = false;
        let telemetryRegion = TenantRegion.Row;
        let accountEnvironment = Environment.Prod;
        const { idTokenClaims } = account;
        if (idTokenClaims) {
            if (idTokenClaims[AuthTokenClaims.TelemetryDataBoundary] === TenantRegion.Eu) {
                telemetryRegion = TenantRegion.Eu;
            }
            // User is a guest if the homeAccountId does not contain the tenantId
            // and the idp claim is present in idTokenClaims
            // or claim 'acct' is set to 1.
            if (idTokenClaims.idp) {
                isGuest = !account.homeAccountId.includes(account.tenantId);
            }
            if (idTokenClaims[AuthTokenClaims.Acct] === GuestUserAcctClaimValue) {
                isGuest = true;
            }
            const tenant_region_sub_scope = idTokenClaims.tenant_region_sub_scope;
            if (tenant_region_sub_scope === TenantRegionSubScope.GCC) {
                accountEnvironment = Environment.Gcc;
            } else if (tenant_region_sub_scope === TenantRegionSubScope.DODCON) {
                accountEnvironment = Environment.Gcch;
            } else if (tenant_region_sub_scope === TenantRegionSubScope.DOD) {
                accountEnvironment = Environment.Dod;
            }
        }
        return {
            userId: account.localAccountId,
            tenantId: account.tenantId,
            loginHint: account.username,
            displayName: account.name,
            isGuest,
            telemetryRegion,
            accountEnvironment
        };
    }
    /**
     * Reset the logger after the user has been logged in
     * @param loggers The logger
     * @param appName App name
     * @param clientVersion client version
     * @param correlationId correlationId for MATS
     */ setLogger(loggers, appName, clientVersion, correlationId) {
        this.loggers = loggers;
        const logger = new Logger(this.createLoggerOptions(correlationId), appName, clientVersion);
        this.msalObject.setLogger(logger);
    }
    /**
     * Stop the Msal logger
     */ stopLogger() {
        // Set an empty logger which will not log anything
        const emptyLogger = new Logger({});
        this.msalObject.setLogger(emptyLogger);
    }
    // #region Token Related Function
    /**
     * Try to login in silently. This function will throw an exception on failure.
     * @param scopes Scopes for the endpoint
     * @param [account] Account info object
     * @param [claimsChallenge] Claims for getting the token
     */ async attemptSilentLogin(scopes, account, claimsChallenge) {
        const claims = this.getClaimsParameter(claimsChallenge);
        const authority = this.getAuthorityUrl(account?.tenantId);
        const request = {
            account,
            scopes,
            claims,
            authority,
            redirectUri: this.silentRedirectUri
        };
        try {
            const response = await this.msalObject.acquireTokenSilent(request);
            return this.handleSilentLoginSuccess(response);
        } catch (acquireTokenSilentError) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e29a410 /* tag_4k0qq */ , TraceLevel.Warning, this.constructErrorMessage({
                error: acquireTokenSilentError,
                hasClaims,
                scopes
            }));
            if (acquireTokenSilentError instanceof InteractionRequiredAuthError && this.options.handleInteractionRequiredOnSilentLogin) {
                // During bootstrap user login we retry with redirect as it is more reliable than popup
                await this.login({
                    promptType: 0,
                    account,
                    scopes,
                    claimsChallenge
                });
            } else {
                throw acquireTokenSilentError;
            }
        }
    }
    // #endregion
    /**
     * Login redirect to the app
     * @param params Parameters to login
     */ async login(params) {
        const { promptType, tenantId, loginHint, claimsChallenge, scopes = this.getDefaultAppScopes(), account } = params;
        const prompt = this.getPromptNameFromType(promptType);
        const claims = this.getClaimsParameter(claimsChallenge);
        const loginRequest = {
            scopes,
            prompt,
            loginHint,
            authority: this.getAuthorityUrl(tenantId),
            claims,
            account,
            redirectUri: this.redirectUri
        };
        try {
            // For non-iframe scenarios redirect the user for login
            if (!this.isInIframe) {
                return await this.msalObject.loginRedirect(loginRequest);
            }
            // Otherwise, use a popup based login
            const popupResponse = await this.msalObject.loginPopup(loginRequest);
            const responseAccount = popupResponse?.account;
            if (responseAccount) {
                // Set the account as active
                this.msalObject.setActiveAccount(responseAccount);
            } else {
                throw new NoAccountAuthError(popupResponse.requestId);
            }
        } catch (error) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e2e004c /* tag_4l6bm */ , TraceLevel.Warning, this.constructErrorMessage({
                error,
                hasClaims,
                scopes
            }));
            throw error;
        }
    }
    getPromptNameFromType(prompt) {
        switch(prompt){
            case 0:
                return PromptValue.LOGIN;
            case 1:
                return PromptValue.SELECT_ACCOUNT;
            case 2:
                return PromptValue.NONE;
            default:
                ErrorUtilities.unreachableCase(prompt, (log)=>{
                    this.loggers.traceLogger.logTrace(0x1e311612 /* tag_4mrys */ , TraceLevel.Warning, log);
                });
                return PromptValue.NONE;
        }
    }
    /**
     * Helper function to logout the active account
     * @param postLogoutRedirectUrl Redirect URL after logout
     */ async logout(postLogoutRedirectUrl) {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            return;
        }
        const logoutRequest = {
            account,
            postLogoutRedirectUri: postLogoutRedirectUrl?.toString() ?? undefined
        };
        try {
            await this.msalObject.setActiveAccount(null);
            if (this.isInIframe) {
                await this.msalObject.logoutPopup(logoutRequest);
            } else {
                await this.msalObject.logoutRedirect(logoutRequest);
            }
        } catch (error) {
            this.loggers.traceLogger.logTrace(0x1e2e004b /* tag_4l6bl */ , TraceLevel.Warning, this.constructErrorMessage({
                error,
                hasClaims: false
            }));
            throw error;
        }
    }
    /**
     * Register a function to be called when an interaction required error occurs
     * @param callback The function to be called when an interaction required error occurs
     */ registerInteractionRequiredErrorHandler(callback) {
        this.msalObject.addEventCallback((message)=>{
            const { eventType, error, interactionType } = message;
            const isInteractionRequiredTypeEvent = eventType === EventType.LOGIN_FAILURE || eventType === EventType.ACQUIRE_TOKEN_FAILURE || eventType === EventType.SSO_SILENT_FAILURE;
            if (!isInteractionRequiredTypeEvent) {
                return;
            }
            const isGetAccessTokenSilentFailure = eventType === EventType.ACQUIRE_TOKEN_FAILURE && interactionType === InteractionType.Silent;
            if (this.options.handleInteractionRequiredOnSilentLogin && isGetAccessTokenSilentFailure) {
                // if enableSilentSignInPopup is set then the acquire token failure is handled during getAccessToken
                return;
            }
            if (this.options.handleInteractionRequiredOnSilentLogin && eventType === EventType.ACQUIRE_TOKEN_FAILURE && interactionType === InteractionType.Popup && error instanceof BrowserAuthError && error.errorCode === BrowserAuthErrorCodes.popupWindowError) {
                const status = EventMessageUtils.getInteractionStatusFromEvent(message);
                if (status === null || status === InteractionStatus.None) {
                    this.loggers.traceLogger.logTrace(0x1e29a387 /* tag_4k0oh */ , TraceLevel.Warning, `[Msal] popup blocked. ${this.constructErrorMessage({
                        error,
                        hasClaims: false
                    })}`);
                    return;
                }
            }
            if (error instanceof InteractionRequiredAuthError) {
                const status = EventMessageUtils.getInteractionStatusFromEvent(message);
                if (status === null || status === InteractionStatus.None) {
                    const claimsChallenge = error?.claims;
                    this.loggers.traceLogger.logTrace(0x1e307203 /* tag_4mhid */ , TraceLevel.Info, `[Msal] User interaction required to login. ${this.constructErrorMessage({
                        error,
                        hasClaims: !!claimsChallenge
                    })}`);
                    callback(/* claims */ claimsChallenge, /* isPopupBlockedError*/ false);
                }
            }
        });
    }
    /**
     * Register a function to be called when browser popup blocked error occurs during acquireTokenPopup
     * @param callback The function to be called when browser popup blocked error occurs
     */ registerPopupBlockedErrorHandler(callback) {
        if (callback === null || callback === undefined) {
            return;
        }
        this.loggers.traceLogger.logTrace(0x1e28b607 /* tag_4klyh */ , TraceLevel.Info, `[Msal] Registering popup blocked error handler.`);
        this.handlePopupBlockedErrorCallback = callback;
    }
    async prefetchAndEvaluateConditionalAccess(prefetchScopes) {
        // We will fetch main scopes and evaluate conditional access policies with login redirect
        // We can avoid acquire token with popup and use redirect since we are loading the app and popup might be blocked by browsers by default
        for (const scope of prefetchScopes){
            try {
                await this.attemptSilentTokenFetchWithRetry(scope);
            } catch (error) {
                // This is likely a NOOP since if actually fails it will attempt to login with redirect navigation
                this.loggers.traceLogger.logTrace(0x1e29820e /* tag_4kyio */ , TraceLevel.Warning, `Prefetch failure: ${this.constructErrorMessage({
                    error,
                    hasClaims: !!error.claims,
                    scopes: [
                        scope
                    ]
                })}`);
            }
        }
    }
    /**
     * Attempts to silently fetch token for given scope and login with redirect.
     * This method retries on retriable msal errors
     * @param scope the scope to attempt login
     */ async attemptSilentTokenFetchWithRetry(scope) {
        const scopes = [
            scope
        ];
        const maxTokenFetchRetryCount = 3;
        await retry(async ()=>await this.attemptSilentLogin(scopes, this.msalObject.getActiveAccount() ?? undefined), maxTokenFetchRetryCount - 1, /* interval */ 0, this.canRetry);
    }
    /**
     * Checks if can retry given msal auth error
     * @param error the error to retry
     * @returns Returns TRUE if can retry
     */ canRetry(error) {
        const errorCode = error?.errorCode;
        return errorCode === BrowserAuthErrorCodes.monitorWindowTimeout || errorCode === "temporarily_unavailable" || errorCode === "endpoints_resolution_error";
    }
    /**
     * Login scopes for app
     * @returns Default app scopes for login
     */ getDefaultAppScopes() {
        return [
            "openid",
            "profile",
            `${this.clientId}/.default`
        ];
    }
    /**
     * Handle the popup blocked error
     * @param scopes Scope for the resource
     * @param claimsChallenge claims challenge parameter
     */ async handlePopupBlockedError(scopes, claimsChallenge) {
        if (!this.handlePopupBlockedErrorCallback) {
            return;
        }
        this.handlePopupBlockedErrorCallback(scopes, async ()=>await this.acquireTokenPopupHelper(scopes, this.userInitiatedAcquireTokenPopup.bind(this), claimsChallenge));
    }
    /**
     * User initiated acquire the token for the set of specified scopes
     * @param [claims] Claims for getting the token
     */ async userInitiatedAcquireTokenPopup(scopes, claimsChallenge) {
        const prompt = this.getPromptNameFromType(0);
        const claims = this.getClaimsParameter(claimsChallenge);
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            throw new NoAccountAuthError();
        }
        const tenantId = account.tenantId;
        const acquireTokenRequest = {
            scopes,
            prompt,
            authority: this.getAuthorityUrl(tenantId),
            claims,
            account,
            redirectUri: this.redirectUri
        };
        try {
            await this.msalObject.acquireTokenRedirect(acquireTokenRequest);
        } catch (error) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e28b419 /* tag_4klqz */ , TraceLevel.Warning, this.constructErrorMessage({
                error,
                hasClaims,
                scopes
            }));
            throw error;
        }
    }
    constructor(authConfig, options, loggers){
        super(authConfig, loggers, options), this.handlePopupBlockedErrorCallback = null;
        this.clientId = authConfig.clientId;
        this.isInIframe = self !== top;
    }
}
MsalHelper._instance = null;
