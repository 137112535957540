// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
import { FailureAction } from "../FailureAction";
export class FetchTeamsTasksTabsAction extends TypedAction(ActionType.FetchTeamsTasksTabs) {
}
export class FetchTeamsTasksTabsSucceededAction extends TypedAction(ActionType.FetchTeamsTasksTabsSucceeded) {
    constructor(tabs){
        super(), this.tabs = tabs;
    }
}
export class FetchTeamsTasksTabsFailedAction extends FailureAction {
    constructor(response, textStatus, errorThrown){
        super(ActionType.FetchTeamsTasksTabsFailed, response, textStatus, errorThrown);
    }
}
