// Models
import { Form } from "../planner/form/Form";
import { SupportedReferenceTypes } from "./ITaskAttachmentType";
import { TaskAttachment } from "./TaskAttachment";
import { TaskAttachmentType } from "./TaskAttachmentType";
/**
 * Configuration and building of TaskAttachment objects
 */ export class TaskAttachmentBuilder {
    /**
     * Build out the TaskAttachment
     */ build() {
        return new TaskAttachment(this);
    }
    /**
     * Create the task attachment type from drive item
     * @param driveUrl The group's SharePoint Url
     * @param driveItem The drive item to create the task attachment type from
     */ fromDriveItem(driveUrl, driveItem) {
        this.url = driveUrl + driveItem.itemPath.replace("/drive/root:", "") + "/" + encodeURIComponent(driveItem.name) + "?web=1";
        this.alias = driveItem.name.substring(0, TaskAttachment.AliasMaxLength);
        this.taskAttachmentType = TaskAttachmentType.builder.fromDriveItem(this.url, driveItem).build();
        return this;
    }
    /**
     * Create the task attachment type from from
     * @param form The form to create the task attachment type from
     */ fromForm(form, urlType) {
        this.url = Form.getFormUrl(form.urlFormId, urlType, form);
        this.alias = form.displayName;
        this.taskAttachmentType = TaskAttachmentType.builder.withPropertyBag({
            taskAttachmentType: SupportedReferenceTypes.Form
        }).build();
        return this;
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.alias = propertyBag.alias || this.alias || "";
        this.taskAttachmentType = propertyBag.taskAttachmentType || this.taskAttachmentType || TaskAttachmentType.builder.withPropertyBag({
            url: propertyBag.url
        }).build();
        this.url = propertyBag.url || this.url || "";
        this.isShownInTaskCard = !!propertyBag.isShownInTaskCard;
        return this;
    }
    constructor(){
        this.isShownInTaskCard = false;
    }
}
