// Constants
import { ClientAppId, ClientFlavor, Environment, TeamsAppId } from "@ms/uno-constants/lib/local/AppConstants";
import { TeamsRingId } from "@ms/uno-constants/lib/local/TeamsConstants";
import { TelemetryPlatform } from "@ms/uno-constants/lib/local/TelemetryConstants";
import { LoggingStrings } from "@ms/uno-constants/lib/local/LoggingConstants";
import { MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
import { TeamsTheme } from "@ms/uno-models/lib/local/theme/TeamsTheme";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { isV1UnoEntityId } from "@ms/uno-routing";
// teams-js
import * as microsoftTeams from "@microsoft/teams-js-uno";
import { HostClientType } from "@microsoft/teams-js-uno";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { generateLogStringFromError } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
// Utilities
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
import { generateDeepLinkFromRoute, generateRouteFromDeepLink } from "@ms/uno-routing";
import { safelyOpenExternalUrl } from "@ms/uno-utilities/lib/local/BrowserUtilities";
import { stringToEnum } from "@ms/uno-utilities/lib/local/EnumUtilities";
import JwtDecode from "jwt-decode";
import { generateRouteFromLegacyDeepLink, isChannelTab } from "./utils/LegacyTeamsDeepLinkUtils";
import { AppPageIdFlavor, isTeamsAppEntityId, tryParseAppRoute, tryParseMessageRoute, tryParseTabRoute } from "./utils/TeamsRouteUtils";
import { NestedAppAuthHelper } from "@ms/uno-utilities/lib/local/auth/msal/NestedAppAuthHelper";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
/**
 * The prefix for PPE Channel Tab PageId
 * Here's an example:
 * teamstasksppe.channelTab_19:PS0P_nk0KOFz7EyHvY-dHkflczHoc3UcqB-vy2P9bK81@thread.tacv2_plan:9ZVwTwC5Z0u7ucV7_utvVJUAE6PQ
 */ const PpeChannelTabPageIdPrefix = "teamstasksppe.channelTab";
/**
 * To generate Teams DeepLink to our UNO app, we need AppId and PageId appropriate to the app flavor
 *
 * For Tasks PPE app, our deep link would start with:
 * https://teams.microsoft.com/l/entity/9c0fcd8d-7cca-466f-b549-9254dac9c036/teamstasksppe.personalApp.mytasks?
 *
 * For Tasks Prod app, our deep link would start with:
 * https://teams.microsoft.com/l/entity/com.microsoft.teamspace.tab.planner/mytasks
 *
 * Teams SDK provides the PageId (appropriate to given app flavor), and we use this to determine the correct AppId
 */ const pageIdToAppIdMap = {
    [AppPageIdFlavor.Dev]: TeamsAppId.Dev,
    [AppPageIdFlavor.PPE]: TeamsAppId.PPE,
    [AppPageIdFlavor.Prod]: TeamsAppId.Prod
};
/**
 * Class implementing IUnoHostAdaptor interface to provide interaction with Teams platform
 */ export class TeamsAdaptor {
    get teamsRootUrl() {
        if (this._teamsRootUrl === undefined) {
            this._teamsRootUrl = this.computeTeamsRootUrl();
        }
        return this._teamsRootUrl;
    }
    /**
     * Sets the loggers to be used by the adaptor
     */ setLoggers(loggers) {
        this.loggers = loggers;
        this.getContextFromTeamsSDK().then((teamsContext)=>{
            if (this.ringId == null) {
                this.loggers?.traceLogger.logTrace(0x1e3ce7d2 /* tag_4po5s */ , TraceLevel.Warning, `initFromTeamsContext - Invalid ringId. [RingId=${teamsContext.app?.host?.ringId}]`);
            }
            this.loggers?.traceLogger.logTrace(0x1e3c0547 /* tag_4pavh */ , TraceLevel.Info, `locale: ${teamsContext.app.locale} osLocaleInfo.regionalFormat: ${teamsContext.app.osLocaleInfo?.regionalFormat ?? "null"} osLocaleInfo.longDate: ${teamsContext.app.osLocaleInfo?.longDate ?? "null"}`);
        });
    }
    /**
     * Set up Nested App Auth
     * @param loggers
     */ async setupNestedAppAuth(loggers) {
        if (!microsoftTeams.nestedAppAuth.isNAAChannelRecommended()) {
            this.loggers?.traceLogger.logTrace(0x1e291651 /* tag_4krzr */ , TraceLevel.Error, `Nested App Auth is not supported.`);
            this.useNestAppAuth = false;
            return;
        }
        const environment = this.configProvider().sessionMetaData.environment;
        const clientName = LoggingStrings.ProductName;
        // Initialize Nested Ap Auth
        try {
            const setupCorrelationId = generateGuid();
            loggers.traceLogger.logTrace(0x1e28e751 /* tag_4ko3r */ , TraceLevel.Info, `setupNestedAppAuth. [CorrelationId=${setupCorrelationId}]`);
            const msalConfigSettings = {
                clientId: ClientAppId.MicrosoftPlannerClient,
                environment,
                clientName,
                correlationId: setupCorrelationId,
                tenantId: this.tenantId || ""
            };
            this.nestedAppAuthHelper = await new NestedAppAuthHelper(msalConfigSettings, loggers);
            this.nestedAppAuthHelper.initialize(msalConfigSettings);
            this.useNestAppAuth = true;
        } catch (error) {
            this.loggers?.traceLogger.logTrace(0x1e29758a /* tag_4kxwk */ , TraceLevel.Error, `Failed to set up Nest App Auth: [ErrorMessage=${ErrorUtilities.getMessage(error)}]`);
            this.useNestAppAuth = false;
        }
    }
    /**
     * Initializes the Teams SDK
     */ async initializeSdk() {
        await microsoftTeams.app.initialize();
        // Get the timestamp of the user's click on the app to include in the extra data for the InitializedTeamsSDK marker.
        const teamsContext = await this.getTeamsContext();
        const userClickTime = teamsContext.app.userClickTime;
        const markExtraData = userClickTime ? {
            userClickTime: String(userClickTime)
        } : undefined;
        // Mark the Teams SDK initialization
        this.performanceMarker.mark(MarkerTag.InitializedTeamsSDK, markExtraData);
        await this.initFromTeamsContext();
    }
    /**
     * Checks if the Teams SDK is initialized
     */ isInitialized() {
        return microsoftTeams.app.isInitialized();
    }
    async getInitialRoute() {
        const teamsContext = await this.getTeamsContext();
        // We'll try to parse the tab route from PageId, SubPageId and FrameContext
        const tabRoute = tryParseTabRoute(teamsContext.page.id, teamsContext.page.subPageId, teamsContext.page.frameContext, this.loggers);
        if (tabRoute) {
            return tabRoute;
        }
        const newPath = teamsContext.page.subPageId;
        return generateRouteFromDeepLink(newPath, this.loggers, generateRouteFromLegacyDeepLink);
    }
    generateDeepLink(route) {
        // In case of create Task from message, we don't have a page id info.
        // App id is calculated based on the page id, so we need to calculate both pageId and appId here.
        let pageId = this.pageId;
        if (!pageId) {
            switch(this.configProvider().sessionMetaData.environment){
                case Environment.Ppe:
                    pageId = AppPageIdFlavor.PPE;
                    break;
                case Environment.Prod:
                    pageId = AppPageIdFlavor.Prod;
                    break;
                default:
                    // We are going to have different Environments (e.g., GCC-High and DoD) so have to handle them also.
                    ErrorUtilities.unreachableCase(this.configProvider().sessionMetaData.environment, (log)=>{
                        this.loggers?.traceLogger.logTrace(0x1e3cc5c2 /* tag_4pmxc */ , TraceLevel.Error, log);
                    });
                    pageId = AppPageIdFlavor.Prod;
            }
        }
        const channelTab = this.isChannelTab ?? (isChannelTab(pageId) || isV1UnoEntityId(pageId));
        const appId = isV1UnoEntityId(pageId) ? this.getTeamsAppId(channelTab, pageId) : this.appId ?? this.getTeamsAppIdDeprecated(channelTab, pageId);
        if (!pageId || !appId || !this.tenantId || !this.teamsRootUrl) {
            this.loggers?.traceLogger.logTrace(0x1e3e1886 /* tag_4p78g */ , TraceLevel.Error, `generateDeepLink - Missing required deep link info`);
            return null;
        }
        const deepLink = generateDeepLinkFromRoute(route, this.loggers);
        if (!deepLink) {
            this.loggers?.traceLogger.logTrace(0x1e3e1885 /* tag_4p78f */ , TraceLevel.Error, `generateDeepLink - Unable to generate DeepLink from Route`);
            return null;
        }
        let contextObject = {
            subEntityId: deepLink
        };
        if (channelTab) {
            if (!this.channelId) {
                this.loggers?.traceLogger.logTrace(0x1e3d33ce /* tag_4ptpo */ , TraceLevel.Error, `generateDeepLink - Missing channelId for channel tab`);
                return null;
            }
            contextObject = {
                ...contextObject,
                channelId: this.channelId
            };
        }
        const teamAppLinkUrl = `${this.teamsRootUrl}/l/entity`;
        const webUrl = `https://tasks.teams.microsoft.com/teamsui/personalApp/alltasklists`;
        const context = JSON.stringify(contextObject);
        const fullDeepLinkUrl = `${teamAppLinkUrl}/${appId}/${pageId}` + `?tenantId=${this.tenantId}` + `&webUrl=${encodeURIComponent(webUrl)}` + `&context=${encodeURIComponent(context)}`;
        return fullDeepLinkUrl;
    }
    async openLinkInHost(relativeUrl) {
        if (!microsoftTeams.app.isInitialized()) {
            this.loggers?.traceLogger.logTrace(0x1e3cd35b /* tag_4pnn1 */ , TraceLevel.Error, `openLinkInHost- Teams SDK not initialized`);
            throw new Error("Teams SDK not initialized");
        }
        if (!relativeUrl) {
            this.loggers?.traceLogger.logTrace(0x1e3ce404 /* tag_4poqe */ , TraceLevel.Error, `openLinkInHost - Missing relativeUrl`);
            throw new Error("Missing relativeUrl");
        }
        if (!this.isRelativeUrl(relativeUrl)) {
            this.loggers?.traceLogger.logTrace(0x1e3cc21b /* tag_4pmi1 */ , TraceLevel.Error, `openLinkInHost - Invalid URL must be relative`);
            throw new Error("Invalid URL must be relative");
        }
        try {
            const deepLink = new URL(relativeUrl, `${this.teamsRootUrl}`);
            await microsoftTeams.app.openLink(deepLink.toString());
        } catch (e) {
            this.loggers?.traceLogger.logTrace(0x1e3ce403 /* tag_4poqd */ , TraceLevel.Error, `openLinkInHost - ${generateLogStringFromError(e)}`);
            throw new Error(generateLogStringFromError(e));
        }
    }
    async openExternalAppLinkInHost(externalUrl) {
        if (!microsoftTeams.app.isInitialized()) {
            this.loggers?.traceLogger.logTrace(0x1e30a208 /* tag_4mkii */ , TraceLevel.Error, `openExternalAppLinkInHost- Teams SDK not initialized`);
            throw new Error("Teams SDK not initialized");
        }
        if (!externalUrl) {
            this.loggers?.traceLogger.logTrace(0x1e30a207 /* tag_4mkih */ , TraceLevel.Error, `openExternalAppLinkInHost - Missing externalUrl`);
            throw new Error("Missing externalUrl");
        }
        try {
            await microsoftTeams.app.openLink(externalUrl);
        } catch (e) {
            this.loggers?.traceLogger.logTrace(0x1e30a206 /* tag_4mkig */ , TraceLevel.Error, `openExternalAppLinkInHost - ${generateLogStringFromError(e)}`);
            throw new Error(generateLogStringFromError(e));
        }
    }
    openAttachmentInHost(attachment, route) {
        if (!this.configProvider().flights.EnableOpenFilePreview || !attachment.hasKnownExtension()) {
            // The should handle if the attachment is not previewable, i.e. a link and not a file
            safelyOpenExternalUrl(attachment.url, this.loggers?.traceLogger, this.configProvider().flights.EnableExternalUrlRegexCheck);
            return;
        }
        try {
            const fileExtension = attachment.getFileExtension();
            if (fileExtension == null) {
                // Couldn't parse the file type. Fall back to default behavior
                safelyOpenExternalUrl(attachment.url, this.loggers?.traceLogger, this.configProvider().flights.EnableExternalUrlRegexCheck);
                return;
            }
            // Build metadata
            const decodedUrl = decodeURIComponent(attachment.url);
            let subEntityId = undefined;
            if (route != null) {
                subEntityId = generateDeepLinkFromRoute(route, this.loggers) ?? undefined;
            }
            // Call open in teams preview
            microsoftTeams.openFilePreview({
                title: attachment.alias,
                type: fileExtension,
                objectUrl: decodedUrl,
                subEntityId: subEntityId,
                entityId: ""
            });
        } catch (error) {
            this.loggers?.traceLogger.logTrace(0x1e2de095 /* tag_4l4cv */ , TraceLevel.Warning, `Teams open file preview - Failed ${generateLogStringFromError(error)}`);
            // Fallback to the regular behavior
            safelyOpenExternalUrl(attachment.url, this.loggers?.traceLogger, this.configProvider().flights.EnableExternalUrlRegexCheck);
        }
    }
    async getContext() {
        const teamsContext = await this.getContextFromTeamsSDK();
        const context = {
            locale: teamsContext.app.locale,
            theme: this.mapToUnoTheme(teamsContext.app.theme),
            sessionId: teamsContext.app.host.sessionId,
            userId: teamsContext.user?.id ?? "",
            displayName: teamsContext.user?.displayName ?? "",
            userUpn: teamsContext.user?.userPrincipalName ?? "",
            tenantId: teamsContext.user?.tenant?.id ?? "",
            ringId: this.normalizeRingId(teamsContext.app.host.ringId),
            platform: this.getTelemetryPlatform(teamsContext.app.host.clientType),
            isGuestUser: teamsContext.team?.userRole === microsoftTeams.UserTeamRole.Guest,
            isTenantAdmin: teamsContext.team?.userRole === microsoftTeams.UserTeamRole.Admin,
            userTeamsLicenseType: teamsContext.user?.tenant?.teamsSku,
            teamId: teamsContext.team?.internalId,
            teamName: teamsContext.team?.displayName,
            groupId: teamsContext.team?.groupId,
            channelId: teamsContext.channel?.id,
            channelName: teamsContext.channel?.displayName,
            chatId: teamsContext.chat?.id,
            messageId: teamsContext.app.parentMessageId,
            tabId: teamsContext.channel?.id ? teamsContext.page?.id : undefined
        };
        return context;
    }
    async getAccessToken(resource, claims) {
        if (this.useNestAppAuth && this.configProvider().flights.EnableNestedAppAuth) {
            try {
                return await this.nestedAppAuthHelper.getAccessToken([
                    `${resource}/.default`
                ], claims);
            } catch (error) {
                this.loggers?.traceLogger.logTrace(0x1e298198 /* tag_4kygy */ , TraceLevel.Warning, `Error fetching the access token using NAA: [ErrorMessage=${ErrorUtilities.getMessage(error)}]`);
                return Promise.reject(error);
            }
        } else {
            let authTokenRequestParams = {
                resources: [
                    resource
                ]
            };
            if (claims) {
                authTokenRequestParams = {
                    ...authTokenRequestParams,
                    claims: [
                        claims
                    ]
                };
            }
            const token = await microsoftTeams.authentication.getAuthToken(authTokenRequestParams);
            let parsedTokenInfo = null;
            try {
                parsedTokenInfo = JwtDecode(token, {
                    header: false
                });
            } catch (err) {
                this.loggers?.traceLogger.logTrace(0x1e3c42de /* tag_4pel4 */ , TraceLevel.Error, `getAccessToken - Unable to jwt parse access token.[Resource=${resource}][Claims=${claims}]`);
            }
            const accessToken = {
                accessToken: token,
                expiry: parsedTokenInfo?.exp ? new Date(parsedTokenInfo.exp * 1000) : null
            };
            return accessToken;
        }
    }
    async getTeamsContext() {
        return await this.getContextFromTeamsSDK();
    }
    registerThemeChangeHandler(themeChangeHandler) {
        microsoftTeams.app.registerOnThemeChangeHandler((theme)=>{
            themeChangeHandler(this.mapToUnoTheme(theme));
        });
    }
    notifyLoaded() {
        this.loggers?.traceLogger.logTrace(0x1e3866a0 /* tag_4og06 */ , TraceLevel.Info, `Notify Teams app loaded.`);
        return microsoftTeams.app.notifyAppLoaded();
    }
    async setConfig(config) {
        await microsoftTeams.pages.config.setConfig({
            entityId: config.entityId,
            suggestedDisplayName: config.displayName,
            contentUrl: config.contentUrl,
            removeUrl: config.removeUrl,
            websiteUrl: config.websiteUrl
        });
    }
    registerOnSaveHandler(handler) {
        microsoftTeams.pages.config.registerOnSaveHandler(handler);
    }
    registerOnRemoveHandler(handler) {
        microsoftTeams.pages.config.registerOnRemoveHandler(handler);
    }
    canSetDocumentTitle() {
        return false;
    }
    usingNestedAppAuth() {
        return this.useNestAppAuth;
    }
    setValidityState(isValid) {
        microsoftTeams.pages.config.setValidityState(isValid);
    }
    notifyRenderSuccess() {
        this.loggers?.traceLogger.logTrace(0x1e38669f /* tag_4og05 */ , TraceLevel.Info, `Notify Teams render success.`);
        microsoftTeams.app.notifySuccess();
    }
    notifyRenderFailure(failure) {
        this.loggers?.traceLogger.logTrace(0x1e38669e /* tag_4og04 */ , TraceLevel.Error, `Notify Teams render failure. [Reason=${failure.reason}]`);
        return microsoftTeams.app.notifyFailure(failure);
    }
    /** Register handlers like onLoad and beforeUnload handlers that are required to enable caching in MOS apps. Callback invoked if there's new route from onLoad */ setupHostCaching(onLoad, onUnload) {
        if (this.configProvider().settings.disableAppCaching) {
            return;
        }
        microsoftTeams.teamsCore.registerOnLoadHandler(async (loadContext)=>{
            const teamsContext = await this.getTeamsContext();
            const userClickTime = teamsContext.app.userClickTime;
            const markExtraData = userClickTime ? {
                userClickTime: String(userClickTime)
            } : undefined;
            this.performanceMarker.mark(MarkerTag.CachedLoadStart, markExtraData);
            // With Teams App Caching, it's possible to reuse the same webview across different EntityIds (e.g., App, Tab1, Tab2, etc.)
            // As such, the TeamsContext will change, and we should reinit some state from the new TeamsContext
            // Side Note: We aren't using the teams context init state here, so don't await here as we don't want to delay invoking notifySuccess
            this.initFromTeamsContext().catch(()=>{
                this.loggers?.traceLogger.logTrace(0x1e3d1861 /* tag_4pr77 */ , TraceLevel.Warning, `setupHostCaching - Error initializing from TeamsContext on cached load.`);
            });
            // If we can't determine the cachedRouteInfo (more specifically, the ClientFlavor), we'll fall back ClientFavor.App
            // so that the user at least has a fully usable app instead of the last cached view
            let contentUrl;
            try {
                contentUrl = new URL(loadContext.contentUrl);
            } catch (error) {
                this.loggers?.traceLogger.logTrace(0x1e2922ce /* tag_4kslo */ , TraceLevel.Warning, `setupHostCaching- Invalid Content Url`);
            }
            const cachedRouteInfo = this.getCachedRouteInfo({
                ...loadContext,
                contentUrl
            }) ?? {
                route: null,
                clientFlavor: ClientFlavor.App
            };
            await onLoad(cachedRouteInfo.clientFlavor, cachedRouteInfo.route, loadContext.contentUrl);
        });
        /**
         * This handler is called before the app is being unloaded.
         * All cleanup activities can be performed in this block.
         */ microsoftTeams.teamsCore.registerBeforeUnloadHandler((readyToUnload)=>{
            onUnload(readyToUnload);
            return true;
        });
    }
    static resizeDialog(height, width) {
        microsoftTeams.dialog.update.resize({
            height,
            width
        });
    }
    static dismissDialog() {
        microsoftTeams.dialog.url.submit();
    }
    /** Authenticate using Teams sdk's authenticate API
     * @param url - The URL to authenticate against
     * @param onSuccess - The callback to invoke on successful authentication
     * @param onFailure - The callback to invoke on failed authentication
     */ authenticate(url, onSuccess, onFailure) {
        microsoftTeams.authentication.authenticate({
            url: url,
            width: 600,
            height: 600,
            successCallback: onSuccess,
            failureCallback: onFailure
        });
    }
    /**
     * Sets the configProvider for the adaptor
     */ setConfigProvider(configProvider) {
        this.configProvider = configProvider;
        this.homeRingId = this.configProvider().sessionMetaData?.homeRingId;
        if (this.normalizeRingId(this.homeRingId) == null) {
            this.loggers?.traceLogger.logTrace(0x1e3ce7d3 /* tag_4po5t */ , TraceLevel.Warning, `setConfigProvider - Invalid session homeRingId. [HomeRingId=${this.homeRingId}]`);
        }
    }
    /**
    /**
     * Initializes state from current TeamsContext (which can change when load from App Cache)
     * e.g., ChannelTab -> App, or ChannelTab1 -> ChannelTab2
     */ async initFromTeamsContext() {
        const teamsContext = await this.getContextFromTeamsSDK();
        // normalize ring as it can be empty, undefined or garbage sometimes
        this.ringId = this.normalizeRingId(teamsContext.app?.host?.ringId);
        this.pageId = teamsContext.page.id;
        this.isChannelTab = isChannelTab(this.pageId) || isV1UnoEntityId(this.pageId);
        this.channelId = teamsContext.channel?.id ?? null;
        this.appId = this.getTeamsAppIdDeprecated(this.isChannelTab, this.pageId); // Remove when graduating flight EnableUnoTeamsTabExperience
        this.tenantId = teamsContext.user?.tenant?.id ?? null;
    }
    /**
     * Ensures that app config is valid for the current Teams context.
     * Checks whether ringId in sessionMetaData matches ringId from teamsContext
     * @returns validation error message. null if valid.
     */ validateRingId() {
        // Ensure that the ringId in sessionMetaData matches what teamsContext gives us
        const { ringId: sessionRingId } = this.configProvider().sessionMetaData;
        if (typeof sessionRingId === "string" && sessionRingId.length > 0 && this.ringId && // Ensure both teamsContext ringId and session's ringId are valid/non-empty
        this.ringId !== sessionRingId) {
            // The teamsContext ringId does not match the session's ringId
            // This could mean that the current session contains feature flights not meant for this user (feature leak)
            return `Teams client ringId does not match session ringId [ClientRingId=${this.ringId}][SessionRingId=${sessionRingId}][SessionHomeRingId=${this.homeRingId ?? ""}]`;
        }
        return null;
    }
    /**
     * Ensures the ringId value is one of accepted/valid values
     * @param ringId
     */ normalizeRingId(ringId) {
        if (typeof ringId !== "string" || ringId.trim().length === 0) {
            return null;
        }
        const normalRingId = stringToEnum(ringId.trim().toLowerCase(), TeamsRingId);
        return normalRingId;
    }
    /**
     * Returns the root URL of the Teams app (typically https://teams.microsoft.com)
     */ computeTeamsRootUrl() {
        const environment = this.configProvider().sessionMetaData.environment;
        switch(environment){
            case Environment.Ppe:
            case Environment.Prod:
            case Environment.Gcc:
                return "https://teams.microsoft.com";
            case Environment.Gcch:
            case Environment.Dod:
            case Environment.Agc:
                // TODO [ADO#8848162] - Need to confirm the URLs to use for these environments
                this.loggers?.traceLogger.logTrace(0x1e3d0342 /* tag_4pqnc */ , TraceLevel.Error, `computeTeamsRootUrl Unsupported environment. [Environment = ${environment}]`);
                return null;
            default:
                this.loggers?.traceLogger.logTrace(0x1e3d0341 /* tag_4pqnb */ , TraceLevel.Error, `computeTeamsRootUrl Unknown environment. [Environment = ${environment}]`);
                return null;
        }
    }
    /**
     * Maps Teams theme to Uno theme
     * @param theme Teams theme, possible values are "default", "dark", "contrast"
     * @returns Uno theme
     */ mapToUnoTheme(theme) {
        switch(theme){
            case TeamsTheme.Dark:
                return UnoTheme.TeamsDark;
            case TeamsTheme.Contrast:
                return UnoTheme.TeamsContrast;
            case TeamsTheme.Default:
            default:
                this.loggers?.traceLogger.logTrace(0x1e41115b /* tag_4qrf1 */ , TraceLevel.Info, `Unsupported theme. [Theme=${theme}]]`);
                return UnoTheme.TeamsLight;
        }
    }
    /**
     * Returns the Teams AppId based on the specified PageId (and if it's a ChannelTab or not)
     * @deprecated Use getTeamsAppId instead. Graduate with flight EnableUnoTeamsTabExperience
     */ getTeamsAppIdDeprecated(isChannelTab, pageId) {
        if (!pageId) {
            return null;
        }
        if (isChannelTab) {
            if (pageId.startsWith(PpeChannelTabPageIdPrefix)) {
                return TeamsAppId.PPE;
            } else {
                return TeamsAppId.Prod;
            }
        }
        return pageIdToAppIdMap[pageId] ?? null;
    }
    /**
     * Returns the Teams AppId based on the environment
     */ getTeamsAppId(isChannelTab, pageId) {
        if (!pageId) {
            return null;
        }
        if (isChannelTab) {
            switch(this.configProvider().sessionMetaData.environment){
                case Environment.Ppe:
                    return TeamsAppId.PPE;
                case Environment.Prod:
                    return TeamsAppId.Prod;
                default:
                    return AppPageIdFlavor.Prod;
            }
        }
        return pageIdToAppIdMap[pageId] ?? null;
    }
    /**
     * Returns the context from the Teams SDK
     */ async getContextFromTeamsSDK() {
        if (!microsoftTeams.app.isInitialized()) {
            this.loggers?.traceLogger.logTrace(0x1e48700b /* tag_4shal */ , TraceLevel.Error, `Teams SDK failed to initialize.`);
        }
        const teamsContext = await microsoftTeams.app.getContext();
        return teamsContext;
    }
    /**
     * Parses the route from the teams loadContext in the cached app scenario
     * @param loadContext The load context from the Teams SDK
     */ getCachedRouteInfo(loadContext) {
        try {
            const parsedContentUrl = this.parseContentUrl(loadContext.contentUrl.toString());
            // There are different EntityIds (think of it as different "app type"). We have Tab, Message, and App
            const tabRoute = tryParseTabRoute(loadContext.entityId, parsedContentUrl?.subEntityId, undefined, this.loggers);
            if (tabRoute) {
                return {
                    route: tabRoute,
                    clientFlavor: ClientFlavor.Tab
                };
            }
            if (parsedContentUrl == null) {
                this.loggers?.traceLogger.logTrace(0x1e3d3303 /* tag_4ptmd */ , TraceLevel.Info, `getCachedRouteInfo. No parsedContentUrl - Just return]`);
                return null;
            }
            const messageRoute = tryParseMessageRoute(parsedContentUrl.entityId);
            if (messageRoute) {
                return {
                    route: messageRoute,
                    clientFlavor: ClientFlavor.TaskFromMessage
                };
            }
            const appRoute = tryParseAppRoute(parsedContentUrl.entityId, parsedContentUrl.subEntityId, this.loggers);
            if (appRoute) {
                return {
                    route: appRoute,
                    clientFlavor: ClientFlavor.App
                };
            }
            // Note: It's possible we are in App scenario (and there's no route to parse), in which case, return null route
            //       (signals no route override, use existing/default route)
            //       Example: User opens UNO Teams App and navigates around. Then user goes to Teams Chat. Then user quickly
            //       comes back to UNO Teams App. There is no subEntityId to parse route, and we want user be where they left off.
            if (isTeamsAppEntityId(parsedContentUrl.entityId)) {
                return {
                    route: null,
                    clientFlavor: ClientFlavor.App
                };
            }
        } catch (e) {
            this.loggers?.traceLogger.logTrace(0x1e3d464d /* tag_4puzn */ , TraceLevel.Warning, `getCachedRouteInfo - Error processing route from deep link on cached load. ${generateLogStringFromError(e)}`);
            return null;
        }
        this.loggers?.traceLogger.logTrace(0x1e3d464c /* tag_4puzm */ , TraceLevel.Warning, `getCachedRouteInfo - Unable to parse route from deep link on cached load.`);
        return null;
    }
    parseContentUrl(contentUrl) {
        if (!contentUrl) {
            return null;
        }
        try {
            const url = new URL(contentUrl);
            const params = new URLSearchParams(url.search);
            const entityId = decodeURIComponent(params.get("entityId") ?? "");
            const subEntityId = decodeURIComponent(params.get("subEntityId") ?? "");
            const planRoute = decodeURIComponent(params.get("planRoute") ?? "");
            // In case of latest Uno Tab experience (using v1 tab format), the subEntityId is passed as planRoute for tab route
            // Else it is passed as subEntityId (legacy, deeplink to task in tab).
            const parsedContentUrl = {
                entityId,
                subEntityId: subEntityId === "" ? planRoute : subEntityId
            };
            return parsedContentUrl;
        } catch (e) {
            this.loggers?.traceLogger.logTrace(0x1e3d3302 /* tag_4ptmc */ , TraceLevel.Warning, `parseContentUrl - Error parsing content URL`);
            return null;
        }
    }
    isRelativeUrl(url) {
        const lowerCaseRelativeUrl = url.toLowerCase();
        return !(lowerCaseRelativeUrl.startsWith("http") || lowerCaseRelativeUrl.startsWith("//"));
    }
    /**
     * Returns the appropriate TelemetryPlatform based on the Teams HostClientType
     */ getTelemetryPlatform(teamsHostClientType) {
        switch(teamsHostClientType){
            case HostClientType.desktop:
                return TelemetryPlatform.Desktop;
            case HostClientType.web:
                return TelemetryPlatform.Web;
            case HostClientType.android:
                return TelemetryPlatform.Android;
            case HostClientType.ios:
            case HostClientType.ipados:
                return TelemetryPlatform.iOS;
            case HostClientType.macos:
                return TelemetryPlatform.macOS;
            case HostClientType.surfaceHub:
            case HostClientType.teamsDisplays:
            case HostClientType.teamsPhones:
            case HostClientType.teamsRoomsAndroid:
            case HostClientType.teamsRoomsWindows:
            case HostClientType.rigel:
                return TelemetryPlatform.Other;
            default:
                ErrorUtilities.unreachableCase(teamsHostClientType, (log)=>{});
                return TelemetryPlatform.Other;
        }
    }
    /**
     * Constructor for TeamsAdaptor
     * @param performanceMarker - The performance marker. It is needed as the loggers will not be available upfront.
     */ constructor(performanceMarker){
        this.performanceMarker = performanceMarker;
        this.loggers = null;
        this.useNestAppAuth = false;
        this.pageId = null;
        this.appId = null;
        this.tenantId = null;
        this.ringId = null;
        this.homeRingId = null;
        this.isChannelTab = null;
        this.channelId = null;
        this._teamsRootUrl = undefined;
    }
}
