// Models
import { DayOrDate } from "./DayOrDate";
/**
 * Represents a fixed date of a recurrence.
 * @example 14th of February.
 * @example 31st of a month.
 */ export class FixedDate extends DayOrDate {
    getDateForPeriod(period) {
        const month = this.getMonthForPeriod(period);
        return this.setToDate(period.clone().month(month));
    }
    /**
     * Set the date within a given period.
     * @example Having a fixed date of the 31st. Given the period representing the month of February,
     * set the date to either the the 28th or 29th depending on the year.
     */ setToDate(period) {
        const date = period.clone();
        if (this.dayOfMonth > date.daysInMonth()) {
            // Set to last day of month
            return date.date(date.daysInMonth());
        }
        return date.date(this.dayOfMonth);
    }
    constructor(dayOfMonth){
        super();
        this.dayOfMonth = dayOfMonth;
    }
}
