export var SupportedReferenceTypes;
(function(SupportedReferenceTypes) {
    SupportedReferenceTypes["Word"] = "Word";
    SupportedReferenceTypes["Excel"] = "Excel";
    SupportedReferenceTypes["PowerPoint"] = "PowerPoint";
    SupportedReferenceTypes["OneNote"] = "OneNote";
    SupportedReferenceTypes["Project"] = "Project";
    SupportedReferenceTypes["Visio"] = "Visio";
    SupportedReferenceTypes["Pdf"] = "Pdf";
    SupportedReferenceTypes["Loop"] = "Loop";
    SupportedReferenceTypes["Form"] = "Form";
    SupportedReferenceTypes["TeamsHostedApp"] = "TeamsHostedApp";
    SupportedReferenceTypes["Other"] = "Other";
})(SupportedReferenceTypes || (SupportedReferenceTypes = {}));
/** Subset of SupportedFileTypes that are non-previewables */ export const NonPreviewableSupportedFileTypes = [
    "OneNote",
    "Project",
    "Visio",
    "Form"
];
/** These are types that have preview, so if the taskAttachmentType is "Other" we will only fetch preview if the attachment is one of this. */ export const FileTypesWithPreview = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp"
];
