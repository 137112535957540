// Constants
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { AttachmentTypeBuilder } from "../attachment/AttachmentTypeBuilder";
import { DeltaTranslationMap } from "../deltaSync/DeltaTranslationMap";
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { PreviewType, PreviewTypeConverter } from "../previewType/PreviewType";
import { Attachment } from "../attachment/Attachment";
import { ChecklistItem } from "./ChecklistItem";
import { TaskDetails } from "./TaskDetails";
import { CompletionRequirements } from "./mandatoryRequirements/CompletionRequirements";
import { Form } from "../form/Form";
import { Approval } from "../approval/Approval";
// Server resource
import { ContentType } from "../../../service/graph/general/ContentType";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import transform from "lodash/transform";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Configuration and building of task details objects
 */ export class TaskDetailsBuilder {
    /**
     * Build out the task details
     */ build() {
        return new TaskDetails(this);
    }
    /**
     * Build from a graph taskdetails resource entity as initialization data.
     * @param taskDetailsResource Task details resource entity to use for initializing the task details builder
     */ fromGraphResource(taskDetailsResource) {
        const checklistItems = ChecklistItem.getGraphClientDictionaryType(taskDetailsResource.checklist);
        const references = Attachment.getGraphClientDictionaryType(taskDetailsResource.references);
        const isFormsRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryForms;
        const isApprovalRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryApprovals;
        const taskDetailsPayload = this.withPropertyBag({
            checklist: checklistItems,
            description: taskDetailsResource.description,
            notes: taskDetailsResource.notes,
            id: taskDetailsResource.id,
            itemVersion: taskDetailsResource["@odata.etag"],
            previewType: PreviewTypeConverter.getPreviewTypeFromGraph(taskDetailsResource.previewType),
            references: references
        });
        taskDetailsPayload.completionRequirements = taskDetailsResource.completionRequirements ? CompletionRequirements.builder.fromGraphResource(taskDetailsResource?.completionRequirements).build() : null;
        if (isFormsRequirementEnabled && taskDetailsResource.forms) {
            taskDetailsPayload.forms = Form.getGraphClientDictionaryType(taskDetailsResource.forms);
        }
        if (isApprovalRequirementEnabled && taskDetailsResource.approvalAttachment) {
            taskDetailsPayload.approval = Approval.fromGraphApprovalResource(taskDetailsResource.approvalAttachment);
        }
        return taskDetailsPayload;
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.completionRequirements = propertyBag.completionRequirements || this.completionRequirements;
        this.checklist = propertyBag.checklist || this.checklist;
        this.description = propertyBag.description != null ? propertyBag.description : this.description;
        this.notes = propertyBag.notes != null ? propertyBag.notes : this.notes;
        this.id = propertyBag.id;
        this.itemVersion = propertyBag.itemVersion || this.itemVersion;
        this.previewType = propertyBag.previewType != null ? propertyBag.previewType : this.previewType;
        this.references = propertyBag.references || this.references;
        this.forms = propertyBag.forms || this.forms;
        this.approval = propertyBag.approval || this.approval;
        this.clientExtensions = propertyBag.clientExtensions || this.clientExtensions;
        return this;
    }
    /**
     * A test method to add preview type and data associated with the preview type
     * @param previewType The preview type we want to use
     * @param [references] The references to use when the preview type is Reference
     */ withPreviewType(previewType, references) {
        let vals = {};
        switch(previewType){
            case PreviewType.Description:
                vals = {
                    description: "preview-type-description",
                    notes: {
                        content: "preview-type-description",
                        contentType: ContentType.HTML
                    }
                };
                break;
            case PreviewType.CheckList:
                const checklistItemDictionary = {};
                const cliId = "check list item Id";
                const cli = new ChecklistItem(cliId, false, "abc a", "cli title");
                checklistItemDictionary[cli.id] = cli;
                vals = {
                    checklist: checklistItemDictionary
                };
                break;
            case PreviewType.Reference:
                vals = {
                    references: references
                };
                break;
            default:
                break;
        }
        return this.withPropertyBag({
            ...vals,
            previewType: previewType,
            id: this.id
        });
    }
    withCompletionRequirements(completionRequirements) {
        this.completionRequirements = completionRequirements;
        return this;
    }
    /**
     * A test method to add a locally unique id to the user
     */ withLocallyUniqueId() {
        return this.withPropertyBag({
            id: LocalIdGenerator.generate()
        });
    }
    /**
     * Test method to add a specific id to the task details
     * @param id Id to assign to the task details
     */ withId(id) {
        this.id = id;
        return this;
    }
    /**
     * Create the task from diff sync
     * @param update Changes from diff sync to apply
     */ newFromDifferentialUpdate(update) {
        if (!(update.entityType === TaskServiceEntityType.TaskDetails || update.entityType === TaskServiceEntityType.Task)) {
            throw new Error("ArgumentException: invalid entity");
        }
        const baseline = TaskDetails.builder.withPropertyBag({
            id: update.id,
            itemVersion: update.itemVersion
        }).build();
        const diffData = update.getCreateDiffData(TaskServiceEntityType.TaskDetails);
        const details = baseline.applyDiffs(diffData);
        return this.forClone(details);
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(taskDetails) {
        return this.withPropertyBag({
            checklist: cloneDeep(taskDetails.checklist),
            completionRequirements: cloneDeep(taskDetails.completionRequirements),
            description: taskDetails.description || undefined,
            notes: taskDetails.notes != null ? {
                content: taskDetails.notes.content,
                contentType: taskDetails.notes.contentType
            } : undefined,
            id: taskDetails.id,
            itemVersion: taskDetails.itemVersion,
            previewType: cloneDeep(taskDetails.previewType),
            references: cloneDeep(taskDetails.references),
            forms: cloneDeep(taskDetails.forms),
            approval: cloneDeep(taskDetails.approval),
            clientExtensions: cloneDeep(taskDetails.clientExtensions)
        });
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        const translateChecklistUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const checklist = storeCopy?.checklist ? cloneDeep(storeCopy.checklist) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete checklist[key];
                } else if (key in checklist) {
                    // Update Item values - may include partial properties
                    if ("title" in graphUpdates[key]) {
                        checklist[key] = checklist[key].setProperty("title", graphUpdates[key].title);
                    }
                    if ("isChecked" in graphUpdates[key]) {
                        checklist[key] = checklist[key].setProperty("isChecked", graphUpdates[key].isChecked);
                    }
                    if ("orderHint" in graphUpdates[key]) {
                        checklist[key] = checklist[key].setProperty("orderHint", graphUpdates[key].orderHint);
                    }
                } else {
                    // Insert item
                    checklist[key] = ChecklistItem.getGraphChecklistItem(key, graphUpdates[key]);
                }
            }
            return checklist;
        };
        const translateFormsUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const forms = storeCopy?.forms ? cloneDeep(storeCopy.forms) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete forms[key];
                } else if (key in forms) {
                    // Update Item values - may include partial properties
                    if ("displayName" in graphUpdates[key]) {
                        forms[key] = forms[key].setProperty("displayName", graphUpdates[key].displayName);
                    }
                    if ("formResponse" in graphUpdates[key]) {
                        forms[key] = forms[key].setProperty("formResponse", graphUpdates[key].formResponse);
                    }
                    if ("formWebUrl" in graphUpdates[key]) {
                        forms[key] = forms[key].setProperty("collectResponsesUrl", graphUpdates[key].formWebUrl);
                    }
                } else {
                    // Insert item
                    forms[key] = Form.fromGraphFormResource(key, graphUpdates[key]);
                }
            }
            return forms;
        };
        const translateApprovalUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset approval
                return null;
            }
            const approval = storeCopy?.approval ? cloneDeep(storeCopy.approval) : {};
            if ("approvalId" in graphUpdates) {
                approval["approvalId"] = graphUpdates.approvalId;
            }
            if ("status" in graphUpdates) {
                approval["approvalStatus"] = graphUpdates.status;
            }
            return approval;
        };
        const translateAttachmentUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            // Decode keys to be used in the client
            graphUpdates = transform(graphUpdates, (collection, value, key)=>collection[decodeURIComponent(key)] = value);
            const references = storeCopy?.references ? cloneDeep(storeCopy.references) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete references[key];
                } else if (key in references) {
                    // Update Item values - may include partial properties
                    if ("alias" in graphUpdates[key]) {
                        references[key] = references[key].setProperty("alias", graphUpdates[key].alias);
                    }
                    if ("previewPriority" in graphUpdates[key]) {
                        references[key] = references[key].setProperty("previewPriority", graphUpdates[key].previewPriority);
                    }
                    if ("type" in graphUpdates[key]) {
                        const attachmentTypeBuilder = new AttachmentTypeBuilder();
                        const taskAttachmentType = attachmentTypeBuilder.fromGraphAttachmentResource(key, graphUpdates[key]).build();
                        const updateReference = references[key];
                        updateReference["taskAttachmentType"] = taskAttachmentType;
                    }
                } else {
                    // Insert item
                    references[key] = Attachment.builder.fromGraphAttachmentResource(key, graphUpdates[key]).build();
                }
            }
            return references;
        };
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("checklist", "checklist", translateChecklistUpdates);
        graphTranslationMap.addMapping("forms", "forms", translateFormsUpdates);
        graphTranslationMap.addMapping("approvalAttachment", "approval", translateApprovalUpdates);
        graphTranslationMap.addMapping("description", "description");
        graphTranslationMap.addMapping("notes", "notes");
        graphTranslationMap.addMapping("previewType", "previewType", (value)=>PreviewTypeConverter.getPreviewTypeFromGraph(value));
        graphTranslationMap.addMapping("references", "references", translateAttachmentUpdates);
        return graphTranslationMap.translate(update);
    }
    /**
     * Initialize an instance of the task details builder
     */ constructor(){
        this.id = LocalIdGenerator.generate();
        this.completionRequirements = null;
        this.checklist = {};
        this.references = {};
        this.description = null;
        this.notes = null;
        this.previewType = PreviewType.Automatic;
        this.forms = {};
        this.approval = null;
        this.clientExtensions = null;
    }
}
