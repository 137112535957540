// Models
import { SupportedVideoTypes } from "../SupportedVideoTypes";
export var DailyMotion;
(function(DailyMotion) {
    const videoUrlPattern = // eslint-disable-next-line security/detect-unsafe-regex
    /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/;
    function tryParseVideoUrl(url, result) {
        const matches = url.match(videoUrlPattern);
        if (matches != null) {
            result.canPlay = true;
            result.embedUrl = `https://www.dailymotion.com/embed/video/${matches[1]}?autoplay=0`;
            result.provider = SupportedVideoTypes.DailyMotion;
            return true;
        }
        return false;
    }
    /**
     * Try to parse a url as a provider video url.
     * Returns true if parsing is a success, false otherwise
     * @param url Url to parse
     * @param result Result of the Video parsing
     */ DailyMotion.tryParseVideoUrl = tryParseVideoUrl;
})(DailyMotion || (DailyMotion = {}));
