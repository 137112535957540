// Builder
import { TaskDetailsBuilder } from "./TaskDetailsBuilder";
// Constants
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { TaskServiceEntity } from "../TaskServiceEntity";
import { PreviewType, PreviewTypeConverter } from "../previewType/PreviewType";
import { Attachment } from "../attachment/Attachment";
import { ChecklistItem } from "./ChecklistItem";
import { Form } from "../form/Form";
import { FormsCompletionRequirement } from "./mandatoryRequirements/FormsCompletionRequirement";
import { CompletionRequirements } from "./mandatoryRequirements/CompletionRequirements";
import { ApprovalCompletionRequirement } from "./mandatoryRequirements/ApprovalCompletionRequirement";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import every from "lodash/every";
import filter from "lodash/filter";
import first from "lodash/first";
import forEach from "lodash/forEach";
import forOwn from "lodash/forOwn";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import maxBy from "lodash/maxBy";
import mergeWith from "lodash/mergeWith";
import minBy from "lodash/minBy";
import size from "lodash/size";
import values from "lodash/values";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import { generateOrderHintBetween } from "@ms/uno-utilities/lib/local/OrdinalUtilities";
import { GraphPlannerODataType } from "../../../service/graph/planner/GraphPlannerODataType";
import { SupportedReferenceTypes } from "../../taskAttachment/ITaskAttachmentType";
import { hasNonWhitespaceContent } from "@ms/uno-utilities/lib/local/HtmlUtilities";
/**
 * Represents a TaskDetails entity in the client
 */ export class TaskDetails extends TaskServiceEntity {
    /**
     * Builder for Task Details objects
     */ static get builder() {
        return new TaskDetailsBuilder();
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (key === "checklist" || key === "references" || key === "forms") {
            if (isEqual(target, DeltaApiConstants.CollectionCleanupValue)) {
                // All removed workaround
                return {};
            }
            return target;
        } else if (key === "completionRequirements" && !isEqual(source, target)) {
            return target;
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    get entityType() {
        return TaskServiceEntityType.TaskDetails;
    }
    get hasThumbnail() {
        if (this.previewType === PreviewType.Reference || this.previewType === PreviewType.Automatic) {
            if (this.getReferenceWithLowestPriority() != null) {
                return true;
            }
        }
        return false;
    }
    toGraphSerializable() {
        // Remove temporary attachments (pending upload) before serializing. Temporary attachments should never be sent to the server.
        const tasksDetailsToSerialize = this.removeTemporaryAttachments();
        const checklistItemResources = ChecklistItem.getGraphResourceDictionaryType(tasksDetailsToSerialize.checklist);
        const attachmentResources = Attachment.getGraphResourceDictionaryType(tasksDetailsToSerialize.references);
        const isFormsRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryForms;
        const isApprovalRequirementEnabled = AppContext.appConfiguration.flights.EnableMandatoryApprovals;
        // We can only set either notes or description, but not both.
        // With rich text we use notes, while in the past for legacy plain text we used description.
        const notesContent = tasksDetailsToSerialize.notes?.content ? {
            content: tasksDetailsToSerialize.notes.content,
            contentType: tasksDetailsToSerialize.notes.contentType
        } : undefined;
        const descriptionContent = undefined;
        const taskDetailsPayload = {
            "@odata.type": GraphPlannerODataType.TaskDetails,
            references: attachmentResources,
            checklist: checklistItemResources,
            id: tasksDetailsToSerialize.id,
            description: descriptionContent,
            notes: notesContent,
            completionRequirements: tasksDetailsToSerialize.completionRequirements?.toGraphSerializable(),
            previewType: PreviewTypeConverter.getGraphPreviewType(tasksDetailsToSerialize.previewType),
            "@odata.etag": tasksDetailsToSerialize.itemVersion != null ? tasksDetailsToSerialize.itemVersion : undefined
        };
        if (isFormsRequirementEnabled) {
            taskDetailsPayload.forms = Form.getGraphResourceDictionaryType(tasksDetailsToSerialize.forms);
        }
        if (isApprovalRequirementEnabled) {
            taskDetailsPayload.approvalAttachment = tasksDetailsToSerialize.approval?.toGraphSerializable() ?? null;
        }
        return taskDetailsPayload;
    }
    /**
     * @param key Property on the TaskDetails object
     * @param value Value to set on the property
     */ setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "id" || key === "itemVersion" || key === "clientExtensions") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    /**
     * @param target TaskDetails to diff with
     */ getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === "checklist" || key === "references" || key === "forms") {
                if (!isEqual(source, target)) {
                    if (isEqual(target, {})) {
                        // Remove all checklist or references workaround. if {} is returned it treats it as no diff
                        return DeltaApiConstants.CollectionCleanupValue;
                    }
                    return target;
                } else {
                    return {};
                }
            } else if (key === "completionRequirements" && !isEqual(source, target)) {
                return target;
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    /**
     * @param diffs Set of diffs to apply
     */ applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(TaskDetails.builder.build(), this, ...diffs, TaskDetails.mergeCustomizer);
        }
        return this;
    }
    /**
     * @param update Diff sync changes
     */ applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this, this.entityType);
        return this.applyDiffs(diffData);
    }
    getReferenceWithHighestPriority() {
        const index = maxBy(keys(this.references), (key)=>{
            return this.references[key].previewPriority;
        });
        if (index == null) {
            return null;
        }
        return this.references[index];
    }
    getReferenceWithLowestPriority() {
        const index = minBy(keys(this.references), (key)=>{
            return this.references[key].previewPriority;
        });
        if (index == null) {
            return null;
        }
        return this.references[index];
    }
    getTaskPreviewReference() {
        return this.getReferenceWithLowestPriority();
    }
    getAttachmentsSortedByAlias() {
        const referenceList = [];
        // Map dictionary to a list
        forOwn(this.references, (value, key)=>{
            if (value.taskAttachmentType.taskAttachmentType !== SupportedReferenceTypes.TeamsHostedApp) {
                referenceList.push(value);
            }
        });
        // Now sort by alias
        referenceList.sort((item1, item2)=>Attachment.aliasCompare(item1, item2));
        return referenceList;
    }
    /**
     * @param attachment Attachment to verify
     */ attachmentExists(attachment) {
        return this.references[attachment.url] != null;
    }
    /**
     * @param url Url to verify exists as an attachment on the task
     */ doesUrlExistAsAttachment(url) {
        return this.references[url] != null;
    }
    /**
     * @param attachment Attachment to add
     */ addAttachment(attachment) {
        if (this.references[attachment.url] != null) {
            throw new Error("AggregateException: attachment already exists");
        }
        const updatedReferenceCollection = cloneDeep(this.references);
        updatedReferenceCollection[attachment.url] = attachment;
        return this.setProperty("references", updatedReferenceCollection);
    }
    /**
     * @param attachment Attachment to remove
     */ removeAttachment(attachmentUrl) {
        if (this.references[attachmentUrl] == null) {
            throw new Error("NotFoundException: attachment not found on task details");
        }
        const updatedReferenceCollection = keyBy(filter(this.references, (reference)=>{
            return reference.url !== attachmentUrl;
        }), (reference)=>{
            return reference.url;
        });
        return this.setProperty("references", updatedReferenceCollection);
    }
    removeTemporaryAttachments() {
        const updatedReferenceCollection = keyBy(filter(this.references, (reference)=>{
            return !LocalIdGenerator.isLocalTempId(reference.url);
        }), (reference)=>{
            return reference.url;
        });
        return this.setProperty("references", updatedReferenceCollection);
    }
    /**
     * @param oldAttachment Attachment to replace
     * @param newAttachment Attachment to replace with
     */ replaceAttachment(oldAttachment, newAttachment) {
        if (this.references[oldAttachment.url] == null) {
            throw new Error("NotFoundException: oldAttachment not found on task details");
        }
        // Remove old attachment
        const updatedReferenceCollection = keyBy(filter(this.references, (reference)=>{
            return reference.url !== oldAttachment.url;
        }), (reference)=>{
            return reference.url;
        });
        // Add the new attachment
        updatedReferenceCollection[newAttachment.url] = newAttachment;
        return this.setProperty("references", updatedReferenceCollection);
    }
    /**
     * @param checklistId Id of the checklist item to update
     * @param isChecked Whether or not the item is being checked or unchecked
     */ checkChecklistItem(checklistId, isChecked) {
        const updatedChecklist = ChecklistItem.setChecklistProperty(this.checklist, checklistId, "isChecked", isChecked);
        return this.setProperty("checklist", updatedChecklist);
    }
    getPromotedProperties() {
        const numAttachments = this.references == null ? 0 : size(this.references);
        const checklistStats = this.getChecklistStats();
        return {
            hasDescription: this.notes?.content ? hasNonWhitespaceContent(this.notes.content) : !!this.description,
            numberOfAttachments: numAttachments,
            numberOfActiveChecklistItems: checklistStats.numActiveItems,
            numberOfChecklistItems: checklistStats.numItems,
            previewType: this.previewType
        };
    }
    hasMandatoryChecklist() {
        return this.checklist != null && this.completionRequirements?.checklistRequirement != null;
    }
    getCopy() {
        const taskDetails = this.getClone();
        if (taskDetails.checklist != null) {
            const checklistArray = ChecklistItem.getSortedChecklistItems(taskDetails.checklist);
            forEach(checklistArray, (item, index)=>{
                let newOrderHint;
                if (item === first(checklistArray)) {
                    newOrderHint = generateOrderHintBetween(checklistArray[index].orderHint, null);
                } else {
                    newOrderHint = generateOrderHintBetween(checklistArray[index].orderHint, checklistArray[index - 1].orderHint);
                }
                taskDetails.checklist[checklistArray[index].id] = taskDetails.checklist[checklistArray[index].id].setProperty("orderHint", newOrderHint);
            });
        }
        if (taskDetails.references != null) {
            const referenceArray = Attachment.getSortedAttachments(taskDetails.references);
            forEach(referenceArray, (item, index)=>{
                let newOrderHint;
                if (item === first(referenceArray)) {
                    newOrderHint = generateOrderHintBetween(referenceArray[index].previewPriority, null);
                } else {
                    newOrderHint = generateOrderHintBetween(referenceArray[index].previewPriority, referenceArray[index - 1].previewPriority);
                }
                taskDetails.references[referenceArray[index].url] = taskDetails.references[referenceArray[index].url].setProperty("previewPriority", newOrderHint);
            });
        }
        return taskDetails;
    }
    /**
     * Return a clone of this object
     */ getCloneBuilder() {
        return TaskDetails.builder.forClone(this);
    }
    getClone() {
        return this.getCloneBuilder().build();
    }
    /**
     * Get the number of completed checklist items & number of total checklist items
     */ getChecklistStats() {
        const checklistItems = this.checklist == null ? {} : this.checklist;
        const noNullValues = compact(values(checklistItems));
        const numItems = noNullValues.length;
        const numActiveItems = noNullValues.filter((item)=>{
            return !item.isChecked;
        }).length;
        return {
            numItems: numItems,
            numActiveItems: numActiveItems
        };
    }
    getFormsSortedByAlias() {
        const formList = [];
        // Map dictionary to a list
        forOwn(this.forms, (value, key)=>{
            formList.push(value);
        });
        // Now sort by display name
        formList.sort((item1, item2)=>Form.displayNameCompare(item1, item2));
        return formList;
    }
    /**
     * @param form form to add
     */ addForm(form) {
        if (this.forms[form.formId] != null) {
            throw new Error("AggregateException: form already exists");
        }
        const updatedFormCollection = cloneDeep(this.forms);
        // Add new form to collection
        updatedFormCollection[form.formId] = form;
        const updatedTaskDetails = this.setProperty("forms", updatedFormCollection);
        // Add new form id to form completion requirement
        const updatedFormIds = keys(updatedFormCollection);
        return this.updateFormsRequirement(updatedFormIds, updatedTaskDetails);
    }
    /**
     * @param oldForm form to replace
     * @param newForm form to replace with
     */ replaceForm(oldForm, newForm) {
        if (this.forms[oldForm.formId] == null) {
            throw new Error("NotFoundException: oldForm not found on task details");
        }
        // Remove old form
        const updatedFormCollection = keyBy(filter(this.forms, (form)=>{
            return form.formId !== oldForm.formId;
        }), (form)=>{
            return form.formId;
        });
        // Add the new form
        updatedFormCollection[newForm.formId] = newForm;
        const updatedTaskDetails = this.setProperty("forms", updatedFormCollection);
        // Replace form id in form completion requirement
        const updatedFormIds = keys(updatedFormCollection);
        return this.updateFormsRequirement(updatedFormIds, updatedTaskDetails);
    }
    /**
     * @param formToRemove form to remove
     */ removeForm(formToRemove) {
        if (this.forms[formToRemove.formId] == null) {
            throw new Error("NotFoundException: form not found on task details");
        }
        const updatedFormCollection = keyBy(filter(this.forms, (form)=>{
            return form.formId !== formToRemove.formId;
        }), (form)=>{
            return form.formId;
        });
        const updatedTaskDetails = this.setProperty("forms", updatedFormCollection);
        // Remove form id from form completion requirement
        const updatedFormIds = keys(updatedFormCollection);
        return this.updateFormsRequirement(updatedFormIds, updatedTaskDetails);
    }
    /** Update the form completion requirement
     * @param formIds The form ids to update the completion requirement with
     * @param taskDetails The task details to update
     */ updateFormsRequirement(formIds, taskDetails = this) {
        let updatedCompletionRequirement = taskDetails.completionRequirements ?? CompletionRequirements.builder.build();
        // If there are no forms required, set the forms requirement to null
        // Otherwise, update the forms requirement
        const formsRequirement = formIds.length === 0 ? null : new FormsCompletionRequirement(formIds);
        updatedCompletionRequirement = updatedCompletionRequirement.setProperty("formsRequirement", formsRequirement);
        return taskDetails.setProperty("completionRequirements", updatedCompletionRequirement);
    }
    /** Update the approval completion requirement
     * @param isApprovalRequired Whether approval is required or not
     */ updateApprovalRequirement(isApprovalRequired) {
        const approvalRequirement = new ApprovalCompletionRequirement(isApprovalRequired);
        if (!this.completionRequirements) {
            return this.setProperty("completionRequirements", CompletionRequirements.builder.withApprovalRequirement(approvalRequirement).build());
        } else {
            const updatedCompletionRequirement = this.completionRequirements.setProperty("approvalRequirement", approvalRequirement);
            return this.setProperty("completionRequirements", updatedCompletionRequirement);
        }
    }
    /**
     * Initializes a new instance of the "Task Details" entity.
     * @param taskDetailsBuilder Builder with the initialization data
     */ constructor(taskDetailsBuilder){
        super(taskDetailsBuilder.id, taskDetailsBuilder.itemVersion);
        this.notes = taskDetailsBuilder.notes ? taskDetailsBuilder.notes : null;
        this.description = taskDetailsBuilder.description ? taskDetailsBuilder.description : null;
        this.checklist = taskDetailsBuilder.checklist;
        this.previewType = taskDetailsBuilder.previewType;
        this.references = taskDetailsBuilder.references;
        this.completionRequirements = taskDetailsBuilder.completionRequirements;
        this.forms = taskDetailsBuilder.forms;
        this.approval = taskDetailsBuilder.approval ? taskDetailsBuilder.approval : null;
        this.clientExtensions = taskDetailsBuilder.clientExtensions;
    }
}
/** Matches TaskLogic.cs : NotesSizeLimit */ TaskDetails.DescriptionMaxLength = 32768;
TaskDetails.MaxChecklistItemsPerTask = 20;
