// Constants
import { MaxDailyRecurrenceInterval, MaxMonthlyRecurrenceInterval, MaxWeeklyRecurrenceInterval, MaxYearlyRecurrenceInterval } from "@ms/uno-constants/lib/local/RecurrenceConstants";
// Models
import { RecurrencePatternType } from "../../../../../service/graph-legacy/task/recurrence/RecurrencePatternType";
import { RecurrenceDropdownType } from "../../../../../service/graph-legacy/task/recurrence/RecurrenceDropdownType";
/**
 * Returns the interval limits for a given recurrence pattern
 */ export function getRecurrenceIntervalLimits(patternOrDropdownType) {
    switch(patternOrDropdownType){
        case RecurrencePatternType.Daily:
        case RecurrenceDropdownType.Daily:
            return MaxDailyRecurrenceInterval;
        case RecurrencePatternType.Weekly:
        case RecurrenceDropdownType.Weekly:
        case RecurrenceDropdownType.Weekdays:
            return MaxWeeklyRecurrenceInterval;
        case RecurrencePatternType.AbsoluteMonthly:
        case RecurrencePatternType.RelativeMonthly:
        case RecurrenceDropdownType.Monthly:
            return MaxMonthlyRecurrenceInterval;
        case RecurrencePatternType.AbsoluteYearly:
        case RecurrencePatternType.RelativeYearly:
        case RecurrenceDropdownType.Yearly:
            return MaxYearlyRecurrenceInterval;
        case RecurrenceDropdownType.NotRepeat:
        case RecurrenceDropdownType.Custom:
            return 0;
        default:
            throw new Error(`RecurrencePatternUtilities.getRecurrenceIntervalLimits - Invalid recurrence pattern type [PatternType=${patternOrDropdownType}]`);
    }
}
/**
 * Returns the moment zero-based month index for task recurrence month, which is 1-based.
 * @param month The recurrence month value
 */ export function getMomentMonthIndexFromRecurrenceMonth(month) {
    let isInvalidMonth = false;
    let errorMessage = "";
    // Recurrence month is 1-based indexing, moment month is 0-based indexing
    if (month < 1) {
        isInvalidMonth = true;
        errorMessage = "InvalidArgument: month < 1";
    } else if (month > 12) {
        isInvalidMonth = true;
        errorMessage = "InvalidArgument: month > 12";
    }
    if (isInvalidMonth) {
        throw new Error(`RecurrencePatternUtilities.getMomentMonthIndexFromRecurrenceMonth - ${errorMessage}`);
    }
    return month - 1;
}
/**
 * Returns the task recurrence 1-based month index for moment zero-based month index.
 * @param date The moment date
 */ export function getRecurrenceMonthIndexFromMoment(date) {
    // Recurrence month is 1-based indexing, moment month is 0-based indexing
    return date.month() + 1;
}
