// Models
/**
 * Base entity for all top level task service entities
 */ export class TaskServiceEntity {
    /**
     * Initialize a new instance of the TaskServiceEntity class
     * @param id Id of the entity
     * @param itemVersion Version of the entity
     */ constructor(id, itemVersion){
        this.id = id;
        this.itemVersion = itemVersion;
    }
}
