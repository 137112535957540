/**
 * Performance Marker tag enum for all the performance markers
 */ export var MarkerTag;
(function(MarkerTag) {
    // Very first tag to be marked once the app bootstrap gets called.
    MarkerTag["AppBootstrap"] = "AppBootstrap";
    // Marked when the Teams SDK is initialized. This is only applicable for Teams app.
    MarkerTag["InitializedTeamsSDK"] = "InitializedTeamsSDK";
    // Marked in the lifecycle of any smart component.
    MarkerTag["ViewRender"] = "ViewRender";
    // Marked when the app load is started from cache.
    MarkerTag["CachedLoadStart"] = "CachedLoadStart";
    // Marked when the app load completes from cache.
    MarkerTag["CachedLoadComplete"] = "CachedLoadComplete";
    // Marked when the view data fetch cycle meets the checkpoints.
    MarkerTag["ViewDataFetch"] = "ViewDataFetch";
})(MarkerTag || (MarkerTag = {}));
export var FetchedDataState;
(function(FetchedDataState) {
    // All data required by the component
    FetchedDataState[FetchedDataState["All"] = 2] = "All";
    // Visually critical data sufficient to make the component feel loaded
    FetchedDataState[FetchedDataState["Critical"] = 1] = "Critical";
    // No data fetched
    FetchedDataState[FetchedDataState["None"] = 0] = "None";
})(FetchedDataState || (FetchedDataState = {}));
export var ViewDataFetchState;
(function(ViewDataFetchState) {
    // The data fetch is started
    ViewDataFetchState["Started"] = "Started";
    // The data fetch is completed
    ViewDataFetchState["Completed"] = "Completed";
})(ViewDataFetchState || (ViewDataFetchState = {}));
export var ViewName;
(function(ViewName) {
    // The view name for the leftNav
    ViewName["UnoLeftNavContainer"] = "UnoLeftNavContainer";
    // The view name for MyDay
    ViewName["MyDayPage"] = "MyDayPage";
    // The view name for MyTasks
    ViewName["MyTasksPage"] = "MyTasksPage";
    // The view name for MyPlans
    ViewName["MyPlansPage"] = "MyPlansPage";
    // The view name for MyPortfolios
    ViewName["MyPortfoliosPage"] = "MyPortfoliosPage";
    // The view name for BasicPlan
    ViewName["BasicPlanPage"] = "BasicPlanPage";
    // The view name for Task Details
    ViewName["TaskEditorContainer"] = "TaskEditorContainer";
    // The view name for Teams create tab
    ViewName["CreateTeamsTabPage"] = "CreateTeamsTabPage";
    // The view name for Teams create task from message flow, and Viva connections create task flow
    ViewName["CreateTaskPage"] = "CreateTaskPage";
    // The view name for removing Teams tab
    ViewName["RemoveTeamsTabPage"] = "RemoveTeamsTabPage";
    // The view name for Teams Tab Landing Experience
    ViewName["TeamsTabLandingExperienceContainer"] = "TeamsTabLandingExperienceContainer";
})(ViewName || (ViewName = {}));
