// Model
import { Task } from "./Task";
import { TaskDetails } from "./TaskDetails";
import { TaskEntitySet } from "./TaskEntitySet";
import { AssignedToTaskboardTaskFormatting } from "./taskFormatting/AssignedToTaskboardTaskFormatting";
import { BucketTaskboardTaskFormatting } from "./taskFormatting/BucketTaskboardTaskFormatting";
import { ProgressTaskboardTaskFormatting } from "./taskFormatting/ProgressTaskboardTaskFormatting";
import { TaskboardTaskFormattingCollection } from "./taskFormatting/TaskboardTaskFormattingCollection";
/**
 * Configuration and building of Task entity set objects
 */ export class TaskEntitySetBuilder {
    /**
     * Build out the Task formatting data object
     */ build() {
        return new TaskEntitySet(this);
    }
    /**
     * Build from a Graph Task format collection resource entity as initialization data.
     */ fromGraphResource(resource) {
        return this.withPropertyBag({
            task: Task.builder().fromGraphResource(resource).build(),
            taskDetails: resource?.details ? TaskDetails.builder.fromGraphResource(resource.details).build() : undefined,
            taskFormatData: resource && (resource.assignedToTaskBoardFormat || resource.bucketTaskBoardFormat || resource.progressTaskBoardFormat) ? TaskboardTaskFormattingCollection.builder.withPropertyBag({
                assignedBoardFormat: resource.assignedToTaskBoardFormat ? AssignedToTaskboardTaskFormatting.builder.fromGraphResource(resource.assignedToTaskBoardFormat).build() : undefined,
                bucketBoardFormat: resource.bucketTaskBoardFormat ? BucketTaskboardTaskFormatting.builder.fromGraphResource(resource.bucketTaskBoardFormat).build() : undefined,
                progressBoardFormat: resource.progressTaskBoardFormat ? ProgressTaskboardTaskFormatting.builder.fromGraphResource(resource.progressTaskBoardFormat).build() : undefined
            }).build() : undefined
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.task = propertyBag.task;
        this.taskDetails = propertyBag.taskDetails || this.taskDetails;
        this.taskFormatData = propertyBag.taskFormatData || this.taskFormatData;
        return this;
    }
    /** Build with given Task object */ withTask(task) {
        this.task = task;
        return this;
    }
    /** Build with given Task details object */ withDetails(taskDetails) {
        this.taskDetails = taskDetails;
        return this;
    }
    /** Build with given Task format data */ withFormatData(taskFormatData) {
        this.taskFormatData = taskFormatData;
        return this;
    }
    /**
     * Update this builder task entity set with given task ID
     */ withId(taskId) {
        if (!this.task) {
            throw new Error("task must be specified before calling withId");
        }
        this.task = Task.builder().forClone(this.task).withId(taskId).build();
        if (this.taskDetails) {
            this.taskDetails = TaskDetails.builder.forClone(this.taskDetails).withId(taskId).build();
        }
        if (this.taskFormatData) {
            const taskFormatBuilder = TaskboardTaskFormattingCollection.builder;
            if (this.taskFormatData.bucketBoardFormat) {
                taskFormatBuilder.bucketBoardFormat = BucketTaskboardTaskFormatting.builder.forClone(this.taskFormatData.bucketBoardFormat).withId(taskId).build();
            }
            if (this.taskFormatData.assignmentBoardFormat) {
                taskFormatBuilder.assignedBoardFormat = AssignedToTaskboardTaskFormatting.builder.forClone(this.taskFormatData.assignmentBoardFormat).withId(taskId).build();
            }
            if (this.taskFormatData.progressBoardFormat) {
                taskFormatBuilder.progressBoardFormat = ProgressTaskboardTaskFormatting.builder.forClone(this.taskFormatData.progressBoardFormat).withId(taskId).build();
            }
            this.taskFormatData = taskFormatBuilder.build();
        }
        return this;
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(taskEntitySet) {
        return this.withPropertyBag({
            task: taskEntitySet.task,
            taskDetails: taskEntitySet.taskDetails,
            taskFormatData: taskEntitySet.taskFormatData
        });
    }
}
