// Models
import { GraphSpecifiedCompletionRequirements } from "../../../service/graph-legacy/task/completionRequirements/GraphSpecifiedCompletionRequirements";
import { SpecifiedCompletionRequirements } from "../../../service/graph-legacy/task/completionRequirements/SpecifiedCompletionRequirements";
import { ApprovalStatus } from "../approval/ApprovalStatus";
import { SupportedReferenceTypes } from "../../taskAttachment/ITaskAttachmentType";
/**
 * Returns true if the task has completion requirements enabled
 * @param task Task to check
 * @param taskDetails The details of the task.
 * @returns True if the task has completion requirements enabled
 * */ export function areChecklistCompletionRequirementSet(task, taskDetails) {
    if (taskDetails == null) {
        return false;
    }
    return !task.isComplete() && hasChecklistRequirement(taskDetails) && task.numberOfActiveChecklistItems !== 0;
}
/**
 * Checks if forms completion requirement is set for a task.
 * @param task - The task to check.
 * @param taskDetails - The details of the task.
 * @returns True if forms completion requirement is set, false otherwise.
 */ export function areFormsCompletionRequirementSet(task, taskDetails) {
    if (taskDetails == null) {
        return false;
    }
    return !task.isComplete() && hasFormsRequirement(taskDetails) && !areFormsCompleted(taskDetails.forms);
}
/**
 * Checks if all forms are completed for a given task.
 * @param forms - The forms Object to check.
 * @returns True if all required forms are completed, false otherwise.
 */ export function areFormsCompleted(forms) {
    const hasFormsWithNoResponse = Object.values(forms).some((form)=>{
        if (form.formResponse === null) {
            return true;
        }
    });
    return !hasFormsWithNoResponse;
}
/**
 * Checks if approval completion requirement is set for a task.
 * @param task - The task to check.
 * @param taskDetails - The details of the task.
 * @returns True if approval completion requirement is set, false otherwise.
 */ export function areApprovalCompletionRequirementSet(task, taskDetails) {
    if (taskDetails == null) {
        return false;
    }
    const hasApprovalStatus = taskDetails.approval?.approvalStatus !== undefined;
    const hasApprovalDetailsSet = !!taskDetails.approval;
    const isApprovalApproved = taskDetails.approval?.approvalStatus === ApprovalStatus.Approved;
    return !task.isComplete() && hasApprovalRequirement(taskDetails) && (!hasApprovalDetailsSet || hasApprovalDetailsSet && !hasApprovalStatus || hasApprovalDetailsSet && hasApprovalStatus && !isApprovalApproved);
}
/**
 * Checks if all required forms are completed for a given task.
 * @param forms - The forms Object to check.
 */ export function areRequiredFormsCompleted(forms) {
    if (!forms) {
        return false;
    }
    const hasFormsWithNoResponse = Object.values(forms).some((form)=>{
        if (form.formResponse === null) {
            return true;
        }
    });
    return !hasFormsWithNoResponse;
}
/**
 * Checks if a task has required forms.
 * @param {ITaskDetails} taskDetails - The details of the task.
 */ export function hasFormsRequirement(taskDetails) {
    if (taskDetails == null) {
        return false;
    }
    return (taskDetails.completionRequirements?.formsRequirement?.requiredForms.length ?? 0) > 0;
}
/**
 * Checks if a task has required approval.
 * @param taskDetails
 */ export function hasApprovalRequirement(taskDetails) {
    if (taskDetails == null) {
        return false;
    }
    return taskDetails.completionRequirements?.approvalRequirement?.isApprovalRequired ?? false;
}
/**
 * Checks if a task has required checklist items.
 * @param taskDetails
 */ export function hasChecklistRequirement(taskDetails) {
    if (taskDetails == null) {
        return false;
    }
    return (taskDetails.completionRequirements?.checklistRequirement?.requiredChecklistItemIds.length ?? 0) > 0;
}
/**
 * Map graph specifiedCompletionRequirements values to SpecifiedCompletionRequirements
 * @param graphSpecifiedCompletionRequirements - graph api specifiedCompletionRequirements values
 */ export function getSpecifiedCompletionRequirementsFromGraph(graphSpecifiedCompletionRequirements) {
    switch(graphSpecifiedCompletionRequirements){
        case GraphSpecifiedCompletionRequirements.Approval:
            return SpecifiedCompletionRequirements.ApprovalCompletion;
        case GraphSpecifiedCompletionRequirements.Form:
            return SpecifiedCompletionRequirements.FormCompletion;
        case GraphSpecifiedCompletionRequirements.Checklist:
            return SpecifiedCompletionRequirements.ChecklistItemsCompletion;
        case GraphSpecifiedCompletionRequirements.CompletionInHostedApp:
            return SpecifiedCompletionRequirements.CompletionInHostedApp;
        case GraphSpecifiedCompletionRequirements.None:
        default:
            return SpecifiedCompletionRequirements.None;
    }
}
/**
 * Map SpecifiedCompletionRequirements to graph specifiedCompletionRequirements values
 * @param specifiedCompletionRequirements
 */ export function getGraphSpecifiedCompletionRequirements(specifiedCompletionRequirements) {
    switch(specifiedCompletionRequirements){
        case SpecifiedCompletionRequirements.ApprovalCompletion:
            return GraphSpecifiedCompletionRequirements.Approval;
        case SpecifiedCompletionRequirements.FormCompletion:
            return GraphSpecifiedCompletionRequirements.Form;
        case SpecifiedCompletionRequirements.ChecklistItemsCompletion:
            return GraphSpecifiedCompletionRequirements.Checklist;
        case SpecifiedCompletionRequirements.CompletionInHostedApp:
            return GraphSpecifiedCompletionRequirements.CompletionInHostedApp;
        case SpecifiedCompletionRequirements.None:
        default:
            return GraphSpecifiedCompletionRequirements.None;
    }
}
/**
 * Retrieves the alias of the first task attachment that is a Teams-hosted app.
 *
 * @param references - The references object containing task attachments.
 * @returns The alias of the first Teams-hosted app attachment, or null if not found.
 */ export function getAppPoweredTaskAttachmentDetails(references) {
    if (references == null) {
        return null;
    }
    const attachment = Object.values(references).find((details)=>details.taskAttachmentType.taskAttachmentType === SupportedReferenceTypes.TeamsHostedApp);
    return attachment || null;
}
/**
 * Retrieves the link of the powered app from the given references.
 * @param references - The references object containing attachment details.
 * @returns The link of the powered app, or null if not found.
 */ export function getAppPoweredAppLink(references) {
    const attachmentDetails = getAppPoweredTaskAttachmentDetails(references);
    if (attachmentDetails) {
        return attachmentDetails.url;
    }
    return null;
}
