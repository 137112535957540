// Constants
export var IrisAnchors;
(function(IrisAnchors) {
    /** The "My Day" navigation option in the left nav */ IrisAnchors["MyDayNavButton"] = "MyDayNavButton";
    /** The "Task suggestions" button in the My Day view */ IrisAnchors["MyDayTaskSuggestionsButton"] = "MyDayTaskSuggestionsButton";
    /** The "My Tasks" navigation option in the left nav */ IrisAnchors["MyTasksNavButton"] = "MyTasksNavButton";
    /** The "All" pivot in the My Tasks view */ IrisAnchors["MyTasksAllPivot"] = "MyTasksAllPivot";
    /** The "Planner" navigation option in the left nav */ IrisAnchors["MyPlansNavButton"] = "MyPlansNavButton";
    /** The "Recent" pivot in the My Plans view */ IrisAnchors["MyPlansRecentPivot"] = "MyPlansRecentPivot";
    /** The "My Portfolios" navigation option in the left nav */ IrisAnchors["MyPortfoliosNavButton"] = "MyPortfoliosNavButton";
    /** The "+ New plan" button in the My Plans page */ IrisAnchors["MyPlansNewPlanButton"] = "MyPlansNewPlanButton";
    /** The "+ New plan" button in the left nav */ IrisAnchors["LeftNavNewPlanButton"] = "LeftNavNewPlanButton";
    /** The "Grid" view pivot in the basic plan view */ IrisAnchors["BasicPlanGridViewPivot"] = "BasicPlanGridViewPivot";
    /** The "Board" view pivot in the basic plan view */ IrisAnchors["BasicPlanBoardViewPivot"] = "BasicPlanBoardViewPivot";
    /** The "Schedule" view pivot in the basic plan view */ IrisAnchors["BasicPlanScheduleViewPivot"] = "BasicPlanScheduleViewPivot";
    /** The "Charts" pivot in the basic plan view */ IrisAnchors["BasicPlanChartsViewPivot"] = "BasicPlanChartsViewPivot";
    /** The second column header of the basic plan grid view */ IrisAnchors["BasicPlanGridViewSecondColumn"] = "BasicPlanGridViewSecondColumn";
    /** The "Share" button in the basic plan view */ IrisAnchors["BasicPlanShareButton"] = "BasicPlanShareButton";
    /** The "..." button in the basic plan view */ IrisAnchors["BasicPlanMoreOptions"] = "BasicPlanMoreOptions";
    /** The "Filters" button in the basic plan view */ IrisAnchors["BasicPlanFilters"] = "BasicPlanFilters";
    /** The "Filters" button in the my tasks view */ IrisAnchors["MyTasksFilters"] = "MyTasksFilters";
    /** The "..." button in the basic task details dialog */ IrisAnchors["BasicTaskDetailsMoreOptions"] = "BasicTaskDetailsMoreOptions";
    /** The start date calender icon in the basic task details dialog */ IrisAnchors["BasicTaskDetailsStartDateCalendarIcon"] = "BasicTaskDetailsStartDateCalendarIcon";
    /** The "Board" view pivot in the premium plan view */ IrisAnchors["PremiumPlanBoardViewPivot"] = "premiumPlanBoardViewPivot";
    /** The copilot button */ IrisAnchors["CopilotButton"] = "CopilotButton";
    /** The bottom right of the screen (no anchor element) */ IrisAnchors["BottomRight"] = "BottomRight";
    /** The "Share" button in the plan header, regardless if in basic or premium plan view */ IrisAnchors["PlanShareButton"] = "PlanShareButton";
    /** The Goals section in the Gsd plan Home view */ IrisAnchors["GsdHomeGoalsSection"] = "GsdHomeGoalsSection";
    /** The Resources section in the Gsd plan Home view */ IrisAnchors["GsdHomeResourcesSection"] = "GsdHomeResourcesSection";
})(IrisAnchors || (IrisAnchors = {}));
export var IrisSurfacePlacementIds;
(function(IrisSurfacePlacementIds) {
    /** The external surface id to use in production */ IrisSurfacePlacementIds["PlannerExternal"] = "88001031";
    /** The internal surface id to use for development */ IrisSurfacePlacementIds["PlannerSelfhostInternal"] = "88001030";
})(IrisSurfacePlacementIds || (IrisSurfacePlacementIds = {}));
export var IrisTriggerAction;
(function(IrisTriggerAction) {
    /** My Day left nav option opened */ IrisTriggerAction["MyDayOpen"] = "MyDayOpen";
    /** My Tasks left nav option opened */ IrisTriggerAction["MyTasksOpen"] = "MyTasksOpen";
    /** My Plans left nav option opened */ IrisTriggerAction["MyPlansOpen"] = "MyPlansOpen";
    /** Any Basic Plan view opened */ IrisTriggerAction["BasicPlanViewOpen"] = "BasicPlanViewOpen";
    /** Grid view pivot opened in Basic plan */ IrisTriggerAction["BasicGridViewOpen"] = "BasicGridViewOpen";
    /** Board view pivot opened in Basic plan */ IrisTriggerAction["BasicBoardViewOpen"] = "BasicBoardViewOpen";
    /** Schedule view pivot opened in Basic plan */ IrisTriggerAction["BasicScheduleViewOpen"] = "BasicScheduleViewOpen";
    /** Charts view pivot opened in Basic plan */ IrisTriggerAction["BasicChartsViewOpen"] = "BasicChartsViewOpen";
    /** Grid view pivot opened in Premium plan */ IrisTriggerAction["PremiumGridViewOpen"] = "PremiumGridViewOpen";
    /** Board view pivot opened in Premium plan */ IrisTriggerAction["PremiumBoardViewOpen"] = "PremiumBoardViewOpen";
    /** Charts view pivot opened in Premium plan */ IrisTriggerAction["PremiumChartsViewOpen"] = "PremiumChartsViewOpen";
    /** GSD Home view pivot opened in Premium plan */ IrisTriggerAction["PremiumGsdHomeViewOpen"] = "PremiumGsdHomeViewOpen";
    /** GSD Board view pivot opened in Premium plan */ IrisTriggerAction["PremiumGsdBoardViewOpen"] = "PremiumGsdBoardViewOpen";
    /** Goals view pivot opened in Premium plan */ IrisTriggerAction["PremiumGoalsViewOpen"] = "PremiumGoalsViewOpen";
    /** Timeline view pivot opened in Premium plan */ IrisTriggerAction["PremiumTimelineViewOpen"] = "PremiumTimelineViewOpen";
    /** Plan view opened to any pivot (basic or premium) */ IrisTriggerAction["PlanViewOpen"] = "PlanViewOpen";
})(IrisTriggerAction || (IrisTriggerAction = {}));
export var IrisCtaAction;
(function(IrisCtaAction) {
    /** Redirect to provided link */ IrisCtaAction["LinkRedirect"] = "LinkRedirect";
    /** Start premium trial */ IrisCtaAction["Trial"] = "Trial";
    /** Request trial */ IrisCtaAction["RequestFlow"] = "RequestFlow";
    /** Dismiss campaign creative */ IrisCtaAction["Dismiss"] = "Dismiss";
    /** Go to the next step of the campaign creative */ IrisCtaAction["NextStep"] = "NextStep";
})(IrisCtaAction || (IrisCtaAction = {}));
export var IrisPremiumStatusValue;
(function(IrisPremiumStatusValue) {
    /** The user is eligible for a request premium subscription flow */ IrisPremiumStatusValue["IsRequestFlowEligible"] = "IsRequestFlowEligible";
    /** The user is eligible for a premium trial */ IrisPremiumStatusValue["IsTrialEligible"] = "IsTrialEligible";
    /** The user is not eligible for a premium trial or request flow */ IrisPremiumStatusValue["IsNotEligible"] = "IsNotEligible";
    /** The user has a premium trial */ IrisPremiumStatusValue["HasPremiumTrial"] = "HasPremiumTrial";
    /** The user has a premium subscription */ IrisPremiumStatusValue["HasPremiumPaid"] = "HasPremiumPaid";
})(IrisPremiumStatusValue || (IrisPremiumStatusValue = {}));
export var IrisActionBeaconNames;
(function(IrisActionBeaconNames) {
    /** The user dismissed the campaign creative */ IrisActionBeaconNames["Dismiss"] = "dismiss";
    /** The user clicked a CTA button in the campaign callout */ IrisActionBeaconNames["Click"] = "click";
    /** The user interacted with the campaign to completion */ IrisActionBeaconNames["Conversion"] = "conversion";
})(IrisActionBeaconNames || (IrisActionBeaconNames = {}));
/**
 * Map of relevant flights to send to Iris as a real time signal -> Iris friendly name.
 * Can be used to target users with specific flights enabled in Iris Studio.
 * Iris friendly name requirements:
 *    - Must not be long
 *    - Must be unique across all flights.
 *    - If possible, use the ECS config id which is 7 chars long
 */ export const FlightIdsToIrisFriendlyNameMap = {
    EnableDefaultTaskFilterIrisCallout: "1471195",
    EnableGSDInPremiumPlan: "1257836"
};
