/**
 * MruType is an ENUM that defines a different types of document, place or future "things" we don't know about yet.
 * Reference: https://www.owiki.ms/wiki/ICE_Services/Recent_Documents/MRU_V2_API#MRU_Applications
 */ export var MruType;
(function(MruType) {
    /**
     * Original Decoded DAV Based Document and Place URLs. Used in O15 and O16 MSI.
     * All Office client MRU uses this type.
     */ MruType["ClassicMru"] = "ClassicMru";
    /**
     * WOPI based documents used for Exchange attachments that are stored in the in the user's mailbox as part of a e-mail.
     * Requires WOPI to open/save document. Placeholder, not currently in use.
     */ MruType["ClassicAttachment"] = "ClassicAttachment";
    /**
     * DAV Based URL used for Exchange attachments that were uploaded to the cloud and shared to one or more people via email.
     * Uses DAV URLs similar to ClassicMru. Placeholder, not currently in use.
     */ MruType["ModernAttachment"] = "ModernAttachment";
    /**
     * DAV Based URL used for Exchange attachments that were uploaded to the cloud and shared to one or more people via email.
     * Uses DAV URLs similar to ClassicMru. Placeholder, not currently in use.
     */ MruType["WopiThirdParty"] = "WopiThirdParty";
    /**
     * DAV Based URL used for Exchange attachments that were uploaded to the cloud and shared to one or more people via email.
     * Uses DAV URLs similar to ClassicMru. Placeholder, not currently in use.
     */ MruType["DocumentUrl"] = "DocumentUrl";
})(MruType || (MruType = {}));
