// Actions
import { DynamicImportFailureAction } from "@ms/uno-actions/lib/local/app/DynamicImportFailureAction";
// Loader
import { ActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/ActionCreator";
import { createActionCreatorProxy } from "../../utilities/loader/LoaderUtilities";
import { NotSupportedError } from "@ms/uno-errors/lib/local/errors";
// Static action creators
import { PerformanceCollectionActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/performanceCollection/PerformanceCollectionActionCreator";
import { RoutingActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/routing/RoutingActionCreator";
import { DataPreFetchActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/dataPreFetch/DataPreFetchActionCreator";
/** Factory class for action creator providers in Base app */
export class BaseActionCreatorFactory {
    // #endregion
    /**
     * Initialize the action creator factory
     * @param loggers Loggers for telemetry
     */
    constructor(loader, dispatcher, modules, stores, configProvider, loggers) {
        this.loader = loader;
        this.dispatcher = dispatcher;
        this.modules = modules;
        this.stores = stores;
        this.configProvider = configProvider;
        this.loggers = loggers;
        this.loadActivityAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Activity), () => import(
        /* webpackChunkName: "uno-activityac" */ "@ms/uno-actioncreators/lib/local/actioncreators/activity/ActivityActionCreator"), (bundle) => new bundle.ActivityActionCreator(this.dispatcher));
        this.loadAppContextAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.AppContext), () => import(
        /* webpackChunkName: "uno-appcontextac" */ "@ms/uno-actioncreators/lib/local/actioncreators/appContext/AppContextActionCreator"), (bundle) => new bundle.AppContextActionCreator(this.dispatcher, this.modules, this.loggers));
        this.loadBasicBucketAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.BasicBucket), () => import(
        /* webpackChunkName: "uno-basicbucketac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/bucket/BasicBucketActionCreator"), (bundle) => new bundle.BasicBucketActionCreator(this.stores, this.modules, this.loggers));
        this.loadBasicPlanAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.BasicPlan), () => import(
        /* webpackChunkName: "uno-basicplanac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/plan/BasicPlanActionCreator"), (bundle) => new bundle.BasicPlanActionCreator(this.modules, this.stores, this.dispatcher, this.loggers, this.configProvider));
        this.loadBasicTaskAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.BasicTask), () => import(
        /* webpackChunkName: "uno-basictaskac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/task/BasicTaskActionCreator"), (bundle) => new bundle.BasicTaskActionCreator(this.dispatcher, this.stores, this.modules, this.loggers, this.configProvider));
        this.loadCampaignCalloutViewAcProvider = () => this.loader.load(this.getAcBundleKey(ActionCreator.CampaignCalloutView), () => import(
        /* webpackChunkName: "uno-campaigncalloutviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CampaignCalloutViewActionCreator"), (bundle) => new bundle.CampaignCalloutViewActionCreator(this.modules, this.stores, this.loggers));
        this.loadCapabilitiesAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Capabilities), () => import(
        /* webpackChunkName: "uno-capabilitiesac" */ "@ms/uno-actioncreators/lib/local/actioncreators/capabilities/CapabilitiesActionCreator"), (bundle) => new bundle.CapabilitiesActionCreator(this.modules, this.loggers));
        this.loadContainerAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Container), () => import(
        /* webpackChunkName: "uno-containerac" */ "@ms/uno-actioncreators/lib/local/actioncreators/container/ContainerActionCreator"), (bundle) => new bundle.ContainerActionCreator(this.modules, this.loggers));
        this.loadCreateTaskFromTeamsMessageAc = () => {
            throw new NotSupportedError(`CreateTaskFromTeamsMessageAc`);
        };
        this.loadDeltaSyncAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.DeltaSync), () => import(
        /* webpackChunkName: "uno-deltasyncac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/deltaSync/DeltaSyncActionCreator"), (bundle) => new bundle.DeltaSyncActionCreator(this.modules, this.stores, this.dispatcher, this.loggers, this.configProvider));
        this.loadDriveItemAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.DriveItem), () => import(
        /* webpackChunkName: "uno-driveitemac" */ "@ms/uno-actioncreators/lib/local/actioncreators/driveItem/DriveItemActionCreator"), (bundle) => new bundle.DriveItemActionCreator(this.modules, this.loggers));
        this.loadExportAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Export), () => import(
        /* webpackChunkName: "uno-excelexportac" */ "@ms/uno-actioncreators/lib/local/actioncreators/export/ExportActionCreator"), (bundle) => new bundle.ExportActionCreator(this.dispatcher, this.stores, this.modules, this.loggers));
        this.loadGroupsAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Groups), () => import(/* webpackChunkName: "uno-groupsac" */ "@ms/uno-actioncreators/lib/local/actioncreators/groups/GroupsActionCreator"), (bundle) => new bundle.GroupsActionCreator(this.dispatcher, this.stores, this.modules, this.loggers, this.configProvider));
        this.loadIntelligenceAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Intelligence), () => import(
        /* webpackChunkName: "uno-intelligenceac" */ "@ms/uno-actioncreators/lib/local/actioncreators/intelligence/IntelligenceActionCreator"), (bundle) => new bundle.IntelligenceActionCreator(this.modules, this.dispatcher, this.stores, this.configProvider, this.loggers));
        this.loadLinksAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Links), () => import(/* webpackChunkName: "uno-linksac" */ "@ms/uno-actioncreators/lib/local/actioncreators/links/LinksActionCreator"), (bundle) => new bundle.LinksActionCreator(this.modules, this.dispatcher));
        this.loadModalAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Modal), () => import(/* webpackChunkName: "uno-modalac" */ "@ms/uno-actioncreators/lib/local/actioncreators/modal/ModalActionCreator"), (bundle) => new bundle.ModalActionCreator(this.dispatcher, this.stores, this.loggers, this.configProvider));
        this.loadMruAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Mru), () => import(/* webpackChunkName: "uno-mruac" */ "@ms/uno-actioncreators/lib/local/actioncreators/mru/MruActionCreator"), (bundle) => new bundle.MruActionCreator(this.modules, this.stores, this.loggers));
        this.loadNotificationAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Notification), () => import(
        /* webpackChunkName: "uno-notificationac" */ "@ms/uno-actioncreators/lib/local/actioncreators/notification/NotificationActionCreator"), (bundle) => new bundle.NotificationActionCreator(this.dispatcher, this.modules));
        this.loadOCVAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.OCV), () => import(/* webpackChunkName: "uno-ocvac" */ "@ms/uno-actioncreators/lib/local/actioncreators/OCV/OCVActionCreator"), (bundle) => new bundle.OCVActionCreator(this.modules, this.stores, this.loggers, this.configProvider));
        this.loadOperationTrackingAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.OperationTracking), () => import(
        /* webpackChunkName: "uno-operationtrackingac" */ "@ms/uno-actioncreators/lib/local/actioncreators/operationTracking/OperationTrackingActionCreator"), (bundle) => new bundle.OperationTrackingActionCreator(this.dispatcher, this.modules, this.stores, this.loggers, this.configProvider));
        this.loadOrgAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Org), () => import(/* webpackChunkName: "uno-orgac" */ "@ms/uno-actioncreators/lib/local/actioncreators/org/OrgActionCreator"), (bundle) => new bundle.OrgActionCreator(this.modules, this.loggers, this.configProvider));
        this.loadPlanConversionAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.PlanConversion), () => import(
        /* webpackChunkName: "uno-planconversionac" */ "@ms/uno-actioncreators/lib/local/actioncreators/upsell/PlanConversionActionCreator"), (bundle) => new bundle.PlanConversionActionCreator(this.configProvider, this.modules, this.stores, this.loggers));
        this.loadPortfolioAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Portfolio), () => import(
        /* webpackChunkName: "uno-portfolioac" */ "@ms/uno-actioncreators/lib/local/actioncreators/portfolio/PortfolioActionCreator"), (bundle) => new bundle.PortfolioActionCreator(this.dispatcher, this.modules, this.stores, this.loggers));
        this.loadPublishingAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Publishing), () => import(
        /* webpackChunkName: "uno-publishingac" */ "@ms/uno-actioncreators/lib/local/actioncreators/publishing/PublishingActionCreator"), (bundle) => new bundle.PublishingActionCreator());
        this.loadPremiumPlanAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.PremiumPlan), () => import(
        /* webpackChunkName: "uno-premiumplanac" */ "@ms/uno-actioncreators/lib/local/actioncreators/project/premiumPlan/PremiumPlanActionCreator"), (bundle) => new bundle.PremiumPlanActionCreator(this.modules, this.stores, this.dispatcher, this.loggers, this.configProvider));
        this.loadProjectHomeResourceAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.ProjectHomeResource), () => import(
        /* webpackChunkName: "uno-projecthomeresourceac" */ "@ms/uno-actioncreators/lib/local/actioncreators/projectHome/ProjectHomeResourceActionCreator"), (bundle) => new bundle.ProjectHomeResourceActionCreator(this.dispatcher, this.modules, this.stores, this.configProvider, this.loggers));
        this.loadRecommendedPlansAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.RecommendedPlans), () => import(
        /* webpackChunkName: "uno-recommendedplansac" */ "@ms/uno-actioncreators/lib/local/actioncreators/recommendedPlan/RecommendedPlansActionCreator"), (bundle) => new bundle.RecommendedPlansActionCreator(this.dispatcher, this.modules, this.stores, this.loggers));
        this.loadTeamsAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Teams), () => import(/* webpackChunkName: "uno-teamsac" */ "@ms/uno-actioncreators/lib/local/actioncreators/teams/TeamsActionCreator"), (bundle) => new bundle.TeamsActionCreator(this.modules, this.loggers));
        this.loadTeamsTabAc = () => {
            throw new NotSupportedError("TeamsTabAc");
        };
        this.loadTemplateAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Template), () => import(
        /* webpackChunkName: "uno-templateac" */ "@ms/uno-actioncreators/lib/local/actioncreators/templates/TemplateActionCreator"), (bundle) => new bundle.TemplateActionCreator(this.modules, this.stores, this.dispatcher, this.loggers, this.configProvider));
        this.loadTenantAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Tenant), () => import(/* webpackChunkName: "uno-tenantac" */ "@ms/uno-actioncreators/lib/local/actioncreators/tenant/TenantActionCreator"), (bundle) => new bundle.TenantActionCreator(this.modules, this.loggers));
        this.loadUnoShellAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.UnoShell), () => import(
        /* webpackChunkName: "uno-unoshellac" */ "@ms/uno-actioncreators/lib/local/actioncreators/unoShell/UnoShellActionCreator"), (bundle) => new bundle.UnoShellActionCreator(this.dispatcher));
        this.loadUpsellAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Upsell), () => import(/* webpackChunkName: "uno-upsellac" */ "@ms/uno-actioncreators/lib/local/actioncreators/upsell/UpsellActionCreator"), (bundle) => new bundle.UpsellActionCreator(this.dispatcher, this.configProvider, this.modules, this.stores, this.loggers));
        this.loadUserAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.User), () => import(/* webpackChunkName: "uno-userac" */ "@ms/uno-actioncreators/lib/local/actioncreators/user/UserActionCreator"), (bundle) => new bundle.UserActionCreator(this.dispatcher, this.modules, this.stores, this.loggers));
        this.loadBasicPlanViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.BasicPlanView), () => import(
        /* webpackChunkName: "uno-basicplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/BasicPlanViewActionCreator"), (bundle) => new bundle.BasicPlanViewActionCreator(this.modules, this.stores, this.loggers, this.configProvider));
        this.loadCreatePortfolioViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.CreatePortfolioView), () => import(
        /* webpackChunkName: "uno-createportfolioviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreatePortfolioViewActionCreator"), (bundle) => new bundle.CreatePortfolioViewActionCreator(this.modules, this.loggers));
        this.loadCreatePlanViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.CreatePlanView), () => import(
        /* webpackChunkName: "uno-createplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreatePlanViewActionCreator"), (bundle) => new bundle.CreatePlanViewActionCreator(this.modules, this.loggers, this.configProvider));
        this.loadCopyPlanViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.CopyPlanView), () => import(
        /* webpackChunkName: "uno-copyplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CopyPlanViewActionCreator"), (bundle) => new bundle.CopyPlanViewActionCreator(this.modules, this.loggers, this.configProvider));
        this.loadLeftNavViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.LeftNavView), () => import(
        /* webpackChunkName: "uno-leftnavviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/LeftNavViewActionCreator"), (bundle) => new bundle.LeftNavViewActionCreator(this.modules, this.stores, this.loggers, this.configProvider));
        this.loadMyDayViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.MyDayView), () => import(
        /* webpackChunkName: "uno-mydayviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyDayViewActionCreator"), (bundle) => new bundle.MyDayViewActionCreator(this.modules, this.loggers, this.configProvider));
        this.loadMyPlansViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.MyPlansView), () => import(
        /* webpackChunkName: "uno-myplansviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyPlansViewActionCreator"), (bundle) => new bundle.MyPlansViewActionCreator(this.modules, this.loggers));
        this.loadMyTasksViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.MyTasksView), () => import(
        /* webpackChunkName: "uno-mytasksviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyTasksViewActionCreator"), (bundle) => new bundle.MyTasksViewActionCreator(this.modules, this.stores, this.loggers, this.configProvider));
        this.loadMyPortfoliosViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.MyPortfoliosView), () => import(
        /* webpackChunkName: "uno-myportfoliosviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyPortfoliosViewActionCreator"), (bundle) => new bundle.MyPortfoliosViewActionCreator(this.modules, this.loggers));
        this.loadUnoShellViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.UnoShellView), () => import(
        /* webpackChunkName: "uno-unoshellviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/UnoShellViewActionCreator"), (bundle) => new bundle.UnoShellViewActionCreator(this.dispatcher, this.modules, this.stores, this.loggers, this.configProvider));
        this.loadViewTransformsAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.ViewTransforms), () => import(
        /* webpackChunkName: "uno-viewtransformsac" */ "@ms/uno-actioncreators/lib/local/actioncreators/viewTransforms/ViewTransformsActionCreator"), (bundle) => new bundle.ViewTransformsActionCreator(this.dispatcher, this.modules));
        this.loadConvertPlanViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.ConvertPlanView), () => import(
        /* webpackChunkName: "uno-convertplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/ConvertPlanViewActionCreator"), (bundle) => new bundle.ConvertPlanViewActionCreator(this.modules, this.loggers));
        this.loadSharePlanViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.SharePlanView), () => import(
        /* webpackChunkName: "uno-shareplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/SharePlanViewActionCreator"), (bundle) => new bundle.SharePlanViewActionCreator(this.dispatcher, this.modules, this.stores, this.loggers, this.configProvider));
        this.loadCreateTeamsTabViewAc = () => {
            throw new NotSupportedError("CreateTeamsTabViewAc");
        };
        this.loadCampaignAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Campaign), () => import(
        /* webpackChunkName: "uno-campaignac" */ "@ms/uno-actioncreators/lib/local/actioncreators/campaign/CampaignActionCreator"), (bundle) => new bundle.CampaignActionCreator(this.dispatcher, this.modules, this.stores, this.loggers));
        this.loadPlannerTaskAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.BasicTask), () => import(
        /* webpackChunkName: "uno-basictaskac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/task/BasicTaskActionCreator"), (bundle) => new bundle.BasicTaskActionCreator(this.dispatcher, this.stores, this.modules, this.loggers, this.configProvider));
        this.loadTemplatesAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.Template), () => import(
        /* webpackChunkName: "uno-templateac" */ "@ms/uno-actioncreators/lib/local/actioncreators/templates/TemplateActionCreator"), (bundle) => new bundle.TemplateActionCreator(this.modules, this.stores, this.dispatcher, this.loggers, this.configProvider));
        this.loadPublishingLeftNavViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.PublishingLeftNavView), () => import(
        /* webpackChunkName: "publishingleftnavviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/PublishingLeftNavViewActionCreator"), (bundle) => new bundle.PublishingLeftNavViewActionCreator());
        this.loadCreateTaskViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.CreateTaskView), () => import(
        /* webpackChunkName: "uno-createtaskviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreateTaskViewActionCreator"), (bundle) => new bundle.CreateTaskViewActionCreator(this.modules, this.stores, this.loggers));
        this.loadSuiteNavViewAc = () => {
            throw new NotSupportedError("SuiteNavViewAc");
        };
        this.loadNewsBarAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.NewsBar), () => import(
        /* webpackChunkName: "uno-newsbarac" */ "@ms/uno-actioncreators/lib/local/actioncreators/newsBar/NewsBarActionCreator"), (bundle) => new bundle.NewsBarActionCreator(this.dispatcher));
        /** Map of all functions that load an action creator */
        this.actionCreatorLoaders = {
            [ActionCreator.Activity]: this.loadActivityAc,
            [ActionCreator.AppContext]: this.loadAppContextAc,
            [ActionCreator.BasicBucket]: this.loadBasicBucketAc,
            [ActionCreator.BasicPlan]: this.loadBasicPlanAc,
            [ActionCreator.BasicTask]: this.loadBasicTaskAc,
            [ActionCreator.DataPreFetch]: this.getDataPreFetchAcProvider().bind(this),
            [ActionCreator.Campaign]: this.loadCampaignAc,
            [ActionCreator.Capabilities]: this.loadCapabilitiesAc,
            [ActionCreator.Container]: this.loadContainerAc,
            [ActionCreator.CreateTaskFromTeamsMessage]: this.loadCreateTaskFromTeamsMessageAc,
            [ActionCreator.DeltaSync]: this.loadDeltaSyncAc,
            [ActionCreator.DriveItem]: this.loadDriveItemAc,
            [ActionCreator.Export]: this.loadExportAc,
            [ActionCreator.Groups]: this.loadGroupsAc,
            [ActionCreator.Intelligence]: this.loadIntelligenceAc,
            [ActionCreator.Links]: this.loadLinksAc,
            [ActionCreator.Modal]: this.loadModalAc,
            [ActionCreator.Mru]: this.loadMruAc,
            [ActionCreator.NewsBar]: this.loadNewsBarAc,
            [ActionCreator.Notification]: this.loadNotificationAc,
            [ActionCreator.OCV]: this.loadOCVAc,
            [ActionCreator.OperationTracking]: this.loadOperationTrackingAc,
            [ActionCreator.Org]: this.loadOrgAc,
            [ActionCreator.PerformanceCollection]: this.getPerformanceCollectionAcProvider(),
            [ActionCreator.PlanConversion]: this.loadPlanConversionAc,
            [ActionCreator.Portfolio]: this.loadPortfolioAc,
            [ActionCreator.Publishing]: this.loadPublishingAc,
            [ActionCreator.PremiumPlan]: this.loadPremiumPlanAc,
            [ActionCreator.ProjectHomeResource]: this.loadProjectHomeResourceAc,
            [ActionCreator.RecommendedPlans]: this.loadRecommendedPlansAc,
            [ActionCreator.Routing]: this.getRoutingAcProvider().bind(this),
            [ActionCreator.Teams]: this.loadTeamsAc,
            [ActionCreator.TeamsTab]: this.loadTeamsTabAc,
            [ActionCreator.Template]: this.loadTemplateAc,
            [ActionCreator.Tenant]: this.loadTenantAc,
            [ActionCreator.UnoShell]: this.loadUnoShellAc,
            [ActionCreator.Upsell]: this.loadUpsellAc,
            [ActionCreator.User]: this.loadUserAc,
            [ActionCreator.ViewTransforms]: this.loadViewTransformsAc,
            [ActionCreator.BasicPlanView]: this.loadBasicPlanViewAc,
            [ActionCreator.CampaignCalloutView]: this.loadCampaignCalloutViewAcProvider,
            [ActionCreator.CreatePortfolioView]: this.loadCreatePortfolioViewAc,
            [ActionCreator.CreatePlanView]: this.loadCreatePlanViewAc,
            [ActionCreator.CreateTaskView]: this.loadCreateTaskViewAc,
            [ActionCreator.CreateTeamsTabView]: this.loadCreateTeamsTabViewAc,
            [ActionCreator.CopyPlanView]: this.loadCopyPlanViewAc,
            [ActionCreator.LeftNavView]: this.loadLeftNavViewAc,
            [ActionCreator.MyDayView]: this.loadMyDayViewAc,
            [ActionCreator.MyTasksView]: this.loadMyTasksViewAc,
            [ActionCreator.MyPlansView]: this.loadMyPlansViewAc,
            [ActionCreator.MyPortfoliosView]: this.loadMyPortfoliosViewAc,
            [ActionCreator.UnoShellView]: this.loadUnoShellViewAc,
            [ActionCreator.ConvertPlanView]: this.loadConvertPlanViewAc,
            [ActionCreator.SharePlanView]: this.loadSharePlanViewAc,
            [ActionCreator.PublishingLeftNavView]: this.loadPublishingLeftNavViewAc,
            [ActionCreator.SuiteNavView]: this.loadSuiteNavViewAc,
        };
        this.actionCreatorSynchronousProxyLoaders = createProxyLoaders(this.actionCreatorLoaders, this.loggers);
        this.performanceCollection = new PerformanceCollectionActionCreator(this.dispatcher, this.stores, this.loggers, this.configProvider);
        this.dataPrefetch = new DataPreFetchActionCreator(this.stores, this.dispatcher, this.loggers);
        this.routing = new RoutingActionCreator(this.dispatcher, this.modules, this.stores, this.loggers);
    }
    getActivityAcProvider() {
        return this.loadActivityAc;
    }
    getAppContextAcProvider() {
        return this.loadAppContextAc;
    }
    getBasicBucketAcProvider() {
        return this.loadBasicBucketAc;
    }
    getBasicPlanAcProvider() {
        return this.loadBasicPlanAc;
    }
    getBasicTaskAcProvider() {
        return this.loadBasicTaskAc;
    }
    getDataPreFetchAcProvider() {
        return () => this.dataPrefetch;
    }
    getCapabilitiesAcProvider() {
        return this.loadCapabilitiesAc;
    }
    getContainerAcProvider() {
        return this.loadContainerAc;
    }
    getCreateTaskFromTeamsMessageAcProvider() {
        return this.loadCreateTaskFromTeamsMessageAc;
    }
    getDeltaSyncAcProvider() {
        return this.loadDeltaSyncAc;
    }
    getDriveItemAcProvider() {
        return this.loadDriveItemAc;
    }
    getExportAcProvider() {
        return this.loadExportAc;
    }
    getGroupsAcProvider() {
        return this.loadGroupsAc;
    }
    getIntelligenceAcProvider() {
        return this.loadIntelligenceAc;
    }
    getLinksAcProvider() {
        return this.loadLinksAc;
    }
    getModalAcProvider() {
        return this.loadModalAc;
    }
    getMruAcProvider() {
        return this.loadMruAc;
    }
    getNotificationAcProvider() {
        return this.loadNotificationAc;
    }
    getOCVAcProvider() {
        return this.loadOCVAc;
    }
    getOperationTrackingAcProvider() {
        return this.loadOperationTrackingAc;
    }
    getOrgAcProvider() {
        return this.loadOrgAc;
    }
    getPerformanceCollectionAcProvider() {
        return () => this.performanceCollection;
    }
    getPublishingAcProvider() {
        return this.loadPublishingAc;
    }
    getPlanConversionAcProvider() {
        return this.loadPlanConversionAc;
    }
    getPortfolioAcProvider() {
        return this.loadPortfolioAc;
    }
    getPremiumPlanAcProvider() {
        return this.loadPremiumPlanAc;
    }
    getResourceAcProvider() {
        return this.loadProjectHomeResourceAc;
    }
    getRecommendedPlansAcProvider() {
        return this.loadRecommendedPlansAc;
    }
    getRoutingAcProvider() {
        return () => this.routing;
    }
    getTeamsAcProvider() {
        return this.loadTeamsAc;
    }
    getTeamsTabAcProvider() {
        return this.loadTeamsTabAc;
    }
    getTemplateAcProvider() {
        return this.loadTemplateAc;
    }
    getTenantAcProvider() {
        return this.loadTenantAc;
    }
    getUpsellAcProvider() {
        return this.loadUpsellAc;
    }
    getUserAcProvider() {
        return this.loadUserAc;
    }
    getViewTransformsAcProvider() {
        return this.loadViewTransformsAc;
    }
    getBasicPlanViewAcProvider() {
        return this.loadBasicPlanViewAc;
    }
    getCreatePortfolioViewAcProvider() {
        return this.loadCreatePortfolioViewAc;
    }
    getCreatePlanViewAcProvider() {
        return this.loadCreatePlanViewAc;
    }
    getCopyPlanViewAcProvider() {
        return this.loadCopyPlanViewAc;
    }
    getConvertPlanViewAcProvider() {
        return this.loadConvertPlanViewAc;
    }
    getLeftNavViewAcProvider() {
        return this.loadLeftNavViewAc;
    }
    getMyDayViewAcProvider() {
        return this.loadMyDayViewAc;
    }
    getMyPlansViewAcProvider() {
        return this.loadMyPlansViewAc;
    }
    getMyTasksViewAcProvider() {
        return this.loadMyTasksViewAc;
    }
    getMyPortfoliosViewAcProvider() {
        return this.loadMyPortfoliosViewAc;
    }
    getUnoShellViewAcProvider() {
        return this.loadUnoShellViewAc;
    }
    getSharePlanViewAcProvider() {
        return this.loadSharePlanViewAc;
    }
    getCampaignCalloutViewAcProvider() {
        return this.loadCampaignCalloutViewAcProvider;
    }
    getCampaignAcProvider() {
        return this.loadCampaignAc;
    }
    getUnoShellAcProvider() {
        return this.loadUnoShellAc;
    }
    getPublishingLeftNavViewAcProvider() {
        return this.loadPublishingLeftNavViewAc;
    }
    getPlannerTaskAcProvider() {
        return this.loadPlannerTaskAc;
    }
    getTemplatesAcProvider() {
        return this.loadTemplatesAc;
    }
    getCreateTaskViewAcProvider() {
        return this.loadCreateTaskViewAc;
    }
    getSuiteNavViewAcProvider() {
        return this.loadSuiteNavViewAc;
    }
    getCreateTeamsTabViewAcProvider() {
        return this.loadCreateTeamsTabViewAc;
    }
    load(actionCreator) {
        return this.actionCreatorSynchronousProxyLoaders[actionCreator]();
    }
    /**
     * Handle import error: dispatch action to route to the global error page
     * @param dispatcher The dispatcher the SPA uses to dispatch actions
     */
    async handleImportError(dispatcher) {
        await dispatcher.dispatchActionAsync(new DynamicImportFailureAction());
    }
    getAcBundleKey(actionCreator) {
        return `uno-${actionCreator}ac`;
    }
}
/**
 * Utility method to create proxy loaders from dynamic loaders
 * @param dynamicLoaders Dynamic loaders that load action creators async
 * @param loggers Loggers for error telemetry
 */
function createProxyLoaders(dynamicLoaders, loggers) {
    return Object.keys(dynamicLoaders).reduce((acc, componentName) => {
        acc[componentName] = () => createActionCreatorProxy(componentName, dynamicLoaders[componentName], loggers);
        return acc;
    }, {});
}
