import { compareStringOrdinal } from "./Comparators";
/**
 * Creates an expression used by the service to calculate a new order hint based on lowOrderHint and highOrderhint.
 *
 * for null, null => orderhint should be " !"
 * for A, null => orderhint should be "A !"
 * for null, B => orderhint should be " B!"
 * for A, B => orderhint should be "A B!"
 * @param lowOrderHint Smaller order hint
 * @param highOrderHint Bigger order hint
 */ export function generateOrderHintBetween(lowOrderHint, highOrderHint) {
    if (lowOrderHint && highOrderHint && compareStringOrdinal(lowOrderHint, highOrderHint) > 0) {
        throw new Error("ArgumentException: lowOrderHint cannot be higher than highOrderHint");
    }
    const lowValue = lowOrderHint || "";
    const highValue = highOrderHint || "";
    return lowValue + " " + highValue + "!";
}
