// Worker
// Utilities
import { extractMyTasksRoutingDetailsFromSubRoute, extractPlanRoutingDetailsFromSubRoute } from "@ms/uno-routing/lib/local/utilities/RouteUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Constants
import { ClientFlavor } from "@ms/uno-constants/lib/local/AppConstants";
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { MarkerTag, ViewDataFetchState } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
import { DataPreFetchCompletedEventName, PreFetchedDataTypes } from "@ms/uno-constants/lib/local/DataPreFetcherConstants";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
import { ActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/ActionCreator";
/**
 * Data fetch worker
 */ export class DataFetchWorker {
    init() {
        if (this.initialized) {
            return;
        }
        // Fetch the data for the initial route
        this.fetchDataForTheInitialRoute();
        // Subscribe to routeStore to fetch view data based on route change,
        // not waiting on the respective view to be loaded
        this.routeStore.subscribeToStore(this.fetchViewDataBasedOnRoute);
        this.initialized = true;
    }
    /**
     * Stops the worker by removing the event listeners
     */ stop() {
        this.routeStore.unsubscribeFromStore(this.fetchViewDataBasedOnRoute);
        this.initialized = false;
        this.lastRoute = undefined;
    }
    /**
     * Fetch the data for the initial route
     */ async fetchDataForTheInitialRoute() {
        // Wait for data pre-fetch completion before triggering data fetch for initial route
        await this.waitForDataPreFetchCompletion();
        this.fetchViewDataBasedOnRoute();
        if (this.shouldFetchDataForLeftNav()) {
            const leftNavViewAc = this.acLoader.load(ActionCreator.LeftNavView);
            leftNavViewAc.fetchViewData();
        }
    }
    /**
     * Check if the data should be fetched for the left nav
     */ shouldFetchDataForLeftNav() {
        const { clientFlavor } = this.configProvider().sessionMetaData.appMetadata;
        const subRouteType = this.routeStore.getCurrentSubRouteType();
        return clientFlavor !== ClientFlavor.TaskFromMessage && clientFlavor !== ClientFlavor.Tab && subRouteType !== SubRouteType.ConfigTeamsTab && subRouteType !== SubRouteType.RemoveTeamsTab;
    }
    /**
     * Fetch data for the MyDay view
     */ fetchMyDayViewData() {
        const myDayViewAc = this.acLoader.load(ActionCreator.MyDayView);
        myDayViewAc.fetchViewData();
    }
    /**
     * Fetch data for the MyTasks view
     */ fetchMyTasksViewData() {
        const currentSubRoute = this.routeStore.getCurrentSubRoute();
        const myTasksRoutingDetails = extractMyTasksRoutingDetailsFromSubRoute(currentSubRoute, SubRouteType.MyTasks);
        if (!myTasksRoutingDetails) {
            return;
        }
        const myTasksViewAc = this.acLoader.load(ActionCreator.MyTasksView);
        /**
         * MyTasks view load is the initialLoad if any of the following conditions are met:
         * 1. It it the initial route i.e lastRoute is undefined
         * 2. The last route is not MyTasks view
         * 3. The last route is MyTasks view but have current route doesn't have taskId in it i.e. it is a pivot change
         */ const isInitialLoad = this.lastRoute === undefined || this.lastRoute.subRouteType !== SubRouteType.MyTasks || !myTasksRoutingDetails.task;
        myTasksViewAc.fetchViewData(myTasksRoutingDetails.pivotType, isInitialLoad);
    }
    /**
     * Fetch data for the MyPlans view
     */ fetchMyPlansViewData() {
        const myPlansViewwAc = this.acLoader.load(ActionCreator.MyPlansView);
        myPlansViewwAc.fetchViewData();
    }
    /**
     * Fetch data for the MyPortfolios view
     */ fetchMyPortfoliosViewData() {
        const myPortfoliosViewAc = this.acLoader.load(ActionCreator.MyPortfoliosView);
        myPortfoliosViewAc.fetchViewData();
    }
    /**
     * Fetch data for the BasicPlan view
     */ fetchPlanViewData() {
        const subRouteType = this.routeStore.getCurrentSubRouteType();
        const isNativeBasicPlanEnabled = this.configProvider().flights.EnableNativeBasicPlanViews;
        // In case of non-native basic plans, don't trigger the data fetch
        if (subRouteType === SubRouteType.BasicPlan && !isNativeBasicPlanEnabled) {
            return;
        }
        const subRoute = this.routeStore.getCurrentSubRoute();
        const planRouingDetails = extractPlanRoutingDetailsFromSubRoute(subRoute, subRouteType);
        if (!planRouingDetails) {
            return;
        }
        const basicPlanViewAc = this.acLoader.load(ActionCreator.BasicPlanView);
        basicPlanViewAc.fetchViewData(planRouingDetails.planId);
    }
    /**
     * Update the last route
     */ updateLastRoute() {
        const route = {
            rootUrl: "",
            subRouteType: this.routeStore.getCurrentSubRouteType(),
            subRoute: this.routeStore.getCurrentSubRoute()
        };
        this.lastRoute = route;
    }
    /**
     * Subscribe to data pre-fetcher completion event
     */ async waitForDataPreFetchCompletion() {
        if (!this.configProvider().flights.EnableDataPreFetcher) {
            return;
        }
        // If pre-fetch is already completed, store the pre-fetched data
        if (window.dataPreFetchCompleted) {
            this.handlePreFetchCompletion();
            return;
        }
        // Subscribe to pre-fetcher completion event
        await new Promise((resolve)=>{
            window.addEventListener(DataPreFetchCompletedEventName, ()=>{
                this.handlePreFetchCompletion();
                resolve();
            }, {
                once: true
            });
        });
    }
    /**
     * Store the pre-fetched data
     */ handlePreFetchCompletion() {
        const fetchedData = window.dataPreFetcherResult;
        if (fetchedData) {
            Object.keys(fetchedData).forEach((key)=>{
                const serviceResult = fetchedData[key];
                if (serviceResult.data) {
                    this.savePrefetchedData(key, serviceResult.data);
                } else {
                    const message = serviceResult.errorMessage ?? "";
                    this.loggers.traceLogger.logTrace(0x1e28901e /* tag_4kja4 */ , TraceLevel.Warning, `PreFetcher failed: [Type:${key}]${message}`);
                }
            });
        }
    }
    /**
     * Save the pre-fetched data
     * @param type The type of the pre-fetched data
     * @param data The data returned by the service
     */ savePrefetchedData(type, data) {
        const dataPreFetchActionCreator = this.acLoader.load(ActionCreator.DataPreFetch);
        switch(type){
            case PreFetchedDataTypes.Mru:
                dataPreFetchActionCreator.storePreFetchedMruData(data);
                break;
            default:
                break;
        }
    }
    /**
     * Constructor for the DataFetchWorker
     * @param acLoader The action creator loader
     * @param storeLoader The store loader
     * @param loggers The logger context
     * @param configProvider The config provider
     */ constructor(acLoader, storeLoader, loggers, configProvider){
        this.acLoader = acLoader;
        this.storeLoader = storeLoader;
        this.loggers = loggers;
        this.configProvider = configProvider;
        this.initialized = false;
        this.routeStore = this.storeLoader.load(Store.Route);
        this.lastRoute = undefined;
        this.fetchViewDataBasedOnRoute = async ()=>{
            this.loggers.performanceMarker.mark(MarkerTag.ViewDataFetch, {
                state: ViewDataFetchState.Started
            });
            const currentSubRouteType = this.routeStore.getCurrentSubRouteType();
            switch(currentSubRouteType){
                case SubRouteType.MyDay:
                    this.fetchMyDayViewData();
                    break;
                case SubRouteType.MyTasks:
                    this.fetchMyTasksViewData();
                    break;
                case SubRouteType.MyPlans:
                    this.fetchMyPlansViewData();
                    break;
                case SubRouteType.MyPortfolios:
                    this.fetchMyPortfoliosViewData();
                    break;
                case SubRouteType.BasicPlan:
                case SubRouteType.TodoList:
                    this.fetchPlanViewData();
                    break;
                default:
                    break;
            }
            this.updateLastRoute();
        };
    }
}
