// Constants
import { ClientAppId } from "@ms/uno-constants/lib/local/AppConstants";
/**
 * Determine if a given Entra app id (authorization) is one of Microsoft Teams clients.
 * 1. Teams Web
 * 2. Native Teams apps: Desktop/Native iOS/Native Android
 * @param appId The app id
 */ function isTeamsClientEntraAppId(appId) {
    return appId === ClientAppId.MSTeamsWeb || appId === ClientAppId.MSTeamsDesktop;
}
/**
 * Determine if a given Entra app id (authorization) is one of the client entra app ids we use or have used for accessing our
 * services from our Tasks org owned 1P Teams app (Legacy Teams Tasks app, new Uno/Planner Teams app)
 * 1. The new Uno client entra app "Microsoft Planner Client" (using the On-behalf-Of mechanism aka. OBO)
 * 2. Legacy TeamsTasks/Publishing client entra app "Microsoft Teams Retail Services" (using the On-behalf-Of mechanism aka. OBO)
 * 3. Teams clients (web/desktop/mobile), previously before OBO auth, we were using Teams SDK to get tokens for all our services.
 * @param appId The app id
 */ export function isTeamsPlannerClientEntraAppId(appId) {
    return appId === ClientAppId.MicrosoftPlannerClient || appId === ClientAppId.MicrosoftTeamsRetailService || isTeamsClientEntraAppId(appId);
}
/**
 * Determine if a given app id is from Microsoft Sharepoint
 * @param appId The app id
 */ export function isSharepointAppId(appId) {
    return appId === ClientAppId.Sharepoint || appId === ClientAppId.DeprecatedSharepoint;
}
