// Constants
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { TaskServiceEntityType } from "../../ITaskServiceEntity";
import { AssignedToTaskboardTaskFormatting } from "./AssignedToTaskboardTaskFormatting";
// Server resource
import { DeltaTranslationMap } from "../../deltaSync/DeltaTranslationMap";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Configuration and building of AssignedTo task format objects
 */ export class AssignedToTaskboardTaskFormattingBuilder {
    /**
     * Build out the task formatting data for "AssignedTo" taskboard type
     */ build() {
        return new AssignedToTaskboardTaskFormatting(this);
    }
    /**
     * Build from a Graph task format resource entity as initialization data.
     * @param taskFormatResource Task format resource entity to use for initializing the task format builder
     */ fromGraphResource(taskFormatResource) {
        return this.withPropertyBag({
            version: taskFormatResource["@odata.etag"],
            id: taskFormatResource.id,
            orderHintsByAssignee: taskFormatResource.orderHintsByAssignee || undefined,
            unassignedOrderHint: taskFormatResource.unassignedOrderHint
        });
    }
    /**
     * Create the assignment format data from diff sync
     * @param update Changes from diff sync to apply
     */ newFromDifferentialUpdate(update) {
        if (!(update.entityType === TaskServiceEntityType.Task || update.entityType === TaskServiceEntityType.TaskboardAssignedToTaskFormatting)) {
            throw new Error("ArgumentException: invalid entity");
        }
        const baseline = AssignedToTaskboardTaskFormatting.builder.withPropertyBag({
            id: update.id,
            version: update.itemVersion
        }).build();
        const diffData = update.getCreateDiffData(TaskServiceEntityType.TaskboardAssignedToTaskFormatting);
        const assignmentFormat = baseline.applyDiffs(diffData);
        return this.forClone(assignmentFormat);
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(assignmentFormat) {
        return this.withPropertyBag({
            id: assignmentFormat.id,
            orderHintsByAssignee: cloneDeep(assignmentFormat.orderHintsByAssignee),
            unassignedOrderHint: assignmentFormat.unassignedOrderHint,
            version: assignmentFormat.itemVersion
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.id = propertyBag.id;
        this.version = propertyBag.version || this.version;
        this.orderHintsByAssignee = propertyBag.orderHintsByAssignee || this.orderHintsByAssignee;
        this.unassignedOrderHint = propertyBag.unassignedOrderHint || this.unassignedOrderHint;
        return this;
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        const translateAssigneeOrderHintUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const assigneeOrderHints = storeCopy?.orderHintsByAssignee ? cloneDeep(storeCopy.orderHintsByAssignee) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete assigneeOrderHints[key];
                } else {
                    // Insert or Update item
                    assigneeOrderHints[key] = graphUpdates[key];
                }
            }
            return assigneeOrderHints;
        };
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("unassignedOrderHint", "unassignedOrderHint");
        graphTranslationMap.addMapping("orderHintsByAssignee", "orderHintsByAssignee", translateAssigneeOrderHintUpdates);
        return graphTranslationMap.translate(update);
    }
    /**
     * A test method to build a default valued task format object
     */ withDefaultValues() {
        return this.withPropertyBag({
            id: "TaskID",
            version: "1",
            unassignedOrderHint: "orderHint",
            orderHintsByAssignee: {}
        });
    }
    /**
     * Test method to add a specific id to the AssignedToTaskboardTaskFormatting
     * @param id Id to assign to the AssignedToTaskboardTaskFormatting
     */ withId(id) {
        this.id = id;
        return this;
    }
    /**
     * Initialize an instance of the task format builder
     */ constructor(){
        this.id = LocalIdGenerator.generate();
        this.version = "";
        this.orderHintsByAssignee = {};
        this.unassignedOrderHint = " !";
    }
}
