// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../../ActionType";
import { FailureAction } from "../../FailureAction";
export class FetchBasicBucketAction extends TypedAction(ActionType.FetchBasicBucketAction) {
    loggingData() {
        return {
            bucketId: this.bucketId
        };
    }
    constructor(bucketId){
        super(), this.bucketId = bucketId;
    }
}
export class FetchBasicBucketFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            bucketId: this.bucketId
        };
    }
    constructor(bucketId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicBucketFailedAction, response, textStatus, errorThrown), this.bucketId = bucketId;
    }
}
export class FetchBasicBucketSucceededAction extends TypedAction(ActionType.FetchBasicBucketSucceededAction) {
    loggingData() {
        return {
            bucketId: this.bucket.id,
            bucketVersion: this.bucket.itemVersion
        };
    }
    constructor(bucket){
        super(), this.bucket = bucket;
    }
}
export class FetchBasicBucketsForPlanAction extends TypedAction(ActionType.FetchBasicBucketsForPlanAction) {
    loggingData() {
        return {
            planId: this.planId
        };
    }
    constructor(planId){
        super(), this.planId = planId;
    }
}
export class FetchBasicBucketsForPlanFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            planId: this.planId
        };
    }
    constructor(planId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicBucketsForPlanFailedAction, response, textStatus, errorThrown), this.planId = planId;
    }
}
export class FetchBasicBucketsForPlanSucceededAction extends TypedAction(ActionType.FetchBasicBucketsForPlanSucceededAction) {
    loggingData() {
        return {
            planId: this.planId,
            bucketCount: this.buckets.length.toString()
        };
    }
    constructor(planId, buckets){
        super(), this.planId = planId, this.buckets = buckets;
    }
}
