// Constants
import { TelemetryPlatform } from "@ms/uno-constants/lib/local/TelemetryConstants";
import { WebQueryParams, WebUrlSegments } from "@ms/uno-constants/lib/local/WebConstants";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { generateDeepLinkFromRoute, generateRouteFromDeepLink, parseRouteDescriptor, removePathPrefixIfPresent } from "@ms/uno-routing";
import { safelyOpenExternalUrl } from "@ms/uno-utilities/lib/local/BrowserUtilities";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
import { generateRouteFromLegacyDeepLink, generateRouteFromTransformedLegacyDeepLink } from "./utils/LegacyWebDeepLinkUtils";
import { getLocale } from "./utils/WebAdaptorUtils";
/**
 * Web Class implementing IUnoHostAdaptor interface to provide interaction with host platform
 */ export class WebAdaptor {
    /**
     * Sets the loggers to be used by the adaptor
     */ setLoggers(loggers) {
        this.loggers = loggers;
    }
    async getContext() {
        const account = this.msalHelperProvider().getActiveAccount();
        if (account == null) {
        // TEMP[UnoWeb] - Gracefully error out
        }
        // TEMP[UnoWeb] - Remove hard coded values and replace with actual values
        const context = {
            locale: getLocale(),
            theme: this.themeQueryEvaluator(),
            sessionId: this.getCurrentSessionId(),
            userId: account?.userId ?? null,
            displayName: account?.displayName ?? null,
            userUpn: account?.loginHint ?? null,
            tenantId: account?.tenantId ?? null,
            ringId: null,
            platform: TelemetryPlatform.Web,
            isGuestUser: account?.isGuest ?? false,
            isTenantAdmin: false
        };
        this.tenantId = context.tenantId;
        return context;
    }
    async getAccessToken(resource, claims) {
        try {
            return await this.msalHelperProvider().getAccessToken([
                `${resource}/.default`
            ], claims);
        } catch (error) {
            this.loggers?.traceLogger.logTrace(0x1e30250c /* tag_4mcum */ , TraceLevel.Warning, `[WebAdaptor] Error fetching the access token`);
            return Promise.reject(error);
        }
    }
    notifyLoaded() {
    // TODO: Implement logic to notify the host that the app has loaded
    }
    notifyRenderSuccess() {
    // TODO: Implement logic to notify the host that the app has rendered successfully
    }
    notifyRenderFailure(failure) {
    // TODO: Implement logic to notify the host about the app initialization failure
    }
    openLinkInHost(relativeUrl) {
    // TODO: Implement logic to open a link in the host application
    }
    openExternalAppLinkInHost(externalUrl) {
        safelyOpenExternalUrl(externalUrl);
    }
    openAttachmentInHost(attachment) {
        safelyOpenExternalUrl(attachment.url, this.loggers?.traceLogger, this.appConfig().flights.EnableExternalUrlRegexCheck);
    }
    async getInitialRoute() {
        // Let's see if there's initial route from the browser URL path
        // We will attempt to parse as UNO DeepLink Schema first
        // Then we will attempt to parse as UNO Route path
        let { pathname } = window.location;
        // TODO[UnoWeb] ADO#9153353 - Until we have AFD mapping, there is webui prefix that we need to adjust for
        pathname = removePathPrefixIfPresent(pathname, WebUrlSegments.WebUI);
        const { search, hash } = window.location;
        const urlSearchParams = new URLSearchParams(search ?? "");
        const urlParamsString = urlSearchParams.toString();
        let path = pathname;
        path = urlParamsString ? `${path}?${urlParamsString}` : path;
        path = hash ? `${path}${hash}` : path;
        const routeFromDeepLink = generateRouteFromDeepLink(path, this.loggers, generateRouteFromLegacyDeepLink);
        if (routeFromDeepLink) {
            this.loggers?.traceLogger.logTrace(0x1e313815 /* tag_4mt6v */ , TraceLevel.Info, `getInitialRoute - Route from DeepLink`);
            return routeFromDeepLink;
        }
        const routeFromPath = parseRouteDescriptor(path, null);
        if (routeFromPath) {
            this.loggers?.traceLogger.logTrace(0x1e313814 /* tag_4mt6u */ , TraceLevel.Info, `getInitialRoute - Route from Path`);
            return routeFromPath;
        }
        const routeFromTransformedLegacyParse = generateRouteFromTransformedLegacyDeepLink(pathname, hash, this.loggers);
        if (routeFromTransformedLegacyParse) {
            this.loggers?.traceLogger.logTrace(0x1e2da259 /* tag_4l0jz */ , TraceLevel.Info, `getInitialRoute - Route from Transformed Legacy Parse`);
            return routeFromTransformedLegacyParse;
        }
        return null;
    }
    generateDeepLink(route) {
        const deepLink = generateDeepLinkFromRoute(route, this.loggers);
        if (!deepLink) {
            this.loggers?.traceLogger.logTrace(0x1e313813 /* tag_4mt6t */ , TraceLevel.Error, `generateDeepLink - Unable to generate DeepLink from Route`);
            return null;
        }
        let deepLinkUrl = null;
        try {
            // TODO[UnoWeb] ADO#9153353 - We only need to add webui while we don't have AFD mapping
            deepLinkUrl = new URL(`${window.location.origin}/${WebUrlSegments.WebUI}${deepLink}`);
        } catch (e) {
            this.loggers?.traceLogger.logTrace(0x1e30d4a1 /* tag_4mns7 */ , TraceLevel.Error, `generateDeepLink - Error instantiating URL object`);
            return null;
        }
        const defaultQueryParameters = this.getDefaultQueryParameters() ?? {};
        for(const key in defaultQueryParameters){
            if (!deepLinkUrl.searchParams.has(key)) {
                deepLinkUrl.searchParams.set(key, defaultQueryParameters[key]);
            }
        }
        return deepLinkUrl.toString();
    }
    getDefaultQueryParameters() {
        return this.tenantId ? {
            [WebQueryParams.TenantId]: this.tenantId
        } : null;
    }
    registerThemeChangeHandler(handler) {
        if (window.matchMedia) {
            const listener = ()=>{
                const theme = this.themeQueryEvaluator();
                handler(theme);
            };
            this.hcQuery.addEventListener?.("change", listener);
            this.darkQuery.addEventListener?.("change", listener);
        }
    }
    canSetDocumentTitle() {
        return true;
    }
    /**
     * Returns the sessionId to use for telemetry
     * Note: This changes based on whether there is an active account or not
     */ getCurrentSessionId() {
        // First check if the active account situation changed (and update if necessary)
        if (this.currentSessionIdComputedWhenActiveAccount !== this.msalHelperProvider().hasActiveAccount()) {
            this.currentSessionIdComputedWhenActiveAccount = this.msalHelperProvider().hasActiveAccount();
            this.currentSessionId = generateGuid();
        }
        return this.currentSessionId;
    }
    constructor(appConfig, msalHelperProvider){
        this.appConfig = appConfig;
        this.msalHelperProvider = msalHelperProvider;
        this.loggers = null;
        this.tenantId = null;
        this.hcQuery = matchMedia("(forced-colors: active)");
        this.darkQuery = matchMedia("(prefers-color-scheme: dark)");
        this.themeQueryEvaluator = ()=>{
            if (this.hcQuery.matches) {
                return UnoTheme.TeamsContrast;
            } else if (this.darkQuery.matches) {
                return UnoTheme.TeamsDark;
            } else {
                return UnoTheme.TeamsLight;
            }
        };
        if (msalHelperProvider().hasActiveAccount()) {
            this.currentSessionIdComputedWhenActiveAccount = true;
            this.currentSessionId = generateGuid();
        } else {
            this.currentSessionIdComputedWhenActiveAccount = false;
            this.currentSessionId = this.appConfig().sessionMetaData.sessionId;
        }
    }
}
