// Models
import { TaskAttachmentType } from "../../taskAttachment/TaskAttachmentType";
import { TaskAttachmentTypeBuilder } from "../../taskAttachment/TaskAttachmentTypeBuilder";
import { SupportedReferenceTypes } from "../../taskAttachment/ITaskAttachmentType";
// Utilities
import { VideoUrlParser } from "@ms/uno-utilities/lib/local/video/VideoUrlParser";
/**
 * Configuration and building of AttachmentType objects
 */ export class AttachmentTypeBuilder extends TaskAttachmentTypeBuilder {
    /**
     * Create the attachment type from a graph AttachmentResource
     * @param key The key (url) used for this attachment
     * @param attachment The attachment to create the type from
     */ fromGraphAttachmentResource(key, attachment) {
        this.taskAttachmentType = TaskAttachmentType.getTaskAttachmentTypeFromString(attachment.type);
        if (this.taskAttachmentType === SupportedReferenceTypes.Other && key) {
            this.specificType = this.getSpecificType(key);
            this.videoUrlParseResult = VideoUrlParser.TryParseVideoUrl(key);
        }
        return this;
    }
    /**
     * Create the attachment type from a FormResource
     * @param form The form
     */ fromGraphFormResource(form) {
        if (form) {
            this.taskAttachmentType = SupportedReferenceTypes.Form;
        }
        return this;
    }
}
