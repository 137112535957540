// Constants
import { EntryType } from "../performance/constants/PerformanceEntryType";
import { MarkerTag } from "../performance/constants/PerformanceMarkerConstants";
/**
 * Converts a performance marker tag object to a string representation.
 * @param markerTag - Marker tag.
 * @param extraData - [Optional] Additional data to be included in the string representation.
 * The string returned will be in the format of "MarkerTag-viewName:ViewName-viewData:FetchedDataState".
 */ export function convertPerformanceMarkerTagToString(markerTag, extraData) {
    const otherViewEntries = extraData ? Object.entries(extraData).map(([key, value])=>`${key}:${value}`) : [];
    return [
        markerTag,
        ...otherViewEntries
    ].join("-");
}
/**
 * Converts a string representation of a performance marker tag to MarkEntry.
 * Example of string representation: "TestViewMark-viewName:TestView-viewData:Critical" or "TestMark"
 * In the above example TestViewMark and TestMark are the MarkerTag enums, TestView is the viewName, Critical, All, and None are the FetchedDataState enums.
 * @param markTag - The string representation of the performance marker tag.
 * @param markTime - The time at which the marker was created.
 * @throws Will throw an error if the marker tag or any of its components are invalid.
 */ export function convertStringToMarkEntry(markTag, markTime) {
    const markEntryFields = markTag.split("-");
    if (markEntryFields.length === 0) {
        throw new Error(`[InvalidMarker: ${markTag}]`);
    }
    const tag = getPerformanceMarkerTag(markEntryFields[0]);
    let extraData;
    if (markEntryFields.length > 1) {
        extraData = markEntryFields.slice(1).reduce((acc, field)=>{
            const [key, value] = field.split(":");
            acc[key] = value;
            return acc;
        }, {});
    }
    const entry = {
        entryType: EntryType.MarkEntry,
        tag,
        markTime,
        extraData
    };
    return entry;
}
/**
 *
 * Extracts and calculates various performance metrics from the PerformanceNavigationTiming object.
 * @param {PerformanceNavigationTiming} performanceNavigationTiming - The PerformanceNavigationTiming object containing navigation timing data.
 * @returns {INavigationEntry} An object containing the extracted and calculated performance metrics.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming PerformanceNavigationTiming}
 */ export function getWebPerformanceNavigationData(performanceNavigationTiming) {
    // End Time - either loadEventEnd if available, or domComplete
    // if the loadEventEnd event did not occur yet, then it will be 0. In that case, we should use domComplete since we
    // always want to have a (non-zero) value. Under normal circumstances, by the time this code executes, we should
    // have a value for loadEventEnd.
    const endTime = performanceNavigationTiming.loadEventEnd > 0 ? performanceNavigationTiming.loadEventEnd : performanceNavigationTiming.domComplete;
    // PLT Full - full Performance Timing timeline
    const pltFull = endTime - performanceNavigationTiming.startTime;
    // redirTime: this measure computes the time between navigation started until all last redirect finished.
    // using fetchStart here instead of redirectEnd would be same but IE does not set redirectEnd value.
    const pltRedir = performanceNavigationTiming.fetchStart - performanceNavigationTiming.startTime;
    // PLT Network - measures time on the network
    const pltNetwork = performanceNavigationTiming.responseEnd - performanceNavigationTiming.fetchStart;
    // PLT Dom - measures time after domLoading started until page loaded
    const pltDom = endTime - performanceNavigationTiming.domInteractive;
    // Gap or overlap between the pltNetwork and pltDom timeframes. If the value is positive, there is a gap of that size. If it is negative, then there is an overlap of that size.
    const pltNetworkDomGap = performanceNavigationTiming.domInteractive - performanceNavigationTiming.responseEnd;
    const navigationEntry = {
        entryType: EntryType.NavigationEntry,
        startTime: performanceNavigationTiming.startTime,
        redirectStart: performanceNavigationTiming.redirectStart,
        redirectEnd: performanceNavigationTiming.redirectEnd,
        fetchStart: performanceNavigationTiming.fetchStart,
        domainLookupStart: performanceNavigationTiming.domainLookupStart,
        domainLookupEnd: performanceNavigationTiming.domainLookupEnd,
        connectStart: performanceNavigationTiming.connectStart,
        unloadEventStart: performanceNavigationTiming.unloadEventStart,
        unloadEventEnd: performanceNavigationTiming.unloadEventEnd,
        secureConnectionStart: performanceNavigationTiming.secureConnectionStart,
        connectEnd: performanceNavigationTiming.connectEnd,
        requestStart: performanceNavigationTiming.requestStart,
        responseStart: performanceNavigationTiming.responseStart,
        responseEnd: performanceNavigationTiming.responseEnd,
        domInteractive: performanceNavigationTiming.domInteractive,
        domContentLoadedEventStart: performanceNavigationTiming.domContentLoadedEventStart,
        domContentLoadedEventEnd: performanceNavigationTiming.domContentLoadedEventEnd,
        domComplete: performanceNavigationTiming.domComplete,
        loadEventStart: performanceNavigationTiming.loadEventStart,
        loadEventEnd: performanceNavigationTiming.loadEventEnd,
        pltFull: pltFull,
        pltRedir: pltRedir,
        pltNetwork: pltNetwork,
        pltDom: pltDom,
        pltNetworkDomGap: pltNetworkDomGap
    };
    return navigationEntry;
}
/**
 * Retrieves the performance marker tag object from a string representation.
 * @param markTag - The string representation of the general marker tag.
 * @throws Will throw an error if the marker tag is invalid.
 */ function getPerformanceMarkerTag(markTag) {
    const tag = stringToEnum(markTag, MarkerTag);
    if (tag == null) {
        throw new Error(`[InvalidMarker: ${markTag}]`);
    }
    return tag;
}
/** Converts a string to an enum value, or null if the string is not a valid enum value
 * @param value The string value to convert
 * @param enum The enum to convert to
 */ function stringToEnum(stringValue, enumType) {
    for(const key in enumType){
        if (stringValue === enumType[key]) {
            return enumType[key];
        }
    }
    return null;
}
