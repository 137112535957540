// Models
import { ToUTC10am } from "@ms/uno-utilities/lib/local/DateUtilities";
/**
 * Represents a recurrence pattern calculator
 */ export class RecurrenceCalculator {
    /**
     * Get the period dates between the start and end dates.
     *
     * @description The periods will be represented by arbitrary dates within the patterned type.
     * These dates will be determined by the start parameter.
     *
     * @example For a weekly pattern the periods will be any date within each week, this could be a
     * series of tuesdays etc. Then add 1 week to calculate the next period date.
     *
     * @example For a monthly pattern the periods will be any date within each month, this could be
     * a series of the 12th day in each month etc. Then add 1 month to calculate the next period date.
     *
     * @param start Start date of the window.
     * @param end End date of the window.
     * @returns The period dates between the start and end dates.
     */ getPeriodsBetween(start, end) {
        const currentPeriod = start.clone();
        const periods = [];
        while(this.isSameOrBefore(currentPeriod, end)){
            periods.push(currentPeriod.clone());
            currentPeriod.add(1, this.periodPattern);
        }
        return periods;
    }
    /**
     * Normalize the date to aid in calculations.
     * @param date Date to normalize.
     * @returns The normalized date.
     */ normalizeDate(date) {
        return ToUTC10am(date);
    }
    constructor(interval, patternStartDate, periodPattern){
        this.interval = interval;
        this.periodPattern = periodPattern;
        this.patternStartDate = this.normalizeDate(patternStartDate);
    }
}
