// Constants
import { ClientFlavor, UserPolicyFlags } from "@ms/uno-constants/lib/local/AppConstants";
// Errors
import { NotSupportedError } from "@ms/uno-errors/lib/local/errors/NotSupportedError";
import { AppContextStore } from "@ms/uno-stores/lib/local/stores/appContext/AppContextStore";
import { AuthStore } from "@ms/uno-stores/lib/local/stores/auth/AuthStore";
import { PerformanceCollectionStore } from "@ms/uno-stores/lib/local/stores/performanceCollection/PerformanceCollectionStore";
import { RouteStore } from "@ms/uno-stores/lib/local/stores/route/RouteStore";
import { UnoShellStore } from "@ms/uno-stores/lib/local/stores/unoShell/UnoShellStore";
import { UserStore } from "@ms/uno-stores/lib/local/stores/user/UserStore";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
/**
 * Factory class for store providers in Base app
 */
export class BaseStoreFactory {
    // #endregion
    /**
     * Initialize a new instance of the TeamsStoreFactory
     * @param loader The component loader to use for lazy loading stores
     * @param initialAppContext The initial context value to populate the AppContextStore
     * @param user The user data to populate the UserStore
     * @param rootPath The root path of the app
     * @param loggers The loggers for telemetry
     * @param persistentStorage The persistent storage to use for the stores
     * @param configProvider app configuration to populate the AppContextStore
     * @param isDeepLinkInit Whether the app is initialized from a deep link
     * @param defaultQueryParameters If specified, these are default query parameters when serializing route
     */
    constructor(loader, initialAppContext, user, rootPath, loggers, persistentStorage, configProvider, isDeepLinkInit, defaultQueryParameters) {
        this.loader = loader;
        this.loggers = loggers;
        this.persistentStorage = persistentStorage;
        this.configProvider = configProvider;
        this.loadActivityStore = () => this.loader.load(this.getStoreBundleKey(Store.Activity), () => import(/* webpackChunkName: "uno-activitystore" */ "@ms/uno-stores/lib/local/stores/activity/ActivityStore"), (bundle) => new bundle.ActivityStore(this.loggers));
        this.loadAggregatesStore = () => this.loader.load(this.getStoreBundleKey(Store.Aggregates), () => import(/* webpackChunkName: "uno-aggregatesstore" */ "@ms/uno-stores/lib/local/stores/aggregates/AggregatesStore"), (bundle) => new bundle.AggregatesStore());
        this.loadBasicBucketStore = () => this.loader.load(this.getStoreBundleKey(Store.BasicBucket), () => import(/* webpackChunkName: "uno-plannerbucketstore" */ "@ms/uno-stores/lib/local/stores/planner/bucket/BasicBucketStore"), (bundle) => new bundle.BasicBucketStore(this.loggers));
        this.loadBasicPlanStore = () => this.loader.load(this.getStoreBundleKey(Store.BasicPlan), () => import(/* webpackChunkName: "uno-basicplanstore" */ "@ms/uno-stores/lib/local/stores/planner/plan/BasicPlanStore"), (bundle) => new bundle.BasicPlanStore(this.loggers));
        this.loadBasicPlanConversionStatusStore = () => this.loader.load(this.getStoreBundleKey(Store.BasicPlanConversionStatus), () => import(
        /* webpackChunkName: "uno-basicplanconversionstatusstore" */ "@ms/uno-stores/lib/local/stores/upsell/BasicPlanConversionStatusStore"), (bundle) => new bundle.BasicPlanConversionStatusStore(this.loggers, this.configProvider));
        this.loadBasicPermissionsStore = () => this.loader.load(this.getStoreBundleKey(Store.BasicPermissions), () => import(
        /* webpackChunkName: "uno-basicpermissionsstore" */ "@ms/uno-stores/lib/local/stores/permissions/BasicPermissionsStore"), (bundle) => new bundle.BasicPermissionsStore(this.appContextStore.getAppContext(), this.configProvider, UserPolicyFlags.None, // TODO: Remove this once we have a way to get the user's policy flags
        this.loggers));
        this.loadBasicTaskStore = () => this.loader.load(this.getStoreBundleKey(Store.BasicTask), () => import(/* webpackChunkName: "uno-basictaskstore" */ "@ms/uno-stores/lib/local/stores/planner/task/BasicTaskStore"), (bundle) => new bundle.BasicTaskStore(this.loggers));
        this.loadCampaignStore = () => this.loader.load(this.getStoreBundleKey(Store.Campaign), () => import(/* webpackChunkName: "uno-campaignstore" */ "@ms/uno-stores/lib/local/stores/campaign/CampaignStore"), (bundle) => new bundle.CampaignStore(this.loggers, this.persistentStorage, this.configProvider));
        this.loadCapabilitiesStore = () => this.loader.load(this.getStoreBundleKey(Store.Capabilities), () => import(/* webpackChunkName: "uno-capabilitiesstore" */ "@ms/uno-stores/lib/local/stores/capabilities/CapabilitiesStore"), (bundle) => new bundle.CapabilitiesStore(this.configProvider, this.loggers));
        this.loadContainerStore = () => this.loader.load(this.getStoreBundleKey(Store.Container), () => import(/* webpackChunkName: "uno-containerstore" */ "@ms/uno-stores/lib/local/stores/container/ContainerStore"), (bundle) => new bundle.ContainerStore(this.loggers));
        this.loadConversationsStore = () => this.loader.load(this.getStoreBundleKey(Store.Conversations), () => import(/* webpackChunkName: "uno-conversationsstore" */ "@ms/uno-stores/lib/local/stores/conversations/ConversationsStore"), (bundle) => new bundle.ConversationsStore(this.loggers));
        this.loadCreateTaskFromMessageStore = () => {
            throw new NotSupportedError(`[Store=${Store.CreateTaskFromMessage}]`);
        };
        this.loadDeltaSyncStore = () => this.loader.load(this.getStoreBundleKey(Store.DeltaSync), () => import(/* webpackChunkName: "uno-deltasyncstore" */ "@ms/uno-stores/lib/local/stores/planner/deltaSync/DeltaSyncStore"), (bundle) => new bundle.DeltaSyncStore(this.loggers));
        this.loadDriveItemStore = () => this.loader.load(this.getStoreBundleKey(Store.DriveItem), () => import(/* webpackChunkName: "uno-driveitemstore" */ "@ms/uno-stores/lib/local/stores/driveItem/DriveItemStore"), (bundle) => new bundle.DriveItemStore(this.loggers));
        this.loadGroupsStore = () => this.loader.load(this.getStoreBundleKey(Store.Groups), () => import(/* webpackChunkName: "uno-groupsstore" */ "@ms/uno-stores/lib/local/stores/groups/GroupsStore"), (bundle) => new bundle.GroupsStore(this.loggers));
        this.loadIntelligenceStore = () => this.loader.load(this.getStoreBundleKey(Store.Intelligence), () => import(/* webpackChunkName: "uno-intelligencestore" */ "@ms/uno-stores/lib/local/stores/intelligence/IntelligenceStore"), (bundle) => new bundle.IntelligenceStore(this.loggers));
        this.loadModalStore = () => this.loader.load(this.getStoreBundleKey(Store.Modal), () => import(/* webpackChunkName: "uno-modalstore" */ "@ms/uno-stores/lib/local/stores/modal/ModalStore"), (bundle) => new bundle.ModalStore(this.loggers, this.configProvider));
        this.loadMruStore = () => this.loader.load(this.getStoreBundleKey(Store.Mru), () => import(/* webpackChunkName: "uno-mrustore" */ "@ms/uno-stores/lib/local/stores/mru/MruStore"), (bundle) => new bundle.MruStore(this.loggers, this.configProvider));
        this.loadNotificationStore = () => this.loader.load(this.getStoreBundleKey(Store.Notification), () => import(/* webpackChunkName: "uno-notificationstore" */ "@ms/uno-stores/lib/local/stores/notification/NotificationStore"), (bundle) => new bundle.NotificationStore(this.loggers));
        this.loadOperationTrackingStore = () => this.loader.load(this.getStoreBundleKey(Store.OperationTracking), () => import(
        /* webpackChunkName: "uno-optrackstore" */ "@ms/uno-stores/lib/local/stores/operationTracking/OperationTrackingStore"), (bundle) => new bundle.OperationTrackingStore(this.loggers));
        this.loadOrgStore = () => this.loader.load(this.getStoreBundleKey(Store.Org), () => import(/* webpackChunkName: "uno-orgstore" */ "@ms/uno-stores/lib/local/stores/org/OrgStore"), (bundle) => new bundle.OrgStore(this.configProvider(), this.persistentStorage, this.loggers));
        this.loadPlanConversionStore = () => this.loader.load(this.getStoreBundleKey(Store.PlanConversion), () => import(/* webpackChunkName: "uno-planconversionstore" */ "@ms/uno-stores/lib/local/stores/upsell/PlanConversionStore"), (bundle) => new bundle.PlanConversionStore(this.loggers, this.configProvider));
        this.loadPortfolioStore = () => this.loader.load(this.getStoreBundleKey(Store.Portfolio), () => import(/* webpackChunkName: "uno-portfoliostore" */ "@ms/uno-stores/lib/local/stores/portfolio/PortfolioStore"), (bundle) => new bundle.PortfolioStore(this.loggers));
        this.loadPremiumPlanStore = () => this.loader.load(this.getStoreBundleKey(Store.PremiumPlan), () => import(
        /* webpackChunkName: "uno-premiumplanstore" */ "@ms/uno-stores/lib/local/stores/project/premiumPlan/PremiumPlanStore"), (bundle) => new bundle.PremiumPlanStore(this.loggers, this.configProvider));
        this.loadPremiumPlanPermissionsStore = () => this.loader.load(this.getStoreBundleKey(Store.PremiumPlanPermissions), () => import(
        /* webpackChunkName: "uno-premiumplanpermissionsstore" */ "@ms/uno-stores/lib/local/stores/permissions/PremiumPlanPermissionsStore"), (bundle) => new bundle.PremiumPlanPermissionsStore(this.loggers));
        this.loadPublishingStore = () => this.loader.load(this.getStoreBundleKey(Store.Publishing), () => import(/* webpackChunkName: "uno-publishingstore" */ "@ms/uno-stores/lib/local/stores/publishing/PublishingStore"), (bundle) => new bundle.PublishingStore(this.loggers));
        this.loadProjectHomeResourceStore = () => this.loader.load(this.getStoreBundleKey(Store.ProjectHomeResource), () => import(
        /* webpackChunkName: "uno-projecthomeresourcestore" */ "@ms/uno-stores/lib/local/stores/projectHome/ProjectHomeResourceStore"), (bundle) => new bundle.ProjectHomeResourceStore(this.loggers));
        this.loadRecommendedPlansStore = () => this.loader.load(this.getStoreBundleKey(Store.RecommendedPlans), () => import(
        /* webpackChunkName: "uno-recommendedplansstore" */ "@ms/uno-stores/lib/local/stores/recommendedPlan/RecommendedPlansStore"), (bundle) => new bundle.RecommendedPlansStore(this.loggers));
        this.loadStatusReportStore = () => this.loader.load(this.getStoreBundleKey(Store.StatusReport), () => import(/* webpackChunkName: "uno-statusreportstore" */ "@ms/uno-stores/lib/local/stores/statusReport/StatusReportStore"), (bundle) => new bundle.StatusReportStore(this.loggers));
        this.loadSuiteNavStore = () => {
            throw new NotSupportedError(`[Store=${Store.SuiteNav}]`);
        };
        this.loadTeamsStore = () => this.loader.load(this.getStoreBundleKey(Store.Teams), () => import(/* webpackChunkName: "uno-teamsstore" */ "@ms/uno-stores/lib/local/stores/teams/TeamsStore"), (bundle) => new bundle.TeamsStore(this.loggers));
        this.loadTemplatesStore = () => this.loader.load(this.getStoreBundleKey(Store.Templates), () => import(/* webpackChunkName: "uno-templatesstore" */ "@ms/uno-stores/lib/local/stores/templates/TemplateStore"), (bundle) => new bundle.TemplateStore(this.loggers));
        this.loadTenantStore = () => this.loader.load(this.getStoreBundleKey(Store.Tenant), () => import(/* webpackChunkName: "uno-tenantstore" */ "@ms/uno-stores/lib/local/stores/tenant/TenantStore"), (bundle) => new bundle.TenantStore(this.loggers));
        this.loadUpsellStore = () => this.loader.load(this.getStoreBundleKey(Store.Upsell), () => import(/* webpackChunkName: "uno-upsellstore" */ "@ms/uno-stores/lib/local/stores/upsell/UpsellStore"), (bundle) => new bundle.UpsellStore(this.loggers, this.persistentStorage));
        this.loadUserMembershipStore = () => this.loader.load(this.getStoreBundleKey(Store.UserMembership), () => import(/* webpackChunkName: "uno-usermembershipstore" */ "@ms/uno-stores/lib/local/stores/user/UserMembershipStore"), (bundle) => new bundle.UserMembershipStore(this.loggers));
        this.loadViewStore = () => this.loader.load(this.getStoreBundleKey(Store.View), () => import(/* webpackChunkName: "uno-viewstore" */ "@ms/uno-stores/lib/local/stores/view/ViewStore"), (bundle) => new bundle.ViewStore(this.loggers, this.persistentStorage, this.configProvider));
        this.loadNavigationStore = () => this.loader.load(this.getStoreBundleKey(Store.Navigation), () => import(/* webpackChunkName: "uno-navigationstore" */ "@ms/uno-stores/lib/local/stores/route/NavigationStore"), (bundle) => new bundle.NavigationStore(this.loggers));
        /** Map of all functions that load a store */
        this.storeLoaders = {
            [Store.BasicPlan]: () => this.loadBasicPlanStore(),
            [Store.Route]: () => this.routeStore,
            [Store.Activity]: () => this.loadActivityStore(),
            [Store.Aggregates]: () => this.loadAggregatesStore(),
            [Store.AppContext]: () => this.appContextStore,
            [Store.Auth]: () => this.authStore,
            [Store.BasicBucket]: () => this.loadBasicBucketStore(),
            [Store.BasicPlanConversionStatus]: () => this.loadBasicPlanConversionStatusStore(),
            [Store.BasicPermissions]: () => this.loadBasicPermissionsStore(),
            [Store.BasicTask]: () => this.loadBasicTaskStore(),
            [Store.Campaign]: () => this.loadCampaignStore(),
            [Store.Capabilities]: () => this.loadCapabilitiesStore(),
            [Store.Container]: () => this.loadContainerStore(),
            [Store.Conversations]: () => this.loadConversationsStore(),
            [Store.CreateTaskFromMessage]: () => this.loadCreateTaskFromMessageStore(),
            [Store.DeltaSync]: () => this.loadDeltaSyncStore(),
            [Store.DriveItem]: () => this.loadDriveItemStore(),
            [Store.Groups]: () => this.loadGroupsStore(),
            [Store.Intelligence]: () => this.loadIntelligenceStore(),
            [Store.Modal]: () => this.loadModalStore(),
            [Store.Mru]: () => this.loadMruStore(),
            [Store.Navigation]: () => this.loadNavigationStore(),
            [Store.Notification]: () => this.loadNotificationStore(),
            [Store.OperationTracking]: () => this.loadOperationTrackingStore(),
            [Store.Org]: () => this.loadOrgStore(),
            [Store.PerformanceCollection]: () => this.performanceCollectionStore,
            [Store.PlanConversion]: () => this.loadPlanConversionStore(),
            [Store.Portfolio]: () => this.loadPortfolioStore(),
            [Store.PremiumPlan]: () => this.loadPremiumPlanStore(),
            [Store.PremiumPlanPermissions]: () => this.loadPremiumPlanPermissionsStore(),
            [Store.RecommendedPlans]: () => this.loadRecommendedPlansStore(),
            [Store.ProjectHomeResource]: () => this.loadProjectHomeResourceStore(),
            [Store.Publishing]: () => this.loadPublishingStore(),
            [Store.StatusReport]: () => this.loadStatusReportStore(),
            [Store.SuiteNav]: () => this.loadSuiteNavStore(),
            [Store.Teams]: () => this.loadTeamsStore(),
            [Store.Templates]: () => this.loadTemplatesStore(),
            [Store.Tenant]: () => this.loadTenantStore(),
            [Store.Upsell]: () => this.loadUpsellStore(),
            [Store.UnoShell]: () => this.unoShellStore,
            [Store.User]: () => this.userStore,
            [Store.UserMembership]: () => this.loadUserMembershipStore(),
            [Store.View]: () => this.loadViewStore(),
        };
        // Setup the static stores
        this.appContextStore = new AppContextStore(initialAppContext, configProvider(), loggers);
        this.authStore = new AuthStore(loggers);
        this.unoShellStore = new UnoShellStore(loggers);
        // Only enable persistence for the app flavor.
        // Tabs, Components will require a more elaborate strategy due to requiring independent caches which
        // results in unbounded storage requirements (as there is no bound on number of tabs or components)
        // CreateTaskFromMessage has a fixed route and thus also does not require this.
        const clientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
        const shouldEnableRoutePersistence = clientFlavor === ClientFlavor.App;
        // Enable hydration only for app flavor and when not initialized from a deep link. (Tabs and components are not supported for now)
        const shouldEnableRouteHydration = clientFlavor === ClientFlavor.App && !isDeepLinkInit;
        this.routeStore = new RouteStore(rootPath, loggers, persistentStorage, configProvider, shouldEnableRoutePersistence, shouldEnableRouteHydration, this.getRouteStorePathPrefix(), defaultQueryParameters);
        this.performanceCollectionStore = new PerformanceCollectionStore(this.routeStore.getCurrentSubRouteType.bind(this.routeStore), loggers);
        this.userStore = new UserStore(user, loggers, configProvider);
    }
    /**
     * Gets the prefix (if any) to use for RouteStore when forming the route path
     * TODO[UnoWeb] ADO#9153353 - We only need to do this while we don't have AFD mapping
     */
    getRouteStorePathPrefix() {
        // By default, no prefix
        return null;
    }
    getRouteStoreProvider() {
        return () => this.routeStore;
    }
    isLoaded(store) {
        return this.loader.isLoaded(this.getStoreBundleKey(store));
    }
    getActivityStoreProvider() {
        return this.loadActivityStore;
    }
    getAggregatesStoreProvider() {
        return this.loadAggregatesStore;
    }
    getAppContextStoreProvider() {
        return () => this.appContextStore;
    }
    getAuthStoreProvider() {
        return () => this.authStore;
    }
    getBasicBucketStoreProvider() {
        return this.loadBasicBucketStore;
    }
    getBasicPlanStoreProvider() {
        return this.loadBasicPlanStore;
    }
    getBasicPlanConversionStatusStoreProvider() {
        return this.loadBasicPlanConversionStatusStore;
    }
    getBasicPermissionsStoreProvider() {
        return this.loadBasicPermissionsStore;
    }
    getBasicTaskStoreProvider() {
        return this.loadBasicTaskStore;
    }
    getCampaignStoreProvider() {
        return this.loadCampaignStore;
    }
    getCapabilitiesStoreProvider() {
        return this.loadCapabilitiesStore;
    }
    getContainerStoreProvider() {
        return this.loadContainerStore;
    }
    getConversationsStoreProvider() {
        return this.loadConversationsStore;
    }
    getCreateTaskFromMessageStoreProvider() {
        return this.loadCreateTaskFromMessageStore;
    }
    getDeltaSyncStoreProvider() {
        return this.loadDeltaSyncStore;
    }
    getDriveItemStoreProvider() {
        return this.loadDriveItemStore;
    }
    getGroupsStoreProvider() {
        return this.loadGroupsStore;
    }
    getIntelligenceStoreProvider() {
        return this.loadIntelligenceStore;
    }
    getModalStoreProvider() {
        return this.loadModalStore;
    }
    getMruStoreProvider() {
        return this.loadMruStore;
    }
    getNavigationStoreProvider() {
        return this.loadNavigationStore;
    }
    getNotificationStoreProvider() {
        return this.loadNotificationStore;
    }
    getOperationTrackingStoreProvider() {
        return this.loadOperationTrackingStore;
    }
    getOrgStoreProvider() {
        return this.loadOrgStore;
    }
    getPerformanceCollectionStoreProvider() {
        return () => this.performanceCollectionStore;
    }
    getPlanConversionStoreProvider() {
        return this.loadPlanConversionStore;
    }
    getPortfolioStoreProvider() {
        return this.loadPortfolioStore;
    }
    getPremiumPlanStoreProvider() {
        return this.loadPremiumPlanStore;
    }
    getPremiumPlanPermissionsStoreProvider() {
        return this.loadPremiumPlanPermissionsStore;
    }
    getPublishingStoreProvider() {
        return this.loadPublishingStore;
    }
    getRecommendedPlansStoreProvider() {
        return this.loadRecommendedPlansStore;
    }
    getProjectHomeResourceStoreProvider() {
        return this.loadProjectHomeResourceStore;
    }
    getStatusReportStoreProvider() {
        return this.loadStatusReportStore;
    }
    getSuiteNavStoreProvider() {
        return this.loadSuiteNavStore;
    }
    getTeamsStoreProvider() {
        return this.loadTeamsStore;
    }
    getTemplatesStoreProvider() {
        return this.loadTemplatesStore;
    }
    getTenantStoreProvider() {
        return this.loadTenantStore;
    }
    getUpsellStoreProvider() {
        return this.loadUpsellStore;
    }
    getUnoShellStoreProvider() {
        return () => this.unoShellStore;
    }
    getUserStoreProvider() {
        return () => this.userStore;
    }
    getUserMembershipStoreProvider() {
        return this.loadUserMembershipStore;
    }
    getViewStoreProvider() {
        return this.loadViewStore;
    }
    getStoreBundleKey(storeType) {
        return `uno-${storeType.toString().toLowerCase()}store`;
    }
    /**
     * Load a store for reading from it and subscribing to it (used by UI components)
     * @param store The store to load
     * @returns The interface that only allows reads and subscriptions
     */
    load(store) {
        return this.storeLoaders[store]();
    }
    /**
     * Load a store for dispatching an action to it (used by the dispatcher)
     * @param store The store to load
     * @returns The IStore interface that only allows action dispatch
     */
    loadForDispatch(store) {
        return this.storeLoaders[store]();
    }
}
