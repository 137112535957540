// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../../ActionType";
import { FailureAction } from "../../FailureAction";
export class FetchBasicPlanAction extends TypedAction(ActionType.FetchBasicPlanAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            localId: this.planId
        };
    }
    constructor(planId){
        super(), this.planId = planId;
    }
}
export class FetchBasicPlanFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            id: this.planId
        };
    }
    constructor(planId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicPlanFailedAction, response, textStatus, errorThrown), this.planId = planId;
    }
}
export class FetchBasicPlanSucceededAction extends TypedAction(ActionType.FetchBasicPlanSucceededAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            id: this.planEntitySet.id
        };
    }
    constructor(planEntitySet){
        super(), this.planEntitySet = planEntitySet;
    }
}
export class FetchBasicPlansForContainerAction extends TypedAction(ActionType.FetchBasicPlansForContainerAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            cotainerType: this.containerType,
            containerId: this.containerExternalId
        };
    }
    constructor(containerType, containerExternalId){
        super(), this.containerType = containerType, this.containerExternalId = containerExternalId;
    }
}
export class FetchBasicPlansForContainerFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            cotainerType: this.containerType,
            containerId: this.containerExternalId
        };
    }
    constructor(containerType, containerExternalId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicPlansForContainerFailedAction, response, textStatus, errorThrown), this.containerType = containerType, this.containerExternalId = containerExternalId;
    }
}
export class FetchBasicPlansForContainerSucceededAction extends TypedAction(ActionType.FetchBasicPlansForContainerSucceededAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            cotainerType: this.containerType,
            containerId: this.containerExternalId
        };
    }
    constructor(containerType, containerExternalId, planEntitySets){
        super(), this.containerType = containerType, this.containerExternalId = containerExternalId, this.planEntitySets = planEntitySets;
    }
}
export class FetchAllBasicPlansForContainerTypeAction extends TypedAction(ActionType.FetchAllBasicPlansForContainerTypeAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            containerType: this.containerType
        };
    }
    constructor(containerType){
        super(), this.containerType = containerType;
    }
}
export class FetchAllBasicPlansForContainerTypeFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            containerType: this.containerType,
            isLastPage: this.isLastPage.toString()
        };
    }
    constructor(containerType, isLastPage, response, textStatus, errorThrown){
        super(ActionType.FetchAllBasicPlansForContainerTypeFailedAction, response, textStatus, errorThrown), this.containerType = containerType, this.isLastPage = isLastPage;
    }
}
export class FetchAllBasicPlansForContainerTypeSucceededAction extends TypedAction(ActionType.FetchAllBasicPlansForContainerTypeSucceededAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            containerType: this.containerType,
            isLastPage: this.isLastPage.toString()
        };
    }
    constructor(containerType, isLastPage){
        super(), this.containerType = containerType, this.isLastPage = isLastPage;
    }
}
export class FetchPlansForChannelGroupIdAction extends TypedAction(ActionType.FetchPlansForChannelGroupIdAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            groupId: this.groupId
        };
    }
    constructor(groupId){
        super(), this.groupId = groupId;
    }
}
export class FetchPlansForChannelGroupIdFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            groupId: this.groupId
        };
    }
    constructor(groupId, response, textStatus, errorThrown){
        super(ActionType.FetchPlansForChannelGroupIdFailedAction, response, textStatus, errorThrown), this.groupId = groupId;
    }
}
export class FetchPlansForChannelGroupIdSucceededAction extends TypedAction(ActionType.FetchPlansForChannelGroupIdSucceededAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            groupId: this.groupId
        };
    }
    constructor(groupId, planEntitySets){
        super(), this.groupId = groupId, this.planEntitySets = planEntitySets;
    }
}
export class FetchCopyPlanOperationDescriptorAction extends TypedAction(ActionType.FetchCopyPlanOperationDescriptorAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            clientOperationId: this.clientOperationId,
            operationId: this.operationId
        };
    }
    constructor(clientOperationId, operationId){
        super(), this.clientOperationId = clientOperationId, this.operationId = operationId;
    }
}
export class FetchCopyPlanOperationDescriptorSucceededAction extends TypedAction(ActionType.FetchCopyPlanOperationDescriptorSucceededAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            sourcePlanId: this.copyPlanOperationDescriptor.sourcePlanId,
            targetContainerId: this.copyPlanOperationDescriptor.targetContainer.externalId,
            targetPlanId: this.copyPlanOperationDescriptor.targetPlanId,
            clientOperationId: this.copyPlanOperationDescriptor.clientOperationId,
            operationId: this.copyPlanOperationDescriptor.id,
            operationStatus: this.copyPlanOperationDescriptor.operationStatus
        };
    }
    constructor(copyPlanOperationDescriptor){
        super(), this.copyPlanOperationDescriptor = copyPlanOperationDescriptor;
    }
}
export class FetchCopyPlanOperationDescriptorFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            clientOperationId: this.clientOperationId,
            operationId: this.operationId
        };
    }
    constructor(clientOperationId, operationId, response, textStatus, errorThrown){
        super(ActionType.FetchCopyPlanOperationDescriptorFailedAction, response, textStatus, errorThrown), this.clientOperationId = clientOperationId, this.operationId = operationId;
    }
}
