// Models
/**
 * Represents information about background
 */ export class Background {
    setProperty(key, value) {
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    getClone() {
        return new Background(this.baseColor, this.overlayImage);
    }
    /**
     * Initialize an instance of the background information
     * @param baseColor Hex color code of the base background color
     * @param overlayImage The URL of the background image
     */ constructor(baseColor, overlayImage){
        this.baseColor = baseColor;
        this.overlayImage = overlayImage;
    }
}
