import { HostAdaptorAuthService } from "@ms/uno-services/lib/local/services/auth/HostAdaptorAuthService";
import { RetailServicesTokenExchangeService } from "@ms/uno-services/lib/local/services/auth/RetailServicesTokenExchangeService";
import { BaseServiceFactory } from "../../base/factories/BaseServiceFactory";
/** Implementation of the service provider factory for the Uno Teams app */
export class TeamsServiceFactory extends BaseServiceFactory {
    getAuthServiceProvider(ajaxClient, hostAdaptor, configProvider) {
        return () => {
            if (this.auth) {
                return this.auth;
            }
            if (hostAdaptor.usingNestedAppAuth() && configProvider().flights.EnableNestedAppAuth) {
                this.auth = new HostAdaptorAuthService(hostAdaptor, configProvider);
                return this.auth;
            }
            this.auth = new RetailServicesTokenExchangeService(ajaxClient, hostAdaptor, configProvider);
            return this.auth;
        };
    }
}
