// Errors
import { ArgumentNullError } from "@ms/uno-errors/lib/local/errors/ArgumentNullError";
import { MultipleDaysOrDatesRecurrenceCalculator } from "./MultipleDaysOrDatesRecurrenceCalculator";
// Utilities
import { getMomentMonthIndexFromRecurrenceMonth } from "../utilities/RecurrencePatternUtilities";
/**
 * Represents a yearly recurrence pattern calculator.
 * @example 14th of February every year. (YearlyFixed)
 * @example Fourth Thursday of November every year. (YearlyFloating)
 */ export class YearlyRecurrenceCalculator extends MultipleDaysOrDatesRecurrenceCalculator {
    offsetStart(start) {
        const startPeriod = super.offsetStart(start);
        /** OPTIMIZATION BELOW: Trim months outside of view of yearly month based pattern */ const adder = (12 + this.month - startPeriod.month()) % 12;
        return startPeriod.add(adder, "month");
    }
    isSameOrBefore(start, end) {
        // Set to beginning of the year then check. This is because we iterate through the year
        return start.clone().month(0).date(1).isSameOrBefore(end.clone().month(0).date(1));
    }
    offsetEnd(end) {
        const endPeriod = end.clone();
        if (endPeriod.isBefore(this.patternStartDate)) {
            // No need to modify as the end date doesn't have the pattern start date
            return endPeriod;
        }
        /** OPTIMIZATION BELOW: Trim months outside of view of yearly month based pattern */ const subtracter = (12 + endPeriod.month() - this.month) % 12;
        // Subtract the number of months between and then set date to the pattern start date date.
        // This will ensure we cover the last pattern start date occurrence.
        return endPeriod.subtract(subtracter, "month").date(this.patternStartDate.date());
    }
    constructor(pattern, patternStartDate){
        super(pattern, patternStartDate, "year");
        if (pattern.month == null) {
            throw new ArgumentNullError("month");
        }
        // Moment month is 0 based, Pattern month is 1 based
        // normalized month to moment
        this.month = getMomentMonthIndexFromRecurrenceMonth(pattern.month);
    }
}
