// Constants
import { FileTypeExtensions } from "@ms/uno-constants/lib/local/FileTypeExtensions";
import { FileTypesWithPreview, SupportedReferenceTypes } from "./ITaskAttachmentType";
import { TaskAttachmentType } from "./TaskAttachmentType";
// Utilities
import { VideoUrlParser } from "@ms/uno-utilities/lib/local/video/VideoUrlParser";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
/**
 * Configuration and building of TaskAttachmentType objects
 */ export class TaskAttachmentTypeBuilder {
    /** Build out the TaskAttachmentType */ build() {
        return new TaskAttachmentType(this);
    }
    /**
     * Create the task attachment type from a drive item
     * @param key The key (url) used for this attachment
     * @param driveItem The drive item to create the type from
     */ fromDriveItem(key, driveItem) {
        this.taskAttachmentType = driveItem.fileType;
        if (this.taskAttachmentType === SupportedReferenceTypes.Other && key) {
            this.specificType = this.getSpecificType(key);
            this.videoUrlParseResult = VideoUrlParser.TryParseVideoUrl(key);
        }
        return this;
    }
    /**
     * Create the task attachment type from the file name
     * @param fileName Name of the file
     */ fromFileName(fileName) {
        let fileType = SupportedReferenceTypes.Other;
        let specificType = null;
        const lastDotIndex = fileName.lastIndexOf(".");
        if (lastDotIndex > -1) {
            const fileExtension = fileName.slice(lastDotIndex + 1).toLowerCase();
            for (const extensionType of Object.keys(FileTypeExtensions)){
                const fileExtensions = FileTypeExtensions[extensionType];
                const extension = find(fileExtensions, (extension)=>{
                    return extension.toLowerCase() === fileExtension;
                });
                if (extension) {
                    fileType = TaskAttachmentType.getTaskAttachmentTypeFromString(extensionType);
                    break;
                }
            }
            if (indexOf(FileTypesWithPreview, fileExtension) >= 0) {
                specificType = fileExtension;
            }
        }
        this.specificType = specificType;
        this.taskAttachmentType = fileType;
        return this;
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.taskAttachmentType = propertyBag.taskAttachmentType || this.taskAttachmentType;
        this.specificType = propertyBag.specificType || this.specificType;
        if (this.taskAttachmentType === SupportedReferenceTypes.Other && propertyBag.url) {
            if (this.specificType == null) {
                this.specificType = this.getSpecificType(propertyBag.url);
            }
            this.videoUrlParseResult = VideoUrlParser.TryParseVideoUrl(propertyBag.url);
        }
        return this;
    }
    /**
     * Set the type of an Attachment type given supported drive item type
     * @param fileType The type to give this attachment type
     */ ofDriveItemType(fileType) {
        this.taskAttachmentType = fileType;
        return this;
    }
    /**
     * Get the internal specific type for an attachment type by parsing the url
     * @param url The url for this attachment
     */ getSpecificType(url) {
        // Remove all items before a . and split on common URL values (#, ?, & and whitespace)
        const regexed = url.slice((url.lastIndexOf(".") - 1 >>> 0) + 2).split(/\#|\?|\&|\s/g);
        if (regexed.length > 0 && indexOf(FileTypesWithPreview, regexed[0].toLowerCase()) >= 0) {
            return regexed[0];
        }
        return null;
    }
    /** Initialize an instance of the TaskAttachmentType builder */ constructor(){
        this.taskAttachmentType = SupportedReferenceTypes.Other;
        this.specificType = null;
        this.videoUrlParseResult = {
            canPlay: false,
            embedUrl: "",
            provider: "Unknown"
        };
    }
}
