// Models
import { FloatingDay } from "./FloatingDay";
/**
 * Represents a monthly floating day
 * @example Second Monday of a month.
 */ export class MonthlyFloatingDay extends FloatingDay {
    getMonthForPeriod(period) {
        return period.month();
    }
}
