import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { Mode } from "./IndexedDBConstants";
/** IndexedDB implementation */ export class IndexedDBStorage {
    /** ensures storage DB is initialized and open*/ async getDB() {
        if (this.database) {
            return this.database;
        }
        return this.initDB();
    }
    /** Method to initialize the storage
     * To be used to run the setup steps for the indexedDB storage
     */ async initDB() {
        if (this.dbOpenPromise) {
            return this.dbOpenPromise;
        }
        this.dbOpenPromise = new Promise((resolve, reject)=>{
            const request = indexedDB.open(this.name, this.version);
            request.onupgradeneeded = ()=>{
                this.traceLogger.logTrace(0x1e28b0cb /* tag_4kldl */ , TraceLevel.Verbose, `initStorageDBOnupgradeneeded [Db=${this.name}]`);
                const db = request.result;
                this.createObjectStores(db);
            };
            request.onsuccess = ()=>{
                this.database = request.result;
                this.traceLogger.logTrace(0x1e28b0ca /* tag_4kldk */ , TraceLevel.Verbose, `initStorageDBOpened [Db=${this.name}]`);
                this.database.onversionchange = ()=>{
                    this.traceLogger.logTrace(0x1e28b0c9 /* tag_4kldj */ , TraceLevel.Info, `initStorageDBOnversionchange [Db=${this.name}]`);
                    this.database?.close();
                    location.reload();
                };
                resolve(this.database);
                this.dbOpenPromise = null;
            };
            request.onerror = ()=>{
                this.traceLogger.logTrace(0x1e28b0c8 /* tag_4kldi */ , TraceLevel.Error, `initStorageDBOpenFailure [Db=${this.name}][Error=${request.error}]`);
                reject();
                this.dbOpenPromise = null;
            };
        });
        return this.dbOpenPromise;
    }
    /** Method to create object stores for the storage DB
     * Also creates the specified indexes for the object stores
     * @param db database
     */ createObjectStores(db) {
        this.objectStoreSchema.forEach((store)=>{
            if (!db.objectStoreNames.contains(store.name)) {
                const objectStore = db.createObjectStore(store.name, {
                    keyPath: store.keyPath
                });
                if (objectStore) {
                    store.indexes.forEach((index)=>{
                        objectStore.createIndex(index.name, index.name, {
                            unique: index.isUnique,
                            multiEntry: index.isMultiEntry
                        });
                    });
                    this.traceLogger.logTrace(0x1e28b0c7 /* tag_4kldh */ , TraceLevel.Verbose, `initStorageObjectStoreCreated [Db=${this.name}][store=${store.name}]`);
                }
            }
        });
    }
    /** Checks if indexedDB is supported on the browser */ static isIndexedDBSupported() {
        return "indexedDB" in window;
    }
    /** Reads the data from an indexedDB object store for the specified key */ async read(objectStore, key) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadOnly);
                const store = transaction.objectStore(objectStore);
                const request = store.get(key);
                request.onsuccess = ()=>{
                    const result = request.result;
                    resolve(result ? result : undefined);
                };
                request.onerror = ()=>{
                    this.traceLogger.logTrace(0x1e28b0c6 /* tag_4kldg */ , TraceLevel.Error, `DBReadFailed [Db=${this.name}][store=${objectStore}][Error=${request.error}]`);
                    reject();
                };
            } catch (error) {
                this.traceLogger.logTrace(0x1e289847 /* tag_4kj7h */ , TraceLevel.Error, `ReadFailed [Db=${this.name}][objectStore=${objectStore}][Error=${error}]`);
                reject();
            }
        });
    }
    /** Reads all the data from an indexedDB object store */ async readAll(objectStore) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadOnly);
                const store = transaction.objectStore(objectStore);
                const keyPath = store.keyPath;
                const request = store.getAll();
                request.onsuccess = ()=>{
                    const formattedResults = {};
                    request.result.forEach((item)=>{
                        formattedResults[item[keyPath]] = item;
                    });
                    resolve(formattedResults);
                };
                request.onerror = ()=>{
                    this.traceLogger.logTrace(0x1e28b0c5 /* tag_4kldf */ , TraceLevel.Error, `DBReadAllFailed [Db=${this.name}][store=${objectStore}][Error=${request.error}]`);
                    reject();
                };
            } catch (error) {
                this.traceLogger.logTrace(0x1e289846 /* tag_4kj7g */ , TraceLevel.Error, `ReadAllFailed [Db=${this.name}][objectStore=${objectStore}][Error=${error}]`);
                reject();
            }
        });
    }
    /** Reads the data from an indexedDB entity object store for the specified index */ async readByIndex(objectStore, indexName, indexValue) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadOnly);
                const store = transaction.objectStore(objectStore);
                const keyPath = store.keyPath;
                const index = store.index(indexName);
                const request = index.getAll(indexValue);
                request.onsuccess = ()=>{
                    const formattedResults = {};
                    request.result.forEach((item)=>{
                        formattedResults[item[keyPath]] = item;
                    });
                    resolve(formattedResults);
                };
                request.onerror = ()=>{
                    this.traceLogger.logTrace(0x1e28b0c4 /* tag_4klde */ , TraceLevel.Error, `DBReadByIndexFailed [Db=${this.name}][store=${objectStore}][index=${indexName}][Error=${request.error}]`);
                    reject();
                };
            } catch (error) {
                this.traceLogger.logTrace(0x1e289845 /* tag_4kj7f */ , TraceLevel.Error, `ReadByIndexFailed [Db=${this.name}][objectStore=${objectStore}][Error=${error}]`);
                reject();
            }
        });
    }
    /** Adds a new object to the indexedDB object store or replaces an existing object in the object store */ async write(objectStore, items) {
        const operationRecord = {};
        operationRecord[objectStore] = {
            itemsToWrite: items,
            keysToPurge: []
        };
        return this.performBatchOperation(operationRecord);
    }
    /** Removes data from the indexedDB object stores for the given keys */ async purge(objectStore, keys) {
        const operationRecord = {};
        operationRecord[objectStore] = {
            itemsToWrite: [],
            keysToPurge: keys
        };
        return this.performBatchOperation(operationRecord);
    }
    /** Perform batch put or delete over multiple object stores in the async storage DB */ async performBatchOperation(operations) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const storeNames = Object.keys(operations);
                const transaction = db.transaction(storeNames, Mode.ReadWrite);
                storeNames.forEach((storeName)=>{
                    const store = transaction.objectStore(storeName);
                    const { itemsToWrite, keysToPurge } = operations[storeName];
                    itemsToWrite.forEach((item)=>{
                        const request = store.put(item);
                        request.onerror = ()=>{
                            this.traceLogger.logTrace(0x1e28a020 /* tag_4kka6 */ , TraceLevel.Error, `performBatchOperationWriteError [Db=${this.name}][Error=${request.error}]`);
                            reject();
                        };
                    });
                    keysToPurge.forEach((key)=>{
                        const request = store.delete(key);
                        request.onerror = ()=>{
                            this.traceLogger.logTrace(0x1e289844 /* tag_4kj7e */ , TraceLevel.Error, `performBatchOperationPurgeError [Db=${this.name}][Error=${request.error}]`);
                            reject();
                        };
                    });
                });
                transaction.oncomplete = ()=>{
                    resolve();
                };
                transaction.onerror = ()=>{
                    reject(transaction.error);
                    this.traceLogger.logTrace(0x1e289843 /* tag_4kj7d */ , TraceLevel.Error, `PerformBatchOperationTransactionFailed [Db=${this.name}][Error=${transaction.error}]`);
                };
            } catch (error) {
                this.traceLogger.logTrace(0x1e289842 /* tag_4kj7c */ , TraceLevel.Error, `performBatchOperationsFailed [Db=${this.name}][Error=${error}]`);
                reject();
            }
        });
    }
    /** Clears all data from an object store in the async storage database */ async clearObjectStore(objectStore) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadWrite);
                const store = transaction.objectStore(objectStore);
                const request = store.clear();
                request.onsuccess = ()=>{
                    this.traceLogger.logTrace(0x1e28a023 /* tag_4kka9 */ , TraceLevel.Info, `DBObjectStoreCleared [Db=${this.name}][store=${objectStore}]`);
                    resolve();
                };
                request.onerror = ()=>{
                    this.traceLogger.logTrace(0x1e28a022 /* tag_4kka8 */ , TraceLevel.Error, `DBObjectStoreClearFailed [Db=${this.name}][store=${objectStore}][Error=${request.error}]`);
                    reject(request.error);
                };
            } catch (error) {
                this.traceLogger.logTrace(0x1e289841 /* tag_4kj7b */ , TraceLevel.Error, `DBObjectStoreClearError [Db=${this.name}][store=${objectStore}][Error=${error}]`);
                reject();
            }
        });
    }
    /** Clears the indexedDB storage database */ async clearStorage() {
        return new Promise((resolve, reject)=>{
            const request = indexedDB.deleteDatabase(this.name);
            request.onsuccess = ()=>{
                this.database = null;
                this.traceLogger.logTrace(0x1e28a01f /* tag_4kka5 */ , TraceLevel.Info, `DBDeleted [Db=${this.name}]`);
                resolve();
            };
            request.onerror = ()=>{
                this.traceLogger.logTrace(0x1e28b0a3 /* tag_4klc9 */ , TraceLevel.Error, `DBClearFailed [Db=${this.name}][Error=${request.error}]`);
                reject();
            };
            request.onblocked = ()=>{
                // Triggered if the deletion is blocked, for eg, if there are open connections to the database
                this.traceLogger.logTrace(0x1e28b0a2 /* tag_4klc8 */ , TraceLevel.Info, `DBDeletionBlocked [Db=${this.name}]`);
            };
        });
    }
    constructor(userId, databaseNamePrefix, version, objectStoreSchema, traceLogger){
        /** indexedDB instance */ this.database = null;
        /** IDBDatabase open promise */ this.dbOpenPromise = null;
        this.name = `${databaseNamePrefix}_${userId}`;
        this.version = version;
        this.objectStoreSchema = objectStoreSchema;
        this.traceLogger = traceLogger;
        // Starting the initialization of the storage in the constructor itself to minimize the delay before DB is ready for operations
        // Promise is handled separately below to ensure the initialization completes before accessing the DB
        this.initDB();
    }
}
