// Action creators
import { ActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/ActionCreator";
import { BaseActionCreatorFactory } from "../../base/factories/BaseActionCreatorFactory";
/** Factory class for action creator providers in Web app */
export class WebActionCreatorFactory extends BaseActionCreatorFactory {
    constructor() {
        super(...arguments);
        this.loadSuiteNavViewAc = () => this.loader.load(this.getAcBundleKey(ActionCreator.SuiteNavView), () => import(
        /* webpackChunkName: "uno-suitenavviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/SuiteNavViewActionCreator"), (bundle) => new bundle.SuiteNavViewActionCreator(this.modules, this.loggers));
    }
}
