// Models
export var ICalendarDialogType;
(function(ICalendarDialogType) {
    ICalendarDialogType[ICalendarDialogType["Plan"] = 0] = "Plan";
    ICalendarDialogType[ICalendarDialogType["AssignedToMe"] = 1] = "AssignedToMe";
})(ICalendarDialogType || (ICalendarDialogType = {}));
export var IUpdateEntityStatusForICalendar;
(function(IUpdateEntityStatusForICalendar) {
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["PublishSucceeded"] = 0] = "PublishSucceeded";
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["UnpublishSucceeded"] = 1] = "UnpublishSucceeded";
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["PublishFailed"] = 2] = "PublishFailed";
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["UnpublishFailed"] = 3] = "UnpublishFailed";
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["PublishingTasks"] = 4] = "PublishingTasks";
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["UnpublishingTasks"] = 5] = "UnpublishingTasks";
    IUpdateEntityStatusForICalendar[IUpdateEntityStatusForICalendar["None"] = 6] = "None";
})(IUpdateEntityStatusForICalendar || (IUpdateEntityStatusForICalendar = {}));
