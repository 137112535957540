// Models
// Utilities
import moment from "moment";
/**
 * Publication source information for the task
 */ export class Publication {
    /**
     * Creates a new Publication instance from a given Graph service resource
     * @param resource The service model
     */ static fromGraphResource(resource) {
        return new Publication(resource.publicationId, resource.publishingTeamId, resource.publishingTeamName, resource.lastModifiedDateTime, resource.publishedToPlanId, /* publishableTaskId is not available on Graph API */ "");
    }
    /**
     * Initialize an instance of Publication Source Information for a task
     * @param id Id of the publication
     * @param sourceTeamId Id of the team publishing the task
     * @param sourceTeamName Name of the team publishing the task
     * @param dateString The date at which the publication was most recently updated
     * @param publishedToPlanId Id of the plan this task was published to
     * @param publishableTaskId Id of the publishing template of the task
     */ constructor(id, sourceTeamId, sourceTeamName, dateString, publishedToPlanId, publishableTaskId){
        this.id = id;
        this.sourceTeamId = sourceTeamId;
        this.sourceTeamName = sourceTeamName;
        this.lastModifiedTime = moment(dateString);
        this.publishedToPlanId = publishedToPlanId;
        this.publishableTaskId = publishableTaskId;
    }
}
