import forEach from "lodash/forEach";
import isFunction from "lodash/isFunction";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import moment from "moment";
import { ArgumentError } from "@ms/uno-errors/lib/local/errors";
/**
 * Compares the source and target and returns an object corresponding to target - source
 * This only works reliably with objects with primitive contents
 * @param source The base to diff against
 * @param target The updated object to compute the diff with
 * @param customDiff An optional diff function that is passed the source value, target value, and key of all values in the diffed objects. It should return the diff for these values.
 * If the function returns undefined, the default deep comparision will be used. If the function returns an empty object, then the key will be skipped (meaning no diff)
 */ export function getDiff(source, target, customDiff) {
    "use strict";
    if (target == null) {
        return {};
    }
    if (source == null) {
        return target;
    }
    // In Typescript, numbers and strings are still 'Objects', and so can be passed to this function. Throw if that happens
    if (!(isObject(source) && isObject(target))) {
        throw new ArgumentError("source/target", "Both source and target must be objects. Source: " + JSON.stringify(source) + ". Target: " + JSON.stringify(target));
    }
    const returnObject = {};
    forEach(source, (value, key)=>{
        // If a customDiff function was passed in, try to use that
        if (customDiff) {
            const diff = customDiff(value, target[key], key);
            // If the diff function returned a value other than undefined, use it
            if (diff !== undefined) {
                // If the diff function returned an empty object, then skip this key
                if (!isEqual(diff, {})) {
                    returnObject[key] = diff;
                }
                return;
            }
        }
        // Use the default diff method
        if (isFunction(target[key]) || target[key] === value) {
            return;
        }
        if (isObject(value) && isObject(target[key])) {
            const diff = getDiff(value, target[key], customDiff);
            // If the diff function returned an empty object, then skip this key
            if (!isEqual(diff, {})) {
                returnObject[key] = diff;
            }
            return;
        }
        returnObject[key] = target[key];
    });
    return returnObject;
}
/**
 * Customizer function to be used with getDiff. Checks to see if the diffed value is a moment. If it is, the function will return target if the
 * moments are different or an empty diff if they are the same
 */ export function getDiffMomentCustomizer(source, target, key) {
    if (moment.isMoment(source) || moment.isMoment(target)) {
        // If either of these values are moments, then we should handle this scenario
        if (moment.isMoment(source) && moment.isMoment(target)) {
            if (source.valueOf() === target.valueOf()) {
                // If both are valid moments and are the same, then we don't add to diff set.
                return {};
            }
        }
        return target;
    } else {
        // If neither of these are moments, let the default differ handle this
        return undefined;
    }
}
/**
 * Customizer function to be used with lodash merge. Checks to see if the source is a moment, and returns the target if it is.
 */ export function applyDiffMomentCustomizer(source, target, key) {
    if (isFunction(source)) {
        return source;
    }
    if (moment.isMoment(source)) {
        return target;
    }
}
/**
 * Returns a new object with only all the truthy values from the input object
 * @param source Reference object
 */ export function stripOutFalseyValues(source) {
    if (source == null) {
        return null;
    }
    // use getDiff() method, passing in our object for both source and target and specifying a custom diff method
    return getDiff(source, source, (source, target, key)=>{
        if (target) {
            // if truthy, return the target
            return target;
        } else {
            // if falsey, return empty object which tells diff method to skip this key (i.e. omit)
            return {};
        }
    });
}
/**
 * Safe JSON parse
 * @param jsonString JSON string
 * @param fallback Fallback value
 * @returns Parsed JSON or fallback value
 */ export function safeJSONParse(jsonString, fallback = {}) {
    try {
        if (jsonString) {
            return JSON.parse(jsonString);
        }
        return fallback;
    } catch (error) {
        return fallback;
    }
}
