/**
 * The recurrence dropdown type
 */ export var RecurrenceDropdownType;
(function(RecurrenceDropdownType) {
    RecurrenceDropdownType["NotRepeat"] = "doesnotrepeat";
    RecurrenceDropdownType["Daily"] = "daily";
    RecurrenceDropdownType["Weekdays"] = "weekdays";
    RecurrenceDropdownType["Weekly"] = "weekly";
    RecurrenceDropdownType["Monthly"] = "monthly";
    RecurrenceDropdownType["Yearly"] = "yearly";
    RecurrenceDropdownType["Custom"] = "custom";
})(RecurrenceDropdownType || (RecurrenceDropdownType = {}));
