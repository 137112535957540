// Models
import { ContextState } from "../../../service/graph-legacy/plan/ContextState";
import { PriorityName } from "../task/ITask";
import { ProgressState } from "../progressState/ProgressState";
/**
 * PercentComplete to ProgressState converter
 *
 * @param percentComplete A percent value
 */ export function ConvertPercentCompleteToProgressState(percentComplete) {
    let progress = ProgressState.InProgress;
    if (percentComplete === 0) {
        progress = ProgressState.NotStarted;
    } else if (percentComplete === 100) {
        progress = ProgressState.Complete;
    }
    return progress;
}
/**
 * ProgressState to PercentComplete converter
 *
 * @param state A progress state
 */ export function ConvertProgressStateToPercentComplete(state) {
    let percentComplete = 0;
    if (state === ProgressState.InProgress) {
        percentComplete = 50;
    } else if (state === ProgressState.Complete) {
        percentComplete = 100;
    }
    return percentComplete;
}
/**
 * Priority value to PriorityName converter
 *
 * @param priority A priority value
 */ export function ConvertPriorityToPriorityName(priority) {
    if (priority < 0 || priority > 10) {
        throw new Error("ArgumentException: Invalid Priority value");
    }
    if (priority < 2) {
        // 0-1
        return PriorityName.Urgent;
    } else if (priority < 5) {
        // 2-4
        return PriorityName.Important;
    } else if (priority < 8) {
        // 5-7
        return PriorityName.Medium;
    } else {
        // 8-10
        return PriorityName.Low;
    }
}
/**
 * PriorityName to Priority value converter
 * @param priority A priority name
 */ export function ConvertPriorityNameToPriority(priorityName) {
    switch(priorityName){
        case PriorityName.Urgent:
            return 1;
        case PriorityName.Important:
            return 3;
        case PriorityName.Medium:
            return 5;
        case PriorityName.Low:
            return 9;
        default:
            // Assume medium priority
            return 5;
    }
}
/**
 * Numeric ContextState value to ContextStateName string converter
 * @param contextState State of a plan context
 * Bug 5820481: Remove plan context details' status field's converter once TodoWeb's fix is in
 */ export function ConvertContextStateToContextStateName(contextState) {
    if (contextState < 0 || contextState > 1) {
        throw new Error("ArgumentException: Invalid ContextState value");
    }
    let contextStateName;
    if (contextState === 0) {
        contextStateName = ContextState.Active;
    } else if (contextState === 1) {
        contextStateName = ContextState.Delinked;
    }
    return contextStateName;
}
