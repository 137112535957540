// Models
import { SupportedVideoTypes } from "../SupportedVideoTypes";
export var Vimeo;
(function(Vimeo) {
    const showcaseUrlPattern = /vimeo\.com\/album\/.+\/video\/(.+)/;
    const channelUrlPattern = /vimeo\.com\/channels\/.+\/(.+)/;
    const groupUrlPattern = /vimeo\.com\/groups\/.+\/videos\/(.+)/;
    const onDemandUrlPattern = /vimeo\.com\/ondemand\/.+\/(.+)/;
    const videoUrlPattern = /vimeo\.com\/(.+)/;
    function tryParseVideoUrl(url, result) {
        const matches = url.match(showcaseUrlPattern) ? url.match(showcaseUrlPattern) : url.match(channelUrlPattern) ? url.match(channelUrlPattern) : url.match(groupUrlPattern) ? url.match(groupUrlPattern) : url.match(onDemandUrlPattern) ? url.match(onDemandUrlPattern) : url.match(videoUrlPattern);
        if (matches != null) {
            result.canPlay = true;
            result.embedUrl = `https://player.vimeo.com/video/${matches[1]}?autoplay=0`;
            result.provider = SupportedVideoTypes.Vimeo;
            return true;
        }
        return false;
    }
    /**
     * Try to parse a url as a provider video url.
     * Returns true if parsing is a success, false otherwise
     * @param url Url to parse
     * @param result Result of the Video parsing
     */ Vimeo.tryParseVideoUrl = tryParseVideoUrl;
})(Vimeo || (Vimeo = {}));
