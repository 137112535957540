// Models
import { Attachment } from "./Attachment";
import { AttachmentTypeBuilder } from "./AttachmentTypeBuilder";
import { SupportedReferenceTypes, FileTypesWithPreview } from "../../taskAttachment/ITaskAttachmentType";
import { TaskAttachmentBuilder } from "../../taskAttachment/TaskAttachmentBuilder";
import { TaskAttachmentType } from "../../taskAttachment/TaskAttachmentType";
/**
 * Configuration and building of Attachment objects
 */ export class AttachmentBuilder extends TaskAttachmentBuilder {
    /**
     * Build out the Attachment
     */ build() {
        return new Attachment(this);
    }
    /**
     * Create the attachment type from a graph reference
     * @param key The key from the dictionary, used as the url for the client
     * @param attachment The attachment to create the type from
     */ fromGraphAttachmentResource(key, attachment) {
        this.url = key;
        this.alias = attachment.alias;
        const attachmentTypeBuilder = new AttachmentTypeBuilder();
        this.taskAttachmentType = attachmentTypeBuilder.fromGraphAttachmentResource(key, attachment).build();
        this.previewPriority = attachment.previewPriority;
        return this;
    }
    /**
     * Create the attachment type from drive item
     * @param driveUrl The group drive URL
     * @param driveItem The attachment to create the type from
     */ attachmentFromDriveItem(driveUrl, driveItem) {
        super.fromDriveItem(driveUrl, driveItem);
        this.previewPriority = "";
        this.isProtected = false;
        return this;
    }
    /**
     * Create the attachment type from from
     * @param form The form to create the attachment type from
     */ attachmentFromForm(form, urlType) {
        super.fromForm(form, urlType);
        this.previewPriority = "";
        this.isProtected = false;
        return this;
    }
    /**
     * Create the attachment type from recently uploaded drive item
     * @param driveUrl The group drive URL
     * @param driveItemName The recently uploaded drive items name
     * @param taskAttachmentType The task attachment type
     * @param previewPriority The preview priority
     * @param name Name of the item the file service returned
     */ fromRecentlyUploadedDriveItem(driveUrl, driveItemName, taskAttachmentType, previewPriority, name) {
        this.url = driveUrl + "/" + encodeURIComponent(name) + "?web=1";
        this.alias = driveItemName.substring(0, Attachment.AliasMaxLength);
        this.taskAttachmentType = TaskAttachmentType.builder.withPropertyBag({
            url: this.url,
            taskAttachmentType: taskAttachmentType.taskAttachmentType
        }).build();
        this.previewPriority = previewPriority;
        this.isProtected = false;
        return this;
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        super.withPropertyBag({
            alias: propertyBag.alias,
            taskAttachmentType: propertyBag.taskAttachmentType,
            url: propertyBag.url
        });
        this.previewPriority = propertyBag.previewPriority || this.previewPriority || "";
        this.isProtected = propertyBag.isProtected !== undefined ? propertyBag.isProtected : this.isProtected;
        this.clientExtensions = propertyBag.clientExtensions || this.clientExtensions;
        return this;
    }
    /**
     * A test method to create an attachment pre-populated
     * @param uid A number to use for populating unique data
     */ withExpectedData(uid) {
        return this.withPropertyBag({
            alias: "name/alias" + uid,
            taskAttachmentType: TaskAttachmentType.builder.withPropertyBag({
                taskAttachmentType: SupportedReferenceTypes.Other,
                specificType: FileTypesWithPreview[2]
            }).build(),
            url: "https://microsoft.sharepoint.com/teams/plannerweb/Shared%20Documents/documents/" + uid + ".png?web=1",
            previewPriority: "1000" + uid,
            isProtected: false
        });
    }
    /**
     * A test method to create an attachment of a certain type
     * @param uid A number to use for making this unique
     * @param fileType The type of attachment we want to create
     * @param isSpecial Used when type is Other, and want to make this a specially handled type
     */ ofType(uid, fileType, isSpecial) {
        return this.withExpectedData(uid).withPropertyBag({
            taskAttachmentType: TaskAttachmentType.builder.withPropertyBag({
                taskAttachmentType: fileType,
                specificType: fileType === SupportedReferenceTypes.Other ? isSpecial ? FileTypesWithPreview[0] : "unknown" : null
            }).build()
        });
    }
    constructor(...args){
        super(...args), this.isProtected = false, this.clientExtensions = null;
    }
}
