/**
 * Enumeration of the possible application events that can generate notifications
 */ export var NotificationEvents;
(function(NotificationEvents) {
    /**
     * No notifications should be generated.
     */ NotificationEvents[NotificationEvents["None"] = 0] = "None";
    /**
     * Task creation events generate notifications.
     */ NotificationEvents[NotificationEvents["TaskCreation"] = 1] = "TaskCreation";
    /**
     * Task assignment events generate notifications.
     */ NotificationEvents[NotificationEvents["TaskAssignment"] = 2] = "TaskAssignment";
    /**
     * Task completion events generate notifications.
     */ NotificationEvents[NotificationEvents["TaskCompleted"] = 4] = "TaskCompleted";
    /**
     * All events generate notifications.
     */ NotificationEvents[NotificationEvents["All"] = -1] = "All";
})(NotificationEvents || (NotificationEvents = {}));
