// Models
export class ChecklistCompletionRequirement {
    toGraphSerializable() {
        return {
            requiredChecklistItemIds: this.requiredChecklistItemIds
        };
    }
    static fromResource(resource) {
        return new ChecklistCompletionRequirement(resource.requiredChecklistItemIds);
    }
    setProperty(key, value) {
        const clone = new ChecklistCompletionRequirement([
            ...this.requiredChecklistItemIds
        ]);
        clone[key] = value;
        return clone;
    }
    constructor(requiredChecklistItemIds = []){
        this.requiredChecklistItemIds = requiredChecklistItemIds;
    }
}
