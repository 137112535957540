import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for BasicPermissionsStore, for use in the registry */ export const BasicPermissionsStoreActionTypes = [
    ActionType.BatchFetchContainerPermissionsSucceededAction,
    ActionType.BatchFetchTaskPermissionsFailedAction,
    ActionType.BatchFetchTaskPermissionsSucceededAction,
    ActionType.CheckCurrentUserToGroupsMembershipsSucceededAction,
    ActionType.CreateBasicPlanAction,
    ActionType.CreateBasicPlanFailedAction,
    ActionType.CreateBasicPlanSucceededAction,
    ActionType.DeleteBasicPlanSucceededAction,
    ActionType.DeleteGroupSucceededAction,
    ActionType.DeltaSyncDeleteBasicPlanAction,
    ActionType.FetchAppContextSucceeded,
    ActionType.FetchBasicPlanSucceededAction,
    ActionType.FetchContainerFailedAction,
    ActionType.FetchContainerSucceededAction,
    ActionType.FetchDriveItemFailedAction,
    ActionType.FetchDriveItemSucceededAction,
    ActionType.FetchGroupDetailsSucceededAction,
    ActionType.FetchTenantGroupSettingsSucceededAction,
    ActionType.FetchUserFeatureEntitlementsSucceededAction,
    ActionType.JoinGroupSucceededAction,
    ActionType.LeaveGroupSucceededAction,
    ActionType.PrefetchBasicPlanDataSucceededAction,
    ActionType.SetDefaultPlanAction,
    ActionType.SetFlaggedEmailPlanAction,
    ActionType.SetUsageModeAction
];
