// Models
import { FormUrlType } from "./FormUrlType";
// Utilities
import { compareStringForDisplay } from "@ms/uno-utilities/lib/local/Comparators";
import { getQueryParamValue } from "@ms/uno-utilities/lib/local/UrlUtilities";
import forOwn from "lodash/forOwn";
import random from "lodash/random";
/**
 * Represents an form entity on a task
 */ export class Form {
    /**
     * Compare display names of two forms
     * @param form1 first form
     * @param form2 second form
     */ static displayNameCompare(item1, item2) {
        return compareStringForDisplay(item1?.displayName?.toUpperCase(), item2?.displayName?.toUpperCase());
    }
    /**
     * Generate a form id for a new form that is unique to the other forms on the task
     * @param forms Dictionary of forms on the task to check against
     */ static generateFormUniqueId(forms) {
        const maxId = 99999;
        const minId = 10000;
        let id = "";
        do {
            id = random(minId, maxId, false).toString();
        }while (forms[id] != null)
        return id;
    }
    /** Parse the form id from the url
     * @param formUrl Url to parse the form id from
     */ static parseFormIdFromUrl(formUrl) {
        return getQueryParamValue(formUrl, "id");
    }
    /** Get the form url for the given form id
     * @param urlFormId Form id to use for the url
     * @param urlType Type of url to get
     * @param form Form to use for the url
     */ static getFormUrl(urlFormId, urlType, form) {
        if (!urlFormId) return "";
        switch(urlType){
            case FormUrlType.EditForm:
                return `https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=${urlFormId}`;
            case FormUrlType.ViewResponses:
                return `https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=${urlFormId}&analysis=true`;
            case FormUrlType.CollectResponses:
            default:
                return form?.collectResponsesUrl ?? `https://forms.office.com/Pages/ResponsePage.aspx?id=${urlFormId}`;
        }
    }
    /**
     * Convert the dictionary of graph references from resource type to client type
     * @param forms Dictionary where key is form id
     */ static getGraphClientDictionaryType(forms) {
        const result = {};
        forOwn(forms, (value, key)=>{
            key = decodeURIComponent(key);
            result[key] = Form.fromGraphFormResource(key, value);
        });
        return result;
    }
    /**
     * Converts the graph form items dictionary to one of IGraphFormResource type
     * @param forms forms dictionary of client type
     */ static getGraphResourceDictionaryType(forms) {
        const result = {};
        forOwn(forms, (value, key)=>{
            result[key] = value.toGraphSerializable();
        });
        return result;
    }
    /**
     * Create the form type from a graph reference
     * @param key The key from the dictionary, used as the unique form id on the task for the client
     * @param form The form to create the type from
     */ static fromGraphFormResource(key, form) {
        return new Form({
            displayName: form.displayName,
            formResponse: form.formResponse,
            formId: key,
            urlFormId: Form.parseFormIdFromUrl(form.formWebUrl) ?? "",
            collectResponsesUrl: form.formWebUrl
        });
    }
    /**
     * Create the form type from an office form
     * @param officeForm The office form
     * @param formId A generated form id
     */ static fromOfficeForm(officeForm, formId) {
        return new Form({
            displayName: officeForm.title,
            formResponse: null,
            formId: formId,
            urlFormId: officeForm.id,
            collectResponsesUrl: Form.getFormUrl(officeForm.id, FormUrlType.CollectResponses)
        });
    }
    toGraphSerializable() {
        return {
            "@odata.type": "#microsoft.taskServices.form",
            displayName: this.displayName,
            formWebUrl: this.collectResponsesUrl,
            formResponse: this.formResponse
        };
    }
    getClone() {
        return new Form(this);
    }
    /**
     * @param key Name of property on the form object
     * @param value Value to set on the property
     */ setProperty(key, value) {
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    constructor(propertyBag){
        this.displayName = propertyBag.displayName;
        this.formResponse = propertyBag.formResponse;
        this.formId = propertyBag.formId;
        this.collectResponsesUrl = propertyBag.collectResponsesUrl;
        this.urlFormId = propertyBag.urlFormId;
    }
}
/** Max length limitation of form title */ Form.TitleMaxLength = 90;
