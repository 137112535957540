import * as DOMPurify from "dompurify";
/**
 * Create trusted html
 */ export const createTrustedHtml = (html)=>{
    const unoPolicy = window.trustedTypes?.createPolicy("uno-policy", {
        createHTML: (htmlString)=>htmlString
    });
    return unoPolicy ? unoPolicy.createHTML(html) : html;
};
/**
 * Create trusted script URL
 */ export const createTrustedScriptUrl = (url)=>{
    const cdnUrlPolicy = window.trustedTypes?.createPolicy("cdn-url#flux", {
        createScriptURL: (urlString)=>urlString
    });
    return cdnUrlPolicy ? cdnUrlPolicy.createScriptURL(url) : url;
};
/**
 * Create default policy
 * This is a temporary fix for Trusted Types violations within jQuery and FullCalendar library.
 * This fix can be removed if all of the following conditions are met:
 *      1. Onboard to Microsoft SignalR and remove jQuery.
 *      2. FullCalendar supports Trusted Types. (https://github.com/fullcalendar/fullcalendar/issues/6482)
 */ export const createDefaultPolicy = ()=>{
    if (window?.trustedTypes) {
        window.trustedTypes.createPolicy("default", {
            createHTML: (str)=>{
                return DOMPurify.sanitize(str);
            }
        });
    }
};
