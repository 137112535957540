// Utilities
/**
 * Ajax client for GraphOdataApi which convert the graph error response to standard Error
 */ export class GraphAjaxClient {
    async executeRequest(url, options) {
        try {
            return await this.innerAjaxClient.executeRequest(url, options);
        } catch (e) {
            if (e.response && !e.error) {
                const error = await this.generateGraphError(e.response);
                if (error) {
                    e.error = error;
                }
            }
            throw e;
        }
    }
    /**
     * Handle a graph error response
     * @param response Response
     */ async generateGraphError(response) {
        try {
            const errorResponse = await response.clone().json();
            const error = new Error(errorResponse.error?.message);
            error.name = errorResponse.error?.code;
            return error;
        } catch (e) {
            return null;
        }
    }
    /**
     * Initializes a new instance of the GraphAjaxClient
     * @param innerAjaxClient Ajax client
     */ constructor(innerAjaxClient){
        this.innerAjaxClient = innerAjaxClient;
    }
}
