import keys from "lodash/keys";
/**
 * Helper class to generate a translation map between a Resource server Model into the Client delta object.
 */ export class DeltaTranslationMap {
    /**
     * Adds a map between a resource key from the Server OM to a client key in the client OM.
     * Basic values will be just copied on the translation, for example, number -> number
     * @param resourceKey A name of parameter from the Server OM that will be translated from
     * @param clientKey A result name that the translation with be used
     * @param [customTranslator] If this function is specified it will be used to generate the new value on the resulting translation delta
     */ addMapping(resourceKey, clientKey, customTranslator) {
        if (!this.translationMap[resourceKey]) {
            this.translationMap[resourceKey] = {};
        }
        const translationFunction = customTranslator ? customTranslator : (value)=>value;
        this.translationMap[resourceKey][clientKey] = translationFunction;
    }
    /** Returns true if the resource key is set as translation in the translation map */ hasMapping(resourceKey) {
        return resourceKey in this.translationMap;
    }
    /** Returns the list of client keys mapped to given resource key */ getMappedKeys(resourceKey) {
        return keys(this.translationMap[resourceKey]);
    }
    /** Returns all resource map keys */ getResourceKeys() {
        return keys(this.translationMap);
    }
    /** Returns the list of client key mapped to given resource key */ getMappedClientKey(resourceKey) {
        const keys = this.getMappedKeys(resourceKey);
        if (keys.length === 0) {
            throw new Error("ArgumentException: mapping not found");
        }
        if (keys.length > 1) {
            throw new Error("ArgumentException: too many mappings");
        }
        return keys[0];
    }
    /** Clears the mapping */ reset() {
        this.translationMap = {};
    }
    /** Translate given mapping resource value into client value for given (resourceKey, clientKey) mapping  */ translateValue(resourceKey, clientKey, originalValue) {
        if (!this.hasMapping(resourceKey)) {
            throw new Error("ArgumentException: resourceKey not found");
        }
        if (!(clientKey in this.translationMap[resourceKey])) {
            throw new Error("ArgumentException: clientKey not found");
        }
        return this.translationMap[resourceKey][clientKey](originalValue);
    }
    /**
     * Translates the given delta update from a Server Resource OM into delta object update for client OM using current translation map from this instance
     * @param update The input delta update
     */ translate(update) {
        const result = {};
        for(const resourceKey in update){
            for(const clientKey in this.translationMap[resourceKey]){
                const originalValue = update[resourceKey];
                result[clientKey] = this.translateValue(resourceKey, clientKey, originalValue);
            }
        }
        return result;
    }
    /**
     * Constructor, starts a empty translation map
     */ constructor(){
        this.reset();
    }
}
