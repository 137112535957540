// Styles
import { flexParent, styleScrollbar } from "@ms/uno-controls/lib/local/utilities/Mixin.styles";
import { memoizeFunction, mergeStyles } from "@fluentui/react";
/**
 * Helper to get styles for the createTaskFromMessage shell
 * @param theme The theme
 */ export const getCreateTaskFromMessageShellStyles = memoizeFunction((theme)=>{
    const createTaskFromMessageShellStyles = {
        root: [
            flexParent("column"),
            {
                backgroundColor: theme.semanticColors.bodyBackground,
                maxHeight: "100vh",
                maxWidth: "100vw",
                inset: "0px",
                position: "fixed",
                "*": styleScrollbar(theme),
                ul: {
                    margin: "0",
                    padding: "0"
                },
                li: {
                    listStyleType: "none"
                }
            }
        ]
    };
    return createTaskFromMessageShellStyles;
});
/**
 * Get the classnames associated with the computed styles for the createTaskFromMessage shell
 * @param styles The styles for the component
 */ export const getCreateTaskFromMessageShellClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("root", styles.root)
    };
});
