import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for OrgStore, for use in the registry */ export const OrgStoreActionTypes = [
    ActionType.FetchCdsInstanceWithPackageAction,
    ActionType.FetchCdsInstanceWithPackageFailedAction,
    ActionType.FetchCdsInstanceWithPackageSucceededAction,
    ActionType.FetchOrgCapabilitiesSucceeded,
    ActionType.FetchCdsInstanceWithAllPackagesAction,
    ActionType.FetchCdsInstanceWithAllPackagesSucceededAction,
    ActionType.FetchCdsInstanceWithAllPackagesFailedAction
];
