/**
 * Event name for Data Pre-fetcher completion
 */ export const DataPreFetchCompletedEventName = "DataPreFetchCompleted";
/**
 * Variable name for Data Pre-fetcher result in the window object
 */ export const DataPreFetcherResultVarName = "dataPreFetcherResult";
/**
 * Variable name for Data Pre-fetcher completed status in the window object
 */ export const DataPreFetchCompletedVarName = "dataPreFetchCompleted";
export var PreFetchedDataTypes;
(function(PreFetchedDataTypes) {
    /**
     * Mru type
     */ PreFetchedDataTypes["Mru"] = "mru";
})(PreFetchedDataTypes || (PreFetchedDataTypes = {}));
