// Models
// Utilities
import forEach from "lodash/forEach";
/**
 * Represents a plan context details.
 */ export class PlanContextDetails {
    /**
     * Converts the given dictionary of PlanContextDetails into a dictionary of IGraphPlanDetailsContextResource for serialization.
     * @param contexts Dictionary of contexts from a plan
     */ static getGraphContextResources(contexts) {
        const contextResources = {};
        const keys = Object.keys(contexts);
        forEach(keys, (key)=>{
            contextResources[key] = contexts[key].toGraphSerializable();
        });
        return contextResources;
    }
    /**
     * Converts the given IGraphPlanDetailsContextResource into a PlanContextDetails.
     */ static getPlanContextDetailsFromGraph(value) {
        return new PlanContextDetails(value.url, value.displayLinkType, value.customLinkText, value.state);
    }
    /**
     * Converts the given dictionary of IGraphPlanDetailsContextResource into a dictionary of PlanContextDetails for serialization.
     * @param contextResources Dictionary of contexts from a plan
     */ static getContextsFromGraphResource(contextResources) {
        const contexts = {};
        const keys = Object.keys(contextResources);
        forEach(keys, (key)=>{
            contexts[key] = PlanContextDetails.getPlanContextDetailsFromGraph(contextResources[key]);
        });
        return contexts;
    }
    setProperty(key, value) {
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    toGraphSerializable() {
        return {
            "@odata.type": "#microsoft.graph.plannerPlanContextDetails",
            url: this.url,
            displayLinkType: this.displayAs,
            customLinkText: this.customLinkText,
            state: this.status
        };
    }
    getClone() {
        return new PlanContextDetails(this.url, this.displayAs, this.customLinkText, this.status);
    }
    isVisible() {
        return this.url != null && this.displayAs != null;
    }
    /**
     * Initialize a new instance of the plan context details object
     * @param url Optional link to the external location.
     * @param displayAs Optional display information for the given context
     * @param customLinkText Optional text to be used when the displayAs is set to Other
     * @param status Optional property indicates the status of the plan context
     */ constructor(url, displayAs, customLinkText, status){
        this.url = url;
        this.displayAs = displayAs;
        this.customLinkText = customLinkText;
        this.status = status;
    }
}
