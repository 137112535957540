// Models
import { ExternalBucketSource } from "./ExternalBucketSource";
/**
 * Creation source information for the bucket
 */ export class BucketCreationSource {
    toGraphSerializable() {
        const resource = {};
        if (this.external) {
            resource.external = this.external.toGraphSerializable();
        }
        return resource;
    }
    /**
     * Initialize an instance of the creation source information for a bucket
     * @param creationSource creation source information from the service
     */ constructor(creationSource){
        if (creationSource.external != null) {
            this.external = ExternalBucketSource.fromResource(creationSource.external);
        }
    }
}
