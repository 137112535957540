export class ApprovalCompletionRequirement {
    toGraphSerializable() {
        return {
            isApprovalRequired: this.isApprovalRequired
        };
    }
    static fromResource(resource) {
        return new ApprovalCompletionRequirement(resource.isApprovalRequired);
    }
    setProperty(key, value) {
        const clone = new ApprovalCompletionRequirement(this.isApprovalRequired);
        clone[key] = value;
        return clone;
    }
    constructor(isApprovalRequired = false){
        this.isApprovalRequired = isApprovalRequired;
    }
}
