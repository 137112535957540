/**
 * Enumeration of the possible item types in task service.
 *
 * Note: This is used to determine entity types on diff sync updates and thus
 * must be updated if the values for those change
 */ export var TaskServiceEntityType;
(function(TaskServiceEntityType) {
    /** Task entity */ TaskServiceEntityType[TaskServiceEntityType["Task"] = 1] = "Task";
    /** Task details entity */ TaskServiceEntityType[TaskServiceEntityType["TaskDetails"] = 2] = "TaskDetails";
    /** Plan entity */ TaskServiceEntityType[TaskServiceEntityType["Plan"] = 3] = "Plan";
    /** Plan details entity */ TaskServiceEntityType[TaskServiceEntityType["PlanDetails"] = 4] = "PlanDetails";
    /** User entity */ TaskServiceEntityType[TaskServiceEntityType["User"] = 5] = "User";
    /** User details entity */ TaskServiceEntityType[TaskServiceEntityType["UserDetails"] = 6] = "UserDetails";
    /** Formatting entity for a task on timeline */ TaskServiceEntityType[TaskServiceEntityType["TimelineTaskFormatting"] = 7] = "TimelineTaskFormatting";
    /** Formatting entity for timeline */ TaskServiceEntityType[TaskServiceEntityType["TimelineFormatting"] = 8] = "TimelineFormatting";
    /** Formatting entity for status taskboard */ TaskServiceEntityType[TaskServiceEntityType["TaskboardStatusFormatting"] = 9] = "TaskboardStatusFormatting";
    /** Formatting entity for a task on status taskboard */ TaskServiceEntityType[TaskServiceEntityType["TaskboardStatusTaskFormatting"] = 10] = "TaskboardStatusTaskFormatting";
    /** Formatting entity for assignedTo taskboard */ TaskServiceEntityType[TaskServiceEntityType["TaskboardAssignedToFormatting"] = 11] = "TaskboardAssignedToFormatting";
    /** Formatting entity for a task on assignedTo taskboard */ TaskServiceEntityType[TaskServiceEntityType["TaskboardAssignedToTaskFormatting"] = 12] = "TaskboardAssignedToTaskFormatting";
    /** Bucket entity */ TaskServiceEntityType[TaskServiceEntityType["Bucket"] = 13] = "Bucket";
    /** Formatting entity for bucket taskboard */ TaskServiceEntityType[TaskServiceEntityType["TaskboardBucketFormatting"] = 14] = "TaskboardBucketFormatting";
    /** Formatting entity for a task on bucket taskboard */ TaskServiceEntityType[TaskServiceEntityType["TaskboardBucketTaskFormatting"] = 15] = "TaskboardBucketTaskFormatting";
})(TaskServiceEntityType || (TaskServiceEntityType = {}));
