// Utilities
import { TraceLevel } from "@ms/uno-constants/lib/local/configuration/ISettings";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
/**
 * Utility to create a proxy for a dynamically loaded action creator
 * This is used for calling methods on an action creator.
 *
 * Example:
 *
 * interface ISampleActionCreator {
 *     doSomething(): void;
 * }
 *
 * Dynamic usage:
 * const ac = await loader();
 * ac.doSomething();
 *
 * Proxy:
 * const proxy: IComponent = {
 *     doSomething: async () => {
 *         const component = await loader();
 *         component.doSomething();
 *     }
 * }
 *
 *
 * Proxy usage (More concise):
 * proxy.doSomething();
 *
 * IMPORTANT: Absorbing the "await" inside the proxy ensures that the caller can call it synchronously.
 * And given that the inner promise is never returned, the caller cannot forcefully await on it either.
 * This ensures that subsequent operations that don't (and should never) depend on the action creator's result
 * are also executed immediately ensuring optimal performance.
 *
 * @param name Name of the action creator
 * @param loader Loader that loads the action creator async
 * @param loggers Loggers
 */
export function createActionCreatorProxy(name, loader, loggers) {
    return new Proxy({}, {
        get(_, methodName) {
            if (typeof methodName === "string") {
                return async (...args) => {
                    try {
                        // First load the action creator
                        const component = await loader();
                        // Then call the method on the action creator
                        const method = component[methodName].bind(component);
                        const result = method.apply(component, args);
                        // Await if the method returns a promise, so that we can catch any errors
                        if (result instanceof Promise) {
                            await result;
                        }
                        // IMPORTANT: The result is not returned to ensure that callers cannot depend on it
                    }
                    catch (error) {
                        loggers.traceLogger.logTrace(0x1e28e8db /* tag_4ko91 */, TraceLevel.Warning, `[ActionCreator=${name}][Method=${methodName}][Error=${ErrorUtilities.getMessage(error)}]`);
                    }
                };
            }
        },
    });
}
