// Models
import { MonthlyFixedDate } from "../calculator/MonthlyFixedDate";
import { MonthlyFloatingDay } from "../calculator/MonthlyFloatingDay";
import { RecurrencePatternType } from "../../../../../service/graph-legacy/task/recurrence/RecurrencePatternType";
import { WeeklyDay } from "../calculator/WeeklyDay";
import { YearlyFixedDate } from "../calculator/YearlyFixedDate";
import { YearlyFloatingDay } from "../calculator/YearlyFloatingDay";
/**
 * Get the DayOrDate objects given a Recurrence Pattern
 * @param recurrencePattern Recurrence pattern to get days or dates from
 */ export function getDaysOrDates(recurrencePattern) {
    switch(recurrencePattern.recurrencePatternType){
        case RecurrencePatternType.Weekly:
            return recurrencePattern.daysOfWeek.map((dayOfWeek)=>new WeeklyDay(dayOfWeek));
        case RecurrencePatternType.AbsoluteMonthly:
            return [
                new MonthlyFixedDate(recurrencePattern.dayOfMonth)
            ];
        case RecurrencePatternType.AbsoluteYearly:
            return [
                new YearlyFixedDate(recurrencePattern.month, recurrencePattern.dayOfMonth)
            ];
        case RecurrencePatternType.RelativeMonthly:
            return recurrencePattern.daysOfWeek.map((dayOfWeek)=>new MonthlyFloatingDay(recurrencePattern.weekIndex, dayOfWeek));
        case RecurrencePatternType.RelativeYearly:
            return recurrencePattern.daysOfWeek.map((dayOfWeek)=>new YearlyFloatingDay(recurrencePattern.month, recurrencePattern.weekIndex, dayOfWeek));
        case RecurrencePatternType.Daily:
        default:
            // Daily has no day or date as it will be every day or interval day
            return null;
    }
}
