// Constants
import { DueDateCategory } from "@ms/uno-constants/lib/local/DateConstants";
// Errors
import { ArgumentError } from "@ms/uno-errors/lib/local/errors";
// Utilities
import isEmpty from "lodash/isEmpty";
import moment from "moment";
/**
 * Get the default due date for a due date category
 * @param dueDateCategory The due date category
 */ export function getDefaultDueDateForCategory(dueDateCategory) {
    switch(dueDateCategory){
        case DueDateCategory.Late:
            // Yesterday
            return ToUTC10am(moment().subtract(1, "day"));
        case DueDateCategory.Today:
            // Current day
            return ToUTC10am(moment());
        case DueDateCategory.Tomorrow:
            // End of current day + 1 day
            return ToUTC10am(moment().add(1, "day"));
        case DueDateCategory.ThisWeek:
            // Last day of range calculated for this week
            return ToUTC10am(moment().endOf("week"));
        case DueDateCategory.NextWeek:
            // Last day of range calculated for next week
            return ToUTC10am(moment().add(7, "days").endOf("week"));
        case DueDateCategory.Future:
            // First day of range calculated for future
            return ToUTC10am(moment().add(7, "days").endOf("week").add(1, "day"));
        case DueDateCategory.NoDate:
        default:
            return null;
    }
}
/**
 * Random date generator
 * @param start Start of range for date
 * @param end End of range for date
 */ export function randomDate(start, end) {
    if (end < start) {
        throw new ArgumentError("start", "start must be < end");
    }
    return moment(+start + Math.random() * (end - start));
}
/**
 * Generate a random moment prior to the specified timestamp
 * @param timestamp The timestamp to generate a random date before
 */ export const generateRandomDateBefore = (timestamp)=>{
    return moment(randomDate(timestamp.valueOf() - 10000000000, timestamp.valueOf()));
};
/**
 * A customized comparer for moment dates, this is used by _.isEqualWith
 * @param object1 First object to compare
 * @param object2 Second object to compare
 */ export function isEqualMomentCustomizer(object1, object2) {
    if (moment.isMoment(object1) || moment.isMoment(object2)) {
        if (moment.isMoment(object1) && moment.isMoment(object2)) {
            if (object1.valueOf() === object2.valueOf()) {
                return true;
            }
        }
        return false;
    } else {
        // If neither are moments, let default comparer handle it
        return undefined;
    }
}
/**
 * Check if two dates are on the same day (ignore time)
 * @param date1 First date
 * @param date2 Second date
 */ export function IsSameDay(date1, date2) {
    if (date1 == null && date2 == null) {
        return true;
    }
    if (date1 == null || date2 == null) {
        return false;
    }
    if (date1.year() === date2.year() && date1.month() === date2.month() && date1.date() === date2.date()) {
        return true;
    }
    return false;
}
/**
 * Method to convert datetime from local to UTC at 10AM
 * @param date Moment object to convert to UTC 10AM.
 */ export function ToUTC10am(originalDate) {
    // Discard Time info and set Time to noon to prevent Time Conversions from changing the date.
    return moment.utc().year(originalDate.year()).month(originalDate.month()).date(originalDate.date()).hour(10).minute(0).second(0).millisecond(0);
}
/**
 * Method to convert datetime from local to UTC at 10AM
 * @param date Moment object to convert to UTC 10AM.
 */ export function NormalizeDate(originalDate) {
    let utc10amDate = null;
    if (originalDate != null) {
        utc10amDate = ToUTC10am(originalDate);
    }
    return utc10amDate;
}
/**
 * Method to format a date into locale specific string
 * MMM DD if within a year of today or long form if over a year away
 * @param date Moment date to format
 * @param [useYear] Use the year even if the date is within a year of today. Default is false
 * @param [format] The localized format to use. Default is "L". Supported formats are "L" and "ll"
 */ export function FormatDate(date, useYear, format = "L") {
    const currentYear = moment().year();
    const dateYear = date.year();
    const useYearInFormat = useYear || Math.abs(currentYear - dateYear) >= 1;
    const longFormat = moment.localeData().longDateFormat(format);
    const longFormatWithoutYear = longFormat.replace(/[\,\/]*[^\.]?YYYY.?/g, ""); // remove year with either "," or "/" before a separator pattern
    return date.format(useYearInFormat ? longFormat : longFormatWithoutYear);
}
/**
 * Format a date into locale specific string
 * @param date Javascript date to format
 */ export function FormatJSDate(date) {
    const momentDate = moment(date);
    return FormatDate(momentDate);
}
/**
 * Format a date into locale specific string with specified format string
 * @param date Javascript date to format
 * @param format The custom format to use
 */ export function FormatJSDateCustom(date, format) {
    const momentDate = moment(date);
    return momentDate.format(format);
}
/**
 * Format a date into locale specific string long form including the year
 * @param date Javascript date to format
 */ export function FormatJSDateLongForm(date) {
    const momentDate = moment(date);
    return FormatDate(momentDate, true);
}
/**
 * Parse the date from the string using moment to ensure using the correct locale
 * @param dateString String to parse date
 */ export function ParseDateFromString(dateString, strict = true) {
    if (isEmpty(dateString)) {
        return null;
    }
    const momentDate = ParseMomentFromString(dateString, strict);
    if (momentDate?.isValid()) {
        return momentDate.toDate();
    }
    return null;
}
/**
 * Parse the date from a string into a new moment object in the correct locale
 * @param dateString String to parse date
 * @param strict Whether to use strict parsing or not. Default is true
 */ export function ParseMomentFromString(dateString, strict = true) {
    if (isEmpty(dateString)) {
        return null;
    }
    const longFormat = moment.localeData().longDateFormat("L");
    const momentDate = moment(dateString, [
        longFormat
    ], moment.locale(), strict);
    if (momentDate?.isValid()) {
        return momentDate;
    }
    return null;
}
/**
 * Check whether a date is today
 * @param date Moment date to check
 */ export function IsToday(date) {
    if (date == null) {
        return false;
    }
    const today = moment().local().startOf("day").valueOf();
    const dateStartTime = date.clone().local().startOf("day").valueOf();
    return dateStartTime === today;
}
/**
 * Check whether a date is tomorrow
 * @param date Moment date to check
 */ export function IsTomorrow(date) {
    if (date == null) {
        return false;
    }
    const endOfTomorrow = moment().local().add(1, "day").endOf("day").valueOf();
    const dateEndOfDay = date.clone().local().endOf("day").valueOf();
    return dateEndOfDay === endOfTomorrow;
}
/**
 * Check whether a date lies in current week
 * @param date Moment date to check
 */ export function IsInCurrentWeek(date) {
    if (date == null) {
        return false;
    }
    const endOfWeek = moment().local().endOf("week").valueOf();
    const dateEndOfWeek = date.clone().local().endOf("week").valueOf();
    return dateEndOfWeek === endOfWeek;
}
/**
 * Check whether a date lies in next week
 * @param date Moment date to check
 */ export function IsInNextWeek(date) {
    if (date == null) {
        return false;
    }
    const endOfNextWeek = moment().local().add(7, "days").endOf("week").valueOf();
    const dateEndOfWeek = date.clone().local().endOf("week").valueOf();
    return dateEndOfWeek === endOfNextWeek;
}
/**
 * Check whether a date is after end of next week
 * @param date Moment date to check
 */ export function IsAfterEndOfNextWeek(date) {
    if (date == null) {
        return false;
    }
    const endOfNextWeek = moment().local().add(7, "days").endOf("week");
    return date.local().isAfter(endOfNextWeek);
}
/**
 * Calculate the difference between a timeStamp and now
 * @param timeStamp The timeStamp to compare
 * @param [units] The units to return the difference in
 */ export function calculateFromNow(timeStamp, units = "ms") {
    if (timeStamp == null) {
        return 0;
    }
    const now = moment();
    const duration = moment.duration(timeStamp.diff(now));
    return duration.as(units);
}
/**
 * Check whether provided timeStamp was moments ago
 * @param timeStamp The timeStamp to compare
 */ export function InLastMinute(timeStamp) {
    if (timeStamp == null) {
        return false;
    }
    const now = moment();
    const duration = moment.duration(now.diff(timeStamp));
    return duration.asSeconds() < 120;
}
/**
 * Check whether provided timeStamp was minutes ago
 * @param timeStamp The timeStamp to compare
 */ export function InPastFewMinutes(timeStamp) {
    if (timeStamp == null) {
        return false;
    }
    const now = moment();
    const duration = moment.duration(now.diff(timeStamp));
    return duration.asMinutes() >= 2 && duration.asMinutes() <= 60;
}
/**
 * Check whether provided timeStamp was an hour ago
 * @param timeStamp The timeStamp to compare
 */ export function InPastHour(timeStamp) {
    if (timeStamp == null) {
        return false;
    }
    const now = moment();
    const duration = moment.duration(now.diff(timeStamp));
    return duration.asMinutes() >= 61 && duration.asMinutes() <= 90;
}
/**
 * Check whether provided timeStamp was hours ago
 * @param timeStamp The timeStamp to compare
 */ export function InPastFewHours(timeStamp) {
    if (timeStamp == null) {
        return false;
    }
    const now = moment();
    const duration = moment.duration(now.diff(timeStamp));
    return duration.asMinutes() >= 91 && duration.asMinutes() <= 480;
}
/**
 * Check whether provided timeStamp was yesterday
 * @param timeStamp The timeStamp to compare
 */ export function IsYesterday(timeStamp) {
    if (timeStamp == null) {
        return false;
    }
    const startOfYesterday = moment().local().subtract(1, "day").startOf("day").valueOf();
    const startOfDay = timeStamp.clone().local().startOf("day").valueOf();
    return startOfDay === startOfYesterday;
}
/**
 * Check whether provided timeStamp was days ago
 * @param timeStamp The timeStamp to compare
 */ export function InPastFewDays(timeStamp) {
    if (timeStamp == null) {
        return false;
    }
    const startOfYesterday = moment().local().subtract(1, "day").startOf("day").valueOf();
    const startOfLast6ThDay = moment().local().subtract(6, "day").startOf("day").valueOf();
    const startOfDay = timeStamp.clone().local().startOf("day").valueOf();
    return startOfDay < startOfYesterday && startOfDay > startOfLast6ThDay;
}
/**
 * Check whether provided timeStamp was in the last month
 * @param date The timeStamp to compare
 */ export function IsInCurrentMonth(date) {
    if (date == null) {
        return false;
    }
    const endOfMonth = moment().local().endOf("month").valueOf();
    const dateEndOfMonth = date.clone().local().endOf("month").valueOf();
    return dateEndOfMonth === endOfMonth;
}
export var DayOfWeek;
(function(DayOfWeek) {
    DayOfWeek["Sunday"] = "sunday";
    DayOfWeek["Monday"] = "monday";
    DayOfWeek["Tuesday"] = "tuesday";
    DayOfWeek["Wednesday"] = "wednesday";
    DayOfWeek["Thursday"] = "thursday";
    DayOfWeek["Friday"] = "friday";
    DayOfWeek["Saturday"] = "saturday";
})(DayOfWeek || (DayOfWeek = {}));
export var WeekIndex;
(function(WeekIndex) {
    WeekIndex["First"] = "first";
    WeekIndex["Second"] = "second";
    WeekIndex["Third"] = "third";
    WeekIndex["Fourth"] = "fourth";
    WeekIndex["Last"] = "last";
})(WeekIndex || (WeekIndex = {}));
/**
 * Map a moment day to a DayOfWeek enum based on the day being in a range from 0-6.
 * @param moment Moment day
 * @see https://momentjs.com/docs/#/get-set/day/
 */ export function getDayOfWeekFromMomentIndex(day) {
    switch(day){
        case 1:
            return "monday";
        case 2:
            return "tuesday";
        case 3:
            return "wednesday";
        case 4:
            return "thursday";
        case 5:
            return "friday";
        case 6:
            return "saturday";
        case 0:
        default:
            return "sunday";
    }
}
/**
 * Map a day of week to a moment day on the day being in a range from 0-6.
 * @param dayOfWeek DayOfWeek enum
 * @see https://momentjs.com/docs/#/get-set/day/
 */ export function getMomentDayIndexFromDayOfWeek(dayOfWeek) {
    switch(dayOfWeek){
        case "monday":
            return 1;
        case "tuesday":
            return 2;
        case "wednesday":
            return 3;
        case "thursday":
            return 4;
        case "friday":
            return 5;
        case "saturday":
            return 6;
        case "sunday":
        default:
            return 0;
    }
}
/**
 * Get the dayOfWeek array for a week, starting on a specific start weekday.
 * @param startDayIndex The zero-based index of the start day of the week. Should be within 0-6.
 */ export function getDaysOfWeekByStartDay(startDayIndex) {
    const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
    ];
    if (startDayIndex < 0 || startDayIndex > 6) {
        return daysOfWeek;
    }
    const splicedDaysOfWeek = daysOfWeek.splice(startDayIndex);
    return splicedDaysOfWeek.concat(daysOfWeek);
}
/**
 * Map a moment day to a WeekIndex enum.
 * @param moment Moment day
 * @see https://momentjs.com/docs/#/get-set/week/
 */ export function getWeekIndexFromMoment(moment) {
    const firstWeekOfMonthIndex = moment.clone().startOf("month").week();
    const currentWeekOfMonthIndex = moment.week();
    switch(currentWeekOfMonthIndex - firstWeekOfMonthIndex){
        case 0:
            return "first";
        case 1:
            return "second";
        case 2:
            return "third";
        case 3:
            return "fourth";
        default:
            // Negative values can result for the last week of a year, because week()
            // will evaluate to 1 if the day exists on the same week as the first week of the next year.
            // So we will consider any values below 0 and above 3 as part of the last week of a month.
            return "last";
    }
}
/**
 * Map string month to a month index, to handle non-latin based languages for moment not able to generate the month index in en.
 * This function returns 1 based month index.
 * @param month The month string in en
 */ export function getMonthIndexFromMonth(month) {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    return months.indexOf(month) + 1;
}
