// Action creator
import { MruItem } from "@ms/uno-models/lib/local/client/mru/MruItem";
import { TaskEntitySet } from "@ms/uno-models/lib/local/client/planner/task/TaskEntitySet";
import { FetchBasicTasksForAssigneeSucceededAction, FetchBasicTasksForMyDaySucceededAction, FetchBasicTasksForPlanSucceededAction } from "@ms/uno-actions/lib/local/planner/task/BasicTaskFetchActions";
import { Plan } from "@ms/uno-models/lib/local/client/planner/plan/Plan";
import { PlanDetails } from "@ms/uno-models/lib/local/client/planner/plan/PlanDetails";
import { PlanEntitySet } from "@ms/uno-models/lib/local/client/planner/plan/PlanEntitySet";
import { Bucket } from "@ms/uno-models/lib/local/client/planner/bucket/Bucket";
// Actions
import { FetchMruItemsSucceededAction } from "@ms/uno-actions/lib/local/mru/MruActions";
import { FetchBasicPlanSucceededAction } from "@ms/uno-actions/lib/local/planner/plan/BasicPlanFetchActions";
import { FetchTeamsTasksTabsSucceededAction } from "@ms/uno-actions/lib/local/teamsTasksTabs/FetchTeamsTasksTabsActions";
import { FetchBasicBucketsForPlanSucceededAction } from "@ms/uno-actions/lib/local/planner/bucket/BasicBucketFetchActions";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utiliies
import { getPlanRouteDetailsForTab } from "@ms/uno-routing/lib/local/utilities/TeamsTabParsingUtilities";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
// Constants
import { PreFetchedDataTypes } from "@ms/uno-constants/lib/local/DataPreFetcherConstants";
import { PlanType } from "@ms/uno-constants/lib/local/AppConstants";
/**
 * Action creator storing the pre-fetched data
 */ export class DataPreFetchActionCreator {
    storePreFetcherServiceResult(result) {
        if (result.length === 0) {
            this.loggers.traceLogger.logTrace(0x1e283642 /* tag_4kdzc */ , TraceLevel.Info, `PreFetcher result is empty`);
            return;
        }
        for (const serviceResult of result){
            if (serviceResult.errorMessage) {
                this.loggers.traceLogger.logTrace(0x1e283641 /* tag_4kdzb */ , TraceLevel.Warning, `PreFetcher failed: [Type:${serviceResult.dataType}]${serviceResult.errorMessage}`);
            } else {
                try {
                    this.savePrefetchedServiceResult(serviceResult);
                } catch  {
                    this.loggers.traceLogger.logTrace(0x1e283319 /* tag_4kdmz */ , TraceLevel.Warning, `Failed to save pre-fetched result: [Type=${serviceResult.dataType}]`);
                }
            }
        }
    }
    /**
     * Saves the pre-fetched service result to store
     * @param result: The pre-fetched service result
     */ savePrefetchedServiceResult(result) {
        if (result.data == null) {
            this.loggers.traceLogger.logTrace(0x1e283640 /* tag_4kdza */ , TraceLevel.Warning, `Pre-fetched data is null: [Type=${result.dataType}]`);
            return;
        }
        switch(result.dataType){
            case PreFetchedDataTypes.Mru:
                this.storePreFetchedMruData(result.data);
                break;
            case PreFetchedDataTypes.MyDayTasks:
                this.storePreFetchedMyDayTasksData(result.data);
                break;
            case PreFetchedDataTypes.ATMTasks:
                this.storePreFetchedATMTasksData(result.data);
                break;
            case PreFetchedDataTypes.Plan:
                this.storePreFetchedPlanData(result.data);
                break;
            case PreFetchedDataTypes.TTTabs:
                this.storePreFetchedTTTabsData(result.data);
                break;
            case PreFetchedDataTypes.Buckets:
                this.storePreFetchedBucketsData(result.planId, result.data);
                break;
            case PreFetchedDataTypes.BasicPlanTasks:
                this.storePreFetchedBasicPlanTasksData(result.planId, result.data);
                break;
            default:
                this.loggers.traceLogger.logTrace(0x1e283623 /* tag_4kdy9 */ , TraceLevel.Warning, `Unsupported pre-fetched data type to save`);
                break;
        }
    }
    /**
     * Stores the pre-fetched MRU data
     * @param data: The pre-fetched MRU data
     */ storePreFetchedMruData(data) {
        const mruItems = [];
        const items = [
            ...data.documents?.items || [],
            ...data.places?.items || []
        ];
        items.map((item)=>MruItem.fromResource(item)).filter((mruItem)=>mruItem != null).forEach((mruItem)=>mruItems.push(mruItem));
        this.dispatcher.dispatchActionAsync(new FetchMruItemsSucceededAction(mruItems));
    }
    /**
     * Stores the pre-fetched MyDay tasks data
     * @param data: The pre-fetched MyDay tasks data
     */ storePreFetchedMyDayTasksData(data) {
        const succeededAction = new FetchBasicTasksForMyDaySucceededAction(data.map((graphTaskResource)=>TaskEntitySet.builder().fromGraphResource(graphTaskResource).build()), undefined, true, data.map((task)=>task.id));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Stores the pre-fetched ATM tasks data
     * @param data: The pre-fetched ATM tasks data
     */ storePreFetchedATMTasksData(data) {
        const appContextStore = this.storeLoader.load(Store.AppContext);
        const userId = appContextStore.getAppContext().userId;
        if (userId == null) {
            this.loggers.traceLogger.logTrace(0x1e283621 /* tag_4kdy7 */ , TraceLevel.Warning, "User id is null.");
            return;
        }
        const succeededAction = new FetchBasicTasksForAssigneeSucceededAction(userId, data.map((graphTaskResource)=>TaskEntitySet.builder().fromGraphResource(graphTaskResource).build()), undefined, true, data.map((task)=>task.id));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Stores the pre-fetched plan data
     * @param data: The pre-fetched plan data
     */ storePreFetchedPlanData(data) {
        if (data.details == null) {
            throw new Error("No plan details data returned");
        }
        const planEntitySet = PlanEntitySet.builder().withPlan(Plan.builder().fromGraphResource(data).build()).withDetails(PlanDetails.builder().fromGraphResource(data.details).build()).build();
        this.dispatcher.dispatchActionAsync(new FetchBasicPlanSucceededAction(planEntitySet));
    }
    /**
     * Stores the pre-fetched TT tabs data
     * @param data: The pre-fetched TT tabs data
     */ storePreFetchedTTTabsData(data) {
        const tabs = data.items?.map((tabResource)=>{
            const details = getPlanRouteDetailsForTab(tabResource.id, tabResource.tabContentUrl) ?? {
                planId: `invalidEntityId${tabResource.id}`,
                planType: PlanType.Basic
            };
            return {
                ...tabResource,
                tabName: decodeURIComponent(tabResource.tabName),
                entityId: details.planId,
                app: details.planType,
                orgId: details.planType === PlanType.Premium ? details.orgId : null
            };
        }) ?? [];
        this.dispatcher.dispatchActionAsync(new FetchTeamsTasksTabsSucceededAction(tabs));
    }
    /**
     * Stores the pre-fetched buckets data
     * @param planId: The plan id to which the buckets belong
     * @param data: The pre-fetched buckets data
     */ storePreFetchedBucketsData(planId, data) {
        const succeededAction = new FetchBasicBucketsForPlanSucceededAction(planId, data.map((bucketResource)=>Bucket.builder.fromGraphResource(bucketResource).build()));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Stores the pre-fetched tasks data for a basic plan
     * @param planId: The plan id to which the tasks belong
     * @param data: The pre-fetched tasks data
     */ storePreFetchedBasicPlanTasksData(planId, data) {
        const succeededAction = new FetchBasicTasksForPlanSucceededAction(planId, data.map((graphTaskResource)=>TaskEntitySet.builder().fromGraphResource(graphTaskResource).build()), undefined, true, data.map((task)=>task.id));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Initializes a new instance of the DataPreFetchActionCreator.
     * @param storeProviders: Store providers
     * @param dispatcher: Dispatcher to dispatch actions
     * @param loggers: Logger for telemetry
     */ constructor(storeLoader, dispatcher, loggers){
        this.storeLoader = storeLoader;
        this.dispatcher = dispatcher;
        this.loggers = loggers;
    }
}
