// Models
import { FloatingDay } from "./FloatingDay";
import { getMomentMonthIndexFromRecurrenceMonth } from "../utilities/RecurrencePatternUtilities";
/**
 * Represents a yearly floating day.
 * @example Fourth Thursday of November.
 */ export class YearlyFloatingDay extends FloatingDay {
    getMonthForPeriod(period) {
        return this.month;
    }
    constructor(month, weekIndex, dayOfWeek){
        super(weekIndex, dayOfWeek);
        // Moment month is 0 based, Pattern month is 1 based
        // normalized month to moment
        this.month = getMomentMonthIndexFromRecurrenceMonth(month);
    }
}
