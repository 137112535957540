// Models
import { PlanEntitySetBuilder } from "./PlanEntitySetBuilder";
import { GraphPlannerODataType } from "../../../service/graph-legacy/GraphPlannerODataType";
// Utility
import { ArgumentNullError } from "@ms/uno-errors/lib/local/errors/ArgumentNullError";
import isEmpty from "lodash/isEmpty";
import { compareMomentDates } from "@ms/uno-utilities/lib/local/Comparators";
/** Group of entities related to the plan */ export class PlanEntitySet {
    /**
     * Builder for PlanEntitySet objects
     */ static builder() {
        return new PlanEntitySetBuilder();
    }
    /**
     * Takes a plan and planDetails and returns the sorted contexts that are displayable to the user. This should be used for getting the context for breadcrumbs.
     * @param plan The plan
     * @param planDetails The associated plan details
     */ static getSortedDisplayableContexts(plan, planDetails) {
        const contextsToDisplay = [];
        for(const contextKey in plan.contexts){
            // If the Url exists in context details then we'll show that context
            if (planDetails.contextDetails[contextKey]?.isVisible()) {
                contextsToDisplay.push({
                    contextKey,
                    creationDate: plan.contexts[contextKey].createdDate
                });
            }
        }
        if (isEmpty(contextsToDisplay)) {
            return [];
        }
        contextsToDisplay.sort((a, b)=>compareMomentDates(a.creationDate, b.creationDate));
        return contextsToDisplay;
    }
    /**
     * Takes a plan & planDetails and returns the first displayable context in sorted contexts
     * @param plan The plan
     * @param planDetails The plan details
     */ static getFirstDisplayableContextString(plan, planDetails) {
        const contextsToDisplay = this.getSortedDisplayableContexts(plan, planDetails);
        if (contextsToDisplay.length > 0) {
            return planDetails.contextDetails[contextsToDisplay[0].contextKey].displayAs ?? "";
        }
        return "";
    }
    get id() {
        return this.plan.id;
    }
    toGraphSerializable() {
        const plan = this.plan.toGraphSerializable();
        plan.details = this.planDetails?.toGraphSerializable() ?? undefined;
        return {
            "@odata.type": GraphPlannerODataType.Plan,
            ...plan
        };
    }
    getCreationContextInfo() {
        for(const key in this.plan.contexts){
            const context = this.plan.contexts[key];
            if (context.isCreationContext) {
                return {
                    key: key,
                    context: context,
                    contextDetails: this.planDetails?.contextDetails[key]
                };
            }
        }
        // In case there is no matching context, return null
        return null;
    }
    /**
     * Initialize a new instance of the Plan entity set
     * @param builder Builder for Plan entity set
     */ constructor(builder){
        if (builder.plan == null) {
            throw new ArgumentNullError("builder.plan");
        }
        this.plan = builder.plan;
        this.planDetails = builder.planDetails;
    }
}
