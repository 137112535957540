import findIndex from "lodash/findIndex";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
/**
 * Represents an entry of external app context data associated with a plan.
 * Apps may decide to add extra information into the plan data, each entry is stored in the Plan.Contexts dictionary.
 */ export class PlanContext {
    /**
     * Converts the given dictionary of PlanContext into a dictionary of IGraphPlanContextResource for serialization.
     * @param contexts Dictionary of contexts from a plan
     */ static getGraphContextResources(contexts) {
        const contextResources = {};
        const keys = Object.keys(contexts || {});
        forEach(keys, (key)=>{
            contextResources[key] = contexts[key].toGraphSerializable();
        });
        return contextResources;
    }
    /**
     * Converts the given IGraphPlanContextResource into a PlanContext
     */ static getGraphPlanContext(value) {
        return new PlanContext(value.displayNameSegments, value.associationType, value.ownerAppId, moment(value.createdDateTime), value.isCreationContext);
    }
    /**
     * Converts the given dictionary of IGraphPlanContextResource into a dictionary of PlanContext for serialization.
     * @param contextResources Dictionary of contexts from a plan
     */ static getContextsFromGraphResource(contextResources) {
        const contexts = {};
        const keys = Object.keys(contextResources || {});
        forEach(keys, (key)=>{
            const contextResource = contextResources[key];
            contexts[key] = PlanContext.getGraphPlanContext(contextResource);
        });
        return contexts;
    }
    /**
     * Replace special chars as service rejects them.
     */ static sanitizeDisplayNames(displayNames) {
        const sanitizedDisplayNames = displayNames.map((name)=>name.replace(/\r\n|\n|\r|\t|\v/g, " ").replace("\b", "").trim());
        if (findIndex(sanitizedDisplayNames, function(displayNames) {
            return displayNames === "";
        }) > -1) {
            return [];
        }
        return sanitizedDisplayNames;
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (!(key === "displayNames" || key === "associationType")) {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    toGraphSerializable() {
        return {
            "@odata.type": "#microsoft.graph.plannerPlanContext",
            displayNameSegments: this.displayNames,
            associationType: this.associationType
        };
    }
    getClone() {
        return new PlanContext(this.displayNames, this.associationType, this.ownerAppId, this.createdDate);
    }
    /**
     * Initialize a new instance of the plan context object
     * @param displayNames A list of segments that the planner UI will use to show the context to the user.
     * @param associationType Nullable app specific type of the context.
     * @param ownerAppId Id of the app that owns this context.
     * @param createdDate Date and time this context was created.
     * @param isCreationContext Whether or not the context was passed during plan creation and is thus the creation context
     */ constructor(displayNames, associationType, ownerAppId, createdDate, isCreationContext){
        this.displayNames = displayNames;
        this.associationType = associationType;
        this.ownerAppId = ownerAppId;
        this.createdDate = createdDate;
        if (!isEmpty(displayNames)) {
            this.displayNames = PlanContext.sanitizeDisplayNames(this.displayNames);
        }
        this.isCreationContext = isCreationContext ?? false;
    }
}
