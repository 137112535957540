// Models
/**
 * Base for all top level containers
 */ export class Container {
    get externalId() {
        return this.id.externalId;
    }
    get containerType() {
        return this.id.containerType;
    }
    /**
     * Initialize a new instance of the Container class
     * @param id Composite type representing the container id and type
     * @param [displayName] Display name of the container
     */ constructor(id, displayName){
        this.id = id;
        this.displayName = displayName ?? "";
    }
}
