// Client models
import { GraphPlannerODataType } from "../../../service/graph/planner/GraphPlannerODataType";
// Utilities
import compact from "lodash/compact";
import forEach from "lodash/forEach";
import forOwn from "lodash/forOwn";
import toArray from "lodash/toArray";
import { compareOrderHints } from "@ms/uno-utilities/lib/local/Comparators";
/**
 * Represents a checklist item for tasks in a plan in Office 365.
 */ export class ChecklistItem {
    /**
     * Convert the dictionary of checklistItem from graph resource type to client type
     */ static getGraphChecklistItem(id, value) {
        return new ChecklistItem(id, value.isChecked, value.orderHint, value.title);
    }
    /**
     * Convert the dictionary of checklistItems from graph resource type to client type
     * @param checklistItems ChecklistItem dictionary of resource type
     */ static getGraphClientDictionaryType(checklistItems) {
        const result = {};
        forOwn(checklistItems, (value, key)=>{
            result[key] = ChecklistItem.getGraphChecklistItem(key, value);
        });
        return result;
    }
    /**
     * Converts the graph checklist items dictionary to one of IGraphChecklistItemResource type
     * @param checklistItems ChecklistItem dictionary of client type
     */ static getGraphResourceDictionaryType(checklistItems) {
        const result = {};
        forOwn(checklistItems, (value, key)=>{
            result[key] = value.toGraphSerializable();
            // Prevent sending null to graph by only setting modified by fields if they are not null
            if (value.lastModifiedBy != null) {
                result[key].lastModifiedBy = value.lastModifiedBy;
            }
            if (value.lastModifiedDateTime != null) {
                result[key].lastModifiedDateTime = value.lastModifiedDateTime;
            }
        });
        return result;
    }
    /**
     * Remove nulls and then sort by order hint
     * @param checklistItems Map with all checklist items
     */ static getSortedChecklistItems(checklistItems) {
        return compact(toArray(checklistItems)).sort((item1, item2)=>{
            return -1 * compareOrderHints(item1.orderHint, item2.orderHint);
        });
    }
    /**
     * Returns a clone of the given checklist dictionary
     */ static cloneChecklist(checklistItems) {
        const clonedChecklist = {};
        forEach(checklistItems, (item)=>{
            clonedChecklist[item.id] = item.getClone();
        });
        return clonedChecklist;
    }
    /**
     * Return a copy of the given checklist items map with the new value applied to it
     * @param checklistItems The map with checklist items to be updated
     * @param id The id of the checklist item to be updated
     * @param key Name of property on the TaskResource object
     * @param value Value to set on the property
     */ static setChecklistProperty(checklistItems, id, key, value) {
        if (checklistItems[id] == null) {
            throw new Error("ArgumentException: id not found in checklist");
        }
        const clone = ChecklistItem.cloneChecklist(checklistItems);
        clone[id] = clone[id].setProperty(key, value);
        return clone;
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (!(key === "isChecked" || key === "orderHint" || key === "title")) {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    toGraphSerializable() {
        return {
            "@odata.type": GraphPlannerODataType.PlannerChecklistItem,
            isChecked: this.isChecked,
            orderHint: this.orderHint,
            title: this.title
        };
    }
    getClone() {
        return new ChecklistItem(this.id, this.isChecked, this.orderHint, this.title);
    }
    /**
     * Initialize a new instance of the checklist object
     * @param id Id of the user.
     * @param isChecked Indicates whether the checklist item is checked or not.
     * @param orderHint Used to set the relative order of the checklist items in the task board view.
     * @param title Title of the checklist item.
     */ constructor(id, isChecked, orderHint, title){
        this.id = id;
        this.isChecked = isChecked;
        this.orderHint = orderHint;
        this.title = title;
    }
}
/**
     *  Matches CheckListItemFieldChange.cs : TitleSizeLimit
     */ ChecklistItem.TitleMaxLength = 100;
