/**
 * Enumeration of the possible item types in MSGraph Planner @odata.type values.
 *
 * Note: This is used to determine entity types on diff sync updates and thus
 * must be updated if the values for those change
 */ export var GraphPlannerODataType;
(function(GraphPlannerODataType) {
    /** Task entity */ GraphPlannerODataType["Task"] = "#microsoft.graph.plannerTask";
    /** Task details entity */ GraphPlannerODataType["TaskDetails"] = "#microsoft.graph.plannerTaskDetails";
    /** Plan entity */ GraphPlannerODataType["Plan"] = "#microsoft.graph.plannerPlan";
    /** Plan details entity */ GraphPlannerODataType["PlanDetails"] = "#microsoft.graph.plannerPlanDetails";
    /** Formatting entity for a task on status taskboard */ GraphPlannerODataType["TaskboardStatusTaskFormatting"] = "#microsoft.graph.plannerProgressTaskBoardTaskFormat";
    /** Formatting entity for a task on assignedTo taskboard */ GraphPlannerODataType["TaskboardAssignedToTaskFormatting"] = "#microsoft.graph.plannerAssignedToTaskBoardTaskFormat";
    /** Bucket entity */ GraphPlannerODataType["Bucket"] = "#microsoft.graph.plannerBucket";
    /** Formatting entity for a task on bucket taskboard */ GraphPlannerODataType["TaskboardBucketTaskFormatting"] = "#microsoft.graph.plannerBucketTaskBoardTaskFormat";
    /** Graph OData Planner Assignment  */ GraphPlannerODataType["PlannerAssignment"] = "#microsoft.graph.plannerAssignment";
    /** Graph OData Planner Checklist Item  */ GraphPlannerODataType["PlannerChecklistItem"] = "#microsoft.graph.plannerChecklistItem";
    /** Graph OData Planner Plan Context */ GraphPlannerODataType["PlannerPlanContext"] = "#microsoft.graph.plannerPlanContext";
    /** Graph OData Planner Plan Context Details */ GraphPlannerODataType["PlannerPlanContextDetails"] = "#microsoft.graph.plannerPlanContextDetails";
    /** Graph OData Planner Roster */ GraphPlannerODataType["PlannerRoster"] = "#microsoft.graph.plannerRoster";
    /** Graph OData Planner Roster Member */ GraphPlannerODataType["PlannerRosterMember"] = "#microsoft.graph.plannerRosterMember";
    /** Graph OData Planner external reference */ GraphPlannerODataType["PlannerExternalReference"] = "#microsoft.graph.plannerExternalReference";
    /** Graph OData Planner approval attachment */ GraphPlannerODataType["PlannerApprovalAttachment"] = "#microsoft.graph.plannerBasicApprovalAttachment";
})(GraphPlannerODataType || (GraphPlannerODataType = {}));
