// Models
import { FixedDate } from "./FixedDate";
// Utilities
import { getMomentMonthIndexFromRecurrenceMonth } from "../utilities/RecurrencePatternUtilities";
/**
 * Represents a yearly fixed date.
 * @example 14th of February.
 */ export class YearlyFixedDate extends FixedDate {
    getMonthForPeriod(period) {
        return this.month;
    }
    constructor(month, dayOfMonth){
        super(dayOfMonth);
        // Moment month is 0 based, Pattern month is 1 based
        // normalized month to moment
        this.month = getMomentMonthIndexFromRecurrenceMonth(month);
    }
}
