// Builder
import { CreationProcessInfoBuilder } from "./CreationProcessInfoBuilder";
// Utilities
import isEmpty from "lodash/isEmpty";
/**
 * Plan's creation process information
 */ export class CreationProcessInfo {
    /** Builder for Plan objects */ static get builder() {
        return new CreationProcessInfoBuilder();
    }
    isBeingCreated() {
        if (!isEmpty(this.processId)) {
            return !this.processHasFinished;
        }
        return false;
    }
    /**
     * Initializes a new instance of the PublishingDefaultPlanCreationProcessInfo entity.
     */ constructor(builder){
        this.processHasFinished = builder.processHasFinished;
        this.processId = builder.processId;
        this.processType = builder.processType;
        this.sourceId = builder.sourceId;
    }
}
