import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for BasicPlanStore, for use in the registry */ export const BasicPlanStoreActionTypes = [
    ActionType.FetchBasicPlanAction,
    ActionType.FetchBasicPlanFailedAction,
    ActionType.FetchBasicPlanSucceededAction,
    ActionType.FetchBasicPlansForContainerAction,
    ActionType.FetchBasicPlansForContainerFailedAction,
    ActionType.FetchBasicPlansForContainerSucceededAction,
    ActionType.FetchAllBasicPlansForContainerTypeAction,
    ActionType.FetchAllBasicPlansForContainerTypeFailedAction,
    ActionType.FetchAllBasicPlansForContainerTypeSucceededAction,
    ActionType.CreateBasicPlanAction,
    ActionType.CreateBasicPlanFailedAction,
    ActionType.CreateBasicPlanSucceededAction,
    ActionType.DeleteBasicPlanAction,
    ActionType.DeleteBasicPlanFailedAction,
    ActionType.DeleteBasicPlanSucceededAction,
    ActionType.UpdateBasicPlanAction,
    ActionType.UpdateBasicPlanFailedAction,
    ActionType.UpdateBasicPlanSucceededAction,
    ActionType.CloseModalAction,
    ActionType.DeltaSyncDeleteBasicPlanAction,
    ActionType.DeltaSyncUpdateBasicPlanAction,
    ActionType.SetDefaultPlanAction,
    ActionType.SetFlaggedEmailPlanAction,
    ActionType.FetchPlansForChannelGroupIdAction,
    ActionType.FetchPlansForChannelGroupIdFailedAction,
    ActionType.FetchPlansForChannelGroupIdSucceededAction,
    ActionType.MoveBasicPlanOperationAction,
    ActionType.MoveBasicPlanOperationCompletedAction,
    ActionType.MoveBasicPlanOperationCompletedWithErrorsAction
];
