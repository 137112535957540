// Models
import { RecurrenceOccurrenceBuilder } from "./RecurrenceOccurrenceBuilder";
import { RecurrenceSchedule } from "./RecurrenceSchedule";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
/**
 * Represents the recurrence occurrence info entity in the client
 */ export class RecurrenceOccurrence {
    /**
     * Builder for RecurrenceOccurrence objects
     */ static get builder() {
        return new RecurrenceOccurrenceBuilder();
    }
    toGraphSerializable() {
        return {
            seriesId: this.seriesId,
            occurrenceId: this.occurrenceIndex,
            previousInSeriesTaskId: this.previousInSeriesTaskId,
            nextInSeriesTaskId: this.nextInSeriesTaskId,
            recurrenceStartDateTime: this.recurrenceStartDate?.toISOString(),
            schedule: this.schedule != null ? {
                ...this.schedule?.toGraphSerializable(),
                nextOccurrenceDateTime: this.nextOccurrenceDate?.toISOString()
            } : null
        };
    }
    setRecurrenceSchedule(schedule) {
        return !this.isReadonly() ? RecurrenceOccurrence.builder.forClone({
            ...this,
            schedule
        }).build() : this;
    }
    setRecurrenceRangeStartDate(startDate) {
        return this.isActive() ? this.setRecurrenceSchedule(this.schedule.setRecurrenceRangeStartDate(startDate)) : this;
    }
    isActive() {
        return this.schedule != null && this.nextInSeriesTaskId == null;
    }
    isReadonly() {
        return this.nextInSeriesTaskId != null;
    }
    generateHash() {
        const result = [
            this.seriesId
        ];
        if (this.occurrenceIndex != null) {
            result.push(this.occurrenceIndex.toString());
        }
        if (this.previousInSeriesTaskId != null) {
            result.push(this.previousInSeriesTaskId);
        }
        if (this.nextInSeriesTaskId != null) {
            result.push(this.nextInSeriesTaskId);
        }
        if (this.recurrenceStartDate != null) {
            result.push(this.recurrenceStartDate.toISOString());
        }
        if (this.schedule != null) {
            result.push(this.schedule.generateHash());
        }
        if (this.nextOccurrenceDate != null) {
            result.push(this.nextOccurrenceDate.toISOString());
        }
        return result.toString();
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(RecurrenceOccurrence.builder.forClone(this).build(), ...diffs, RecurrenceOccurrence.mergeCustomizer);
        }
        return this;
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === "schedule") {
                if (!isEqual(source, target)) {
                    if (target == null) {
                        // Schedule has been removed.
                        return target;
                    } else if (source == null) {
                        // Schedule is new, just return target properties
                        return {
                            pattern: {
                                ...target.pattern
                            },
                            range: {
                                ...target.range
                            }
                        };
                    } else {
                        // Schedule is a complex object. Handle the getDiff
                        return source.getDiff(target);
                    }
                } else {
                    // Equal
                    return {};
                }
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (key === "schedule" && !isEqual(source, target)) {
            if (target == null) {
                // Schedule has been removed.
                return target;
            }
            // Schedule is a complex object. Handle the merge.
            if (source == null) {
                // Schedule is new to the object, return copy of target
                return mergeWith(RecurrenceSchedule.builder.build(), cloneDeep(target), RecurrenceSchedule.mergeCustomizer);
            } else {
                // Calculate the diff and apply the diff
                return source.applyDiffs(source.getDiff(target));
            }
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    /**
     * Initializes a new instance of the "RecurrenceOccurrence" entity.
     * @param builder Builder with the initialization data
     */ constructor(builder){
        this.seriesId = builder.seriesId;
        this.occurrenceIndex = builder.occurrenceIndex;
        this.previousInSeriesTaskId = builder.previousInSeriesTaskId;
        this.nextInSeriesTaskId = builder.nextInSeriesTaskId;
        this.recurrenceStartDate = builder.recurrenceStartDate;
        this.schedule = builder.schedule;
        this.nextOccurrenceDate = builder.nextOccurrenceDate;
    }
}
