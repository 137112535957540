// Models
import { TaskServiceEntityType } from "../../ITaskServiceEntity";
import { TaskboardType } from "../../taskboardType/TaskboardType";
import { TaskServiceEntity } from "../../TaskServiceEntity";
import { ProgressTaskboardTaskFormattingBuilder } from "./ProgressTaskboardTaskFormattingBuilder";
import { GraphPlannerODataType } from "../../../../service/graph/planner/GraphPlannerODataType";
// Utilities
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import mergeWith from "lodash/mergeWith";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
/**
 * Represents a formatting of the task in the "progress" task board.
 */ export class ProgressTaskboardTaskFormatting extends TaskServiceEntity {
    /**
     * Returns a builder of objects of type BucketTaskboardTaskFormatting
     */ static get builder() {
        return new ProgressTaskboardTaskFormattingBuilder();
    }
    get taskboardType() {
        return TaskboardType.Progress;
    }
    get entityType() {
        return TaskServiceEntityType.TaskboardStatusTaskFormatting;
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key !== "orderHint") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    toGraphSerializable() {
        return {
            "@odata.type": GraphPlannerODataType.TaskboardStatusTaskFormatting,
            "@odata.etag": !isEmpty(this.itemVersion) ? this.itemVersion : undefined,
            id: this.id,
            orderHint: this.orderHint
        };
    }
    getDiff(target) {
        return getDiff(this, target, getDiffMomentCustomizer);
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(ProgressTaskboardTaskFormatting.builder.build(), this, ...diffs, applyDiffMomentCustomizer);
        }
        return this;
    }
    applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this, this.entityType);
        return this.applyDiffs(diffData);
    }
    getClone() {
        return this.getCloneBuilder().build();
    }
    /**
     * Returns a builder for cloning this instance
     */ getCloneBuilder() {
        return ProgressTaskboardTaskFormatting.builder.forClone(this);
    }
    /**
     * Initializes a new instance of the ProgressTaskboardTaskFormatting class
     * @param builder Builder to create this instance
     */ constructor(builder){
        super(builder.id, builder.version);
        this.orderHint = builder.orderHint;
    }
}
