import { GraphPlannerODataType } from "../../../service/graph/planner/GraphPlannerODataType";
/**
 * Represents an approval entity on a task
 */ export class Approval {
    /**
     * Create the approval type from a graph reference
     * @param approval The approval to create the type from
     */ static fromGraphApprovalResource(approval) {
        return new Approval({
            approvalId: approval.approvalId,
            approvalStatus: approval.status
        });
    }
    toGraphSerializable() {
        return {
            "@odata.type": GraphPlannerODataType.PlannerApprovalAttachment,
            approvalId: this.approvalId,
            status: this.approvalStatus
        };
    }
    getClone() {
        return new Approval(this);
    }
    /**
     * @param key Name of property on the approval object
     * @param value Value to set on the property
     */ setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "approvalStatus") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    constructor(propertyBag){
        this.approvalId = propertyBag.approvalId;
        this.approvalStatus = propertyBag.approvalStatus;
    }
}
