// Builder
import { TaskBuilder } from "./TaskBuilder";
// Constants
import { ClientAppId, UnassignedUserId } from "@ms/uno-constants/lib/local/AppConstants";
import { DueDateCategory } from "@ms/uno-constants/lib/local/DateConstants";
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { ContextScenarioConstants } from "@ms/uno-constants/lib/local/ExternalSourceConstants";
import { PreviewType } from "../previewType/PreviewType";
import { Assignment } from "./Assignment";
import { PriorityName, TaskCreationSourceType } from "./ITask";
import { RecurrenceOccurrence } from "./recurrence/RecurrenceOccurrence";
// Resources
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { ProgressState } from "../progressState/ProgressState";
import { TaskServiceEntity } from "../TaskServiceEntity";
import { GraphPlannerODataType } from "../../../service/graph/planner/GraphPlannerODataType";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import every from "lodash/every";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";
import isNumber from "lodash/isNumber";
import keys from "lodash/keys";
import mergeWith from "lodash/mergeWith";
import reduce from "lodash/reduce";
import moment from "moment";
import { SpecifiedCompletionRequirements } from "../../../service/graph-legacy/task/completionRequirements/SpecifiedCompletionRequirements";
import { ConvertPercentCompleteToProgressState, ConvertPriorityToPriorityName, ConvertProgressStateToPercentComplete } from "../utilities/ConversionUtilities";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
import { FormatDate, IsAfterEndOfNextWeek, isEqualMomentCustomizer, IsInCurrentWeek, IsInNextWeek, IsSameDay, IsToday, IsTomorrow } from "@ms/uno-utilities/lib/local/DateUtilities";
import { InvalidOperationError } from "@ms/uno-errors/lib/local/errors";
import { getGraphSpecifiedCompletionRequirements } from "../utilities/CompletionRequirementsUtils";
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
import { generateOrderHintBetween } from "@ms/uno-utilities/lib/local/OrdinalUtilities";
/**
 * Represents a task entity in the client
 */ export class Task extends TaskServiceEntity {
    /**
     * Builder for Task objects
     */ static builder() {
        return new TaskBuilder();
    }
    /**
     * Determine whether a task satisfies a given TaskEntityFilter
     * @param task Task to check
     * @param filterSet Entity filter to use
     * @param filterExceptions List of task ids to ignore
     */ static ApplyEntityFilter(task, filterSet, filterExceptions) {
        if (task == null || filterSet == null) {
            return false;
        }
        // Returns true if the task id exists in filterExceptions list
        if (filterExceptions[task.id]) {
            return true;
        }
        let satisfiesFilterSet = true;
        filterSet.filters.forEach((filter)=>{
            const satisfies = Task.applyFilter(task, filter);
            satisfiesFilterSet = satisfiesFilterSet && satisfies;
        });
        return satisfiesFilterSet;
    }
    /**
     * Get the list of removed assignees between an original task and an updated task
     * @param originalTask The original task without changes
     * @param updatedTask The updated task with changes
     */ static getRemovedAssignees(originalTask, updatedTask) {
        return difference(originalTask.getAssigneeIds(), updatedTask.getAssigneeIds());
    }
    /**
     * Get the list of added assignees between an original task and an updated task
     * @param originalTask The original task without changes
     * @param updatedTask The updated task with changes
     */ static getAddedAssignees(originalTask, updatedTask) {
        return difference(updatedTask.getAssigneeIds(), originalTask.getAssigneeIds());
    }
    /**
     * Get the progress state string for the task
     * @param percentComplete The percent complete value or progress state to get the string for
     */ static getProgressTitle(percentComplete) {
        const state = isNumber(percentComplete) ? ConvertPercentCompleteToProgressState(percentComplete) : percentComplete;
        switch(state){
            case ProgressState.NotStarted:
                return Strings.Task_NotStartedText;
            case ProgressState.Complete:
                return Strings.Task_CompletedText;
            default:
            case ProgressState.InProgress:
                return Strings.Task_InProgressText;
        }
    }
    /**
     * Get the priority string for the task
     * @param priority The priority value or PriorityName to get the string for
     */ static getPriorityText(priority) {
        const priorityName = isNumber(priority) ? ConvertPriorityToPriorityName(priority) : priority;
        switch(priorityName){
            case PriorityName.Low:
                return Strings.Task_PriorityLowText;
            case PriorityName.Important:
                return Strings.Task_PriorityImportantText;
            case PriorityName.Urgent:
                return Strings.Task_PriorityUrgentText;
            default:
            case PriorityName.Medium:
                return Strings.Task_PriorityMediumText;
        }
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (isArray(source) || isArray(target)) {
            return target;
        } else if (key === "assignments") {
            if (isEqual(target, DeltaApiConstants.CollectionCleanupValue)) {
                // Unassign all workaround
                return {};
            }
            return target;
        } else if (key === "recurrence" && !isEqual(source, target) && target != null) {
            if (source == null) {
                // Recurrence is new to the object, return copy of target
                return mergeWith(RecurrenceOccurrence.builder.build(), cloneDeep(target), RecurrenceOccurrence.mergeCustomizer);
            } else {
                // Calculate the diff and apply the diff
                return source.applyDiffs(source.getDiff(target));
            }
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    /**
     * Determine whether a task satisfies a given TaskFilter
     * @param task Task to check
     * @param filter Filter to use
     */ static applyFilter(task, filter) {
        let satisfiesFilter = false;
        switch(filter.field){
            case "appliedCategories":
                forEach(filter.values, (value)=>{
                    if (value === null && isEmpty(task.appliedCategories) || value != null && isArray(task.appliedCategories) && !isEmpty(task.appliedCategories) && task.appliedCategories.indexOf(value) >= 0) {
                        satisfiesFilter = true;
                    }
                });
                break;
            case "assignments":
                forEach(filter.values, (value)=>{
                    if (value === UnassignedUserId && isEmpty(task.getAssigneeIds())) {
                        satisfiesFilter = true;
                    } else if (task.getAssigneeIds().indexOf(value) >= 0) {
                        satisfiesFilter = true;
                    }
                });
                break;
            case "bucketId":
                forEach(filter.values, (value)=>{
                    if (task.bucketId === value) {
                        satisfiesFilter = true;
                    }
                });
                break;
            case "dueDate":
                forEach(filter.values, (value)=>{
                    switch(value){
                        case DueDateCategory.NoDate:
                            if (task.dueDate == null) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.Late:
                            if (task.isLate()) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.Today:
                            if (IsToday(task.dueDate)) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.Tomorrow:
                            if (IsTomorrow(task.dueDate)) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.NextWeek:
                            if (IsInNextWeek(task.dueDate)) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.ThisWeek:
                            if (IsInCurrentWeek(task.dueDate)) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.Future:
                            if (IsAfterEndOfNextWeek(task.dueDate)) {
                                satisfiesFilter = true;
                            }
                            break;
                        case DueDateCategory.SomeDate:
                            if (task.dueDate != null) {
                                satisfiesFilter = true;
                            }
                            break;
                        default:
                            break;
                    }
                });
                break;
            case "percentComplete":
                forEach(filter.values, (value)=>{
                    if (task.percentComplete === value || ConvertPercentCompleteToProgressState(value) === ConvertPercentCompleteToProgressState(task.percentComplete)) {
                        satisfiesFilter = true;
                    }
                });
                break;
            case "priority":
                forEach(filter.values, (value)=>{
                    if (ConvertPriorityToPriorityName(task.priority) === ConvertPriorityToPriorityName(value)) {
                        satisfiesFilter = true;
                    }
                });
                break;
            case "isComplete":
                forEach(filter.values, (value)=>{
                    if (task.isComplete() === value) {
                        satisfiesFilter = true;
                    }
                });
                break;
            case "title":
                forEach(filter.values, (value)=>{
                    const valueToLower = value.toLocaleLowerCase();
                    const taskTitleToLower = task.title.toLocaleLowerCase();
                    try {
                        // eslint-disable-next-line security/detect-non-literal-regexp
                        const regex = new RegExp(valueToLower);
                        if (taskTitleToLower.search(regex) >= 0) {
                            satisfiesFilter = true;
                        }
                    } catch (e) {
                        if (taskTitleToLower.indexOf(valueToLower) >= 0) {
                            satisfiesFilter = true;
                        }
                    }
                });
                break;
            case "userContentLastModifiedBy":
                forEach(filter.values, (value)=>{
                    if (task.userContentLastModifiedBy === value) {
                        satisfiesFilter = true;
                    }
                });
                break;
            default:
                satisfiesFilter = true;
                break;
        }
        return satisfiesFilter;
    }
    get entityType() {
        return TaskServiceEntityType.Task;
    }
    get taskCreationSourceType() {
        if (this.creationSource.publication != null) {
            return TaskCreationSourceType.Publication;
        }
        if (this.creationSource.document != null) {
            return TaskCreationSourceType.Document;
        }
        if (this.creationSource.external?.ownerAppId === ClientAppId.ProjectReplicationService) {
            return TaskCreationSourceType.Project;
        }
        // Treat everything else as regular
        return TaskCreationSourceType.Regular;
    }
    /**
     * @param key Name of property on the Task object
     * @param value Value to set on the property
     */ setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "completedBy" || key === "completedDate" || key === "userContentLastModifiedBy" || key === "userContentLastModifiedDate" || key === "createdBy" || key === "createdDate" || key === "deletedBy" || key === "hasDescription" || key === "id" || key === "itemVersion" || key === "numberOfActiveChecklistItems" || key === "numberOfChecklistItems" || key === "numberOfReferences" || key === "previewType" || key === "creationSource") {
            throw new Error("ReadOnlyException: " + key);
        }
        if (key === "recurrence") {
            // Check if invalid operation
            throw new InvalidOperationError(`${key} should not be set through setProperty`);
        } else if (key === "dueDate") {
            return this.setDueDate(value);
        } else if (key === "startDate") {
            return this.setStartDate(value);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    /**
     * @param promotions The dictionary with new promoted values
     */ setPromotedProperties(promotions) {
        const cloneBuilder = this.getCloneBuilder();
        cloneBuilder.hasDescription = promotions.hasDescription;
        cloneBuilder.numberOfActiveChecklistItems = promotions.numberOfActiveChecklistItems;
        cloneBuilder.numberOfChecklistItems = promotions.numberOfChecklistItems;
        cloneBuilder.numberOfReferences = promotions.numberOfAttachments;
        cloneBuilder.previewType = promotions.previewType;
        return cloneBuilder.build();
    }
    setContentModifiedProperties() {
        const cloneBuilder = this.getCloneBuilder();
        // cloneBuilder.userContentLastModifiedBy = AppContext.currentUserId; TODO: get actual current user id
        cloneBuilder.userContentLastModifiedDate = moment();
        return cloneBuilder.build();
    }
    /**
     * @param target Task to diff with
     */ getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (isArray(source) || isArray(target)) {
                if (!isEqual(source, target)) {
                    // Applied categories, return the target array, since we don't actually want to merge them if we remove items
                    return target;
                } else {
                    // Equal
                    return {};
                }
            } else if (key === "assignments") {
                if (!isEqual(source, target)) {
                    if (isEqual(target, {})) {
                        // Unassigned from all, workaround if {} is returned it treats it as no diff
                        return DeltaApiConstants.CollectionCleanupValue;
                    }
                    return target;
                } else {
                    // Equal
                    return {};
                }
            } else if (key === "recurrence") {
                if (!isEqual(source, target)) {
                    if (source == null) {
                        // Recurrence is new, just return target properties
                        return {
                            ...target,
                            schedule: {
                                ...target.schedule,
                                pattern: {
                                    ...target.schedule.pattern
                                }
                            }
                        };
                    } else {
                        // Calculate diff of recurrence
                        return source.getDiff(target);
                    }
                } else {
                    // Equal
                    return {};
                }
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    /**
     * @param other Task to compare with
     */ isEqual(other) {
        return isEqualWith(this, other, isEqualMomentCustomizer);
    }
    /**
     * @param diffs Set of diffs to apply
     */ applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(Task.builder().build(), this.getClone(), ...diffs, Task.mergeCustomizer);
        }
        return this;
    }
    /**
     * @param update Diff sync changes
     */ applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this);
        return this.applyDiffs(diffData);
    }
    setDueDate(dueDate) {
        if (IsSameDay(dueDate, this.dueDate != null ? this.dueDate : null)) {
            // No change
            return this;
        }
        return this.getCloneBuilder().withDueDate(dueDate).build();
    }
    setStartDate(startDate) {
        if (IsSameDay(startDate, this.startDate != null ? this.startDate : null)) {
            // No change
            return this;
        }
        return this.getCloneBuilder().withStartDate(startDate).build();
    }
    /**
     * @param sortAfter The task to sort after
     * @param sortBefore The task to sort before
     */ setAssigneePriorityBetween(sortAfter, sortBefore) {
        const newOrderHint = generateOrderHintBetween(sortAfter ? sortAfter.assigneePriority : null, sortBefore ? sortBefore.assigneePriority : null);
        return this.setProperty("assigneePriority", newOrderHint);
    }
    setRecurrenceSchedule(schedule) {
        return this.getCloneBuilder().withRecurrenceSchedule(schedule).build();
    }
    toggleCompletionState() {
        return this.setProperty("percentComplete", ConvertPercentCompleteToProgressState(this.percentComplete) === ProgressState.Complete ? ConvertProgressStateToPercentComplete(ProgressState.NotStarted) : ConvertProgressStateToPercentComplete(ProgressState.Complete));
    }
    getFormattedDueDate() {
        if (this.dueDate == null) {
            return "";
        }
        return FormatDate(this.dueDate);
    }
    isLate() {
        if (this.dueDate == null || this.isComplete()) {
            return false;
        }
        const today = moment().local().startOf("day").valueOf();
        // Copy the dueDate otherwise it will adjust the time to local instead of noon utc
        const localDate = this.dueDate.clone();
        const dueTime = localDate.local().startOf("day").valueOf();
        if (dueTime < today) {
            return true;
        }
        return false;
    }
    hasPreview() {
        if (this.previewType === PreviewType.NoPreview) {
            return false;
        } else if (this.previewType === PreviewType.Description && this.hasDescription) {
            return true;
        } else if (this.previewType === PreviewType.Reference && this.numberOfReferences > 0) {
            return true;
        } else if (this.previewType === PreviewType.CheckList && this.numberOfChecklistItems > 0) {
            return true;
        } else if (this.previewType === PreviewType.Automatic && this.numberOfReferences > 0) {
            // Plex only shows a preview for automatic if there are references on the task
            return true;
        }
        return false;
    }
    isComplete() {
        return ConvertPercentCompleteToProgressState(this.percentComplete) === ProgressState.Complete;
    }
    isAppPoweredTask() {
        return this.specifiedCompletionRequirements === SpecifiedCompletionRequirements.CompletionInHostedApp;
    }
    getNumberOfVisibleReferences() {
        // APT reference is not an attachment reference and is need to pass the link to the APR app. We should not render it as a reference or show in preview
        return this.isAppPoweredTask() && this.numberOfReferences > 0 ? this.numberOfReferences - 1 : this.numberOfReferences;
    }
    canComplete() {
        // This return statement checks if the specified completion requirements are satisfied,
        // based on different conditions and bitwise operations.
        // In the given return statement, the !! is applied to the entire expression
        const isRequirementsUndefined = this.specifiedCompletionRequirements == null;
        const isNoneRequirements = this.specifiedCompletionRequirements === SpecifiedCompletionRequirements.None;
        const areCompletedRequirementsHit = (this.specifiedCompletionRequirements & ~SpecifiedCompletionRequirements.None) !== 0 && this.numberOfActiveChecklistItems === 0;
        return isRequirementsUndefined || isNoneRequirements || areCompletedRequirementsHit;
    }
    getAssigneeIds() {
        return keys(this.assignments);
    }
    getReferencedUserIds() {
        const userIdList = this.getAssigneeIds();
        if (this.createdBy && userIdList.indexOf(this.createdBy) === -1) {
            userIdList.push(this.createdBy);
        }
        if (this.completedBy && userIdList.indexOf(this.completedBy) === -1) {
            userIdList.push(this.completedBy);
        }
        if (this.userContentLastModifiedBy && userIdList.indexOf(this.userContentLastModifiedBy) === -1) {
            userIdList.push(this.userContentLastModifiedBy);
        }
        return userIdList;
    }
    toGraphSerializable() {
        const assignmentResource = Assignment.getGraphResourceDictionaryType(this.assignments);
        const appliedCategories = reduce(this.appliedCategories, (result, value)=>{
            result["category" + (value + 1)] = true;
            return result;
        }, {});
        // Note: No need to serialize "createdBy". Graph will reject the call if added. FYI.
        const taskResource = {
            "@odata.type": GraphPlannerODataType.Task,
            appliedCategories: appliedCategories,
            assigneePriority: this.assigneePriority,
            assignments: assignmentResource,
            bucketId: this.bucketId,
            conversationThreadId: this.conversationThreadId,
            dueDateTime: this.dueDate != null ? this.dueDate.utc().toISOString() : null,
            id: this.id,
            isOnMyDay: this.isOnMyDay,
            "@odata.etag": this.itemVersion != null ? this.itemVersion : undefined,
            planId: this.planId,
            startDateTime: this.startDate != null ? this.startDate.utc().toISOString() : null,
            percentComplete: this.percentComplete,
            recurrence: this.recurrence?.toGraphSerializable(),
            title: this.title,
            priority: this.priority,
            specifiedCompletionRequirements: getGraphSpecifiedCompletionRequirements(this.specifiedCompletionRequirements)
        };
        // Only include if it has value. setting as 'null' fails
        if (this.orderHint) {
            taskResource.orderHint = this.orderHint;
        }
        if (this.completedBy) {
            taskResource.completedBy = {
                user: {
                    displayName: "",
                    id: this.completedBy
                }
            };
        }
        if (this.deletedBy) {
            taskResource.deletedBy = {
                user: {
                    displayName: "",
                    id: this.deletedBy
                }
            };
        }
        return taskResource;
    }
    getClone() {
        return this.getCloneBuilder().build();
    }
    /**
     * Return a TaskBuilder with clone values of this object
     */ getCloneBuilder() {
        return Task.builder().forClone(this);
    }
    /**
     * Return a copy of this task with a new set of values applied to it especially used for move task
     * @param properties Clone options
     */ getCloneForMove(properties) {
        const cloneBuilder = this.getCloneBuilder();
        // If we are moving to a different bucket within the same plan, do not reset the labels
        if (cloneBuilder.planId !== properties.plan.id) {
            cloneBuilder.planId = properties.plan.id;
            cloneBuilder.appliedCategories = [];
        }
        if (properties.moveToDifferentContainer) {
            cloneBuilder.conversationThreadId = null;
        }
        cloneBuilder.bucketId = properties.bucketId;
        return cloneBuilder.build();
    }
    isEligibleForRecurrence() {
        return this.dueDate != null;
    }
    isProjectTask() {
        return this.creationSource.external?.contextScenarioId === ContextScenarioConstants.project;
    }
    /**
     * Initializes a new instance of the "Task" entity.
     * @param taskBuilder Builder with the initialization data
     */ constructor(taskBuilder){
        super(taskBuilder.id, taskBuilder.itemVersion);
        this.appliedCategories = taskBuilder.appliedCategories;
        this.assigneePriority = taskBuilder.assigneePriority;
        this.assignments = taskBuilder.assignments;
        this.bucketId = taskBuilder.bucketId;
        this.completedBy = taskBuilder.completedBy;
        this.completedDate = taskBuilder.completedDate;
        this.userContentLastModifiedBy = taskBuilder.userContentLastModifiedBy;
        this.userContentLastModifiedDate = taskBuilder.userContentLastModifiedDate;
        this.conversationThreadId = taskBuilder.conversationThreadId;
        this.createdBy = taskBuilder.createdBy;
        this.createdDate = taskBuilder.createdDate;
        this.creationSource = taskBuilder.creationSource;
        this.deletedBy = taskBuilder.deletedBy;
        this.dueDate = taskBuilder.dueDate;
        this.hasDescription = taskBuilder.hasDescription;
        this.isOnMyDay = taskBuilder.isOnMyDay;
        this.kind = taskBuilder.kind;
        this.numberOfActiveChecklistItems = taskBuilder.numberOfActiveChecklistItems;
        this.numberOfChecklistItems = taskBuilder.numberOfChecklistItems;
        this.numberOfReferences = taskBuilder.numberOfReferences;
        this.orderHint = taskBuilder.orderHint;
        this.percentComplete = taskBuilder.percentComplete;
        this.planId = taskBuilder.planId;
        this.previewType = taskBuilder.previewType;
        this.priority = taskBuilder.priority;
        this.recurrence = taskBuilder.recurrence;
        this.startDate = taskBuilder.startDate;
        this.title = taskBuilder.title;
        this.specifiedCompletionRequirements = taskBuilder.specifiedCompletionRequirements;
    }
}
