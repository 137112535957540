import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for BasicTaskStore, for use in the registry */ export const BasicTaskStoreActionTypes = [
    ActionType.CancelApprovalDetailsAction,
    ActionType.CancelApprovalDetailsFailedAction,
    ActionType.CreateApprovalDetailsAction,
    ActionType.CreateApprovalDetailsFailedAction,
    ActionType.CreateBasicTaskAction,
    ActionType.CreateBasicTaskFailedAction,
    ActionType.CreateBasicTaskSucceededAction,
    ActionType.CreateConversationThreadAction,
    ActionType.CreateConversationThreadFailedAction,
    ActionType.CreateConversationThreadSucceededAction,
    ActionType.DeleteBasicTaskAction,
    ActionType.DeleteBasicTaskFailedAction,
    ActionType.DeleteBasicTaskSucceededAction,
    ActionType.DeltaSyncCreateBasicTaskAction,
    ActionType.DeltaSyncDeleteBasicTaskAction,
    ActionType.DeltaSyncUpdateBasicTaskAction,
    ActionType.FetchApprovalDetailsAction,
    ActionType.FetchApprovalDetailsFailedAction,
    ActionType.FetchApprovalDetailsSucceededAction,
    ActionType.FetchBasicTaskAction,
    ActionType.FetchBasicTaskFailedAction,
    ActionType.FetchBasicTasksForAssigneeAction,
    ActionType.FetchBasicTasksForAssigneeFailedAction,
    ActionType.FetchBasicTasksForAssigneeSucceededAction,
    ActionType.FetchBasicTasksForMyDayAction,
    ActionType.FetchBasicTasksForMyDayFailedAction,
    ActionType.FetchBasicTasksForMyDaySucceededAction,
    ActionType.FetchBasicTasksForPlanAction,
    ActionType.FetchBasicTasksForPlanFailedAction,
    ActionType.FetchBasicTasksForPlanSucceededAction,
    ActionType.FetchBasicTasksForTodoPlanFinalizedAction,
    ActionType.FetchBasicTaskSucceededAction,
    ActionType.UpdateApprovalDetailsAction,
    ActionType.UpdateApprovalDetailsFailedAction,
    ActionType.UpdateBasicTaskAction,
    ActionType.UpdateBasicTaskFailedAction,
    ActionType.UpdateBasicTaskSucceededAction,
    ActionType.UploadAttachmentForTaskAction,
    ActionType.UploadAttachmentForTaskFailedAction,
    ActionType.UploadAttachmentForTaskSucceededAction,
    ActionType.ProjectManagerWorkflowClientStateUpdateAction,
    ActionType.UndeleteBasicTaskAction,
    ActionType.UndeleteBasicTaskFailedAction,
    ActionType.UndeleteBasicTaskSucceededAction
];
