// Models
import { RecurrenceRangeType } from "../../../../service/graph-legacy/task/recurrence/RecurrenceRangeType";
// Utilities
import moment from "moment";
import { ToUTC10am } from "@ms/uno-utilities/lib/local/DateUtilities";
/**
 * Represents a recurrence range entity in the client
 */ export class RecurrenceRange {
    /**
     * Creates the default RecurrenceRange entity
     * @remarks Default is equivalent to no end starting today.
     */ static default() {
        return new RecurrenceRange({
            startDate: ToUTC10am(moment()),
            recurrenceRangeType: RecurrenceRangeType.NoEnd,
            endDate: null,
            numberOfOccurrences: null
        });
    }
    setStartDate(startDate) {
        return new RecurrenceRange({
            ...this,
            startDate: ToUTC10am(startDate)
        });
    }
    generateHash() {
        const result = [
            this.recurrenceRangeType,
            this.startDate.toISOString()
        ];
        if (this.endDate != null) {
            result.push(this.endDate.toISOString());
        }
        if (this.numberOfOccurrences != null) {
            result.push(this.numberOfOccurrences.toString());
        }
        return result.toString();
    }
    /**
     * Initializes a new instance of the "RecurrenceRange" entity.
     */ constructor(resource){
        this.startDate = moment(resource.startDate.toISOString());
        this.recurrenceRangeType = resource.recurrenceRangeType;
        this.endDate = resource.endDate != null ? moment(resource.endDate.toISOString()) : null;
        this.numberOfOccurrences = resource.numberOfOccurrences;
    }
}
