// Models
import { MultipleDaysOrDatesRecurrenceCalculator } from "./MultipleDaysOrDatesRecurrenceCalculator";
/**
 * Represents a weekly recurrence pattern calculator.
 * @example Tuesday of every 2nd week. (Weekly)
 */ export class WeeklyRecurrenceCalculator extends MultipleDaysOrDatesRecurrenceCalculator {
    isSameOrBefore(start, end) {
        // Set to beginning of the week then check. This is because we iterate through the weeks
        return start.clone().day(0).isSameOrBefore(end.clone().day(0));
    }
    getDurationBetweenDateAndPatternStartDate(date) {
        // Set to beginning of week and return number of whole weeks between
        // This is because the built in moment diff function checks total time between two dates.
        // If D1 is Saturday 7/17/2021 and D2 is Thursday 7/22/2020 then the result for number of weeks is 0.
        return date.clone().day(0).diff(this.patternStartDate.clone().day(0), this.periodPattern);
    }
    constructor(pattern, patternStartDate){
        super(pattern, patternStartDate, "week");
    }
}
