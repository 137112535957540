// Project metadata keys
/** Type. stored in metadata. Set to "PJO" for Project Online Projects. */ export const MetadataTypeKey = "type";
/** Place type for url stored in Mru service. Stored in metadata which is used by Mru service to store custom data. */ export const MetadataPlaceTypeKey = "placetype";
/** Org type. stored in metadata. This will be filled for modern Projects only. Will be Named for named org projects and Default for default org projects */ export const MetadataOrgTypeKey = "orgType";
/** CDS Org Id. stored in metadata. */ export const MetadataOrgIdKey = "orgId";
/** Org friendly name. stored in metadata. This will be populated for Named Org Projects and Project Operations projects. */ export const MetadataFriendlyNameKey = "orgFriendlyName";
/** Group Id for the project, if any. */ export const MetadataGroupIdKey = "groupId";
/** Planner plan ID if the project was converted from a Planner plan */ export const MetadataConvertPlanIdKey = "convertPlanId";
// Planner metadata keys
/** Plan container type */ export const MetadataContainerTypeKey = "containerType";
/** Plan container id */ export const MetadataContainerIdKey = "containerId";
// Todo metadata keys
/** isShared value of the todo list */ export const MetadataIsSharedKey = "isShared";
// Portfolio metadata keys
/** Meta data key for the sub type of the portfolio/roadmap item indicating where it was created */ export const MetadataSubTypeKey = "subType";
/** Metadata key fo last pinned time stamp (is common to Todo, Planner, Project) */ export const MetadataLastPinnedTimeStampKey = "lastPinnedTimeStamp";
export var ProjectMetadataType;
(function(ProjectMetadataType) {
    ProjectMetadataType["PJO"] = "PJO";
    ProjectMetadataType["Project"] = "Project";
    ProjectMetadataType["ProjectOperations"] = "ProjectOperations";
})(ProjectMetadataType || (ProjectMetadataType = {}));
export var ProjectMetadataOrgType;
(function(ProjectMetadataOrgType) {
    ProjectMetadataOrgType["None"] = "None";
    ProjectMetadataOrgType["Default"] = "Default";
    ProjectMetadataOrgType["Named"] = "Named";
})(ProjectMetadataOrgType || (ProjectMetadataOrgType = {}));
