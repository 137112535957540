// Models
import { DayOrDate } from "./DayOrDate";
// Utilities
import { getMomentDayIndexFromDayOfWeek } from "@ms/uno-utilities/lib/local/DateUtilities";
/**
 * Represents a weekly day.
 * @example Saturday of a week.
 */ export class WeeklyDay extends DayOrDate {
    getDateForPeriod(period) {
        return period.clone().day(getMomentDayIndexFromDayOfWeek(this.dayOfWeek));
    }
    constructor(dayOfWeek){
        super(), this.dayOfWeek = dayOfWeek;
    }
}
