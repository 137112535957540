/**
 * Enumeration of the possible taskboard types
 */ export var TaskboardType;
(function(TaskboardType) {
    /**
     * Progress taskboard
     */ TaskboardType[TaskboardType["Progress"] = 0] = "Progress";
    /**
     * Assignment taskboard
     */ TaskboardType[TaskboardType["AssignedTo"] = 1] = "AssignedTo";
    /**
     * Bucket taskboard
     */ TaskboardType[TaskboardType["Bucket"] = 2] = "Bucket";
})(TaskboardType || (TaskboardType = {}));
