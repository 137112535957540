// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
import { FailureAction } from "../FailureAction";
// Constants
import { ExportStrings } from "@ms/uno-constants/lib/local/ExportConstants";
export class FetchMruItemsAction extends TypedAction(ActionType.FetchMruItems) {
    getExportName() {
        return ExportStrings.MruItemsFetched;
    }
}
export class FetchMruItemsFailedAction extends FailureAction {
    constructor(response, textStatus, errorThrown){
        super(ActionType.FetchMruItemsFailed, response, textStatus, errorThrown);
    }
}
export class FetchMruItemsSucceededAction extends TypedAction(ActionType.FetchMruItemsSucceeded) {
    constructor(mruItems){
        super(), this.mruItems = mruItems;
    }
}
export class CreateMruItemAction extends TypedAction(ActionType.CreateMruItem) {
    loggingData() {
        return {
            serverDocId: this.mruItem.serverDocId
        };
    }
    getExportName() {
        return ExportStrings.MruItemCreated;
    }
    constructor(mruItem){
        super(), this.mruItem = mruItem;
    }
}
export class CreateMruItemFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            serverDocId: this.mruItem.serverDocId
        };
    }
    constructor(mruItem, response, textStatus, errorThrown){
        super(ActionType.CreateMruItemFailed, response, textStatus, errorThrown), this.mruItem = mruItem;
    }
}
export class CreateMruItemSucceededAction extends TypedAction(ActionType.CreateMruItemSucceeded) {
    loggingData() {
        return {
            serverDocId: this.mruItem.serverDocId,
            mruId: this.mruItem.mruId
        };
    }
    constructor(mruItem){
        super(), this.mruItem = mruItem;
    }
}
export class DeleteMruItemAction extends TypedAction(ActionType.DeleteMruItem) {
    loggingData() {
        return {
            serverDocId: this.serverDocId
        };
    }
    getExportName() {
        return ExportStrings.MruItemDeleted;
    }
    constructor(serverDocId){
        super(), this.serverDocId = serverDocId;
    }
}
export class DeleteMruItemFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            serverDocId: this.deletedMruItem.serverDocId,
            mruId: this.deletedMruItem.mruId
        };
    }
    constructor(deletedMruItem, response, textStatus, errorThrown){
        super(ActionType.DeleteMruItemFailed, response, textStatus, errorThrown), this.deletedMruItem = deletedMruItem;
    }
}
export class DeleteMruItemSucceededAction extends TypedAction(ActionType.DeleteMruItemSucceeded) {
    loggingData() {
        return {
            serverDocId: this.serverDocId
        };
    }
    constructor(serverDocId){
        super(), this.serverDocId = serverDocId;
    }
}
export class UpdateMruItemAction extends TypedAction(ActionType.UpdateMruItem) {
    loggingData() {
        return {
            serverDocId: this.serverDocId
        };
    }
    getExportName() {
        return ExportStrings.MruItemUpdated;
    }
    constructor(serverDocId, delta, updateKey){
        super(), this.serverDocId = serverDocId, this.delta = delta, this.updateKey = updateKey;
    }
}
export class UpdateMruItemFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            serverDocId: this.serverDocId
        };
    }
    constructor(serverDocId, updateKey, response, textStatus, errorThrown){
        super(ActionType.UpdateMruItemFailed, response, textStatus, errorThrown), this.serverDocId = serverDocId, this.updateKey = updateKey;
    }
}
export class UpdateMruItemSucceededAction extends TypedAction(ActionType.UpdateMruItemSucceeded) {
    loggingData() {
        return {
            serverDocId: this.updatedMruItem.serverDocId
        };
    }
    constructor(updatedMruItem, updateKey){
        super(), this.updatedMruItem = updatedMruItem, this.updateKey = updateKey;
    }
}
