/**
 * Enumeration of the possible values for a PlannerTask's specifiedCompletionRequirements property
 */ export var GraphSpecifiedCompletionRequirements;
(function(GraphSpecifiedCompletionRequirements) {
    /**
     * No requirement
     */ GraphSpecifiedCompletionRequirements["None"] = "none";
    /**
     * Some or all of the checklist items are required to be checked.
     */ GraphSpecifiedCompletionRequirements["Checklist"] = "checklistCompletion";
    /**
     * Some other requirement exists.
     */ GraphSpecifiedCompletionRequirements["UnknownFutureValue"] = "unknownFutureValue";
    /**
     * Some or all of the forms are required to be completed.
     */ GraphSpecifiedCompletionRequirements["Form"] = "formCompletion";
    /**
     * Approval is required.
     */ GraphSpecifiedCompletionRequirements["Approval"] = "approvalCompletion";
    /**
     * The task is an App Powered Task which can only be completed in the dedicated app.
     */ GraphSpecifiedCompletionRequirements["CompletionInHostedApp"] = "completionInHostedApp";
})(GraphSpecifiedCompletionRequirements || (GraphSpecifiedCompletionRequirements = {}));
