// Constants
import { ClientFlavor, MsaTenantId } from "@ms/uno-constants/lib/local/AppConstants";
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { SettingsObjectName } from "@ms/uno-constants/lib/local/configuration/ISettings";
import { PlannerAsyncStoragePrefix, PlannerAsyncStorageVersion } from "@ms/uno-constants/lib/local/PersistenceConstants";
// Flux
import { AsyncDispatcher } from "@ms/uno-fluxcore/lib/local/dispatcher";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
// Host Adaptor
import { FailedReason } from "@ms/uno-hostadaptors/lib/local/IUnoHostAdaptor";
// Models
import { AssetLoadingError } from "@ms/uno-errors/lib/local/errors/AssetLoadingError";
import { PersistedObjectStoreSchema, } from "@ms/uno-models/lib/local/client/persistence/PersistedAsyncStorageSchema";
// Services
import { AuthenticatedAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/AuthenticatedAjaxClient";
import { BasicAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/BasicAjaxClient";
import { GatedGetAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/GatedGetAjaxClient";
import { RequestPrioritizer } from "@ms/uno-services/lib/local/priorityRequestQueue/RequestPrioritizer";
import { Log } from "@ms/uno-telemetry/lib/local/events/Action.event";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { setIsOnlineStatusForLogs } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
// Utilities
import { initializeCultureData } from "@ms/uno-shell/lib/local/utilities/CultureUtilities";
import { HtmlSettingsReader } from "@ms/uno-utilities/lib/local/HtmlSettingsReader";
import { IndexedDBStorage } from "@ms/uno-utilities/lib/local/storage/IndexedDBStorage";
/**
 * Sets up the AppContext
 * @throws {Error} If there is an error initializing the AppContext
 */
export function setupAppContext() {
    const reader = new HtmlSettingsReader(SettingsObjectName);
    AppContext.initializeFromSettings(AppContext.BuildContextSettingsFromSettingsGetter(reader.getSetting.bind(reader)));
}
/**
 * Gets the context from hostAdaptor and also initializes culture data
 */
export async function getContextAndInitializeCultureData(hostAdaptor) {
    let context;
    try {
        context = await hostAdaptor.getContext();
        await initializeCultureData(context.locale);
    }
    catch (e) {
        const err = new AssetLoadingError(e.name, e.message);
        hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message: e.message });
        // TODO - 7545465, 8108486 need to implement errors and error logging
        // Task 8108486: Uno LazyLoading and asset loading error management
        throw err;
    }
    return context;
}
/**
 * Gets the TelemetryContext from the Context
 */
export function getTelemetryContext(context) {
    return {
        locale: context.locale,
        userId: context.userId,
        tenantId: context.tenantId,
        ringId: context.ringId, // Teams ring according to Teams client SDK
        isMSA: context.tenantId === MsaTenantId,
        isGuestUser: context.isGuestUser,
        platform: context.platform,
    };
}
/**
 * Gets the StoreProviders from the StoreFactory
 */
export function getStoreProviders(storeFactory) {
    return {
        load: storeFactory.load.bind(storeFactory),
        isLoaded: storeFactory.isLoaded.bind(storeFactory),
        aggregates: storeFactory.getAggregatesStoreProvider(),
        activity: storeFactory.getActivityStoreProvider(),
        auth: storeFactory.getAuthStoreProvider(),
        appContext: storeFactory.getAppContextStoreProvider(),
        basicBucket: storeFactory.getBasicBucketStoreProvider(),
        basicPlan: storeFactory.getBasicPlanStoreProvider(),
        basicPlanConversionStatus: storeFactory.getBasicPlanConversionStatusStoreProvider(),
        basicPermissions: storeFactory.getBasicPermissionsStoreProvider(),
        basicTask: storeFactory.getBasicTaskStoreProvider(),
        campaign: storeFactory.getCampaignStoreProvider(),
        capabilities: storeFactory.getCapabilitiesStoreProvider(),
        container: storeFactory.getContainerStoreProvider(),
        conversations: storeFactory.getConversationsStoreProvider(),
        createTaskFromMessage: storeFactory.getCreateTaskFromMessageStoreProvider(),
        deltaSync: storeFactory.getDeltaSyncStoreProvider(),
        driveItem: storeFactory.getDriveItemStoreProvider(),
        groups: storeFactory.getGroupsStoreProvider(),
        intelligence: storeFactory.getIntelligenceStoreProvider(),
        modal: storeFactory.getModalStoreProvider(),
        mru: storeFactory.getMruStoreProvider(),
        navigation: storeFactory.getNavigationStoreProvider(),
        newsBar: storeFactory.getNewsBarStoreProvider(),
        notification: storeFactory.getNotificationStoreProvider(),
        operationTracking: storeFactory.getOperationTrackingStoreProvider(),
        org: storeFactory.getOrgStoreProvider(),
        performanceCollection: storeFactory.getPerformanceCollectionStoreProvider(),
        planConversion: storeFactory.getPlanConversionStoreProvider(),
        portfolio: storeFactory.getPortfolioStoreProvider(),
        premiumPlan: storeFactory.getPremiumPlanStoreProvider(),
        premiumPlanPermissions: storeFactory.getPremiumPlanPermissionsStoreProvider(),
        projectHomeResource: storeFactory.getProjectHomeResourceStoreProvider(),
        publishing: storeFactory.getPublishingStoreProvider(),
        route: storeFactory.getRouteStoreProvider(),
        statusReport: storeFactory.getStatusReportStoreProvider(),
        suiteNav: storeFactory.getSuiteNavStoreProvider(),
        teams: storeFactory.getTeamsStoreProvider(),
        templates: storeFactory.getTemplatesStoreProvider(),
        tenant: storeFactory.getTenantStoreProvider(),
        upsell: storeFactory.getUpsellStoreProvider(),
        unoShell: storeFactory.getUnoShellStoreProvider(),
        user: storeFactory.getUserStoreProvider(),
        userMembership: storeFactory.getUserMembershipStoreProvider(),
        view: storeFactory.getViewStoreProvider(),
        recommendedPlans: storeFactory.getRecommendedPlansStoreProvider(),
    };
}
/**
 * Gets the AsyncDispatcher
 */
export function getAsyncDispatcher(registry, storeFactory, loggers) {
    return new AsyncDispatcher(registry, (storeType) => () => storeFactory.loadForDispatch(storeType), (event) => Log(event, loggers.logHandler), (tag, traceLevel, message) => loggers.traceLogger.logTrace(tag, traceLevel, message));
}
/**
 * Gets the RequestPrioritizer
 */
export function getRequestPrioritizer(storeProviders) {
    return new RequestPrioritizer((request) => {
        // Cancel request if it is not for the current view
        const currentViewId = storeProviders.load(Store.Route).getCurrentViewId();
        return currentViewId != null && request.viewIds != null && request.viewIds.indexOf(currentViewId) === -1;
    });
}
/**
 * Method to get persistent async storage instance
 * @param configProvider config provider
 * @param userId user id
 * @param loggers used for logging
 * @returns persisted async storage instance or null if data persistence is disabled or IndexedDB is not supported
 */
export function getPersistentAsyncStorage(configProvider, userId, loggers) {
    let persistentAsyncStorage = null;
    if (configProvider().flights.EnableDataPersistence && userId) {
        if (IndexedDBStorage.isIndexedDBSupported()) {
            persistentAsyncStorage = new IndexedDBStorage(userId, PlannerAsyncStoragePrefix, PlannerAsyncStorageVersion, PersistedObjectStoreSchema, loggers.traceLogger);
        }
        else {
            loggers.traceLogger.logTrace(0x1e28338e /* tag_4kdoo */, TraceLevel.Info, "IndexedDB is not supported");
        }
    }
    return persistentAsyncStorage;
}
/**
 * Gets the AuthService
 */
export function getAuthServiceProvider(factory, dispatcher, hostAdaptor, loggers, configProvider) {
    const { auth } = configProvider().settings.serviceConfigurations;
    const { globalAjaxTimeout } = configProvider().settings.retrySettings;
    const apiClient = new AuthenticatedAjaxClient(new BasicAjaxClient(globalAjaxTimeout, loggers), auth.authResourceURI, loggers, hostAdaptor.getAccessToken);
    const gatedApiClient = new GatedGetAjaxClient(apiClient);
    return factory.getAuthServiceProvider(gatedApiClient, hostAdaptor, configProvider);
}
/**
 * Gets the ServiceProviders
 */
export function getServiceProviders(serviceOrchestrator, authService) {
    return {
        augloop: serviceOrchestrator.getAugloopServiceProvider(),
        auth: () => authService,
        centro: serviceOrchestrator.getCentroServiceProvider(),
        chatSvcAgg: serviceOrchestrator.getChatSvcAggServiceProvider(),
        conversations: serviceOrchestrator.getConversationsServiceProvider(),
        driveItem: serviceOrchestrator.getDriveItemServiceProvider(),
        flow: serviceOrchestrator.getFlowServiceProvider(),
        gateway: serviceOrchestrator.getGatewayServiceProvider(),
        iris: serviceOrchestrator.getIrisServiceProvider(),
        groups: serviceOrchestrator.getGroupsServiceProvider(),
        loop: serviceOrchestrator.getLoopServiceProvider(),
        loopMts: serviceOrchestrator.getLoopMtsServiceProvider(),
        mars: serviceOrchestrator.getMarsServiceProvider(),
        mru: serviceOrchestrator.getMruServiceProvider(),
        ocps: serviceOrchestrator.getOcpsServiceProvider(),
        oneshell: serviceOrchestrator.getOneShellServiceProvider(),
        planner: serviceOrchestrator.getPlannerServiceProvider(),
        plp: serviceOrchestrator.getProjectLandingPageServiceProvider(),
        pss: serviceOrchestrator.getProjectSchedulingServiceProvider(),
        tcms: serviceOrchestrator.getTcmsServiceProvider(),
        teams: serviceOrchestrator.getTeamsServiceProvider(),
        tenant: serviceOrchestrator.getTenantServiceProvider(),
        retail: serviceOrchestrator.getRetailServiceProvider(),
        roaming: serviceOrchestrator.getRoamingServiceProvider(),
        siteURL: serviceOrchestrator.getSiteURLServiceProvider(),
        todo: serviceOrchestrator.getTodoServiceProvider(),
        user: serviceOrchestrator.getUserServiceProvider(),
    };
}
/**
 * Gets the LogicModuleProviders
 */
export function getLogicModuleProviders(lmFactory, dispatcher, serviceProviders, storeProviders, configProvider, hostAdaptor, authService, authStore, fetchAccessToken) {
    return {
        adaptiveCard: lmFactory.getAdaptiveCardModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        appContext: lmFactory.getAppContextModuleProvider(dispatcher, serviceProviders, storeProviders, hostAdaptor, configProvider),
        auth: lmFactory.getAuthModuleProvider(dispatcher, authService, authStore, configProvider),
        basicBucket: lmFactory.getBasicBucketModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        basicPlan: lmFactory.getBasicPlanModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        basicTask: lmFactory.getBasicTaskModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        campaign: lmFactory.getCampaignModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        capabilities: lmFactory.getCapabilitiesModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        centro: lmFactory.getCentroModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        chatSvcAgg: lmFactory.getChatSvcAggModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        conversations: lmFactory.getConversationsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        deltaSync: lmFactory.getDeltaSyncModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        excelExport: lmFactory.getExcelExportModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        driveItem: lmFactory.getDriveItemModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        groups: lmFactory.getGroupsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        intelligence: lmFactory.getIntelligenceModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        links: lmFactory.getLinksModuleProvider(dispatcher, serviceProviders, storeProviders, hostAdaptor, configProvider),
        loop: lmFactory.getLoopModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        mru: lmFactory.getMruModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        notification: lmFactory.getNotificationModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        org: lmFactory.getOrgModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        planai: lmFactory.getPlanAiModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        planConversion: lmFactory.getPlanConversionModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        portfolio: lmFactory.getPortfolioModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        premiumPlan: lmFactory.getPremiumPlanModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        premiumTask: lmFactory.getPremiumTaskModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        publishing: lmFactory.getPublishingModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        roaming: lmFactory.getRoamingModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        roster: lmFactory.getRosterModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        suiteNav: lmFactory.getSuiteNavModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        teams: lmFactory.getTeamsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        templates: lmFactory.getTemplateModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        tenant: lmFactory.getTenantModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        todo: lmFactory.getTodoModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider, fetchAccessToken),
        ttTabs: lmFactory.getTeamsTasksTabsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        upsell: lmFactory.getUpsellModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        user: lmFactory.getUserModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
    };
}
/**
 * Gets the ActionCreatorProviders
 */
export function getActionCreatorProviders(acFactory) {
    return {
        load: acFactory.load.bind(acFactory),
        activity: acFactory.getActivityAcProvider(),
        appContext: acFactory.getAppContextAcProvider(),
        basicBucket: acFactory.getBasicBucketAcProvider(),
        basicPlan: acFactory.getBasicPlanAcProvider(),
        basicTask: acFactory.getPlannerTaskAcProvider(),
        campaign: acFactory.getCampaignAcProvider(),
        capabilities: acFactory.getCapabilitiesAcProvider(),
        container: acFactory.getContainerAcProvider(),
        createTaskFromTeamsMessage: acFactory.getCreateTaskFromTeamsMessageAcProvider(),
        dataPreFetch: acFactory.getDataPreFetchAcProvider(),
        deltaSync: acFactory.getDeltaSyncAcProvider(),
        driveItem: acFactory.getDriveItemAcProvider(),
        export: acFactory.getExportAcProvider(),
        groups: acFactory.getGroupsAcProvider(),
        intelligence: acFactory.getIntelligenceAcProvider(),
        links: acFactory.getLinksAcProvider(),
        modal: acFactory.getModalAcProvider(),
        mru: acFactory.getMruAcProvider(),
        notification: acFactory.getNotificationAcProvider(),
        operationTracking: acFactory.getOperationTrackingAcProvider(),
        org: acFactory.getOrgAcProvider(),
        performanceCollection: acFactory.getPerformanceCollectionAcProvider(),
        portfolio: acFactory.getPortfolioAcProvider(),
        publishing: acFactory.getPublishingAcProvider(),
        planConversion: acFactory.getPlanConversionAcProvider(),
        premiumPlan: acFactory.getPremiumPlanAcProvider(),
        routing: acFactory.getRoutingAcProvider(),
        suiteNavView: acFactory.getSuiteNavViewAcProvider(),
        teams: acFactory.getTeamsAcProvider(),
        teamsTab: acFactory.getTeamsTabAcProvider(),
        templates: acFactory.getTemplatesAcProvider(),
        tenant: acFactory.getTenantAcProvider(),
        unoShell: acFactory.getUnoShellAcProvider(),
        upsell: acFactory.getUpsellAcProvider(),
        user: acFactory.getUserAcProvider(),
        ocv: acFactory.getOCVAcProvider(),
        recommendedPlans: acFactory.getRecommendedPlansAcProvider(),
        projectHomeResource: acFactory.getResourceAcProvider(),
        // View AC providers
        basicPlanView: acFactory.getBasicPlanViewAcProvider(),
        createPlanView: acFactory.getCreatePlanViewAcProvider(),
        copyPlanView: acFactory.getCopyPlanViewAcProvider(),
        createPortfolioView: acFactory.getCreatePortfolioViewAcProvider(),
        createTaskView: acFactory.getCreateTaskViewAcProvider(),
        leftNavView: acFactory.getLeftNavViewAcProvider(),
        myDayView: acFactory.getMyDayViewAcProvider(),
        myPlansView: acFactory.getMyPlansViewAcProvider(),
        myPortfoliosView: acFactory.getMyPortfoliosViewAcProvider(),
        myTasksView: acFactory.getMyTasksViewAcProvider(),
        unoShellView: acFactory.getUnoShellViewAcProvider(),
        convertPlanView: acFactory.getConvertPlanViewAcProvider(),
        createTeamsTabView: acFactory.getCreateTeamsTabViewAcProvider(),
        sharePlanView: acFactory.getSharePlanViewAcProvider(),
        publishingLeftNavView: acFactory.getPublishingLeftNavViewAcProvider(),
        campaignCalloutView: acFactory.getCampaignCalloutViewAcProvider(),
        viewTransforms: acFactory.getViewTransformsAcProvider(),
    };
}
/**
 * Gets the WorkerProviders
 */
export function getWorkerProviders(workerFactory, telemetryLoggers, routePathPrefix, persistentAsyncStorage) {
    return {
        basicPlanConversionTrackerWorkerProvider: workerFactory.getBasicPlanConversionTrackerWorkerProvider(),
        cdsProvisioningStatusWorker: workerFactory.getCdsProvisioningStatusWorkerProvider(),
        deltaSyncWorker: workerFactory.getDeltaSyncWorkerProvider(),
        deltaSyncFeedProcessorWorker: workerFactory.getDeltaSyncFeedProcessorWorkerProvider(),
        historyObserverWorker: workerFactory.getHistoryObserverWorkerProvider(routePathPrefix, () => document.location.pathname),
        mruWorker: workerFactory.getMruWorkerProvider(),
        navigationWorker: workerFactory.getNavigationWorkerProvider(),
        networkConnectivityWorker: workerFactory.getNetworkConnectivityWorkerProvider(),
        planConversionStatusTrackerWorker: workerFactory.getPlanConversionStatusTrackerWorkerProvider(),
        premiumPlanCreationStatusWorker: workerFactory.getPremiumPlanCreationStatusWorkerProvider(),
        projectTrialOperationTrackerWorker: workerFactory.getProjectTrialOperationTrackerWorkerProvider(),
        recommendedPlanUpdateWorker: workerFactory.getRecommendedPlanUpdateWorkerProvider(),
        augloopTileSyncWorker: workerFactory.getAugloopTileSyncWorkerProvider(),
        operationTrackingWorker: workerFactory.getOperationTrackingWorkerProvider(),
        performanceObserverWorker: workerFactory.getPerformanceObserverWorkerProvider(telemetryLoggers),
        dataFetchWorker: workerFactory.getDataFetchWorkerProvider(),
        storeHydrationWorker: persistentAsyncStorage
            ? workerFactory.getStoreHydrationWorkerProvider(persistentAsyncStorage, telemetryLoggers)
            : null,
        cacheUpdateWorker: persistentAsyncStorage
            ? workerFactory.getCacheUpdateWorkerProvider(persistentAsyncStorage, telemetryLoggers)
            : null,
    };
}
/**
 * Gets the agent providers from the agent factory
 */
export function getAgentProviders(agentFactory) {
    const agentProviders = {
        projectManager: agentFactory.getProjectManagerAgentProvider(),
    };
    return agentProviders;
}
/**
 * Load chunks and initialize workers
 */
export async function loadAndInitializeWorkers(workerProviders, requestPrioritizer, loggers, configProvider) {
    try {
        // Initializing static workers
        if (configProvider().flights.EnableDataFetchWorker) {
            const dataFetchWorker = workerProviders.dataFetchWorker();
            dataFetchWorker.init();
        }
        const clientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
        const workerPromises = [workerProviders.deltaSyncWorker(), workerProviders.deltaSyncFeedProcessorWorker()];
        if (clientFlavor !== ClientFlavor.TaskFromMessage || !configProvider().flights.EnableCreateTaskFromMessageShell) {
            workerPromises.push(workerProviders.mruWorker(), workerProviders.navigationWorker(), workerProviders.premiumPlanCreationStatusWorker(), workerProviders.projectTrialOperationTrackerWorker(), workerProviders.augloopTileSyncWorker(), workerProviders.operationTrackingWorker(), workerProviders.recommendedPlanUpdateWorker());
        }
        const networkConnectivityWorker = await workerProviders.networkConnectivityWorker();
        const workers = await Promise.all(workerPromises);
        workers.forEach((worker) => worker.init());
        networkConnectivityWorker.init();
        networkConnectivityWorker.addNetworkConnectivityChangeCallback(requestPrioritizer.onNetworkConnectivityChanged.bind(requestPrioritizer));
        networkConnectivityWorker.addNetworkConnectivityChangeCallback(setIsOnlineStatusForLogs);
        if (configProvider().flights.EnableDataPersistence) {
            const storeHydrationWorkerProvider = workerProviders.storeHydrationWorker;
            if (storeHydrationWorkerProvider) {
                const storeHydrationWorker = await storeHydrationWorkerProvider();
                storeHydrationWorker.init();
            }
            else {
                loggers.traceLogger.logTrace(0x1e282455 /* tag_4kcrv */, TraceLevel.Info, "Store hydration worker is not available.");
            }
            const cacheUpdateWorkerProvider = workerProviders.cacheUpdateWorker;
            if (cacheUpdateWorkerProvider) {
                const cacheUpdateWorker = await cacheUpdateWorkerProvider();
                cacheUpdateWorker.init();
            }
            else {
                loggers.traceLogger.logTrace(0x1e2822cf /* tag_4kclp */, TraceLevel.Info, "Cache update worker is not available.");
            }
        }
        //Initialize the performance observer worker
        if (configProvider().flights.EnableModernPerformanceMetrics) {
            const performanceObserverWorker = await workerProviders.performanceObserverWorker();
            performanceObserverWorker.init();
        }
        if (configProvider().flights.EnableUnoLevelProjectProvisioning) {
            const cdsProvisioningStatusWorker = await workerProviders.cdsProvisioningStatusWorker();
            cdsProvisioningStatusWorker.init();
        }
    }
    catch (e) {
        loggers.traceLogger.logTrace(0x1e354195 /* tag_4nugv */, TraceLevel.Warning, `Failed to load and initialize workers. [ErrorType=${e.name}][Error=${e.message}]`);
    }
}
/**
 * Log initial plan view sub route type info for experimentation
 * @param componentName The component name
 * @param subRouteType The current sub route type
 * @param loggers The loggers
 */
export function logPlanViewSubRouteType(componentName, subRouteType, loggers) {
    // If initial route is for a plan view, track in engagement telemetry
    if (subRouteType === SubRouteType.BasicPlan || subRouteType === SubRouteType.PremiumPlan || subRouteType === SubRouteType.TodoList) {
        loggers.engagementLogger.logEngagement(componentName, EngagementTelemetryConstants.OpenPlanFromPersistedRoute, {
            subRouteType: subRouteType,
        });
    }
}
