// Constants
import { WebQueryParams } from "@ms/uno-constants/lib/local/WebConstants";
export function getLocale() {
    // Here's the priority order for locale
    // 1) "mkt" query parameter override
    // 3) Browser's language
    // 4) Default to "en-US" (shouldn't happen)
    const queryParams = new URLSearchParams(window.location.search);
    const mktFromQuery = queryParams.get(WebQueryParams.Mkt);
    if (mktFromQuery) {
        return mktFromQuery;
    }
    const browserLanguage = navigator?.language;
    if (browserLanguage) {
        return browserLanguage;
    }
    return "en-US";
}
