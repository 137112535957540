// Builder
import { BucketBuilder } from "./BucketBuilder";
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { TaskServiceEntity } from "../TaskServiceEntity";
// Utilities
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import mergeWith from "lodash/mergeWith";
import { getDiff, getDiffMomentCustomizer, applyDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
/**
 * Represents a bucket entity in the client
 */ export class Bucket extends TaskServiceEntity {
    /**
     * Builder for Bucket objects
     */ static get builder() {
        return new BucketBuilder();
    }
    get entityType() {
        return TaskServiceEntityType.Bucket;
    }
    toGraphSerializable() {
        const serializableCreationSource = this.creationSource.toGraphSerializable();
        return {
            id: this.id,
            "@odata.etag": this.itemVersion,
            orderHint: this.orderHint,
            planId: this.planId,
            name: this.title,
            creationSource: isEmpty(serializableCreationSource) ? undefined : serializableCreationSource
        };
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "id" || key === "itemVersion" || key === "planId" || key === "creationSource") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    getDiff(target) {
        return getDiff(this, target, getDiffMomentCustomizer);
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(Bucket.builder.build(), this, ...diffs, applyDiffMomentCustomizer);
        }
        return this;
    }
    applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this);
        return this.applyDiffs(diffData);
    }
    getClone() {
        return this.getCloneBuilder().build();
    }
    /**
     * Return a clone of this object
     */ getCloneBuilder() {
        return Bucket.builder.forClone(this);
    }
    /**
     * Initializes a new instance of the bucket entity
     * @param bucketBuilder Builder with the initialization data
     */ constructor(bucketBuilder){
        super(bucketBuilder.id, bucketBuilder.itemVersion);
        this.orderHint = bucketBuilder.orderHint;
        this.planId = bucketBuilder.planId;
        this.title = bucketBuilder.title;
        this.creationSource = bucketBuilder.creationSource;
    }
}
/** Matches BucketLogic.cs : NameSizeLimit */ Bucket.TitleMaxLength = 256;
