/**
 * Event name for Data Pre-fetcher completion
 */ export const DataPreFetchCompletedEventName = "DataPreFetchCompleted";
/**
 * Variable name for Data Pre-fetcher result in the window object
 */ export const DataPreFetcherResultVarName = "dataPreFetcherResult";
/**
 * Variable name for Data Pre-fetcher completed status in the window object
 */ export const DataPreFetchCompletedVarName = "dataPreFetchCompleted";
export var PreFetchedDataTypes;
(function(PreFetchedDataTypes) {
    /**
     * Mru data
     */ PreFetchedDataTypes["Mru"] = "mru";
    /**
     * MyDay tasks data
     */ PreFetchedDataTypes["MyDayTasks"] = "myDayTasks";
    /**
     * ATM tasks data
     */ PreFetchedDataTypes["ATMTasks"] = "atmTasks";
    /**
     * Plan data
     */ PreFetchedDataTypes["Plan"] = "plan";
    /**
     * TeamsTasksTabs data
     */ PreFetchedDataTypes["TTTabs"] = "ttTabs";
    /**
     * Plan buckets data
     */ PreFetchedDataTypes["Buckets"] = "buckets";
    /**
     * Basic plan tasks data
     */ PreFetchedDataTypes["BasicPlanTasks"] = "basicPlanTasks";
})(PreFetchedDataTypes || (PreFetchedDataTypes = {}));
