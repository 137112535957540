// Models
import { SupportedVideoTypes } from "../SupportedVideoTypes";
export var Youtube;
(function(Youtube) {
    const videoUrlPattern = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=/;
    function tryParseVideoUrl(url, result) {
        const matches = url.match(videoUrlPattern);
        if (matches != null) {
            result.canPlay = true;
            result.embedUrl = `https://www.youtube.com/embed/${matches[1]}?autoplay=0`;
            result.provider = SupportedVideoTypes.Youtube;
            return true;
        }
        return false;
    }
    /**
     * Try to parse a url as a provider video url.
     * Returns true if parsing is a success, false otherwise
     * @param url Url to parse
     * @param result Result of the Video parsing
     */ Youtube.tryParseVideoUrl = tryParseVideoUrl;
})(Youtube || (Youtube = {}));
