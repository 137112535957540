// Models
import { SupportedVideoTypes } from "../SupportedVideoTypes";
export var Stream;
(function(Stream) {
    const videoUrlPattern = new RegExp("https://(.*).microsoftstream.com/(video/.*)");
    function tryParseVideoUrl(url, result) {
        const matches = url.match(videoUrlPattern);
        if (matches != null) {
            result.canPlay = true;
            result.embedUrl = `https://${matches[1]}.microsoftstream.com/embed/${matches[2]}?autoplay=false`;
            result.provider = SupportedVideoTypes.Stream;
            return true;
        }
        return false;
    }
    /**
     * Try to parse a url as a provider video url.
     * Returns true if parsing is a success, false otherwise
     * @param url Url to parse
     * @param result Result of the Video parsing
     */ Stream.tryParseVideoUrl = tryParseVideoUrl;
})(Stream || (Stream = {}));
