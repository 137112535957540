// Model
import { DeltaTranslationMap } from "../deltaSync/DeltaTranslationMap";
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { Bucket } from "./Bucket";
import { BucketCreationSource } from "./creationSource/BucketCreationSource";
// Utility
import clone from "lodash/clone";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Configuration and building of bucket objects
 */ export class BucketBuilder {
    /**
     * Build out the bucket
     */ build() {
        return new Bucket(this);
    }
    /**
     * Create the bucket from diff sync
     * @param update Changes from diff sync to apply
     */ newFromDifferentialUpdate(update) {
        if (update.entityType !== TaskServiceEntityType.Bucket) {
            throw new Error("ArgumentException: invalid entity");
        }
        const baseline = Bucket.builder.withPropertyBag({
            id: update.id,
            itemVersion: update.itemVersion,
            planId: "",
            title: ""
        }).build();
        const diffData = update.getCreateDiffData();
        const bucket = baseline.applyDiffs(diffData);
        return this.forClone(bucket);
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(bucket) {
        return this.withPropertyBag({
            id: bucket.id,
            itemVersion: bucket.itemVersion,
            title: bucket.title,
            planId: bucket.planId,
            orderHint: bucket.orderHint,
            creationSource: clone(bucket.creationSource) ?? new BucketCreationSource({})
        });
    }
    /**
     * Build from a graph bucket resource entity as initialization data.
     * @param bucketResource Bucket resource entity to use for initializing the bucket builder
     */ fromGraphResource(bucketResource) {
        return this.withPropertyBag({
            itemVersion: bucketResource["@odata.etag"],
            title: bucketResource.name,
            planId: bucketResource.planId || "",
            orderHint: bucketResource.orderHint,
            id: bucketResource.id,
            creationSource: bucketResource.creationSource ? new BucketCreationSource(bucketResource.creationSource) : undefined
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.id = propertyBag.id;
        this.itemVersion = propertyBag.itemVersion || this.itemVersion;
        this.orderHint = propertyBag.orderHint || this.orderHint;
        this.planId = propertyBag.planId;
        this.title = propertyBag.title;
        this.creationSource = propertyBag.creationSource || this.creationSource;
        return this;
    }
    /**
     * Add a locally unique id to the plan
     */ withLocallyUniqueId() {
        this.id = LocalIdGenerator.generate();
        return this;
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("name", "title");
        graphTranslationMap.addMapping("orderHint", "orderHint");
        graphTranslationMap.addMapping("planId", "planId");
        graphTranslationMap.addMapping("creationSource", "creationSource");
        return graphTranslationMap.translate(update);
    }
    /**
     * Initialize an instance of the bucket builder
     */ constructor(){
        this.id = LocalIdGenerator.generate();
        this.title = "";
        this.planId = LocalIdGenerator.generate();
        this.itemVersion = "";
        this.orderHint = " !";
        this.creationSource = new BucketCreationSource({});
    }
}
