// Models
import { GraphPlannerODataType } from "../../../service/graph/planner/GraphPlannerODataType";
// Utility
import forOwn from "lodash/forOwn";
import moment from "moment";
import { generateOrderHintBetween } from "@ms/uno-utilities/lib/local/OrdinalUtilities";
/**
 * Represents a task assignment
 */ export class Assignment {
    /**
     * Convert the dictionary of assignments from graph resource type to client type
     * @param id The assignment ID
     * @param value The graph resource value
     */ static getAssignmentFromGraph(value) {
        const assignedBy = value.assignedBy != null ? value.assignedBy.user != null ? value.assignedBy.user.id : null : null;
        return new Assignment(assignedBy ?? "", value.assignedDateTime, value.orderHint);
    }
    /**
     * Convert the dictionary of assignments from graph resource type to client type
     * @param assignments Assignments dictionary
     */ static getClientDictionaryTypeFromGraph(assignments) {
        const result = {};
        forOwn(assignments, (value, key)=>{
            result[key] = Assignment.getAssignmentFromGraph(value);
        });
        return result;
    }
    /**
     * Converts the Assignment dictionary to one of IGraphAssignmentResource type
     * @param assignments Assignments dictionary of client type
     */ static getGraphResourceDictionaryType(assignments) {
        const result = {};
        forOwn(assignments, (value, key)=>{
            // NOTE: We don't serialize assignedDate and assignedBy to server since these are not updatable properties
            result[key] = value.toGraphSerializable();
        });
        return result;
    }
    toGraphSerializable() {
        // NOTE: We don't serialize assignedDate and assignedBy to server since these are not updatable properties
        return {
            "@odata.type": GraphPlannerODataType.PlannerAssignment,
            orderHint: this.order
        };
    }
    setProperty(key, value) {
        const clone = new Assignment(this.assignedBy, this.assignedDate.toDate(), this.order);
        clone[key] = value;
        return clone;
    }
    /**
     * Initialize a new instance of the assignment object
     * @param [assignedBy] Id of the user who assigned the task to the assignee.
     * @param [assignedDate] Date the task was assigned to the assignee.
     * @param [order] Order of the assignee among other assignees.
     */ constructor(assignedBy, assignedDate, order){
        this.assignedBy = assignedBy;
        this.assignedDate = assignedDate != null ? moment(assignedDate) : moment();
        this.order = order || generateOrderHintBetween(null, null);
    }
}
