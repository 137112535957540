import { DataFetchWorker } from "@ms/uno-workers/lib/local/workers/dataFetch/DataFetchWorker";
/**
 * Factory class for worker providers in Base app
 */
export class BaseWorkerFactory {
    // #endregion
    /**
     * Initialize the worker factory
     * @param acProviders Action creator providers
     * @param storeProviders Store providers
     * @param loader Component loader
     * @param loggers Loggers for telemetry
     * @param configProvider Config provider
     */
    constructor(acProviders, storeProviders, loader, loggers, configProvider) {
        this.acProviders = acProviders;
        this.storeProviders = storeProviders;
        this.loader = loader;
        this.loggers = loggers;
        this.configProvider = configProvider;
        this.dataFetchWorker = new DataFetchWorker(acProviders, storeProviders, loggers, configProvider);
    }
    getAugloopTileSyncWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("auglooptilesync"), () => import(
        /* webpackChunkName: "uno-auglooptilesyncworker" */ "@ms/uno-workers/lib/local/workers/augloop/AugloopTileSyncWorker"), (bundle) => new bundle.AugloopTileSyncWorker(this.acProviders, this.storeProviders, this.configProvider));
    }
    getBasicPlanConversionTrackerWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("basicplanconversiontracker"), () => import(
        /* webpackChunkName: "uno-basicplanconversiontrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/BasicPlanConversionTrackerWorker"), (bundle) => new bundle.BasicPlanConversionTrackerWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
    }
    getCdsProvisioningStatusWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("cdsProvisioningStatus"), () => import(
        /* webpackChunkName: "uno-cdsProvisioningStatusWorker" */ "@ms/uno-workers/lib/local/workers/plan/CdsProvisioningStatusWorker"), (bundle) => new bundle.CdsProvisioningStatusWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
    }
    getDeltaSyncWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("deltasync"), () => Promise.all([
            import(/* webpackChunkName: "uno-deltasyncworker" */ "@ms/uno-workers/lib/local/workers/deltaSync/DeltaSyncWorker"),
            import(
            /* webpackChunkName: "uno-exponentialbackoffutility" */ "@ms/uno-workers/lib/local/utilities/backoff/ExponentialBackoffUtility"),
            import(
            /* webpackChunkName: "uno-pagevisibilitystatusprovider" */ "@ms/uno-workers/lib/local/utilities/pageVisibility/PageVisibilityStatusProvider"),
        ]), ([worker, backoffUtility, pageVisibilityStatusProvider]) => {
            const deltaSyncConfiguration = this.configProvider().settings.deltaSyncConfiguration;
            return new worker.DeltaSyncWorker(this.acProviders, this.storeProviders, this.configProvider, new backoffUtility.ExponentialBackoffUtility(deltaSyncConfiguration.baseInterval, deltaSyncConfiguration.cutoff, deltaSyncConfiguration.backoffThreshold), new pageVisibilityStatusProvider.PageVisibilityStatusProvider(), this.loggers);
        });
    }
    getDeltaSyncFeedProcessorWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("deltasyncfeedprocessor"), () => import(
        /* webpackChunkName: "uno-deltasyncfeedprocessorworker" */ "@ms/uno-workers/lib/local/workers/deltaSync/DeltaSyncFeedProcessorWorker"), (bundle) => new bundle.DeltaSyncFeedProcessorWorker(this.acProviders, this.storeProviders));
    }
    getHistoryObserverWorkerProvider(routePathPrefix, browserPathProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("historyobserver"), () => import(
        /* webpackChunkName: "uno-historyobserverWorker" */ "@ms/uno-workers/lib/local/workers/history/HistoryObserverWorker"), (bundle) => new bundle.HistoryObserverWorker(this.acProviders, this.storeProviders, routePathPrefix, browserPathProvider));
    }
    getNavigationWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("nav"), () => import(/* webpackChunkName: "uno-navworker" */ "@ms/uno-workers/lib/local/workers/plan/NavigationWorker"), (bundle) => new bundle.NavigationWorker(this.acProviders, this.storeProviders, this.loggers));
    }
    getNetworkConnectivityWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("networkconnectivity"), () => import(
        /* webpackChunkName: "uno-networkconnectivityworker" */ "@ms/uno-workers/lib/local/workers/network/NetworkConnectivityWorker"), (bundle) => new bundle.NetworkConnectivityWorker(this.loggers.logHandler));
    }
    getOperationTrackingWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("operationtracking"), () => import(
        /* webpackChunkName: "uno-operationtrackingworker" */ "@ms/uno-workers/lib/local/workers/operation/OperationTrackingWorker"), (bundle) => new bundle.OperationTrackingWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
    }
    getPerformanceObserverWorkerProvider(telemetryLogger) {
        return () => this.loader.load(this.getWorkerBundleKey("performanceobserver"), () => import(
        /* webpackChunkName: "uno-performanceobserverworker" */ "@ms/uno-workers/lib/local/workers/performance/WebPlatformPerformanceObserverWorker"), (bundle) => new bundle.WebPlatformPerformanceObserverWorker(telemetryLogger, this.configProvider));
    }
    getPlanConversionStatusTrackerWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("planconversionstatustracker"), () => import(
        /* webpackChunkName: "uno-planconversionstatustrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/PlanConversionStatusTrackerWorker"), (bundle) => new bundle.PlanConversionStatusTrackerWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
    }
    getPremiumPlanCreationStatusWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("premiumplancreationstatus"), () => import(
        /* webpackChunkName: "uno-premiumplancreationstatusworker" */ "@ms/uno-workers/lib/local/workers/plan/PremiumPlanCreationStatusWorker"), (bundle) => new bundle.PremiumPlanCreationStatusWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
    }
    getProjectTrialOperationTrackerWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("projecttrialoperationtracker"), () => import(
        /* webpackChunkName: "uno-projecttrialoperationtrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/ProjectTrialOperationTrackerWorker"), (bundle) => new bundle.ProjectTrialOperationTrackerWorker(this.acProviders, this.storeProviders));
    }
    getRecommendedPlanUpdateWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("recommendedplanupdate"), () => import(
        /* webpackChunkName: "uno-recommendedplanupdateworker" */ "@ms/uno-workers/lib/local/workers/plan/RecommendedPlansUpdateWorker"), (bundle) => new bundle.RecommendedPlansUpdateWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
    }
    getDataFetchWorkerProvider() {
        return () => this.dataFetchWorker;
    }
    getStoreHydrationWorkerProvider(telemetryLoggers) {
        return () => this.loader.load(this.getWorkerBundleKey("storeHydration"), () => import(
        /* webpackChunkName: "uno-storeHydrationWorker" */ "@ms/uno-workers/lib/local/workers/cache/StoreHydrationWorker"), (bundle) => new bundle.StoreHydrationWorker(this.storeProviders, this.configProvider, telemetryLoggers));
    }
    /**
     * Get the worker bundle key for the given worker name
     * @param workerName
     */
    getWorkerBundleKey(workerName) {
        return `uno-${workerName}worker`;
    }
}
