// Errors
import { ArgumentNullError } from "@ms/uno-errors/lib/local/errors";
import { RecurrenceCalculator } from "./RecurrenceCalculator";
// Utilities
import flatten from "lodash/flatten";
import { getDaysOrDates } from "../utilities/DayOrDateUtilities";
/**
 * Represents a recurrence calculator with multiple day or date objects.
 * @example Fourth Thursday of November.
 * @example Every Monday and Thursday of every other week.
 */ export class MultipleDaysOrDatesRecurrenceCalculator extends RecurrenceCalculator {
    calculateOccurrencesBetween(start, end) {
        return flatten(this.getPeriodsBetween(this.offsetStart(this.normalizeDate(start)), this.offsetEnd(this.normalizeDate(end))).map((period)=>this.daysOrDates.map((dayOrDate)=>dayOrDate.getDateForPeriod(period)))).filter((period)=>period.isSameOrAfter(this.patternStartDate) && period.isSameOrBefore(end) && this.getDurationBetweenDateAndPatternStartDate(period) % this.interval === 0); // Remove any period days on or before the pattern start date, after the end, and periods that do not fall on the interval
    }
    calculatePatternStartDate() {
        // First run for the current date as the next pattern date could still be in this period
        let result = this.daysOrDates.map((dayOrDate)=>dayOrDate.getDateForPeriod(this.patternStartDate)).filter((period)=>period.isSameOrAfter(this.patternStartDate)) // Including the pattern start date
        .sort((a, b)=>a < b ? -1 : 1); // Sorting to get the dates in order. This is because dayOrDates is not guaranteed to be in order.
        if (result.length > 0) {
            return result[0];
        }
        // Run for the one period later. This is because we want the next pattern date not the next occurrence date
        const nextStartPeriod = this.patternStartDate.clone().add(1, this.periodPattern);
        result = this.daysOrDates.map((dayOrDate)=>dayOrDate.getDateForPeriod(nextStartPeriod)).sort((a, b)=>a < b ? -1 : 1);
        return result[0];
    }
    calculateNextOccurrenceDate() {
        // First run for the current date as the next pattern date could still be in this period
        let result = this.daysOrDates.map((dayOrDate)=>dayOrDate.getDateForPeriod(this.patternStartDate)).filter((period)=>period.isAfter(this.patternStartDate)) // Excluding the pattern start date
        .sort((a, b)=>a < b ? -1 : 1); // Sorting to get the dates in order. This is because dayOrDates is not guaranteed to be in order.
        if (result.length > 0) {
            return result[0];
        }
        // Run for the one period later. This is because we want the next pattern date not the next occurrence date
        const nextStartPeriod = this.patternStartDate.clone().add(this.interval, this.periodPattern);
        result = this.daysOrDates.map((dayOrDate)=>dayOrDate.getDateForPeriod(nextStartPeriod)).sort((a, b)=>a < b ? -1 : 1);
        return result[0];
    }
    offsetStart(start) {
        let startOffset = start.clone();
        /** OPTIMIZATION BELOW: Remove all days before pattern start date */ if (start.isSameOrBefore(this.patternStartDate)) {
            startOffset = this.patternStartDate.clone();
        }
        return startOffset;
    }
    /**
     * Offset the end based on the recurrence pattern and due date.
     * @param end End date to offset.
     * @returns The offset end date for the window.
     */ offsetEnd(end) {
        return end.clone();
    }
    getDurationBetweenDateAndPatternStartDate(date) {
        // Set to beginning of month and return number of whole months between
        // This is because the built in moment diff function checks total time between two dates.
        // If D1 is 5/1/2020 and D2 is 4/3/2020 then the result for number of months is 0.
        return date.clone().date(1).diff(this.patternStartDate.clone().date(1), this.periodPattern);
    }
    constructor(pattern, patternStartDate, diffPattern){
        super(pattern.interval, patternStartDate, diffPattern);
        const daysOrDates = getDaysOrDates(pattern);
        if (daysOrDates == null) {
            throw new ArgumentNullError("Invalid recurrence pattern");
        }
        this.daysOrDates = daysOrDates;
    }
}
