// Models
/**
 * External Source info for a plan
 */ export class ExternalPlanSource {
    /**
     * Creates a new external creation source instance from a given service resource
     * @param resource The service model
     */ static fromResource(resource) {
        return new ExternalPlanSource(resource.contextScenarioId, resource.externalContextId, resource.externalObjectId, resource.ownerAppId);
    }
    toGraphSerializable() {
        return {
            contextScenarioId: this.contextScenarioId,
            externalContextId: this.externalContextId,
            externalObjectId: this.externalObjectId,
            ownerAppId: this.ownerAppId
        };
    }
    /**
     * Create a new instance of an ExternalPlanSource
     * @param contextScenarioId An identifier for the scenario associated with this externally sourced plan Ex com.microsoft.project
     * @param externalContextId The id of the containing entity of the external plan entity. Ex myWorkspaceId
     * @param externalObjectId The id of the entity that an external service associates with a plan. Ex myProjectId
     * @param ownerAppId The Id of the app where the plan was created.
     */ constructor(contextScenarioId, externalContextId, externalObjectId, ownerAppId){
        this.externalObjectId = externalObjectId;
        this.externalContextId = externalContextId;
        this.contextScenarioId = contextScenarioId;
        this.ownerAppId = ownerAppId;
    }
}
