/** These are strings that will be sent to ODIN for GDPR export. All export strings should be PM approved. */ export var ExportStrings;
(function(ExportStrings) {
    ExportStrings["CreatePortfolio"] = "Created a portfolio";
    ExportStrings["CreatePremiumPlan"] = "Created a premium plan";
    ExportStrings["ReadPremiumPlan"] = "Opened a premium plan";
    ExportStrings["UpdatePremiumPlan"] = "Updated a premium plan";
    ExportStrings["UpdatePortfolio"] = "Updated a portfolio";
    ExportStrings["PlanExport"] = "Exported to Excel";
    ExportStrings["MruItemDeleted"] = "Removed an item from recent list";
    ExportStrings["MruItemUpdated"] = "Updated an item in the recents list";
    ExportStrings["MruItemCreated"] = "Created an item in the recents list";
    ExportStrings["MruItemsFetched"] = "Fetched the recents list";
    ExportStrings["ResourceItemCreated"] = "Added resource to a plan";
    ExportStrings["ResourceItemDeleted"] = "Deleted resource from a plan";
})(ExportStrings || (ExportStrings = {}));
