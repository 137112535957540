/**
 * Date string constants for moment library
 */ export var MomentConstants;
(function(MomentConstants) {
    MomentConstants["Second"] = "second";
    MomentConstants["Minute"] = "minute";
    MomentConstants["Hour"] = "hour";
    MomentConstants["Day"] = "day";
    MomentConstants["Week"] = "week";
    MomentConstants["Month"] = "month";
    MomentConstants["Year"] = "year";
})(MomentConstants || (MomentConstants = {}));
export var DueDateCategory;
(function(DueDateCategory) {
    /** Late */ DueDateCategory[DueDateCategory["Late"] = 0] = "Late";
    /** Due today */ DueDateCategory[DueDateCategory["Today"] = 1] = "Today";
    /* Due Tomorrow */ DueDateCategory[DueDateCategory["Tomorrow"] = 2] = "Tomorrow";
    /** Due in the current calendar week */ DueDateCategory[DueDateCategory["ThisWeek"] = 3] = "ThisWeek";
    /** Due in the next calendar week */ DueDateCategory[DueDateCategory["NextWeek"] = 4] = "NextWeek";
    /** Due later than the next calendar week */ DueDateCategory[DueDateCategory["Future"] = 5] = "Future";
    /** No due date set */ DueDateCategory[DueDateCategory["NoDate"] = 6] = "NoDate";
    /** Some due date  */ DueDateCategory[DueDateCategory["SomeDate"] = 7] = "SomeDate";
})(DueDateCategory || (DueDateCategory = {}));
