/*
 * Recognized File extensions
 */ export const FileTypeExtensions = {
    Word: [
        "docx",
        "docm",
        "doc",
        "dotx",
        "dotm"
    ],
    Excel: [
        "xlsx",
        "xlsm",
        "xlsb",
        "xltx",
        "xltm",
        "xls",
        "xlam",
        "xla",
        "xlw",
        "xml"
    ],
    PowerPoint: [
        "pptx",
        "pptm",
        "ppt",
        "potx",
        "potm",
        "pot",
        "thmx",
        "pps",
        "ppsx",
        "ppsm",
        "ppsam",
        "ppa"
    ],
    OneNote: [
        "one"
    ],
    Project: [
        "mpp",
        "mpt",
        "mpd"
    ],
    Visio: [
        "vsdx",
        "vsd",
        "vsdm",
        "vssx",
        "vssm",
        "vstx",
        "vstm"
    ],
    Pdf: [
        "pdf"
    ],
    Loop: [
        "loop",
        "fluid"
    ]
};
