import { createTrustedHtml } from "./TrustedTypeUtilities";
/**
 * This api extracts the content from the HTML to display data. This preserves newlines on certain HTML tags.
 * @param htmlString description html field from task editor
 * @returns text string
 */ export function extractContentfromHtml(htmlString) {
    if (!htmlString) {
        return "";
    }
    const trustedHtmlString = createTrustedHtml(htmlString);
    const doc = new DOMParser().parseFromString(trustedHtmlString, "text/html");
    return htmlToPlainTextWithNewLines(doc.body).trim()// Replaces multiple newlines with a single newline.
    // Parsing the HTML tags may result in more newlines than expected.
    // This also collapses multiple intentional newlines into one.
    .replace(/\n{2,}/g, "\n");
}
/** Nodes that correspond to a newline in plain text */ const newlineTags = [
    "BR",
    "LI",
    "P",
    "DIV"
];
/**
 * Extracts text from a HTML node, preserving newlines on certain HTML tags.
 * Recursively calls itself on child nodes.
 * @param node HTML node
 * @returns text string
 */ function htmlToPlainTextWithNewLines(node) {
    if (node == null) {
        return "";
    }
    if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent ?? "";
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = node;
        let text = "";
        if (newlineTags.includes(elementNode.tagName)) {
            text += "\n";
        }
        for (const childNode of elementNode.childNodes){
            text += htmlToPlainTextWithNewLines(childNode);
        }
        return text;
    }
    return "";
}
// Constants
/**
 * This is the limit of characters to check if a note has empty content.
 * If the note is longer than this limit, we assume it has non-empty content.
 */ const NoteEmptyCheckParsingLimit = 2500;
/**
 * Pattern to match html comments in the note content.
 * Todo service populates the null note filed with dummy content which also contains html comments.
 * We need to ignore these comments when checking for empty content.
 */ const HtmlCommentPattern = /<!--[\s\S]*?-->/g;
/**
 * Check if the html string has empty content
 * @param htmlString The html string to check
 * @returns True if the html string has non-whitespace content or the length is greater than 2500 characters,
 * assuming that there would be non-empty content in the html string if it's long enough, false otherwise
 */ export function hasNonWhitespaceContent(htmlString) {
    if (!htmlString) {
        return false;
    }
    // We trade away correctness in favor of performance. We dont expect there to be many notes which are very large (>2500) but all whitespace.
    if (htmlString.length > NoteEmptyCheckParsingLimit) {
        return true;
    }
    const tempDiv = document.createElement("div");
    // eslint-disable-next-line @microsoft/sdl/no-inner-html
    tempDiv.innerHTML = createTrustedHtml(htmlString);
    const nonCommentContent = tempDiv.textContent?.replace(HtmlCommentPattern, "").trim();
    return /\S/.test(nonCommentContent ?? "");
}
