/**
 * The recurrence pattern type
 */ export var RecurrencePatternType;
(function(RecurrencePatternType) {
    RecurrencePatternType["Daily"] = "daily";
    RecurrencePatternType["Weekly"] = "weekly";
    RecurrencePatternType["AbsoluteMonthly"] = "absoluteMonthly";
    RecurrencePatternType["RelativeMonthly"] = "relativeMonthly";
    RecurrencePatternType["AbsoluteYearly"] = "absoluteYearly";
    RecurrencePatternType["RelativeYearly"] = "relativeYearly";
})(RecurrencePatternType || (RecurrencePatternType = {}));
