/**
 * Enum of the different kinds of completion requirements for a task.
 */ export var SpecifiedCompletionRequirements;
(function(SpecifiedCompletionRequirements) {
    /** Task has no completion requirements */ SpecifiedCompletionRequirements[SpecifiedCompletionRequirements["None"] = 0] = "None";
    /** Task has checklist completion requirements */ SpecifiedCompletionRequirements[SpecifiedCompletionRequirements["ChecklistItemsCompletion"] = 1] = "ChecklistItemsCompletion";
    /** Task has form completion requirements */ SpecifiedCompletionRequirements[SpecifiedCompletionRequirements["FormCompletion"] = 2] = "FormCompletion";
    /** Task has approval completion requirements */ SpecifiedCompletionRequirements[SpecifiedCompletionRequirements["ApprovalCompletion"] = 4] = "ApprovalCompletion";
    /** Task is App Powered Task which can be completed in the dedicated app only */ SpecifiedCompletionRequirements[SpecifiedCompletionRequirements["CompletionInHostedApp"] = 8] = "CompletionInHostedApp";
})(SpecifiedCompletionRequirements || (SpecifiedCompletionRequirements = {}));
