/** Enumeration of all action types */ export var ActionType;
(function(ActionType) {
    /** Activity */ ActionType["UpdateUserActivityTimestamp"] = "UNO_UPDATE_USERACTIVITYTIMESTAMP";
    /** App */ ActionType["DynamicImportFailure"] = "UNO_DYNAMIC_IMPORT_FAILURE";
    /** App context */ ActionType["FetchAppContextSucceeded"] = "UNO_FETCH_APPCONTEXT_SUCCEEDED";
    ActionType["FetchUserExtraFieldsAction"] = "UNO_FETCH_USER_EXTRA_FIELDS";
    ActionType["FetchUserExtraFieldsSucceededAction"] = "UNO_FETCH_USER_EXTRA_FIELDS_SUCCEEDED";
    ActionType["FetchUserExtraFieldsFailedAction"] = "UNO_FETCH_USER_EXTRA_FIELDS_FAILED";
    ActionType["FetchTodoSettingsAction"] = "UNO_FETCH_SETTINGS";
    ActionType["FetchTodoSettingsSucceededAction"] = "UNO_FETCH_SETTINGS_SUCCEEDED";
    ActionType["FetchTodoSettingsFailedAction"] = "UNO_FETCH_SETTINGS_FAILED";
    ActionType["SetDefaultPlanAction"] = "UNO_SET_DEFAULT_PLAN_PROPERTY";
    ActionType["SetFlaggedEmailPlanAction"] = "UNO_SET_FLAGGED_EMAIL_PROPERTY";
    ActionType["UpdateAppThemeAction"] = "UNO_UPDATE_APP_THEME";
    /** Auth */ ActionType["FetchAccessToken"] = "UNO_FETCH_ACCESS_TOKEN";
    ActionType["FetchAccessTokenSucceeded"] = "UNO_FETCH_ACCESS_TOKEN_SUCCEEDED";
    ActionType["FetchAccessTokenFailed"] = "UNO_FETCH_ACCESS_TOKEN_FAILED";
    ActionType["ClearAccessToken"] = "UNO_CLEAR_ACCESS_TOKEN";
    /** Basic Buckets */ ActionType["CreateBasicBucketAction"] = "UNO_CREATE_BASIC_BUCKET";
    ActionType["CreateBasicBucketFailedAction"] = "UNO_CREATE_BASIC_BUCKET_FAILED";
    ActionType["CreateBasicBucketSucceededAction"] = "UNO_CREATE_BASIC_BUCKET_SUCCEEDED";
    ActionType["DeltaSyncCreateBasicBucketAction"] = "UNO_DELTASYNC_CREATE_BASIC_BUCKET";
    ActionType["DeleteBasicBucketAction"] = "UNO_DELETE_BASIC_BUCKET";
    ActionType["DeleteBasicBucketFailedAction"] = "UNO_DELETE_BASIC_BUCKET_FAILED";
    ActionType["DeleteBasicBucketSucceededAction"] = "UNO_DELETE_BASIC_BUCKET_SUCCEEDED";
    ActionType["DeltaSyncDeleteBasicBucketAction"] = "UNO_DELTASYNC_DELETE_BASIC_BUCKET";
    ActionType["FetchBasicBucketAction"] = "UNO_FETCH_BASIC_BUCKET";
    ActionType["FetchBasicBucketFailedAction"] = "UNO_FETCH_BASIC_BUCKET_FAILED";
    ActionType["FetchBasicBucketSucceededAction"] = "UNO_FETCH_BASIC_BUCKET_SUCCEEDED";
    ActionType["FetchBasicBucketsForPlanAction"] = "UNO_FETCH_BASIC_BUCKETS_FOR_PLAN";
    ActionType["FetchBasicBucketsForPlanFailedAction"] = "UNO_FETCH_BASIC_BUCKETS_FOR_PLAN_FAILED";
    ActionType["FetchBasicBucketsForPlanSucceededAction"] = "UNO_FETCH_BASIC_BUCKETS_FOR_PLAN_SUCCEEDED";
    ActionType["UpdateBasicBucketAction"] = "UNO_UPDATE_BASIC_BUCKET";
    ActionType["UpdateBasicBucketFailedAction"] = "UNO_UPDATE_BASIC_BUCKET_FAILED";
    ActionType["UpdateBasicBucketSucceededAction"] = "UNO_UPDATE_BASIC_BUCKET_SUCCEEDED";
    ActionType["DeltaSyncUpdateBasicBucketAction"] = "UNO_DELTASYNC_UPDATE_BASIC_BUCKET";
    /** DeltaSync */ ActionType["BasicDeltaSyncFetchScheduledAction"] = "UNO_DELTASYNC_FETCH_SCHEDULED";
    ActionType["FetchBasicDeltaSyncFeedAction"] = "UNO_FETCH_DELTASYNC_FEED";
    ActionType["FetchBasicDeltaSyncFeedFailedAction"] = "UNO_FETCH_DELTASYNC_FEED_FAILED";
    ActionType["FetchBasicDeltaSyncFeedSucceededAction"] = "UNO_FETCH_DELTASYNC_FEED_SUCCEEDED";
    ActionType["FetchBasicDeltaSyncTokenAction"] = "UNO_FETCH_DELTASYNC_TOKEN";
    ActionType["FetchBasicDeltaSyncTokenFailedAction"] = "UNO_FETCH_DELTASYNC_TOKEN_FAILED";
    ActionType["FetchBasicDeltaSyncTokenSucceededAction"] = "UNO_FETCH_DELTASYNC_TOKEN_SUCCEEDED";
    ActionType["DisableBasicDeltaSyncAction"] = "UNO_DISABLE_DELTASYNC";
    ActionType["EnableBasicDeltaSyncAction"] = "UNO_ENABLE_DELTASYNC";
    /** Basic Plan */ ActionType["CreateBasicPlanAction"] = "UNO_CREATE_BASIC_PLAN";
    ActionType["CreateBasicPlanSucceededAction"] = "UNO_CREATE_BASIC_PLAN_SUCCEEDED";
    ActionType["CreateBasicPlanFailedAction"] = "UNO_CREATE_BASIC_PLAN_FAILED";
    ActionType["DeleteBasicPlanAction"] = "UNO_DELETE_BASIC_PLAN";
    ActionType["DeleteBasicPlanFailedAction"] = "UNO_DELETE_BASIC_PLAN_FAILED";
    ActionType["DeleteBasicPlanSucceededAction"] = "UNO_DELETE_BASIC_PLAN_SUCCEEDED";
    ActionType["DeltaSyncDeleteBasicPlanAction"] = "UNO_DELTASYNC_DELETE_BASIC_PLAN";
    ActionType["FetchBasicPlanAction"] = "UNO_FETCH_BASIC_PLAN";
    ActionType["FetchBasicPlanFailedAction"] = "UNO_FETCH_BASIC_PLAN_FAILED";
    ActionType["FetchBasicPlanSucceededAction"] = "UNO_FETCH_BASIC_PLAN_SUCCEEDED";
    ActionType["FetchBasicPlansForContainerAction"] = "UNO_FETCH_BASIC_PLANS_FOR_CONTAINER";
    ActionType["FetchBasicPlansForContainerFailedAction"] = "UNO_FETCH_BASIC_PLANS_FOR_CONTAINER_FAILED";
    ActionType["FetchBasicPlansForContainerSucceededAction"] = "UNO_FETCH_BASIC_PLANS_FOR_CONTAINER_SUCCEEDED";
    ActionType["FetchAllBasicPlansForContainerTypeAction"] = "UNO_FETCH_ALL_BASIC_PLANS_FOR_CONTAINER_TYPE";
    ActionType["FetchAllBasicPlansForContainerTypeFailedAction"] = "UNO_FETCH_ALL_BASIC_PLANS_FOR_CONTAINER_TYPE_FAILED";
    ActionType["FetchAllBasicPlansForContainerTypeSucceededAction"] = "UNO_FETCH_ALL_BASIC_PLANS_FOR_CONTAINER_TYPE_SUCCEEDED";
    ActionType["FetchPlansForChannelGroupIdAction"] = "UNO_FETCH_PLANS_FOR_CHANNEL_GROUP_ID";
    ActionType["FetchPlansForChannelGroupIdFailedAction"] = "UNO_FETCH_PLANS_FOR_CHANNEL_GROUP_ID_FAILED";
    ActionType["FetchPlansForChannelGroupIdSucceededAction"] = "UNO_FETCH_PLANS_FOR_CHANNEL_GROUP_ID_SUCCEEDED";
    ActionType["UpdateBasicPlanAction"] = "UNO_UPDATE_BASIC_PLAN";
    ActionType["UpdateBasicPlanFailedAction"] = "UNO_UPDATE_BASIC_PLAN_FAILED";
    ActionType["UpdateBasicPlanSucceededAction"] = "UNO_UPDATE_BASIC_PLAN_SUCCEEDED";
    ActionType["DeltaSyncUpdateBasicPlanAction"] = "UNO_DELTASYNC_UPDATE_BASIC_PLAN";
    ActionType["MoveBasicPlanOperationCompletedWithErrorsAction"] = "UNO_UPDATE_BASIC_PLAN_MOVE_STATUS_FAILED";
    ActionType["MoveBasicPlanOperationCompletedAction"] = "UNO_UPDATE_BASIC_PLAN_MOVE_STATUS_SUCCEEDED";
    ActionType["MoveBasicPlanOperationAction"] = "UNO_UPDATE_BASIC_PLAN_MOVE_STATUS";
    ActionType["CopyPlanAction"] = "UNO_COPY_PLAN";
    ActionType["CopyPlanFailedAction"] = "UNO_COPY_PLAN_FAILED";
    ActionType["CopyPlanSucceededAction"] = "UNO_COPY_PLAN_SUCCEEDED";
    ActionType["FetchCopyPlanOperationDescriptorAction"] = "UNO_FETCH_COPY_PLAN_OPERATION_DESCRIPTOR";
    ActionType["FetchCopyPlanOperationDescriptorFailedAction"] = "UNO_FETCH_COPY_PLAN_OPERATION_DESCRIPTOR_FAILED";
    ActionType["FetchCopyPlanOperationDescriptorSucceededAction"] = "UNO_FETCH_COPY_PLAN_OPERATION_DESCRIPTOR_SUCCEEDED";
    /** Basic Tasks */ ActionType["CreateBasicTaskAction"] = "UNO_CREATE_BASIC_TASK";
    ActionType["CreateBasicTaskFailedAction"] = "UNO_CREATE_BASIC_TASK_FAILED";
    ActionType["CreateBasicTaskSucceededAction"] = "UNO_CREATE_BASIC_TASK_SUCCEEDED";
    ActionType["DeltaSyncCreateBasicTaskAction"] = "UNO_DELTASYNC_CREATE_BASIC_TASK";
    ActionType["DeleteBasicTaskAction"] = "UNO_DELETE_BASIC_TASK";
    ActionType["DeleteBasicTaskFailedAction"] = "UNO_DELETE_BASIC_TASK_FAILED";
    ActionType["DeleteBasicTaskSucceededAction"] = "UNO_DELETE_BASIC_TASK_SUCCEEDED";
    ActionType["DeltaSyncDeleteBasicTaskAction"] = "UNO_DELTASYNC_DELETE_BASIC_TASK";
    ActionType["FetchBasicTaskAction"] = "UNO_FETCH_BASIC_TASK";
    ActionType["FetchBasicTaskFailedAction"] = "UNO_FETCH_BASIC_TASK_FAILED";
    ActionType["FetchBasicTaskSucceededAction"] = "UNO_FETCH_BASIC_TASK_SUCCEEDED";
    ActionType["FetchBasicTasksForPlanAction"] = "UNO_FETCH_BASIC_TASKS_FOR_PLAN";
    ActionType["FetchBasicTasksForPlanFailedAction"] = "UNO_FETCH_BASIC_TASKS_FOR_PLAN_FAILED";
    ActionType["FetchBasicTasksForPlanSucceededAction"] = "UNO_FETCH_BASIC_TASKS_FOR_PLAN_SUCCEEDED";
    ActionType["FetchBasicTasksForTodoPlanFinalizedAction"] = "UNO_FETCH_BASIC_TASKS_FOR_TODO_PLAN_FINALIZED";
    ActionType["FetchBasicTasksForAssigneeAction"] = "UNO_FETCH_BASIC_TASKS_FOR_ASSIGNEE";
    ActionType["FetchBasicTasksForAssigneeFailedAction"] = "UNO_FETCH_BASIC_TASKS_FOR_ASSIGNEE_FAILED";
    ActionType["FetchBasicTasksForAssigneeSucceededAction"] = "UNO_FETCH_BASIC_TASKS_FOR_ASSIGNEE_SUCCEEDED";
    ActionType["FetchBasicTasksForMyDayAction"] = "UNO_FETCH_BASIC_TASKS_FOR_MY_DAY";
    ActionType["FetchBasicTasksForMyDaySucceededAction"] = "UNO_FETCH_BASIC_TASKS_FOR_MY_DAY_SUCCEEDED";
    ActionType["FetchBasicTasksForMyDayFailedAction"] = "UNO_FETCH_BASIC_TASKS_FOR_MY_DAY_FAILED";
    ActionType["UpdateBasicTaskAction"] = "UNO_UPDATE_BASIC_TASK";
    ActionType["UpdateBasicTaskFailedAction"] = "UNO_UPDATE_BASIC_TASK_FAILED";
    ActionType["UpdateBasicTaskSucceededAction"] = "UNO_UPDATE_BASIC_TASK_SUCCEEDED";
    ActionType["DeltaSyncUpdateBasicTaskAction"] = "UNO_DELTASYNC_UPDATE_BASIC_TASK";
    ActionType["UploadAttachmentForTaskAction"] = "UNO_UPLOAD_ATTACHMENT_FOR_TASK";
    ActionType["UploadAttachmentForTaskFailedAction"] = "UNO_UPLOAD_ATTACHMENT_FOR_TASK_FAILED";
    ActionType["UploadAttachmentForTaskSucceededAction"] = "UNO_UPLOAD_ATTACHMENT_FOR_TASK_SUCCEEDED";
    ActionType["ReorderBasicTasksAction"] = "UNO_REORDER_BASIC_TASKS";
    ActionType["ReorderBasicTasksFailedAction"] = "UNO_REORDER_BASIC_TASKS_FAILED";
    ActionType["ReorderBasicTasksSucceededAction"] = "UNO_REORDER_BASIC_TASKS_SUCCEEDED";
    ActionType["FetchApprovalDetailsAction"] = "UNO_FETCH_APPROVAL_DETAILS";
    ActionType["FetchApprovalDetailsSucceededAction"] = "UNO_FETCH_APPROVAL_DETAILS_SUCCEEDED";
    ActionType["FetchApprovalDetailsFailedAction"] = "UNO_FETCH_APPROVAL_DETAILS_FAILED";
    ActionType["CancelApprovalDetailsAction"] = "UNO_CANCEL_APPROVAL_DETAILS";
    ActionType["CancelApprovalDetailsFailedAction"] = "UNO_CANCEL_APPROVAL_DETAILS_FAILED";
    ActionType["UpdateApprovalDetailsAction"] = "UNO_UPDATE_APPROVAL_DETAILS";
    ActionType["UpdateApprovalDetailsFailedAction"] = "UNO_UPDATE_APPROVAL_DETAILS_FAILED";
    ActionType["CreateApprovalDetailsAction"] = "UNO_CREATE_APPROVAL_DETAILS";
    ActionType["CreateApprovalDetailsFailedAction"] = "UNO_CREATE_APPROVAL_DETAILS_FAILED";
    /** Campaign */ ActionType["FetchCampaignCreatives"] = "UNO_FETCH_CAMPAIGN_CREATIVES";
    ActionType["FetchCampaignCreativesFailed"] = "UNO_FETCH_CAMPAIGN_CREATIVES_FAILED";
    ActionType["FetchCampaignCreativesSucceeded"] = "UNO_FETCH_CAMPAIGN_CREATIVES_SUCCEEDED";
    ActionType["SendCampaignCreativeImpressionBeacon"] = "UNO_SEND_CAMPAIGN_CREATIVE_IMPRESSION_BEACON";
    ActionType["SendCampaignCreativeImpressionBeaconFailed"] = "UNO_SEND_CAMPAIGN_CREATIVE_IMPRESSION_BEACON_FAILED";
    ActionType["SendCampaignCreativeImpressionBeaconSucceeded"] = "UNO_SEND_CAMPAIGN_CREATIVE_IMPRESSION_BEACON_SUCCEEDED";
    ActionType["DismissActiveCampaignCreative"] = "UNO_DISMISS_ACTIVE_CAMPAIGN_CREATIVE";
    ActionType["ShowNextActiveCampaignCreativeStep"] = "UNO_SHOW_NEXT_ACTIVE_CAMPAIGN_CREATIVE_STEP";
    ActionType["CampaignTriggerAction"] = "UNO_CAMPAIGN_TRIGGER";
    /** Capabilities */ ActionType["FetchTodoCapabilities"] = "UNO_FETCH_TODO_CAPABILITIES";
    ActionType["FetchTodoCapabilitiesSucceeded"] = "UNO_FETCH_TODO_CAPABILITIES_SUCCEEDED";
    ActionType["FetchTodoCapabilitiesFailed"] = "UNO_FETCH_TODO_CAPABILITIES_FAILED";
    ActionType["FetchUserFeatureEntitlementsAction"] = "UNO_FETCH_USER_FEATURE_ENTITLEMENTS";
    ActionType["FetchUserFeatureEntitlementsSucceededAction"] = "UNO_FETCH_USER_FEATURE_ENTITLEMENTS_SUCCEEDED";
    ActionType["FetchUserFeatureEntitlementsFailedAction"] = "UNO_FETCH_USER_FEATURE_ENTITLEMENTS_FAILED";
    ActionType["FetchUserPublishingEligibilitySucceededAction"] = "UNO_FETCH_USER_PUBLISHING_ELIGIBILITY_SUCCEEDED";
    ActionType["FetchUserPublishingEligibilityFailedAction"] = "UNO_FETCH_USER_PUBLISHING_ELIGIBILITY_FAILED";
    ActionType["FetchIsPlannerPremiumEnabledSucceededAction"] = "UNO_FETCH_IS_PLANNER_PREMIUM_ENABLED_SUCCEEDED";
    ActionType["FetchIsPlannerPremiumEnabledFailedAction"] = "UNO_FETCH_IS_PLANNER_PREMIUM_ENABLED_FAILED";
    ActionType["FetchIsPortfolioEnabledSucceededAction"] = "UNO_FETCH_IS_PORTFOLIO_ENABLED_SUCCEEDED";
    ActionType["FetchIsPortfolioEnabledFailedAction"] = "UNO_FETCH_IS_PORTFOLIO_ENABLED_FAILED";
    ActionType["ProvisionPlannerPremiumFailedAction"] = "UNO_PROVISION_PLANNER_PREMIUM_FAILED";
    /** Create Task From Teams Message */ ActionType["UpdateTaskCreationStateAction"] = "UNO_UPDATE_TASK_CREATION_STATE";
    /** Containers */ ActionType["FetchContainerAction"] = "UNO_FETCH_CONTAINER";
    ActionType["FetchContainerFailedAction"] = "UNO_FETCH_CONTAINER_FAILED";
    ActionType["FetchContainerSucceededAction"] = "UNO_FETCH_CONTAINER_SUCCEEDED";
    ActionType["UpdateContainerSucceededAction"] = "UNO_UPDATE_CONTAINER_SUCCEEDED";
    /** Conversations */ ActionType["CleanConversationThreadAction"] = "UNO_CLEAN_CONVERSATION_THREAD";
    ActionType["CreateConversationThreadAction"] = "UNO_CREATE_CONVERSATION_THREAD";
    ActionType["CreateConversationThreadFailedAction"] = "UNO_CREATE_CONVERSATION_THREAD_FAILED";
    ActionType["CreateConversationThreadSucceededAction"] = "UNO_CREATE_CONVERSATION_THREAD_SUCCEEDED";
    ActionType["FetchConversationThreadAction"] = "UNO_FETCH_CONVERSATION_THREAD";
    ActionType["FetchConversationThreadFailedAction"] = "UNO_FETCH_CONVERSATION_THREAD_FAILED";
    ActionType["FetchConversationThreadSucceededAction"] = "UNO_FETCH_CONVERSATION_THREAD_SUCCEEDED";
    ActionType["FetchConversationThreadPostAction"] = "UNO_FETCH_CONVERSATION_THREAD_POST";
    ActionType["FetchConversationThreadPostFailedAction"] = "UNO_FETCH_CONVERSATION_THREADPOST_FAILED";
    ActionType["FetchConversationThreadPostSucceededAction"] = "UNO_FETCH_CONVERSATION_THREADPOST_SUCCEEDED";
    ActionType["PostConversationThreadReplyAction"] = "UNO_POST_CONVERSATION_THREAD_REPLY";
    ActionType["PostConversationThreadReplyFailedAction"] = "UNO_POST_CONVERSATION_THREAD_REPLY_FAILED";
    ActionType["PostConversationThreadReplySucceededAction"] = "UNO_POST_CONVERSATION_THREAD_REPLY_SUCCEEDED";
    /** Dev */ ActionType["UpdateUserFeatureEntitlementsAction"] = "UNO_UPDATE_USER_FEATURE_ENTITLEMENTS";
    ActionType["UpdateProjectSubscriptionStatusAction"] = "UNO_UPDATE_PROJECT_SUBSCRIPTION_STATUS";
    /** DriveItem */ ActionType["CancelUploadSessionAction"] = "UNO_CANCEL_UPLOAD_SESSION";
    ActionType["CancelUploadSessionFailedAction"] = "UNO_CANCEL_UPLOAD_SESSION_FAILED";
    ActionType["CancelUploadSessionSucceededAction"] = "UNO_CANCEL_UPLOAD_SESSION_SUCCEEDED";
    ActionType["CreateUploadSessionAction"] = "UNO_CREATE_UPLOAD_SESSION";
    ActionType["CreateUploadSessionFailedAction"] = "UNO_CREATE_UPLOAD_SESSION_FAILED";
    ActionType["CreateUploadSessionSucceededAction"] = "UNO_CREATE_UPLOAD_SESSION_SUCCEEDED";
    ActionType["FetchDriveItemAction"] = "UNO_FETCH_DRIVE_ITEM";
    ActionType["FetchDriveItemFailedAction"] = "UNO_FETCH_DRIVE_ITEM_FAILED";
    ActionType["FetchDriveItemSucceededAction"] = "UNO_FETCH_DRIVE_ITEM_SUCCEEDED";
    ActionType["FetchDriveItemsInFolderAction"] = "UNO_FETCH_DRIVE_ITEMS_IN_FOLDER";
    ActionType["FetchDriveItemsInFolderFailedAction"] = "UNO_FETCH_DRIVE_ITEMS_IN_FOLDER_FAILED";
    ActionType["FetchDriveItemsInFolderSucceededAction"] = "UNO_FETCH_DRIVE_ITEMS_IN_FOLDER_SUCCEEDED";
    ActionType["FetchDriveItemThumbnailAction"] = "UNO_FETCH_DRIVE_ITEM_THUMBNAIL";
    ActionType["FetchDriveItemThumbnailFailedAction"] = "UNO_FETCH_DRIVE_ITEM_THUMBNAIL_FAILED";
    ActionType["FetchDriveItemThumbnailSucceededAction"] = "UNO_FETCH_DRIVE_ITEM_THUMBNAIL_SUCCEEDED";
    ActionType["FetchDriveItemPermissionsAction"] = "FETCH_DRIVE_ITEM_PERMISSIONS";
    ActionType["FetchDriveItemPermissionsFailedAction"] = "FETCH_DRIVE_ITEM_PERMISSIONS_FAILED";
    ActionType["FetchDriveItemPermissionsSucceededAction"] = "FETCH_DRIVE_ITEM_PERMISSIONS_SUCCEEDED";
    ActionType["FetchFormResponseAction"] = "UNO_FETCH_FORM_RESPONSE";
    ActionType["FetchFormResponseSucceededAction"] = "UNO_FETCH_FORM_RESPONSE_SUCCEEDED";
    ActionType["FetchFormResponseFailedAction"] = "UNO_FETCH_FORM_RESPONSE_FAILED";
    ActionType["FetchFormsInGroupAction"] = "UNO_FETCH_FORMS_IN_GROUP";
    ActionType["FetchFormsInGroupSucceededAction"] = "UNO_FETCH_FORMS_IN_GROUP_SUCCEEDED";
    ActionType["FetchFormsInGroupFailedAction"] = "UNO_FETCH_FORMS_IN_GROUP_FAILED";
    ActionType["ShareDriveItemSucceededAction"] = "UNO_SHARE_DRIVE_ITEM_SUCCEEDED";
    ActionType["ShareDriveItemFailedAction"] = "UNO_SHARE_DRIVE_ITEM_FAILED";
    ActionType["UploadBytesToSessionAction"] = "UNO_UPLOAD_BYTES_TO_SESSION";
    ActionType["UploadBytesToSessionFailedAction"] = "UNO_UPLOAD_BYTES_TO_SESSION_FAILED";
    ActionType["UploadBytesToSessionSucceededAction"] = "UNO_UPLOAD_BYTES_TO_SESSION_SUCCEEDED";
    /** Groups */ ActionType["DeleteGroupAction"] = "UNO_DELETE_GROUP";
    ActionType["DeleteGroupSucceededAction"] = "UNO_DELETE_GROUP_SUCCEEDED";
    ActionType["DeleteGroupFailedAction"] = "UNO_DELETE_GROUP_FAILED";
    ActionType["FetchGroupAction"] = "UNO_FETCH_GROUP";
    ActionType["FetchGroupFailedAction"] = "UNO_FETCH_GROUP_FAILED";
    ActionType["FetchGroupSucceededAction"] = "UNO_FETCH_GROUP_SUCCEEDED";
    ActionType["FetchGroupDetailsAction"] = "UNO_FETCH_GROUP_DETAILS";
    ActionType["FetchGroupDetailsFailedAction"] = "UNO_FETCH_GROUP_DETAILS_FAILED";
    ActionType["FetchGroupDetailsSucceededAction"] = "UNO_FETCH_GROUP_DETAILS_SUCCEEDED";
    ActionType["FetchGroupPhotosSucceededAction"] = "UNO_FETCH_GROUP_PHOTOS_SUCCEEDED";
    ActionType["FetchUserJoinedGroupsAction"] = "UNO_FETCH_USER_JOINED_GROUPS";
    ActionType["FetchUserJoinedGroupsFailedAction"] = "UNO_FETCH_USER_JOINED_GROUPS_FAILED";
    ActionType["FetchUserJoinedGroupsSucceededAction"] = "UNO_FETCH_USER_JOINED_GROUPS_SUCCEEDED";
    ActionType["JoinGroupSucceededAction"] = "UNO_JOIN_GROUP_SUCCEEDED";
    ActionType["LeaveGroupSucceededAction"] = "UNO_LEAVE_GROUP_SUCCEEDED";
    ActionType["CreateGroupAction"] = "UNO_CREATE_GROUP";
    ActionType["CreateGroupSucceededAction"] = "UNO_CREATE_GROUP_SUCCEEDED";
    ActionType["CreateGroupFailedAction"] = "UNO_CREATE_GROUP_FAILED";
    ActionType["UpdateGroupAction"] = "UNO_UPDATE_GROUP";
    ActionType["UpdateGroupSucceededAction"] = "UNO_UPDATE_GROUP_SUCCEEDED";
    ActionType["UpdateGroupFailedAction"] = "UNO_UPDATE_GROUP_FAILED";
    ActionType["GroupNamesValidationSucceededAction"] = "UNO_GROUP_NAMES_VALIDATION_SUCCEEDED";
    /** Intelligence */ ActionType["FetchFileSuggestions"] = "UNO_FETCH_FILE_SUGGESTIONS";
    ActionType["FetchFileSuggestionsFailed"] = "UNO_FETCH_FILE_SUGGESTIONS_FAILED";
    ActionType["FetchFileSuggestionsSucceeded"] = "UNO_FETCH_FILE_SUGGESTIONS_SUCCEEDED";
    ActionType["FileSuggestionsSeen"] = "UNO_FILE_SUGGESTIONS_SEEN";
    ActionType["FetchImageRecommendations"] = "UNO_FETCH_IMAGE_RECOMMENDATIONS";
    ActionType["FetchImageRecommendationsFailed"] = "UNO_FETCH_IMAGE_RECOMMENDATIONS_FAILED";
    ActionType["FetchImageRecommendationsSucceeded"] = "UNO_FETCH_IMAGE_RECOMMENDATIONS_SUCCEEDED";
    ActionType["DatetimeRecognizerSucceededAction"] = "UNO_FETCH_DATETIME_RECOGNIZER_SUCCEEDED";
    ActionType["DatetimeRecognizerFailedAction"] = "UNO_FETCH_DATETIME_RECOGNIZER_FAILED";
    /** Modal */ ActionType["CloseModalAction"] = "UNO_CLOSE_MODAL";
    ActionType["OpenModalAction"] = "UNO_OPEN_MODAL";
    /** Mru */ ActionType["CreateMruItem"] = "UNO_CREATE_MRU_ITEM";
    ActionType["CreateMruItemFailed"] = "UNO_CREATE_MRU_ITEM_FAILED";
    ActionType["CreateMruItemSucceeded"] = "UNO_CREATE_MRU_ITEM_SUCCEEDED";
    ActionType["DeleteMruItem"] = "UNO_DELETE_MRU_ITEM";
    ActionType["DeleteMruItemFailed"] = "UNO_DELETE_MRU_ITEM_FAILED";
    ActionType["DeleteMruItemSucceeded"] = "UNO_DELETE_MRU_ITEM_SUCCEEDED";
    ActionType["FetchMruItems"] = "UNO_FETCH_MRU_ITEMS";
    ActionType["FetchMruItemsFailed"] = "UNO_FETCH_MRU_ITEMS_FAILED";
    ActionType["FetchMruItemsSucceeded"] = "UNO_FETCH_MRU_ITEMS_SUCCEEDED";
    ActionType["UpdateMruItem"] = "UNO_UPDATE_MRU_ITEM";
    ActionType["UpdateMruItemFailed"] = "UNO_UPDATE_MRU_ITEM_FAILED";
    ActionType["UpdateMruItemSucceeded"] = "UNO_UPDATE_MRU_ITEM_SUCCEEDED";
    /** ProjectHome Resources */ ActionType["CreateResourceItem"] = "UNO_CREATE_RESOURCE_ITEM";
    ActionType["CreateResourceItemFailed"] = "UNO_CREATE_RESOURCE_ITEM_FAILED";
    ActionType["CreateResourceItemSucceeded"] = "UNO_CREATE_RESOURCE_ITEM_SUCCEEDED";
    ActionType["DeleteResourceItem"] = "UNO_DELETE_RESOURCE_ITEM";
    ActionType["DeleteResourceItemFailed"] = "UNO_DELETE_RESOURCE_ITEM_FAILED";
    ActionType["DeleteResourceItemSucceeded"] = "UNO_DELETE_RESOURCE_ITEM_SUCCEEDED";
    ActionType["FetchAttachedResourceItemsForPlan"] = "UNO_FETCH_ATTACHED_RESOURCE_ITEMS_FOR_PLAN";
    ActionType["FetchAttachedResourceItemsForPlanFailed"] = "UNO_FETCH_ATTACHED_RESOURCE_ITEMS_FOR_PLAN_FAILED";
    ActionType["FetchAttachedResourceItemsForPlanSucceeded"] = "UNO_FETCH_ATTACHED_RESOURCE_ITEMS_FOR_PLAN_SUCCEEDED";
    ActionType["FetchRecentResourceItemsForPlan"] = "UNO_FETCH_RECENT_RESOURCE_ITEMS_FOR_PLAN";
    ActionType["FetchRecentResourceItemsForPlanFailed"] = "UNO_FETCH_RECENT_RESOURCE_ITEMS_FOR_PLAN_FAILED";
    ActionType["FetchRecentResourceItemsForPlanSucceeded"] = "UNO_FETCH_RECENT_RESOURCE_ITEMS_FOR_PLAN_SUCCEEDED";
    ActionType["InitializeEsdkForResourcesSucceeded"] = "UNO_INITIALIZE_ESDK_FOR_RESOURCES_SUCCEEDED";
    /** Notifications */ ActionType["ClearNotificationsAction"] = "UNO_CLEAR_NOTIFICATIONS_ACTION";
    ActionType["DismissToastNotificationAction"] = "UNO_DISMISS_TOAST_NOTIFICATION_ACTION";
    ActionType["EnqueueToastNotificationAction"] = "UNO_ENQUEUE_TOAST_NOTIFICATION_ACTION";
    ActionType["EnqueueActivityToastAction"] = "UNO_ENQUEUE_ACTIVITY_TOAST_ACTION";
    ActionType["ShowErrorAction"] = "UNO_SHOW_ERROR_ACTION";
    ActionType["ShowInfoAction"] = "UNO_SHOW_INFO_ACTION";
    ActionType["ShowWarningAction"] = "UNO_SHOW_WARNING_ACTION";
    /** Operation Tracking */ ActionType["MarkBeginProcessingCopyPlanOperations"] = "UNO_MARK_BEGIN_PROCESSING_COPY_PLAN_OPERATIONS";
    ActionType["MarkEndProcessingCopyPlanOperations"] = "UNO_MARK_END_PROCESSING_COPY_PLAN_OPERATIONS";
    ActionType["FetchTrackedCopyPlanOperations"] = "UNO_FETCH_TRACKED_COPY_PLAN_OPERATIONS";
    ActionType["FetchTrackedCopyPlanOperationsFailed"] = "UNO_FETCH_TRACKED_COPY_PLAN_OPERATIONS_FAILED";
    ActionType["FetchTrackedCopyPlanOperationsSucceeded"] = "UNO_FETCH_TRACKED_COPY_PLAN_OPERATIONS_SUCCEEDED";
    /** Org */ ActionType["FetchCdsInstanceWithPackageAction"] = "UNO_FETCH_CDS_INSTANCE_WITH_PACKAGE";
    ActionType["FetchCdsInstanceWithPackageSucceededAction"] = "UNO_FETCH_CDS_INSTANCE_WITH_PACKAGE_SUCCEEDED";
    ActionType["FetchCdsInstanceWithPackageFailedAction"] = "UNO_FETCH_CDS_INSTANCE_WITH_PACKAGE_FAILED";
    ActionType["FetchCdsInstanceWithAllPackagesAction"] = "UNO_FETCH_CDS_INSTANCE_WITH_ALL_PACKAGES";
    ActionType["FetchCdsInstanceWithAllPackagesSucceededAction"] = "UNO_FETCH_CDS_INSTANCE_WITH_ALL_PACKAGES_SUCCEEDED";
    ActionType["FetchCdsInstanceWithAllPackagesFailedAction"] = "UNO_FETCH_CDS_INSTANCE_WITH_ALL_PACKAGES_FAILED";
    ActionType["FetchOrgCapabilities"] = "UNO_FETCH_ORG_CAPABILITIES";
    ActionType["FetchOrgCapabilitiesFailed"] = "UNO_FETCH_ORG_CAPABILITIES_FAILED";
    ActionType["FetchOrgCapabilitiesSucceeded"] = "UNO_FETCH_ORG_CAPABILITIES_SUCCEEDED";
    /** Performance collection */ ActionType["AppStartAction"] = "UNO_APP_START";
    ActionType["PageReadyAction"] = "UNO_PAGE_READY";
    /** Portfolio */ ActionType["CreatePortfolioAction"] = "UNO_CREATE_PORTFOLIO";
    ActionType["CreatePortfolioFailedAction"] = "UNO_CREATE_PORTFOLIO_FAILED";
    ActionType["CreatePortfolioSucceededAction"] = "UNO_CREATE_PORTFOLIO_SUCCEEDED";
    ActionType["FetchPortfolioItemsAction"] = "UNO_FETCH_PORTFOLIO_ITEMS";
    ActionType["FetchPortfolioItemsSucceededAction"] = "UNO_FETCH_PORTFOLIO_ITEMS_SUCCEEDED";
    ActionType["FetchPortfolioItemsFailedAction"] = "UNO_FETCH_PORTFOLIO_ITEMS_FAILED";
    ActionType["DeletePortfolioAction"] = "UNO_DELETE_PORTFOLIO";
    ActionType["DeletePortfolioFailedAction"] = "UNO_DELETE_PORTFOLIO_FAILED";
    ActionType["DeletePortfolioSucceededAction"] = "UNO_DELETE_PORTFOLIO_SUCCEEDED";
    ActionType["UpdatePortfolioAction"] = "UNO_UPDATE_PORTFOLIO";
    ActionType["UpdatePortfolioSucceededAction"] = "UNO_UPDATE_PORTFOLIO_SUCCEEDED";
    ActionType["UpdatePortfolioFailedAction"] = "UNO_UPDATE_PORTFOLIO_FAILED";
    /** Premium Plan */ ActionType["EnqueuePostProvisioningPlanCreationAction"] = "UNO_QUEUE_POST_PROVISIONING_PLAN_CREATION";
    ActionType["EnqueuePostProvisioningGroupPlanFetchAction"] = "UNO_QUEUE_POST_PROVISIONING_GROUP_PLAN_FETCH";
    ActionType["EnqueuePostProvisioningUserCreatedPlanFetchAction"] = "UNO_QUEUE_POST_PROVISIONING_USER_CREATED_PLAN_FETCH";
    ActionType["EnqueuePostProvisioningUserSharedPlanFetchAction"] = "UNO_QUEUE_POST_PROVISIONING_USER_SHARED_PLAN_FETCH";
    ActionType["CreatePremiumPlanAction"] = "UNO_CREATE_PREMIUM_PLAN";
    ActionType["CreatePremiumPlanSucceededAction"] = "UNO_CREATE_PREMIUM_PLAN_SUCCEEDED";
    ActionType["CreatePremiumPlanFailedAction"] = "UNO_CREATE_PREMIUM_PLAN_FAILED";
    ActionType["OpenPremiumPlanAction"] = "UNO_OPEN_PREMIUM_PLAN";
    ActionType["OpenPremiumPlanSucceededAction"] = "UNO_OPEN_PREMIUM_PLAN_SUCCEEDED";
    ActionType["OpenPremiumPlanFailedAction"] = "UNO_OPEN_PREMIUM_PLAN_FAILED";
    ActionType["FetchPremiumPlanItemsSharedWithUserAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_SHARED_WITH_USER";
    ActionType["FetchPremiumPlanItemsSharedWithUserSucceededAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_SHARED_WITH_USER_SUCCEEDED";
    ActionType["FetchPremiumPlanItemsSharedWithUserFailedAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_SHARED_WITH_USER_FAILED";
    ActionType["FetchPremiumPlanItemsCreatedByUserAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_CREATED_BY_USER";
    ActionType["FetchPremiumPlanItemsCreatedByUserSucceededAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_CREATED_BY_USER_SUCCEEDED";
    ActionType["FetchPremiumPlanItemsCreatedByUserFailedAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_CREATED_BY_USER_FAILED";
    ActionType["FetchPremiumPlanItemsSharedWithGroupAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_SHARED_WITH_GROUP";
    ActionType["FetchPremiumPlanItemsSharedWithGroupSucceededAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_SHARED_WITH_GROUP_SUCCEEDED";
    ActionType["FetchPremiumPlanItemsSharedWithGroupFailedAction"] = "UNO_FETCH_PREMIUM_PLAN_ITEMS_SHARED_WITH_GROUP_FAILED";
    ActionType["UpdatePremiumPlanAction"] = "UNO_UPDATE_PREMIUM_PLAN";
    ActionType["UpdatePremiumPlanSucceededAction"] = "UNO_UPDATE_PREMIUM_PLAN_SUCCEEDED";
    ActionType["UpdatePremiumPlanFailedAction"] = "UNO_UPDATE_PREMIUM_PLAN_FAILED";
    /** Premium Tasks */ ActionType["CreatePremiumTaskSucceededAction"] = "UNO_CREATE_PREMIUM_TASK_SUCCEEDED";
    ActionType["CreatePremiumTaskFailedAction"] = "UNO_CREATE_PREMIUM_TASK_FAILED";
    /** Permissions */ ActionType["BatchFetchContainerPermissionsSucceededAction"] = "UNO_BATCH_FETCH_CONTAINER_PERMISSIONS_SUCCEEDED";
    ActionType["BatchFetchTaskPermissionsFailedAction"] = "UNO_BATCH_FETCH_TASK_PERMISSIONS_FAILED";
    ActionType["BatchFetchTaskPermissionsSucceededAction"] = "UNO_BATCH_FETCH_TASK_PERMISSIONS_SUCCEEDED";
    /** Prefetch */ ActionType["PrefetchBasicPlanDataSucceededAction"] = "UNO_PREFETCH_BASIC_PLAN_DATA_SUCCEEDED";
    /** Recommended Plans */ ActionType["SetRecommendedPlansAction"] = "UNO_SET_RECOMMENDED_PLANS";
    ActionType["SetRecommendedPlansFailedAction"] = "UNO_SET_RECOMMENDED_PLANS_FAILED";
    ActionType["RemovePlanFromRecommendedListAction"] = "UNO_REMOVE_PLAN_FROM_RECOMMENDED_LIST";
    /** Publishing */ ActionType["FetchPublications"] = "UNO_FETCH_PUBLICATIONS";
    ActionType["FetchPublicationsSucceeded"] = "UNO_FETCH_PUBLICATIONS_SUCCEEDED";
    ActionType["FetchPublicationsFailed"] = "UNO_FETCH_PUBLICATIONS_FAILED";
    /** Routing */ ActionType["EnqueuePlanPostCreateNavigation"] = "UNO_ENQUEUE_PLAN_POST_CREATE_NAVIGATION";
    ActionType["EnqueuePortfolioPostCreateNavigation"] = "UNO_ENQUEUE_PORTFOLIO_POST_CREATE_NAVIGATION";
    ActionType["NavigateToView"] = "UNO_NAVIGATE_TO_VIEW";
    ActionType["PushSubRoute"] = "UNO_PUSH_SUBROUTE";
    ActionType["ReplaceSubRoute"] = "UNO_REPLACE_SUBROUTE";
    /** Status Report */ ActionType["GenerateStatusReportAction"] = "UNO_GENERATE_STATUS_REPORT";
    ActionType["GenerateStatusReportSucceededAction"] = "UNO_GENERATE_STATUS_REPORT_SUCCEEDED";
    ActionType["GenerateStatusReportFailedAction"] = "UNO_GENERATE_STATUS_REPORT_FAILED";
    /** SuiteNav */ ActionType["GetSuiteNavSucceededAction"] = "UNO_GET_SUITE_NAV_SUCCEEDED";
    ActionType["GetSuiteNavFailedAction"] = "UNO_GET_SUITE_NAV_FAILED";
    /** Teams */ ActionType["FetchChannelSucceeded"] = "UNO_FETCH_CHANNEL_SUCCEEDED";
    ActionType["FetchTeamsMessageDetailsSucceeded"] = "UNO_FETCH_TEAMS_MESSAGE_DETAILS_SUCCEEDED";
    /** TeamsTasksTabs */ ActionType["DeleteTeamsTasksTabSucceeded"] = "UNO_DELETE_TEAMSTASKS_TAB_SUCCEEDED";
    ActionType["FetchTeamsTasksTabs"] = "UNO_FETCH_TEAMSTASKS_TABS";
    ActionType["FetchTeamsTasksTabsSucceeded"] = "UNO_FETCH_TEAMSTASKS_TABS_SUCCEEDED";
    ActionType["FetchTeamsTasksTabsFailed"] = "UNO_FETCH_TEAMSTASKS_TABS_FAILED";
    /** Templates */ ActionType["FetchBuiltinTemplateData"] = "UNO_FETCH_BUILTIN_TEMPLATE_DATA";
    ActionType["FetchBuiltinTemplateDataFailed"] = "UNO_FETCH_BUILTIN_TEMPLATE_DATA_FAILED";
    ActionType["FetchBuiltinTemplateDataSucceeded"] = "UNO_FETCH_BUILTIN_TEMPLATE_DATA_SUCCEEDED";
    /** Tenant */ ActionType["CheckCurrentUserToGroupsMembershipsAction"] = "UNO_CHECK_CURRENT_USER_TO_GROUPS_MEMBERSHIPS";
    ActionType["CheckCurrentUserToGroupsMembershipsFailedAction"] = "UNO_CHECK_CURRENT_USER_TO_GROUPS_MEMBERSHIPS_FAILED";
    ActionType["CheckCurrentUserToGroupsMembershipsSucceededAction"] = "UNO_CHECK_CURRENT_USER_TO_GROUPS_MEMBERSHIPS_SUCCEEDED";
    ActionType["CheckCurrentUserToUSMsitTenantMemberSucceededAction"] = "UNO_CHECK_CURRENT_USER_TO_US_MSIT_GROUPS_MEMBERSHIPS_SUCCEEDED";
    ActionType["FetchTenantFeedbackSettingsAction"] = "UNO_FETCH_TENANT_FEEDBACK_SETTINGS";
    ActionType["FetchTenantFeedbackSettingsFailedAction"] = "UNO_FETCH_TENANT_FEEDBACK_SETTINGS_FAILED";
    ActionType["FetchTenantFeedbackSettingsSucceededAction"] = "UNO_FETCH_TENANT_FEEDBACK_SETTINGS_SUCCEEDED";
    ActionType["FetchTenantGroupSettingsAction"] = "UNO_FETCH_TENANT_GROUP_SETTINGS";
    ActionType["FetchTenantGroupSettingsFailedAction"] = "UNO_FETCH_TENANT_GROUP_SETTINGS_FAILED";
    ActionType["FetchTenantGroupSettingsSucceededAction"] = "UNO_FETCH_TENANT_GROUP_SETTINGS_SUCCEEDED";
    ActionType["FetchOrganizationInfoAction"] = "UNO_FETCH_ORGANIZATION_INFO";
    ActionType["FetchOrganizationInfoFailedAction"] = "UNO_FETCH_ORGANIZATION_INFO_FAILED";
    ActionType["FetchOrganizationInfoSucceededAction"] = "UNO_FETCH_ORGANIZATION_INFO_SUCCEEDED";
    ActionType["FetchTenantSensitivityLabelsAction"] = "UNO_FETCH_TENANT_SENSITIVITY_LABELS";
    ActionType["FetchTenantSensitivityLabelsFailedAction"] = "UNO_FETCH_TENANT_SENSITIVITY_LABELS_FAILED";
    ActionType["FetchTenantSensitivityLabelsSucceededAction"] = "UNO_FETCH_TENANT_SENSITIVITY_LABELS_SUCCEEDED";
    ActionType["FetchTenantSensitivityPolicySettingsAction"] = "UNO_FETCH_TENANT_SENSITIVITY_POLICY_SETTINGS";
    ActionType["FetchTenantSensitivityPolicySettingsFailedAction"] = "UNO_FETCH_TENANT_SENSITIVITY_POLICY_SETTINGS_FAILED";
    ActionType["FetchTenantSensitivityPolicySettingsSucceededAction"] = "UNO_FETCH_TENANT_SENSITIVITY_POLICY_SETTINGS_SUCCEEDED";
    ActionType["FetchTenantPortfoliosEnabledSettingAction"] = "UNO_FETCH_TENANT_PORTFOLIOS_ENABLED_SETTING";
    ActionType["FetchTenantPortfoliosEnabledSettingFailedAction"] = "UNO_FETCH_TENANT_PORTFOLIOS_ENABLED_SETTING_FAILED";
    ActionType["FetchTenantPortfoliosEnabledSettingSucceededAction"] = "UNO_FETCH_TENANT_PORTFOLIOS_ENABLED_SETTING_SUCCEEDED";
    ActionType["SetGroupNamePrefixSuffixAction"] = "UNO_SET_GROUP_NAME_PREFIX_SUFFIX";
    /** Upsell */ ActionType["FetchProjectSubscriptionStatusAction"] = "UNO_FETCH_PROJECT_SUBSCRIPTION_STATUS";
    ActionType["FetchProjectSubscriptionStatusSucceededAction"] = "UNO_FETCH_PROJECT_SUBSCRIPTION_STATUS_SUCCEEDED";
    ActionType["FetchProjectSubscriptionStatusFailedAction"] = "UNO_FETCH_PROJECT_SUBSCRIPTION_STATUS_FAILED";
    ActionType["AcquireProjectTrialAction"] = "UNO_ACQUIRE_PROJECT_TRIAL";
    ActionType["AcquireProjectTrialSucceededAction"] = "UNO_ACQUIRE_PROJECT_TRIAL_SUCCEEDED";
    ActionType["AcquireProjectTrialFailedAction"] = "UNO_ACQUIRE_PROJECT_TRIAL_FAILED";
    ActionType["UpdateSelfServiceAction"] = "UNO_UPDATE_SELF_SERVICE_ACTION";
    ActionType["TrialProvisioningSucceededAction"] = "UNO_TRIAL_PROVISIONING_SUCCEEDED";
    ActionType["ResetProjectUpsellExperienceModeAction"] = "UNO_RESET_PROJECT_UPSELL_EXPERIENCE_MODE";
    /** Plan Conversion */ ActionType["AcquireProjectTrialAndConvertPlanAction"] = "UNO_ACQUIRE_PROJECT_TRIAL_AND_CONVERT_PLAN";
    ActionType["ConvertBasicPlanToPremiumAction"] = "UNO_CONVERT_BASIC_PLAN_TO_PREMIUM";
    ActionType["ConvertBasicPlanToPremiumSucceededAction"] = "UNO_CONVERT_BASIC_PLAN_TO_PREMIUM_SUCCEEDED";
    ActionType["TrackConversionProgressAction"] = "UNO_TRACK_CONVERSION_PROGRESS_ACTION";
    ActionType["ConvertBasicPlanToPremiumFailedAction"] = "UNO_CONVERT_BASIC_PLAN_TO_PREMIUM_FAILED";
    ActionType["FetchConvertPlanStatusSucceededAction"] = "UNO_FETCH_CONVERT_PLAN_STATUS_SUCCEEDED";
    ActionType["FetchConvertPlanStatusFailedAction"] = "UNO_FETCH_CONVERT_PLAN_STATUS_FAILED";
    ActionType["BasicPlanConversionSucceededAction"] = "UNO_BASIC_PLAN_CONVERSION_SUCCEEDED";
    ActionType["RecordBasicPlanForDowngradeNotificationAction"] = "UNO_RECORD_BASIC_PLAN_FOR_DOWNGRADE_NOTIFICATION";
    ActionType["ResetPlanConversionStatusAction"] = "UNO_RESET_PLAN_CONVERSION_STATUS";
    ActionType["RecordBasicPlanForPlanUpgradedFREAction"] = "UNO_RECORD_BASIC_PLAN_FOR_PLAN_UPGRADED_FRE";
    /** Usage Mode */ ActionType["SetUsageModeAction"] = "UNO_SET_USAGE_MODE";
    /** User */ ActionType["FetchUserAction"] = "UNO_FETCH_USER_ACTION";
    ActionType["FetchUserFailedAction"] = "UNO_FETCH_USER_FAILED_ACTION";
    ActionType["FetchUserSucceededAction"] = "UNO_FETCH_USER_SUCCEEDED_ACTION";
    ActionType["FetchUserPhotoSucceededAction"] = "UNO_FETCH_USER_PHOTO_SUCCEEDED_ACTION";
    ActionType["FetchUserPhotoFailedAction"] = "UNO_FETCH_USER_PHOTO_FAILED_ACTION";
    ActionType["FetchUsersByKeywordAction"] = "UNO_FETCH_USERS_BY_KEYWORD_ACTION";
    ActionType["FetchUsersByKeywordSucceededAction"] = "UNO_FETCH_USERS_BY_KEYWORD_SUCCEEDED_ACTION";
    ActionType["FetchUsersByKeywordFailedAction"] = "UNO_FETCH_USERS_BY_KEYWORD_FAILED_ACTION";
    ActionType["FetchContainerMembersAction"] = "UNO_FETCH_CONTAINER_MEMBERS_ACTION";
    ActionType["FetchContainerMembersFailedAction"] = "UNO_FETCH_CONTAINER_MEMBERS_FAILED_ACTION";
    ActionType["FetchGroupMembersSucceededAction"] = "UNO_FETCH_GROUP_MEMBERS_SUCCEEDED_ACTION";
    ActionType["FetchRosterMembersSucceededAction"] = "UNO_FETCH_ROSTER_MEMBERS_SUCCEEDED_ACTION";
    ActionType["FetchTodoMembersSucceededAction"] = "UNO_FETCH_TODO_MEMBERS_SUCCEEDED_ACTION";
    ActionType["FetchTeamsChatMembersSucceeded"] = "UNO_FETCH_TEAMS_CHAT_MEMBERS_SUCCEEDED";
    ActionType["AddUsersToRosterMembersAction"] = "UNO_ADD_USERS_TO_ROSTER_MEMBERS_ACTION";
    ActionType["AddUserToRosterMembersSucceededAction"] = "UNO_ADD_USER_TO_ROSTER_MEMBERS_SUCCEEDED_ACTION";
    ActionType["AddUserToRosterMembersFailedAction"] = "UNO_ADD_USER_TO_ROSTER_MEMBERS_FAILED_ACTION";
    ActionType["RemoveUserFromRosterMembersAction"] = "UNO_REMOVE_USER_FROM_ROSTER_MEMBERS_ACTION";
    ActionType["RemoveUserFromRosterMembersSucceededAction"] = "UNO_REMOVE_USER_FROM_ROSTER_MEMBERS_SUCCEEDED_ACTION";
    ActionType["RemoveUserFromRosterMembersFailedAction"] = "UNO_REMOVE_USER_FROM_ROSTER_MEMBERS_FAILED_ACTION";
    ActionType["AddGroupMembersAction"] = "UNO_ADD_GROUP_MEMBERS_ACTION";
    ActionType["AddGroupMembersSucceededAction"] = "UNO_ADD_GROUP_MEMBERS_SUCCEEDED_ACTION";
    ActionType["AddGroupMembersFailedAction"] = "UNO_ADD_GROUP_MEMBERS_FAILED_ACTION";
    ActionType["RemoveGroupMemberAction"] = "UNO_REMOVE_GROUP_MEMBER_ACTION";
    ActionType["RemoveGroupMemberSucceededAction"] = "UNO_REMOVE_GROUP_MEMBER_SUCCEEDED_ACTION";
    ActionType["RemoveGroupMemberFailedAction"] = "UNO_REMOVE_GROUP_MEMBER_FAILED_ACTION";
    ActionType["RemoveGroupOwnerFailedAction"] = "UNO_REMOVE_GROUP_OWNER_FAILED_ACTION";
    ActionType["FetchUserSettingsSucceededAction"] = "UNO_FETCH_USER_SETTINGS_SUCCEEDED_ACTION";
    ActionType["UpdateUserSettingsAction"] = "UNO_UPDATE_USER_SETTINGS_ACTION";
    ActionType["UpdateUserSettingsSucceededAction"] = "UNO_UPDATE_USER_SETTINGS_SUCCEEDED_ACTION";
    ActionType["UpdateUserSettingsFailedAction"] = "UNO_UPDATE_USER_SETTINGS_FAILED_ACTION";
    ActionType["DeltaSyncUpdateUserSettingsAction"] = "UNO_DELTASYNC_UPDATE_USER_SETTINGS_ACTION";
    ActionType["FetchLandingPageNotificationSettingsSucceededAction"] = "UNO_FETCH_LANDINGPAGE_NOTIFICATION_SETTINGS_SUCCEEDED_ACTION";
    ActionType["UpdateLandingPageNotificationSettingsSucceededAction"] = "UNO_UPDATE_LANDINGPAGE_NOTIFICATION_SETTINGS_SUCCEEDED_ACTION";
    /** View */ ActionType["SetCategoryLabelModeAction"] = "UNO_SET_CATEGORY_LABEL_MODE";
    ActionType["SetTaskFilterAction"] = "UNO_SET_TASK_FILTER";
    ActionType["SetTaskGroupingStateAction"] = "UNO_SET_TASK_GROUPING_STATE";
    ActionType["SetTeamsExpandedStateAction"] = "UNO_SET_TEAMS_EXPANDED_STATE";
    ActionType["SetPlanFilterAction"] = "UNO_SET_PLAN_FILTER";
    ActionType["SetSortCriteriaAction"] = "UNO_SET_SORT_CRITERIA";
    ActionType["SetGridColumnWidthAction"] = "UNO_SET_GRID_COLUMN_WIDTH";
    ActionType["SetLeftNavExpandedAction"] = "UNO_SET_LEFT_NAV_EXPANDED";
    /** UnoShell */ ActionType["UpdateChildViewStatusAction"] = "UNO_UPDATE_CHILD_VIEW_STATUS";
    /** Roaming */ ActionType["FetchRoamingUserDataAction"] = "UNO_FETCH_ROAMING_USER_DATA";
    ActionType["FetchRoamingUserDataSucceededAction"] = "UNO_FETCH_ROAMING_USER_DATA_SUCCEEDED";
    ActionType["FetchRoamingUserDataFailedAction"] = "UNO_FETCH_ROAMING_USER_DATA_FAILED";
    ActionType["UpdateRoamingUserDataAction"] = "UNO_UPDATE_ROAMING_USER_DATA";
    ActionType["UpdateRoamingUserDataSucceededAction"] = "UNO_UPDATE_ROAMING_USER_DATA_SUCCEEDED";
    ActionType["UpdateRoamingUserDataFailedAction"] = "UNO_UPDATE_ROAMING_USER_DATA_FAILED";
    /** Copilot */ ActionType["CopilotReSyncTilesAction"] = "UNO_COPILOT_RESYNC_TILES_ACTION";
    /** Project Manger workflow action */ ActionType["ProjectManagerWorkflowClientStateUpdateAction"] = "UNO_PROJECT_MANAGER_WORKFLOW_CLIENT_STATE_UPDATE_ACTION";
})(ActionType || (ActionType = {}));
