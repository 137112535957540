/**
 * Enum to represent the different modals available
 * Notes:
 *   - Some of these values are used in UNO DeepLink (so should not be changed)
 *   - There are DeepLink unit tests checking for the ModalTypes that are used
 *   - All lowercase to be more case-insensitive friendly
 */ export var ModalType;
(function(ModalType) {
    /** Modal for converting basic plan to premium */ ModalType["ConvertPlan"] = "convertplan";
    /** Modal for Copilot upsell */ ModalType["CopilotUpsell"] = "copilotupsell";
    /** Modal for create new basic and premium plan */ ModalType["CreatePlan"] = "createplan";
    /** Modal for selecting existing basic and premium plans */ ModalType["SelectPlan"] = "selectplan";
    /** Modal for showing Copilot sidecar.*/ ModalType["CopilotSidecar"] = "copilotsidecar";
    /** Modal for create new portfolio */ ModalType["CreatePortfolio"] = "createportfolio";
    /** Modal for start premium trial */ ModalType["TryPremium"] = "trypremium";
    /** Modal for showing upsell status */ ModalType["UpsellStatus"] = "upsellstatus";
    /** Modal for renaming a plan */ ModalType["RenameDialog"] = "renamedialog";
    /** Modal for renaming a portfolio */ ModalType["RenamePortfolioDialog"] = "renamePortfoliodialog";
    /** Modal for in-app request premium license */ ModalType["RequestPremiumLicense"] = "requestpremiumlicense";
    ModalType["None"] = "none";
    /** Modal for editing a task */ ModalType["TaskEditor"] = "taskeditor";
    /** Modal displayed when copying a task */ ModalType["CopyTask"] = "copytask";
    /** Modal displayed when copying a plan */ ModalType["CopyPlan"] = "copyplan";
    /** Modal for moving a task */ ModalType["MoveTask"] = "movetask";
    /** Modal displayed when editing a task attachment */ ModalType["TaskAttachmentDetailsEditor"] = "taskattachmentdetailseditor";
    /** Callout for get subscription when users not eligible to upsell */ ModalType["GetSubscription"] = "getsubscription";
    /** Modal for showing any info */ ModalType["Info"] = "info";
    /** Modal for showing app info */ ModalType["AppInfo"] = "appInfo";
    /** Modal for handling auth error */ ModalType["AuthError"] = "autherror";
    /** The FRE modal */ ModalType["FRE"] = "fre";
    /** Modal for showing warning on sharing/assigning a task */ ModalType["ShareFileWarning"] = "sharefilewarning";
    /** Modal for showing delete recurrence task dialog */ ModalType["DeleteRecurringTask"] = "deleterecurringtask";
    /** Modal for showing add non-member warning dialog for adding user to plan */ ModalType["AddNonMemberWarning"] = "addnonmemberwarning";
    /** Modal for showing Forms Iframe Dialog */ ModalType["FormsIFrame"] = "formsiframe";
    /** Modal for showing share plan form */ ModalType["SharePlan"] = "shareplan";
    /** Modal for showing Notification Settings Dialog in SuiteNav */ ModalType["NotificationSettingsDialog"] = "notificationsettingsdialog";
    /** Modal for showing iCalendar dialog */ ModalType["ICalendarDialog"] = "iCalendarDialog";
    /** Modal to delete group */ ModalType["DeleteGroup"] = "deletegroup";
    /** Confirmation Dialog for deleting portfolio */ ModalType["DeletePortfolio"] = "deleteportfolio";
    /** Modal to delete plan */ ModalType["DeletePlan"] = "deleteplan";
    /** Modal to leave plan */ ModalType["LeavePlan"] = "leaveplan";
})(ModalType || (ModalType = {}));
