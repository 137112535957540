// Builder
import { PlanBuilder } from "./PlanBuilder";
import { PlanContext } from "./PlanContext";
// Constants
import { ContextScenarioConstants } from "@ms/uno-constants/lib/local/ExternalSourceConstants";
import { ClientAppId, ClientType, PlanType } from "@ms/uno-constants/lib/local/AppConstants";
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
// Models
import { ContainerType } from "../../../service/graph-legacy/container/ContainerType";
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { TaskServiceEntity } from "../TaskServiceEntity";
import { ArchivalInfo } from "./archival/ArchivalInfo";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isDate from "lodash/isDate";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import pickBy from "lodash/pickBy";
import sortBy from "lodash/sortBy";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
import { isSharepointAppId, isTeamsPlannerClientEntraAppId } from "@ms/uno-utilities/lib/local/AppUtilities";
import { generateEntityExternalId } from "@ms/uno-utilities/lib/local/ExternalContextUtilities";
/**
 * Represents a plan entity in the client
 */ export class Plan extends TaskServiceEntity {
    /** Builder for Plan objects */ static builder() {
        return new PlanBuilder();
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (key === "contexts") {
            if (isEqual(target, DeltaApiConstants.CollectionCleanupValue)) {
                // All removed workaround
                return {};
            }
            return target;
        } else if (isDate(source) || isDate(target)) {
            return target;
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    /**
     * Get the valid (that are from known apps) and first created plan context key for rendering plan context
     * @param planContexts The contexts from which to get the renderable context key
     * @param [clientType] Optional param to specify a particular client type key to get. Can be used to specify sharepoint, teams, or planner web.
     */ static getRenderablePlanContextKey(planContexts, clientType) {
        if (isEmpty(planContexts)) {
            return null;
        }
        const filteredContexts = pickBy(planContexts, (value, key)=>{
            switch(clientType){
                case ClientType.Teams:
                    return isTeamsPlannerClientEntraAppId(value.ownerAppId);
                case ClientType.Sharepoint:
                    return isSharepointAppId(value.ownerAppId);
                case ClientType.Web:
                    return value.ownerAppId === ClientAppId.PlannerWeb;
                case undefined:
                    return isTeamsPlannerClientEntraAppId(value.ownerAppId) || isSharepointAppId(value.ownerAppId) || value.ownerAppId === ClientAppId.PlannerWeb;
                default:
                    throw new Error(`Plan.getRenderablePlanContextKey - Invalid client type specified`);
            }
        });
        const contextKeys = Object.keys(filteredContexts);
        return !isEmpty(contextKeys) ? sortBy(contextKeys, [
            (key)=>filteredContexts[key].createdDate
        ])[0] : null;
    }
    get entityType() {
        return TaskServiceEntityType.Plan;
    }
    get externalId() {
        const contextScenarioId = this.creationSource.external?.contextScenarioId;
        const externalObjectId = this.creationSource.external?.externalObjectId;
        if (contextScenarioId == null || externalObjectId == null) {
            return null;
        }
        return generateEntityExternalId(contextScenarioId, externalObjectId);
    }
    toGraphSerializable() {
        return {
            id: this.id,
            "@odata.etag": this.itemVersion,
            container: {
                containerId: this.container.externalId,
                type: this.container.containerType
            },
            title: this.title,
            creationSource: this.creationSource.toGraphSerializable(),
            contexts: PlanContext.getGraphContextResources(this.contexts),
            sharedWithContainers: this.sharedWithContainers.map((sharedWithContainer)=>sharedWithContainer.toGraphSerializable())
        };
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "archivalInfo" || key === "container" || key === "createdBy" || key === "createdByAppId" || key === "createdDate" || key === "creationSource" || key === "creationProcessInfo" || key === "deletedBy" || key === "id" || key === "isArchived" || key === "itemVersion") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    /**
     * Return a PlanBuilder with clone data of this object
     */ getCloneBuilder() {
        return Plan.builder().withPropertyBag({
            archivalInfo: this.archivalInfo ? ArchivalInfo.fromResource(this.archivalInfo.toGraphSerializable()) : null,
            container: {
                externalId: this.container.externalId,
                containerType: this.container.containerType
            },
            createdBy: this.createdBy,
            createdByAppId: this.createdByAppId,
            createdDate: new Date(this.createdDate.valueOf()),
            creationHasFinished: this.creationProcessInfo.processHasFinished,
            creationProcessId: this.creationProcessInfo.processId,
            creationProcessType: this.creationProcessInfo.processType,
            creationSourceId: this.creationProcessInfo.sourceId,
            deletedBy: this.deletedBy || undefined,
            id: this.id,
            isArchived: this.isArchived,
            itemVersion: this.itemVersion,
            title: this.title,
            contexts: cloneDeep(this.contexts),
            sharedWithContainers: cloneDeep(this.sharedWithContainers)
        });
    }
    /**
     * Return a clone of this object
     */ getClone() {
        return this.getCloneBuilder().build();
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === "contexts") {
                if (!isEqual(source, target)) {
                    if (isEqual(target, {})) {
                        // Remove all checklist or references workaround. if {} is returned it treats it as no diff
                        return DeltaApiConstants.CollectionCleanupValue;
                    }
                    return target;
                } else {
                    return {};
                }
            } else if (isDate(source) || isDate(target)) {
                if (!isEqual(source, target)) {
                    return target;
                } else {
                    return {};
                }
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(Plan.builder().build(), this, ...diffs, Plan.mergeCustomizer);
        }
        return this;
    }
    applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this);
        return this.applyDiffs(diffData);
    }
    isBeingCreated() {
        const processHasFinished = this.creationProcessInfo.processHasFinished ?? true;
        const processId = this.creationProcessInfo.processId;
        return !isEmpty(processId) && !processHasFinished;
    }
    isProjectPlan() {
        return this.container?.containerType === ContainerType.Project;
    }
    isLoopPlan() {
        return this.creationSource.external?.contextScenarioId === ContextScenarioConstants.loop || this.creationSource.external?.contextScenarioId === ContextScenarioConstants.meetingNotes;
    }
    isTodoPlan() {
        return this.container?.containerType === ContainerType.ToDo;
    }
    getPlanType() {
        if (this.isProjectPlan()) {
            return PlanType.Premium;
        } else if (this.isTodoPlan()) {
            return PlanType.ToDo;
        } else {
            return PlanType.Basic;
        }
    }
    /**
     * Initializes a new instance of the plan entity.
     * @param planBuilder Builder with the initialization data
     */ constructor(planBuilder){
        super(planBuilder.id, planBuilder.itemVersion);
        this.archivalInfo = planBuilder.archivalInfo;
        this.container = planBuilder.container;
        this.createdBy = planBuilder.createdBy;
        this.createdByAppId = planBuilder.createdByAppId;
        this.createdDate = planBuilder.createdDate;
        this.creationSource = planBuilder.creationSource;
        this.creationProcessInfo = planBuilder.creationProcessInfo;
        this.deletedBy = planBuilder.deletedBy;
        this.isArchived = planBuilder.isArchived;
        this.title = planBuilder.title;
        this.contexts = planBuilder.contexts;
        this.sharedWithContainers = planBuilder.sharedWithContainers;
    }
}
/** Matches ProjectLogic.cs : TitleSizeLimit */ Plan.NameMaxLength = 255;
