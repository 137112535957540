import { HostAdaptorAuthService } from "@ms/uno-services/lib/local/services/auth/HostAdaptorAuthService";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/** Implementation of the service provider factory for the Base app */
export class BaseServiceFactory {
    /**
     * Initialize the service factory
     * @param loader Component loader
     * @param loggers Loggers for telemetry
     */
    constructor(loader, loggers) {
        this.loader = loader;
        this.loggers = loggers;
    }
    getAugloopServiceProvider(authTokenProvider, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("al"), () => import(/* webpackChunkName: "uno-alservice" */ "@ms/uno-services/lib/local/services/augloop/AugLoopService"), (bundle) => new bundle.AugLoopService(true, this.loggers, authTokenProvider, configProvider));
    }
    getAuthServiceProvider(ajaxClient, hostAdaptor, configProvider) {
        return () => {
            if (this.auth) {
                return this.auth;
            }
            this.auth = new HostAdaptorAuthService(hostAdaptor, configProvider);
            return this.auth;
        };
    }
    getCentroServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("centro"), () => import(/* webpackChunkName: "uno-centroservice" */ "@ms/uno-services/lib/local/services/centro/CentroRequestAdapter"), (bundle) => new bundle.CentroRequestAdapter(requestClient, this.loggers, configProvider));
    }
    getChatSvcAggServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("chatsvcagg"), () => import(
        /* webpackChunkName: "uno-chatsvcaggservice" */ "@ms/uno-services/lib/local/services/chatsvcagg/ChatSvcAggService"), (bundle) => new bundle.ChatSvcAggService(requestClient, configProvider));
    }
    getGroupsServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("groups"), () => import(/* webpackChunkName: "uno-groupsservice" */ "@ms/uno-services/lib/local/services/graph/groups/GroupsService"), (bundle) => new bundle.GroupsService(requestClient, configProvider));
    }
    getIrisServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("iris"), () => import(/* webpackChunkName: "uno-irisservice" */ "@ms/uno-services/lib/local/services/iris/IrisService"), (bundle) => new bundle.IrisService(requestClient, this.loggers, configProvider));
    }
    getLoopServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("loop"), () => import(/* webpackChunkName: "uno-loopservice" */ "@ms/uno-services/lib/local/services/loop/LoopService"), (bundle) => new bundle.LoopService(requestClient, configProvider));
    }
    getLoopMtsServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("loopmts"), () => import(/* webpackChunkName: "uno-loopmtsservice" */ "@ms/uno-services/lib/local/services/loop/LoopMtsService"), (bundle) => new bundle.LoopMtsService(requestClient, configProvider));
    }
    getMarsServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("mars"), () => import(/* webpackChunkName: "uno-marsservice" */ "@ms/uno-services/lib/local/services/mars/MarsService"), (bundle) => new bundle.MarsService(requestClient, configProvider));
    }
    getMruServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("mru"), () => import(/* webpackChunkName: "uno-mruservice" */ "@ms/uno-services/lib/local/services/mru/MruService"), (bundle) => new bundle.MruService(requestClient, this.loggers, configProvider));
    }
    getOcpsServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("ocps"), () => import(/* webpackChunkName: "uno-ocpsservice" */ "@ms/uno-services/lib/local/services/ocps/OcpsService"), (bundle) => new bundle.OcpsService(requestClient, configProvider));
    }
    getOneShellServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return async () => {
            this.loggers.traceLogger.logTrace(0x1e3196c3 /* tag_4mz1d */, TraceLevel.Error, `Shouldn't import oneShell service for base (non-Web) endpoint.`);
            throw new Error("NotSupported");
        };
    }
    getPlannerServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("planner"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gatedplannerservice" */ "@ms/uno-services/lib/local/services/graph/planner/GatedPlannerService"),
            import(
            /* webpackChunkName: "uno-plannerservice" */ "@ms/uno-services/lib/local/services/graph/planner/PlannerService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedPlannerService(new api.PlannerService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    getProjectLandingPageServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("plp"), () => import(
        /* webpackChunkName: "uno-plpservice" */ "@ms/uno-services/lib/local/services/project/ProjectLandingPageService"), (bundle) => new bundle.ProjectLandingPageService(requestClient, configProvider));
    }
    getProjectSchedulingServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("pss"), () => import(/* webpackChunkName: "uno-pssservice" */ "@ms/uno-services/lib/local/services/project/ProjectSchedulingService"), (bundle) => new bundle.ProjectSchedulingService(requestClient, this.loggers, configProvider));
    }
    getRoamingServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("roaming"), () => import(/* webpackChunkName: "uno-roamingservice" */ "@ms/uno-services/lib/local/services/roaming/RoamingService"), (bundle) => new bundle.RoamingService(requestClient, configProvider));
    }
    getSiteURLServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("siteurl"), () => import(/* webpackChunkName: "uno-siteurlservice" */ "@ms/uno-services/lib/local/services/graph/siteURL/SiteURLService"), (bundle) => new bundle.SiteURLService(requestClient, configProvider));
    }
    getTcmsServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("tcms"), () => import(/* webpackChunkName: "uno-tcmsservice" */ "@ms/uno-services/lib/local/services/tcms/TcmsService"), (bundle) => new bundle.TcmsService(requestClient, configProvider));
    }
    getTeamsServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("teams"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gatedteamsservice" */ "@ms/uno-services/lib/local/services/graph/teams/GatedTeamsService"),
            import(/* webpackChunkName: "uno-teamsservice" */ "@ms/uno-services/lib/local/services/graph/teams/TeamsService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedTeamsService(new api.TeamsService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    getConversationsServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("conversations"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gatedconversationsservice" */ "@ms/uno-services/lib/local/services/graph/conversations/GatedConversationsService"),
            import(
            /* webpackChunkName: "uno-conversationsservice" */ "@ms/uno-services/lib/local/services/graph/conversations/ConversationsService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedConversationsService(new api.ConversationsService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    getDriveItemServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("driveitem"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gateddriveitemservice" */ "@ms/uno-services/lib/local/services/graph/driveItem/GatedDriveItemService"),
            import(
            /* webpackChunkName: "uno-driveitemservice" */ "@ms/uno-services/lib/local/services/graph/driveItem/DriveItemService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedDriveItemService(new api.DriveItemService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    getFlowServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("flow"), () => import(/* webpackChunkName: "uno-flowservice" */ "@ms/uno-services/lib/local/services/flow/FlowService"), (bundle) => new bundle.FlowService(requestClient, configProvider));
    }
    getGatewayServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("gateway"), () => import(
        /* webpackChunkName: "uno-gatewayservice" */ "@ms/uno-services/lib/local/services/project/gatewayService/GatewayService"), (bundle) => new bundle.GatewayService(requestClient, configProvider));
    }
    getTenantServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("tenant"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gatedtenantservice" */ "@ms/uno-services/lib/local/services/graph/tenant/GatedTenantService"),
            import(
            /* webpackChunkName: "uno-tenantservice" */ "@ms/uno-services/lib/local/services/graph/tenant/TenantService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedTenantService(new api.TenantService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    getRetailServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("retail"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gatedretailservice" */ "@ms/uno-services/lib/local/services/retail/GatedRetailService"),
            import(/* webpackChunkName: "uno-retailservice" */ "@ms/uno-services/lib/local/services/retail/RetailService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedRetailService(new api.RetailService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    getTodoServiceProvider(requestClient, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("todo"), () => import(/* webpackChunkName: "uno-todoservice" */ "@ms/uno-services/lib/local/services/todo/TodoService"), (bundle) => new bundle.TodoService(requestClient, configProvider));
    }
    getUserServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("user"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-gateduserservice" */ "@ms/uno-services/lib/local/services/graph/user/GatedUserService"),
            import(/* webpackChunkName: "uno-userservice" */ "@ms/uno-services/lib/local/services/graph/user/UserService"),
        ]), ([gatedApi, api]) => new gatedApi.GatedUserService(new api.UserService(requestClient, configProvider), requestPrioritizer, this.loggers));
    }
    /**
     * Get the service bundle key for the given service name
     * @param serviceName
     */
    getServiceBundleKey(serviceName) {
        return `uno-${serviceName}service`;
    }
}
