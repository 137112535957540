// Utilities
import first from "lodash/first";
import last from "lodash/last";
const HttpScheme = "http";
const HttpsScheme = "https";
/**
 * Ensures that the url string starts with http or https.
 * If the URL scheme is already http, it will be formatted with http scheme. Otherwise the url will be formatted with https scheme.
 * @param url The Url to format with http/https scheme if needed
 */ export function formatUrlWithHttpUrlScheme(url) {
    return getUrlScheme(url).toLowerCase() === HttpScheme ? ensureUrlScheme(url, HttpScheme) : ensureUrlScheme(url, HttpsScheme);
}
/**
 * Ensures that string starts with designated scheme
 * @param url The Url to add scheme to
 * @param scheme The scheme to use
 */ export function ensureUrlScheme(url, scheme) {
    const justScheme = scheme && first(scheme.split("://"));
    return `${justScheme}://${removeUrlScheme(url)}`;
}
/**
 * Remove http/https prefix from string (does not mutate string)
 * @param url Url to extract non-http(s) string from
 */ export function removeUrlScheme(url) {
    // eslint-disable-next-line security/detect-unsafe-regex
    const httpsRegex = new RegExp("(http(s)?(:)?(//)?)*(.*)", "i");
    const matches = httpsRegex.exec(url) || [];
    return last(matches) || "";
}
/**
 * Get URL scheme from string, if any (does not mutate string)
 * @param url Url to extract the url scheme from
 */ export function getUrlScheme(url) {
    const httpsRegex = new RegExp("(.*)://.*", "i");
    const matches = httpsRegex.exec(url) || [];
    return last(matches) || "";
}
/**
 * Tries to construct a URL object. Assumes the url includes http(s)://
 * @param url Url to be tested
 */ export function validateUrl(url) {
    try {
        const urlObj = new URL(url);
        if (!urlObj.host || !urlObj.hostname || !urlObj.origin || urlObj.origin === "null" || urlObj.protocol !== "http:" && urlObj.protocol !== "https:") {
            return;
        }
        return urlObj.toString();
    } catch  {
        return;
    }
}
/**
 * Generate the fav-icon url for a domain from its url
 * @param url URL of the domain for which we want the fav-icon link
 */ export function getFavIconUrl(url) {
    return "https://" + extractHostname(url) + "/favicon.ico";
}
/**
 * Formats and encodes the URL and only returns if the URL is a valid one, otherwise returns undefined
 * @param url Url to format and encode
 */ export function getValidUrl(url) {
    const formattedUrl = formatUrlWithHttpUrlScheme(url);
    return validateUrl(formattedUrl);
}
/**
 * Validates if the given URL is valid or not
 * @param url Url to validate
 */ export function isValidURL(url) {
    const formattedUrl = formatUrlWithHttpUrlScheme(url);
    return !!validateUrl(formattedUrl);
}
/**
 * Extract hostname from a string
 * @param url URL string to extract hostname from
 */ export function extractHostname(url) {
    let hostname = "";
    // Find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("://") > -1) {
        hostname = url.split("/")[2];
    } else {
        hostname = url.split("/")[0];
    }
    // Find & remove port number
    hostname = hostname.split(":")[0];
    // Find & remove "?"
    hostname = hostname.split("?")[0];
    // Find & remove "#"
    hostname = hostname.split("#")[0];
    return hostname;
}
/**
 * Get the value of a query param from a url
 * @param url Url to extract query param from
 * @param queryParamName Name of the query param to extract
 * @returns Value of the query param
 */ export function getQueryParamValue(url, queryParamName) {
    const params = new URLSearchParams(new URL(url).search);
    return params.get(queryParamName);
}
/**
 * Get the root url for the current user's tenant. This is used as a base for entity links.
 * @param userEmail
 */ export function getRedirectRootUrl(userEmail) {
    const tenantDomain = getTenantDomain(userEmail);
    const tenantDomainPath = tenantDomain ? "/" + tenantDomain : "";
    // Note: This only works for PROD environment. This will be fixed along with PBI #8616837
    return `https://planner.cloud.microsoft${tenantDomainPath}`;
}
/**
 * Some Project links have two sets of query parameters: one before the # character,
 * and one at the end according to URL standards. Accept a URL string and search both
 * query parameter sections for the specified query parameter.
 * @param urlString The URL string with non-standard query parameter sections
 * @param queryParams The query parameters to search for
 */ export function extractQueryParamsFromProjectUrl(urlString, queryParams) {
    const url = new URL(urlString);
    // searchParams will look at query params in the list at the end of the URL
    const searchParams = new URLSearchParams(url.hash.split("?")[1]);
    // The Project URL has a second list of query params before the hash. This is not standard URL formatting
    const searchParams2 = new URLSearchParams(url.href.split(/[#\?]/)[1]);
    const results = {};
    for (const queryParam of queryParams){
        results[queryParam] = searchParams.get(queryParam) ?? searchParams2.get(queryParam);
    }
    return results;
}
/**
 * Encodes a given share URL to a format suitable for ODSP to get the Shared Drive Item. The URL is base64 encoded and modified to be URL-safe.
 * https://learn.microsoft.com/en-us/graph/api/shares-get?view=graph-rest-1.0&tabs=http#encoding-sharing-urls
 * @param {string} shareUrl - The shareURL to be encoded.
 * @returns {string} The encoded shareURL in the format `u!<encodedshareUrl>`.
 */ export function encodeShareUrlForOdsp(shareUrl) {
    const encodedShareUrl = btoa(shareUrl).replace(/\//g, "_").replace(/\+/g, "-").replace(/=+$/, "");
    return `u!${encodedShareUrl}`;
}
/**
 * Parse tenant domain from user email
 * @param userEmail User email
 */ function getTenantDomain(userEmail) {
    if (!userEmail) {
        return "";
    }
    const emailParts = userEmail.split("@");
    return emailParts.length > 1 ? emailParts[1] : "";
}
