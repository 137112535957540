/**
 * Enumeration of the possible progress states
 */ export var ProgressState;
(function(ProgressState) {
    /**
     * Not started state
     */ ProgressState["NotStarted"] = "NotStarted";
    /**
     * In progress state
     */ ProgressState["InProgress"] = "InProgress";
    /**
     * Complete state
     */ ProgressState["Complete"] = "Complete";
})(ProgressState || (ProgressState = {}));
