// Action creator
import { MruItem } from "@ms/uno-models/lib/local/client/mru/MruItem";
// Actions
import { FetchMruItemsSucceededAction } from "@ms/uno-actions/lib/local/mru/MruActions";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utiliies
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
/**
 * Action creator storing the pre-fetched data
 */ export class DataPreFetchActionCreator {
    async storePreFetchedMruData(data) {
        try {
            const mruItems = [];
            const items = [
                ...data.documents?.items || [],
                ...data.places?.items || []
            ];
            items.map((item)=>MruItem.fromResource(item)).filter((mruItem)=>mruItem != null).forEach((mruItem)=>mruItems.push(mruItem));
            await this.dispatcher.dispatchActionAsync(new FetchMruItemsSucceededAction(mruItems));
        } catch (e) {
            this.loggers.traceLogger.logTrace(0x1e2886db /* tag_4ki11 */ , TraceLevel.Warning, `Saving pre-fetched mru data failed: [Error=${ErrorUtilities.getMessage(e)}]`);
        }
    }
    /**
     * Initializes a new instance of the DataPreFetchActionCreator.
     * @param dispatcher: Dispatcher to dispatch actions
     * @param loggers: Logger for telemetry
     */ constructor(dispatcher, loggers){
        this.dispatcher = dispatcher;
        this.loggers = loggers;
    }
}
