// Model
import { Plan } from "./Plan";
import { PlanDetails } from "./PlanDetails";
import { PlanEntitySet } from "./PlanEntitySet";
/**
 * Configuration and building of Plan entity set objects
 */ export class PlanEntitySetBuilder {
    /**
     * Build out the Plan formatting data object
     */ build() {
        return new PlanEntitySet(this);
    }
    /**
     * Build from a Graph Plan format collection resource entity as initialization data.
     */ fromGraphPlanResource(planResource) {
        return this.withPropertyBag({
            plan: Plan.builder().fromGraphResource(planResource).build(),
            planDetails: planResource.details == null ? undefined : PlanDetails.builder().fromGraphResource(planResource.details).build()
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.plan = propertyBag.plan;
        this.planDetails = propertyBag.planDetails;
        return this;
    }
    /**
     * Build with given Plan object
     * @param plan Plan to set in the set
     */ withPlan(plan) {
        this.plan = plan;
        return this;
    }
    /**
     * Build with given Plan details object
     * @param planDetails Plan details to set in the set
     */ withDetails(planDetails) {
        this.planDetails = planDetails;
        return this;
    }
}
