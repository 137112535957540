// Actions
import { Action } from "@ms/uno-fluxcore/lib/local/actions/Action";
// Constants
import { GraphResponseHeaders, TaskServiceResponseHeaders } from "@ms/uno-constants/lib/local/ServiceConstants";
// TODO: #8769408 - remove default generic value when all actions have been updated to use specific types
export class FailureAction extends Action {
    /**
     * Return any extra information to be logged. Action type is always logged.
     * Return null if you do not want any extra information to be logged.
     * Make sure to not include any PII. Do NOT log EUPI if not necessary.
     */ loggingData() {
        const xCorrelationId = this.response?.headers.get(TaskServiceResponseHeaders.XCorrelationId) ?? "";
        const tCorrelationId = this.response?.headers.get(TaskServiceResponseHeaders.TCorrelationId) ?? "";
        const clientRequestId = this.response?.headers.get(GraphResponseHeaders.ClientRequestId) ?? "";
        const requestId = this.response?.headers.get(GraphResponseHeaders.RequestId) ?? "";
        return {
            xCorrelationId: xCorrelationId,
            tCorrelationId: tCorrelationId,
            clientRequestId: clientRequestId,
            requestId: requestId,
            statusCode: this.response?.status?.toString() ?? ""
        };
    }
    /**
     * Creates an instance of FailureAction.
     * @param actionType Type of failure action
     * @param response The object with error info
     * @param textStatus The description of teh error that occurred
     * @param errorThrown The exception object
     */ constructor(actionType, response, textStatus, errorThrown){
        super(actionType), this.response = response, this.textStatus = textStatus, this.errorThrown = errorThrown;
    }
}
