// Models
import { RecurrenceRange } from "./RecurrenceRange";
import { RecurrencePattern } from "./RecurrencePattern";
import { RecurrenceSchedule } from "./RecurrenceSchedule";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
/**
 * Configuration and building of recurrence schedule objects
 */ export class RecurrenceScheduleBuilder {
    get pattern() {
        return this._pattern;
    }
    get range() {
        return this._range;
    }
    /**
     * Build out the recurrence schedule
     */ build() {
        return new RecurrenceSchedule(this);
    }
    fromGraphResource(resource) {
        return this.withPropertyBag({
            pattern: resource.pattern != null ? RecurrencePattern.builder.fromGraphResource(resource.pattern).build() : undefined,
            range: resource.patternStartDateTime != null ? RecurrenceRange.default().setStartDate(moment(resource.patternStartDateTime)) : undefined
        });
    }
    getDiffDataFromGraphResource(update, storeCopy) {
        // Recurrence update
        if (!("recurrence" in update)) {
            return {};
        }
        const recurrenceUpdate = update["recurrence"];
        // Schedule update
        if (!("schedule" in recurrenceUpdate)) {
            return {};
        }
        const scheduleUpdate = recurrenceUpdate["schedule"];
        const diffUpdates = {};
        // Translate pattern
        if ("pattern" in scheduleUpdate) {
            diffUpdates["pattern"] = RecurrencePattern.builder.getDiffDataFromGraphResource(update);
        }
        // Translate range
        if ("patternStartDateTime" in scheduleUpdate) {
            diffUpdates["range"] = RecurrenceRange.default().setStartDate(moment(scheduleUpdate["patternStartDateTime"]));
        }
        return diffUpdates;
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(recurrenceSchedule) {
        return this.withPropertyBag(cloneDeep(recurrenceSchedule));
    }
    /**
     * Return recurrence schedule builder object with new recurrence pattern set.
     * @param pattern New recurrence pattern info
     */ withRecurrencePattern(pattern) {
        return this.withPropertyBag({
            pattern
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this._pattern = propertyBag.pattern ?? this.pattern;
        this._range = propertyBag.range ?? this.range;
        return this;
    }
    /**
     * Initialize an instance of the recurrence schedule builder.
     * @remarks
     * Equivalent to daily with nextOccurrenceDate undefined (see below)
     * {
     *      pattern to {
     *          dayOfMonth to null
     *          daysOfWeek to null
     *          firstDayOfWeek to null
     *          interval to 1
     *          month to null
     *          recurrencePatternType to Daily
     *          weekIndex to null
     *      }
     *      range to {
     *          startDate to today
     *          recurrenceRangeType to no end
     *          endDate to null
     *          numberOfOccurrences to null
     *      }
     * }
     */ constructor(){
        this._pattern = RecurrencePattern.builder.build();
        this._range = RecurrenceRange.default();
    }
}
