// Models
import { RecurrencePattern } from "./RecurrencePattern";
import { RecurrenceRange } from "./RecurrenceRange";
import { RecurrenceScheduleBuilder } from "./RecurrenceScheduleBuilder";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import { ToUTC10am } from "@ms/uno-utilities/lib/local/DateUtilities";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
/**
 * Represents a recurrence schedule entity in the client
 */ export class RecurrenceSchedule {
    /**
     * Builder for RecurrenceSchedule objects
     */ static get builder() {
        return new RecurrenceScheduleBuilder();
    }
    toGraphSerializable() {
        return {
            pattern: this.pattern.toGraphSerializable(),
            patternStartDateTime: this.range.startDate.toISOString()
        };
    }
    setRecurrencePattern(pattern) {
        return RecurrenceSchedule.builder.forClone({
            ...this,
            pattern
        }).build();
    }
    setRecurrenceRangeStartDate(startDate) {
        return RecurrenceSchedule.builder.forClone({
            ...this,
            range: this.range.setStartDate(ToUTC10am(startDate))
        }).build();
    }
    generateHash() {
        return [
            this.pattern.generateHash(),
            this.range.generateHash()
        ].toString();
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(RecurrenceSchedule.builder.forClone(this).build(), ...diffs, RecurrenceSchedule.mergeCustomizer);
        }
        return this;
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === "pattern") {
                // Recurrence pattern is immutable on the service. The entire object has to be sent when changed.
                if (!isEqual(source, target)) {
                    // Pattern is different,
                    return {
                        ...target
                    };
                } else {
                    // Equal
                    return {};
                }
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (key === "pattern" && !isEqual(source, target)) {
            // Recurrence pattern is immutable on the service. The entire object has to be sent when changed.
            return mergeWith(RecurrencePattern.builder.build(), cloneDeep(target));
        } else if (key === "range") {
            return mergeWith(RecurrenceRange.default(), cloneDeep(source), cloneDeep(target));
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    /**
     * Initializes a new instance of the "RecurrenceSchedule" entity.
     * @param builder Builder with the initialization data
     */ constructor(builder){
        this.pattern = builder.pattern;
        this.range = builder.range;
    }
}
