import { concatStyleSets } from "@fluentui/react";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import * as HighContrastModeConstants from "@ms/uno-constants/lib/local/HighContrastModeConstants";
import { Browser } from "@ms/uno-constants/lib/local/AppConstants";
import { getBrowser } from "@ms/uno-utilities/lib/local/BrowserUtilities";
/**
 * Applies style only if high contrast mode is active
 * @param style Style to apply in high contrast
 */ export const highContrast = (style)=>{
    return {
        "@media screen and (-ms-high-contrast: active)": style
    };
};
/**
 * Applies style only if high contrast black-on-white mode is active
 * @param style Style to apply in high contrast
 */ export const highContrastBlackOnWhite = (style)=>{
    return {
        "@media screen and (-ms-high-contrast: black-on-white)": style
    };
};
/**
 * Applies style only if high contrast white-on-black mode is active
 * @param style Style to apply in high contrast
 */ export const highContrastWhiteOnBlack = (style)=>{
    return {
        "@media screen and (-ms-high-contrast: white-on-black)": style
    };
};
/**
 * Applies style only if high contrast white-on-black mode is active
 * @param style Style to apply in high contrast
 */ export const highContrastSelectionBorder = (style)=>{
    return concatStyleSets(highContrast([
        {
            borderColor: HighContrastModeConstants.HighContrastHighlight,
            "-ms-high-contrast-adjust": "none"
        },
        style
    ]), highContrastWhiteOnBlack([
        {
            borderColor: Colors.HighContrastCyan
        },
        style
    ]));
};
/**
 * Style for border in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastBorders = (style)=>{
    const styleWithBorderColor = (color)=>{
        const borderColorStyle = {
            border: "1px solid " + color
        };
        return concatStyleSets(borderColorStyle, style);
    };
    return concatStyleSets(highContrast(styleWithBorderColor(HighContrastModeConstants.HighContrastWindowText)));
};
/**
 * Style for highlight borders in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastHighlightBorders = (style)=>{
    const styleWithBorderColor = (color)=>{
        const borderColorStyle = {
            border: "1px solid " + color
        };
        return concatStyleSets(borderColorStyle, style);
    };
    return concatStyleSets(highContrast(styleWithBorderColor(HighContrastModeConstants.HighContrastHighlightText)));
};
/**
 * Style for background in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastBackground = (style)=>{
    const styleWithBackgroundColor = (color)=>{
        const backgroundColorStyle = {
            background: color
        };
        return concatStyleSets(backgroundColorStyle, style);
    };
    return concatStyleSets(highContrast(styleWithBackgroundColor(HighContrastModeConstants.HighContrastWindow)));
};
/**
 * Inverted style for background in high contrast
 * @param style Custom style to apply in high contrast
 */ export const invertedHighContrastBackground = (style)=>{
    const styleWithBackgroundColor = (color)=>{
        const backgroundColorStyle = {
            background: color
        };
        return concatStyleSets(backgroundColorStyle, style);
    };
    return concatStyleSets(highContrast(styleWithBackgroundColor(Colors.White)), highContrastBlackOnWhite(styleWithBackgroundColor(Colors.Black)), highContrastWhiteOnBlack(styleWithBackgroundColor(Colors.White)));
};
/**
 * Style for color in high contrast
 */ export const highContrastColor = ()=>{
    return concatStyleSets(highContrast({
        color: HighContrastModeConstants.HighContrastWindowText
    }), highContrastWhiteOnBlack({
        color: Colors.White
    }));
};
/**
 * Style for highlight color in high contrast
 */ export const highContrastHighlightColor = ()=>{
    return highContrast({
        color: HighContrastModeConstants.HighContrastHighlight
    });
};
/**
 * Style for highlight text color in high contrast
 */ export const highContrastHighlightTextColor = ()=>{
    return highContrast({
        color: HighContrastModeConstants.HighContrastHighlightText
    });
};
/**
 * Inverted style for color in high contrast
 */ export const invertedHighContrastColor = ()=>{
    return concatStyleSets(highContrast({
        color: HighContrastModeConstants.HighContrastWindowText
    }), highContrastWhiteOnBlack({
        color: Colors.Black
    }));
};
/**
 * Style for background fill color in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastBackgroundFill = (style)=>{
    const styleWithBackgroundFillColor = (color)=>{
        const backgroundFillStyle = {
            fill: color
        };
        return concatStyleSets(backgroundFillStyle, style);
    };
    return concatStyleSets(highContrast(styleWithBackgroundFillColor(Colors.Black)), highContrastBlackOnWhite(styleWithBackgroundFillColor(Colors.White)), highContrastWhiteOnBlack(styleWithBackgroundFillColor(Colors.Black)));
};
/**
 * Style for text fill color in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastTextFill = (style)=>{
    const styleWithTextFillColor = (color)=>{
        const textFillStyle = {
            fill: color
        };
        return concatStyleSets(textFillStyle, style);
    };
    return concatStyleSets(highContrast(styleWithTextFillColor(Colors.HighContrastYellow)), highContrastBlackOnWhite(styleWithTextFillColor(Colors.Black)), highContrastWhiteOnBlack(styleWithTextFillColor(Colors.White)));
};
/**
 * Style for primary button in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastPrimaryButton = (style)=>{
    const buttonStyle = {
        color: HighContrastModeConstants.HighContrastWindowText,
        backgroundColor: HighContrastModeConstants.HighContrastWindow,
        borderColor: HighContrastModeConstants.HighContrastWindowText,
        // Some button css rules with pseudo class selectors will overwrite high contrast css rules
        // by adding following lines will make sure win11 high contrast rules will have the highest priorities to apply.
        ":disabled, :disabled:hover": {
            color: HighContrastModeConstants.HighContrastWindowText,
            backgroundColor: HighContrastModeConstants.HighContrastWindow,
            borderColor: HighContrastModeConstants.HighContrastWindowText,
            opacity: "0.5"
        }
    };
    return highContrast(concatStyleSets(buttonStyle, style));
};
/**
 * Style for highlight (focus/hover) in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastHighlightStyle = (style)=>{
    const highlightStyle = {
        color: HighContrastModeConstants.HighContrastHighlight,
        backgroundColor: HighContrastModeConstants.HighContrastHighlightText,
        borderColor: HighContrastModeConstants.HighContrastHighlight
    };
    return highContrast(concatStyleSets(highlightStyle, style));
};
/**
 * Style for line in high contrast
 */ export const highContrastLine = ()=>{
    return concatStyleSets(highContrast({
        backgroundColor: HighContrastModeConstants.HighContrastWindowText
    }), highContrastWhiteOnBlack({
        backgroundColor: Colors.White
    }));
};
/**
 * Style for line with inverted colors in high contrast
 */ export const highContrastLineInverted = ()=>{
    return concatStyleSets(highContrast({
        backgroundColor: HighContrastModeConstants.HighContrastWindowText
    }), highContrastWhiteOnBlack({
        backgroundColor: Colors.Black
    }));
};
/**
 * Style for selection in high contrast
 */ export const highContrastSelection = ()=>{
    return concatStyleSets(highContrast({
        backgroundColor: HighContrastModeConstants.HighContrastHighlight,
        "-ms-high-contrast-adjust": "none"
    }), highContrastWhiteOnBlack({
        backgroundColor: Colors.HighContrastCyan
    }));
};
/**
 * Style for selection text inverted in high contrast
 */ export const highContrastSelectionTextInverted = concatStyleSets(highContrast({
    color: HighContrastModeConstants.HighContrastHighlight,
    backgroundColor: HighContrastModeConstants.HighContrastHighlightText,
    "-ms-high-contrast-adjust": "none"
}), highContrastWhiteOnBlack({
    color: Colors.HighContrastCyan,
    backgroundColor: Colors.Black
}));
/**
 * Style for text selection in high contrast
 * @param style Custom style to apply in high contrast
 */ export const highContrastSelectionText = (style)=>{
    const highlightStyle = {
        color: HighContrastModeConstants.HighContrastHighlightText,
        backgroundColor: HighContrastModeConstants.HighContrastHighlight,
        "-ms-high-contrast-adjust": "none"
    };
    return highContrast(concatStyleSets(highlightStyle, style));
};
/**
 * Style for selection button color in high contrast
 */ export const highContrastSelectionButton = concatStyleSets(highContrast({
    color: HighContrastModeConstants.HighContrastHighlightText,
    backgroundColor: HighContrastModeConstants.HighContrastHighlight,
    "-ms-high-contrast-adjust": "none"
}), highContrastWhiteOnBlack({
    color: Colors.Black,
    backgroudColor: Colors.HighContrastCyan
}));
/**
 * High Contrast Mode style for option or button in focus state
 * @param style Custom override style
 */ export const highContrastFocusedButton = (style)=>{
    const onFocusStyle = {
        boxShadow: `inset 0px 0px 0px 1px ${HighContrastModeConstants.HighContrastWindowText}`,
        outline: `1px solid ${HighContrastModeConstants.HighContrastButtonFace}`,
        outlineOffset: "-4px",
        "-ms-high-contrast-adjust": "none"
    };
    return highContrast(concatStyleSets(onFocusStyle, style));
};
/**
 * HCM style for option or button (hover/focus state) on non-Edge browsers only.
 * Note: An outline is needed to show button focus and hover states on non-Edge browsers because -ms-high-contrast styles only work on Edge.
 */ export const highContrastNonEdgeBrowserButtonOutline = ()=>{
    return getBrowser() !== Browser.Edge ? {
        outline: "3px solid transparent"
    } : null;
};
