// Models
import { AttachmentBuilder } from "./AttachmentBuilder";
import { TaskAttachment } from "../../taskAttachment/TaskAttachment";
import { TaskAttachmentType } from "../../taskAttachment/TaskAttachmentType";
import { GraphPlannerODataType } from "../../../service/graph/planner/GraphPlannerODataType";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import forOwn from "lodash/forOwn";
import toArray from "lodash/toArray";
import { compareOrderHints } from "@ms/uno-utilities/lib/local/Comparators";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Represents an attachment entity on a task
 */ export class Attachment extends TaskAttachment {
    /** Builder for Attachment objects */ static get builder() {
        return new AttachmentBuilder();
    }
    /**
     * Convert the dictionary of graph references from resource type to client type
     * @param references Dictionary where key is url
     */ static getGraphClientDictionaryType(references) {
        const result = {};
        forOwn(references, (value, key)=>{
            key = decodeURIComponent(key);
            result[key] = Attachment.builder.fromGraphAttachmentResource(key, value).build();
        });
        return result;
    }
    /**
     * Converts the graph attachment items dictionary to one of IGraphExternalReferenceResource type
     * @param references Reference dictionary of client type
     */ static getGraphResourceDictionaryType(references) {
        const result = {};
        forOwn(references, (value, key)=>{
            result[Attachment.encodeGraphReferenceKey(key)] = value.toGraphSerializable();
        });
        return result;
    }
    /**
     * Remove nulls and then sort by order hint
     * @param attachments Map with all attachments
     */ static getSortedAttachments(attachments) {
        return compact(toArray(attachments)).sort((item1, item2)=>{
            return -1 * compareOrderHints(item1.previewPriority, item2.previewPriority);
        });
    }
    /**
     * Encodes the key attachment reference URL that can be used on graph serialization.
     * Documention says "property names in Open Types cannot contain the following characters: ., :, %, @, # so they need to be encoded"
     * See https://developer.microsoft.com/en-us/graph/docs/api-reference/v1.0/resources/plannerexternalreferences
     * @param key The reference URL to be used in graph serialization
     */ static encodeGraphReferenceKey(key) {
        return key.replace(/%/g, "%25").replace(/\./g, "%2E").replace(/:/g, "%3A").replace(/@/g, "%40").replace(/#/g, "%23");
    }
    toGraphSerializable() {
        return {
            "@odata.type": GraphPlannerODataType.PlannerExternalReference,
            alias: this.alias,
            type: this.taskAttachmentType ? this.taskAttachmentType.taskAttachmentType : null,
            previewPriority: this.previewPriority
        };
    }
    getClone() {
        return Attachment.builder.withPropertyBag({
            alias: this.alias,
            taskAttachmentType: this.taskAttachmentType ? cloneDeep(this.taskAttachmentType) : TaskAttachmentType.builder.withPropertyBag({
                url: this.url
            }).build(),
            url: this.url,
            previewPriority: this.previewPriority,
            isProtected: this.isProtected,
            clientExtensions: this.clientExtentions
        }).build();
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "taskAttachmentType" || key === "isProtected" || key === "clientExtentions") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    getItemPath(driveUrl) {
        if (LocalIdGenerator.isLocalTempId(this.url)) {
            return null;
        }
        const decodedUrl = decodeURIComponent(this.url);
        let decodedDriveUrl = decodeURIComponent(driveUrl);
        // Sadly endsWith is not supported in IE11 (it's in ES6)
        if (decodedDriveUrl.lastIndexOf("/") !== decodedDriveUrl.length - 1) {
            decodedDriveUrl += "/";
        }
        // Use regex so it can search/replace case-insensitive
        // eslint-disable-next-line security/detect-non-literal-regexp
        const regExDriveUrl = new RegExp(decodedDriveUrl, "ig");
        if (decodedUrl.search(regExDriveUrl) < 0) {
            let errorMessage = "";
            // We now have two patterns for the URL:
            // 1) https://microsoft.sharepoint.com/teams/{groupName}/Shared Documents/{itemPath/fileName}?web=1
            // 2) https://microsoft.sharepoint-df.com/teams/{groupName}/_layouts/15/Doc.aspx?sourcedoc={eTag}&file={itemPath/fileName}&action=default&mobileredirect=true
            // Thus we can no longer just apply the WebUrl pattern we need to see if it has this sourcedoc url
            const regExWebUrl = new RegExp("[?]sourcedoc={.*}&file=", "ig");
            if (decodedUrl.search(regExWebUrl) < 0) {
                errorMessage = "OneDrive webUrl attachment url detected.";
            }
            // If the drive url is different from attachment url return null, as User might hit this more often with old data
            errorMessage += "Drive url different from attachment url";
            throw new Error(errorMessage);
        }
        return decodedUrl.replace(regExDriveUrl, "").replace("?web=1", "");
    }
    constructor(attachmentBuilder){
        super(attachmentBuilder);
        this.previewPriority = attachmentBuilder.previewPriority;
        this.isProtected = attachmentBuilder.isProtected;
        this.clientExtentions = attachmentBuilder.clientExtensions;
    }
}
