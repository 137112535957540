/**
 * Portfolio Status types
 */ export var PortfolioItemStatus;
(function(PortfolioItemStatus) {
    PortfolioItemStatus["Default"] = "Default";
    PortfolioItemStatus["NotStarted"] = "NotStarted";
    PortfolioItemStatus["OnTrack"] = "OnTrack";
    PortfolioItemStatus["AtRisk"] = "AtRisk";
    PortfolioItemStatus["OffTrack"] = "OffTrack";
    PortfolioItemStatus["Closed"] = "Closed";
})(PortfolioItemStatus || (PortfolioItemStatus = {}));
/**
 * Portfolio Status type options
 */ export const PortfolioStatusTypeOptions = [
    "NotStarted",
    "OnTrack",
    "AtRisk",
    "OffTrack",
    "Closed"
];
export var PortfolioItemType;
(function(PortfolioItemType) {
    /** Root portfolio item type */ PortfolioItemType["Portfolio"] = "Portfolio";
    /** Child portfolio item type */ PortfolioItemType["Project"] = "Project";
})(PortfolioItemType || (PortfolioItemType = {}));
export var PortfolioItemSubType;
(function(PortfolioItemSubType) {
    /** Root portfolio item created from legacy roadmap experience */ PortfolioItemSubType["Roadmap"] = "Roadmap";
    /** Child portfolio item created from legacy roadmap experience */ PortfolioItemSubType["RoadmapRow"] = "RoadmapRow";
    /** Root portfolio item created from portfolio experience */ PortfolioItemSubType["Portfolio"] = "Portfolio";
    /** Child portfolio item created from portfolio experience */ PortfolioItemSubType["PortfolioRow"] = "PortfolioRow";
})(PortfolioItemSubType || (PortfolioItemSubType = {}));
export var PortfolioExperienceType;
(function(PortfolioExperienceType) {
    /**
     * Old item where upgrade experience needs to be shown
     * Roadmap Created before Portfolio release, service version value - V1
     */ PortfolioExperienceType["Legacy"] = "V1";
    /**
     * Old item that have been upgraded to new item
     * Roadmap Created before Portfolio release and upgraded after Portfolio release, service version value - V1V2
     */ PortfolioExperienceType["Migrated"] = "V1V2";
    /**
     * New item where no upgrade experience needs to be shown
     * Roadmap/Portfolio Created after Portfolio release, service version value - V2
     */ PortfolioExperienceType["Current"] = "V2";
})(PortfolioExperienceType || (PortfolioExperienceType = {}));
