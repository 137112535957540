// Constants
import { SubRouteKeys, SubRouteType, ViewType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { DefaultMyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
import { ClientFlavor } from "@ms/uno-constants/lib/local/AppConstants";
/**
 * Provides the initial route based on the app flavor and configuration
 */
export function getInitialRoute(clientFlavor) {
    return {
        rootUrl: "", // TODO [ADO#8706197] - Should be the teams root url (but setting this currently breaks RouteStore - Need to investigate/fix)
        subRouteType: getInitialSubRouteType(clientFlavor),
        subRoute: getInitialSubRoute(clientFlavor),
        queryParameters: {},
    };
}
/**
 * Provide the initial subRouteType based on the app flavor
 */
export function getInitialSubRouteType(clientFlavor) {
    switch (clientFlavor) {
        case ClientFlavor.ConfigTab:
            return SubRouteType.ConfigTeamsTab;
        case ClientFlavor.RemoveTab:
            return SubRouteType.RemoveTeamsTab;
        case ClientFlavor.TaskFromMessage:
            return SubRouteType.MessageTasks;
        default:
            return SubRouteType.MyTasks;
    }
}
/**
 * Provide the initial subRoute based on the app flavor and configuration
 */
export function getInitialSubRoute(clientFlavor) {
    if (clientFlavor === ClientFlavor.TaskFromMessage) {
        return [];
    }
    else {
        return [DefaultMyTasksViewPivotType, SubRouteKeys.View, ViewType.Grid];
    }
}
