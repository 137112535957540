/**
 * Archival information for a plan
 */ export class ArchivalInfo {
    /**
     * Creates a new archival info object from a given service resource
     * @param resource the service model
     */ static fromResource(resource) {
        return new ArchivalInfo({
            justification: resource.justification,
            statusChangedDateTime: resource.statusChangedDateTime,
            statusChangedBy: {
                application: {
                    displayName: resource.statusChangedBy.application.displayName,
                    id: resource.statusChangedBy.application.id
                },
                user: resource.statusChangedBy.user == null ? null : {
                    displayName: resource.statusChangedBy.user.displayName,
                    id: resource.statusChangedBy.user.id
                }
            }
        });
    }
    toGraphSerializable() {
        return {
            justification: this.justification,
            statusChangedBy: {
                application: {
                    displayName: this.statusChangedBy.application.displayName,
                    id: this.statusChangedBy.application.id
                },
                user: this.statusChangedBy.user == null ? null : {
                    displayName: this.statusChangedBy.user.displayName,
                    id: this.statusChangedBy.user.id
                }
            },
            statusChangedDateTime: this.statusChangedDateTime
        };
    }
    /**
     * Create a new instance of ArchivalInfo
     * @param propertyBag IArchivalInfo object to hydrate the entity
     */ constructor(propertyBag){
        this.justification = propertyBag.justification;
        this.statusChangedDateTime = propertyBag.statusChangedDateTime;
        this.statusChangedBy = {
            application: {
                displayName: propertyBag.statusChangedBy.application.displayName,
                id: propertyBag.statusChangedBy.application.id
            },
            user: propertyBag.statusChangedBy.user == null ? null : {
                displayName: propertyBag.statusChangedBy.user.displayName,
                id: propertyBag.statusChangedBy.user.id
            }
        };
    }
}
