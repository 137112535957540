// Models
import { RecurrencePatternBuilder } from "./RecurrencePatternBuilder";
import { RecurrencePatternType } from "../../../../service/graph-legacy/task/recurrence/RecurrencePatternType";
// Utilities;
import { DayOfWeek, WeekIndex } from "@ms/uno-utilities/lib/local/DateUtilities";
/**
 * Represents a recurrence pattern entity in the client
 */ export class RecurrencePattern {
    /**
     * Builder for RecurrencePattern objects
     */ static get builder() {
        return new RecurrencePatternBuilder();
    }
    toGraphSerializable() {
        // Graph requires default values for properties that are not used for that type. We store these as nulls, thus we need to default the values when null.
        return {
            dayOfMonth: this.dayOfMonth ?? 0,
            daysOfWeek: this.daysOfWeek ?? [],
            firstDayOfWeek: this.firstDayOfWeek ?? DayOfWeek.Sunday,
            index: this.weekIndex ?? WeekIndex.First,
            interval: this.interval,
            month: this.month ?? 0,
            type: this.recurrencePatternType
        };
    }
    generateHash() {
        const result = [
            this.recurrencePatternType,
            this.interval.toString()
        ];
        switch(this.recurrencePatternType){
            case RecurrencePatternType.RelativeYearly:
                result.push(this.month.toString());
            case RecurrencePatternType.RelativeMonthly:
                result.push(this.weekIndex.toString());
            case RecurrencePatternType.Weekly:
                result.push(this.daysOfWeek.toString());
                break;
            case RecurrencePatternType.AbsoluteYearly:
                result.push(this.month.toString());
            case RecurrencePatternType.AbsoluteMonthly:
                result.push(this.dayOfMonth.toString());
                break;
            case RecurrencePatternType.Daily:
            default:
                break;
        }
        return result.toString();
    }
    /**
     * Initializes a new instance of the "RecurrencePattern" entity.
     * @param builder Builder with the initialization data
     */ constructor(builder){
        this.dayOfMonth = builder.dayOfMonth;
        this.daysOfWeek = builder.daysOfWeek;
        this.firstDayOfWeek = builder.firstDayOfWeek;
        this.interval = builder.interval;
        this.month = builder.month;
        this.recurrencePatternType = builder.recurrencePatternType;
        this.weekIndex = builder.weekIndex;
    }
}
