// Constants
import { projectManagerAgentId } from "@ms/uno-constants/lib/local/AgentConstants";
/**
 * Factory class for agent providers
 */
export class AgentFactory {
    /** Initialize a new instance of the agent factory
     * * @param loader The component loader to use for lazy loading agents
     */
    constructor(loader) {
        this.loadProjectManagerAgent = () => this.loader.load(projectManagerAgentId, //We use the id of the agent as the key to load the agent
        () => import("@ms/uno-models/lib/local/client/agent/ProjectManagerAgent"), (bundle) => bundle.getProjectManagerAgent);
        this.loader = loader;
    }
    /** Get the provider for the project manager agent */
    getProjectManagerAgentProvider() {
        return this.loadProjectManagerAgent;
    }
}
