// Model
import { AssignedToTaskboardTaskFormatting } from "./AssignedToTaskboardTaskFormatting";
import { BucketTaskboardTaskFormatting } from "./BucketTaskboardTaskFormatting";
import { ProgressTaskboardTaskFormatting } from "./ProgressTaskboardTaskFormatting";
import { TaskboardTaskFormattingCollection } from "./TaskboardTaskFormattingCollection";
/**
 * Configuration and building of task format collection objects
 */ export class TaskboardTaskFormattingCollectionBuilder {
    /**
     * Build out the task formatting data object
     */ build() {
        return new TaskboardTaskFormattingCollection(this);
    }
    /**
     * Build from a Graph task format collection resource entity as initialization data.
     * @param taskFormatResource Task format resource entity to use for initializing the task format collection builder
     */ fromGraphTaskBoardTaskFormattingCollectionResource(taskFormatResource) {
        return this.withPropertyBag({
            assignedBoardFormat: taskFormatResource.assignmentBoardFormat == null ? undefined : AssignedToTaskboardTaskFormatting.builder.fromGraphResource(taskFormatResource.assignmentBoardFormat).build(),
            bucketBoardFormat: taskFormatResource.bucketBoardFormat == null ? undefined : BucketTaskboardTaskFormatting.builder.fromGraphResource(taskFormatResource.bucketBoardFormat).build(),
            progressBoardFormat: taskFormatResource.progressBoardFormat == null ? undefined : ProgressTaskboardTaskFormatting.builder.fromGraphResource(taskFormatResource.progressBoardFormat).build()
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.assignedBoardFormat = propertyBag.assignedBoardFormat || this.assignedBoardFormat;
        this.bucketBoardFormat = propertyBag.bucketBoardFormat || this.bucketBoardFormat;
        this.progressBoardFormat = propertyBag.progressBoardFormat || this.progressBoardFormat;
        return this;
    }
    /**
     * Initialize an instance of the task format collection builder
     */ constructor(){
    /* Empty constructor */ }
}
