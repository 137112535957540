// Models
import { Container } from "./Container";
// Utilities
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
import mergeWith from "lodash/mergeWith";
/**
 * Represents a container shared with a plan
 */ export class SharedWithContainer extends Container {
    toGraphSerializable() {
        return {
            containerId: this.externalId,
            type: this.containerType,
            accessLevel: this.accessLevel
        };
    }
    getDiff(target) {
        return getDiff(this, target, getDiffMomentCustomizer);
    }
    applyDiffs(...diff) {
        if (diff.length > 0) {
            return mergeWith(this.getClone(), ...diff, applyDiffMomentCustomizer);
        }
        return this;
    }
    getClone() {
        return new SharedWithContainer({
            externalId: this.externalId,
            containerType: this.containerType
        }, this.accessLevel);
    }
    /**
     * Initializes a new instance of the "SharedWithContainer" entity.
     */ constructor(containerId, accessLevel){
        super(containerId);
        this.accessLevel = accessLevel;
    }
}
