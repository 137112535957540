// Constants
import { ClientAppId } from "@ms/uno-constants/lib/local/AppConstants";
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
// Server resource
import { ContainerType } from "../../../service/graph-legacy/container/ContainerType";
// Models
import { ArchivalInfo } from "./archival/ArchivalInfo";
import { CreationProcessInfo } from "./CreationProcessInfo";
import { Plan } from "./Plan";
import { PlanContext } from "./PlanContext";
import { PlanCreationSource } from "./creationSource/PlanCreationSource";
import { SharedWithContainer } from "../container/SharedWithContainer";
import { DeltaTranslationMap } from "../deltaSync/DeltaTranslationMap";
// Utilities
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import cloneDeep from "lodash/cloneDeep";
/**
 * Configuration and building of plan objects
 */ export class PlanBuilder {
    /**
     * Build out the plan
     */ build() {
        return new Plan(this);
    }
    /**
     * Build from a plan resource returned from graph
     * @param graphResource Plan resource entity
     */ fromGraphResource(graphResource) {
        let sharedWithContainers = [];
        if (graphResource.sharedWithContainers != null) {
            sharedWithContainers = graphResource.sharedWithContainers.map((sharedContainerResource)=>new SharedWithContainer({
                    externalId: sharedContainerResource.containerId,
                    containerType: sharedContainerResource.type
                }, sharedContainerResource.accessLevel));
        }
        return this.withPropertyBag({
            archivalInfo: graphResource.archivalInfo ? ArchivalInfo.fromResource(graphResource.archivalInfo) : null,
            container: {
                externalId: graphResource.container.containerId,
                containerType: graphResource.container.type
            },
            createdBy: graphResource.createdBy != null ? graphResource.createdBy.user != null ? graphResource.createdBy.user.id : undefined : undefined,
            createdByAppId: graphResource.createdBy != null ? graphResource.createdBy.application != null ? graphResource.createdBy.application.id : undefined : undefined,
            createdDate: graphResource.createdDateTime,
            deletedBy: graphResource.deletedBy != null ? graphResource.deletedBy.user != null ? graphResource.deletedBy.user.id : undefined : undefined,
            id: graphResource.id,
            isArchived: graphResource.isArchived,
            itemVersion: graphResource["@odata.etag"],
            title: graphResource.title,
            creationSource: graphResource.creationSource ? new PlanCreationSource(graphResource.creationSource) : undefined,
            contexts: PlanContext.getContextsFromGraphResource(graphResource.contexts || {}),
            sharedWithContainers
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.archivalInfo = propertyBag.archivalInfo ?? this.archivalInfo;
        this.container = propertyBag.container ?? this.container;
        this.createdBy = propertyBag.createdBy || this.createdBy;
        this.createdByAppId = propertyBag.createdByAppId || this.createdByAppId;
        this.createdDate = propertyBag.createdDate || this.createdDate;
        this.creationSource = propertyBag.creationSource || this.creationSource;
        this.creationProcessInfo = CreationProcessInfo.builder.withPropertyBag(propertyBag).build();
        this.deletedBy = propertyBag.deletedBy || this.deletedBy;
        this.id = propertyBag.id;
        this.isArchived = propertyBag.isArchived ?? this.isArchived;
        this.itemVersion = propertyBag.itemVersion || this.itemVersion;
        this.title = propertyBag.title || this.title;
        this.contexts = propertyBag.contexts || this.contexts;
        this.sharedWithContainers = propertyBag.sharedWithContainers || this.sharedWithContainers;
        return this;
    }
    /**
     * Add a locally unique id to the plan
     */ withLocallyUniqueId() {
        this.id = LocalIdGenerator.generate();
        return this;
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        const translateGraphUser = (graphUser)=>graphUser?.user?.id;
        const translateReqGraphUser = (graphUser)=>graphUser?.user?.id ?? "";
        const translateGraphAppId = (graphUser)=>graphUser?.application?.id ?? "";
        const translatePlanContextUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const planContexts = storeCopy?.contexts ? cloneDeep(storeCopy.contexts) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete planContexts[key];
                } else if (key in planContexts) {
                    // Update Item values - may include partial properties
                    if ("associationType" in graphUpdates[key]) {
                        planContexts[key] = planContexts[key].setProperty("associationType", graphUpdates[key].associationType);
                    }
                    if ("displayNameSegments" in graphUpdates[key]) {
                        planContexts[key] = planContexts[key].setProperty("displayNames", graphUpdates[key].displayNameSegments);
                    }
                } else {
                    // Insert item
                    planContexts[key] = PlanContext.getGraphPlanContext(graphUpdates[key]);
                }
            }
            return planContexts;
        };
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("createdBy", "createdBy", translateReqGraphUser);
        graphTranslationMap.addMapping("createdBy", "createdByAppId", translateGraphAppId);
        graphTranslationMap.addMapping("createdDateTime", "createdDate");
        graphTranslationMap.addMapping("deletedBy", "deletedBy", translateGraphUser);
        graphTranslationMap.addMapping("title", "title");
        graphTranslationMap.addMapping("contexts", "contexts", translatePlanContextUpdates);
        graphTranslationMap.addMapping("creationSource", "creationSource");
        graphTranslationMap.addMapping("isArchived", "isArchived");
        graphTranslationMap.addMapping("archivalInfo", "archivalInfo");
        return graphTranslationMap.translate(update);
    }
    /**
     * Initialize an instance of the plan builder
     */ constructor(){
        this.archivalInfo = null;
        this.id = LocalIdGenerator.generate();
        this.title = "";
        this.creationProcessInfo = CreationProcessInfo.builder.build();
        this.container = {
            externalId: "",
            containerType: ContainerType.Invalid
        };
        this.createdBy = "";
        this.createdDate = new Date();
        this.deletedBy = null;
        this.isArchived = false;
        this.itemVersion = "";
        this.createdByAppId = ClientAppId.PlannerWeb;
        this.creationSource = new PlanCreationSource({});
        this.contexts = {};
        this.sharedWithContainers = [];
    }
}
