// Constants
import { FileTypeExtensions } from "@ms/uno-constants/lib/local/FileTypeExtensions";
import { FileTypesWithPreview, NonPreviewableSupportedFileTypes, SupportedReferenceTypes } from "./ITaskAttachmentType";
import { TaskAttachmentBuilder } from "./TaskAttachmentBuilder";
// Utilities
import { compareStringForDisplay } from "@ms/uno-utilities/lib/local/Comparators";
import endsWith from "lodash/endsWith";
import indexOf from "lodash/indexOf";
/**
 * Represents an attachment entity on a task
 */ export class TaskAttachment {
    /** Builder for TaskAttachment objects */ static get builder() {
        return new TaskAttachmentBuilder();
    }
    /**
     * Comparator for alias based sort
     * @param item1 First task attachment
     * @param item2 Second task attachment
     */ static aliasCompare(item1, item2) {
        return compareStringForDisplay(item1?.alias ? item1.alias.toUpperCase() : null, item2?.alias ? item2.alias.toUpperCase() : null);
    }
    isExternalLinkAttachment(groupDriveUrl) {
        if (this.taskAttachmentType.taskAttachmentType !== SupportedReferenceTypes.Other) {
            return false;
        }
        // We now have two patterns for the URL:
        // 1) https://microsoft.sharepoint.com/teams/{groupName}/Shared Documents/{itemPath/fileName}?web=1
        // 2) https://microsoft.sharepoint-df.com/teams/{groupName}/_layouts/15/Doc.aspx?sourcedoc={eTag}&file={fileName}&action=default&mobileredirect=true
        // If the taskAttachmentType is "Other" but the URL is like the 2nd pattern, this will return true (is external link).
        const decodedAttachmentUrl = decodeURIComponent(this.url);
        let decodedGroupDriveUrl = decodeURIComponent(groupDriveUrl);
        if (!endsWith(decodedGroupDriveUrl, "/")) {
            decodedGroupDriveUrl += "/";
        }
        // Use regex so it can search/replace case-insensitive
        // eslint-disable-next-line security/detect-non-literal-regexp
        const regExGroupDriveUrl = new RegExp(decodedGroupDriveUrl, "ig");
        return decodedAttachmentUrl.search(regExGroupDriveUrl) < 0;
    }
    getFileExtension() {
        if (this.taskAttachmentType.specificType) {
            return this.taskAttachmentType.specificType;
        }
        let fileExtension = null;
        if (this.taskAttachmentType.taskAttachmentType !== SupportedReferenceTypes.Other) {
            switch(this.taskAttachmentType.taskAttachmentType){
                case SupportedReferenceTypes.Excel:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.Excel);
                    break;
                case SupportedReferenceTypes.OneNote:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.OneNote);
                    break;
                case SupportedReferenceTypes.Pdf:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.Pdf);
                    break;
                case SupportedReferenceTypes.PowerPoint:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.PowerPoint);
                    break;
                case SupportedReferenceTypes.Project:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.Project);
                    break;
                case SupportedReferenceTypes.Visio:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.Visio);
                    break;
                case SupportedReferenceTypes.Word:
                    fileExtension = this.getExtensionFromUrl(this.url, SupportedReferenceTypes.Word);
                    break;
                case SupportedReferenceTypes.TeamsHostedApp:
                    fileExtension = null;
                    break;
                default:
                    break;
            }
        }
        if (fileExtension == null) {
            // Fall back to trying to parse attachment alias for an extension
            const aliasSplit = this.alias.split(".");
            if (aliasSplit.length > 1) {
                const possibleExtension = aliasSplit[aliasSplit.length - 1];
                if (possibleExtension.length === 3 || possibleExtension.length === 4) {
                    // Probably a valid file extension
                    fileExtension = possibleExtension;
                }
            }
        }
        return fileExtension;
    }
    hasKnownExtension() {
        return this.taskAttachmentType.taskAttachmentType !== SupportedReferenceTypes.Other || !!this.taskAttachmentType.specificType && indexOf(FileTypesWithPreview, this.taskAttachmentType.specificType.toLowerCase()) >= 0;
    }
    isAttachmentPreviewable() {
        if (this.taskAttachmentType.taskAttachmentType !== SupportedReferenceTypes.Other) {
            return indexOf(NonPreviewableSupportedFileTypes, this.taskAttachmentType.taskAttachmentType) < 0;
        }
        return !!this.taskAttachmentType.specificType && indexOf(FileTypesWithPreview, this.taskAttachmentType.specificType.toLowerCase()) >= 0;
    }
    isGifAttachment() {
        return this.taskAttachmentType.taskAttachmentType === SupportedReferenceTypes.Other && this.taskAttachmentType.specificType === "gif";
    }
    /**
     * Given a url and a task attachment type, look for the extension knowing that the task attachment has type "taskAttachmentType"
     * @param url The URL
     * @param taskAttachmentType The task attachment type
     */ getExtensionFromUrl(url, taskAttachmentType) {
        let extension = null;
        for (const fileExtension of FileTypeExtensions[taskAttachmentType]){
            if (url.indexOf("." + fileExtension) > -1) {
                extension = fileExtension.toLowerCase();
                break;
            }
        }
        return extension;
    }
    constructor(taskAttachmentBuilder){
        this.alias = taskAttachmentBuilder.alias;
        this.taskAttachmentType = taskAttachmentBuilder.taskAttachmentType;
        this.url = taskAttachmentBuilder.url;
        this.isShownInTaskCard = taskAttachmentBuilder.isShownInTaskCard;
    }
}
TaskAttachment.AliasMaxLength = 100;
TaskAttachment.UrlMaxLength = 2000;
