/**
 * Helper strings to provide easier association between tCMS IDs and template purpose/theme
 * These template IDS can be found by querying tCMS (and are provided when uploaded into the system).
 * Refer to TcmsService for more details.
 */ export var TemplateIdConstants;
(function(TemplateIdConstants) {
    /** Basic Plan/Planner Templates */ TemplateIdConstants["CreateBlankBasicPlanCardId"] = "createBlankPlan";
    TemplateIdConstants["SimpleBasicPlanTemplateId"] = "TM89235692";
    TemplateIdConstants["BusinessBasicPlanTemplateId"] = "TM22759591";
    TemplateIdConstants["EmployeeOnboardingBasicPlanTemplateId"] = "TM11863551";
    TemplateIdConstants["ProjectManagementBasicPlanTemplateId"] = "TM00726568";
    TemplateIdConstants["SoftwareDevelopmentBasicPlanTemplateId"] = "TM11133364";
    /** Premium Plan/Project Templates */ TemplateIdConstants["SimplePremiumPlanTemplateId"] = "TM33489844";
    TemplateIdConstants["ProjectManagementPremiumPlanTemplateId"] = "TM88889595";
    TemplateIdConstants["CommercialConstructionPremiumPlanTemplateId"] = "TM45446330";
    TemplateIdConstants["MarketingCampaignPremiumPlanTemplateId"] = "TM33634609";
    TemplateIdConstants["SoftwareDevelopmentPremiumPlanTemplateId"] = "TM11668731";
    TemplateIdConstants["BusinessPremiumPlanTemplateId"] = "TM66954678";
    TemplateIdConstants["EmployeeOnboardingPremiumPlanTemplateId"] = "TM33963130";
    TemplateIdConstants["SprintPlanningPremiumPlanTemplateId"] = "TM89690571";
    TemplateIdConstants["GoalsAndObjectivesPremiumPlanTemplateId"] = "TM56084195";
    TemplateIdConstants["SprintRetrospectivePremiumPlanTemplateId"] = "TM00639721";
    TemplateIdConstants["EventPlanningPremiumPlanTemplateId"] = "TM33693565";
    TemplateIdConstants["ProjectRetrospectivePremiumPlanTemplateId"] = "TM77945985";
    TemplateIdConstants["TrainingPlanPremiumPlanTemplateId"] = "TM33681953";
    TemplateIdConstants["ProjectBudgetPremiumPlanTemplateId"] = "TM55734375";
    TemplateIdConstants["CrmPipelinePremiumPlanTemplateId"] = "TM00405373";
    TemplateIdConstants["HelpDeskTicketsPremiumPlanTemplateId"] = "TM12197912";
    /** Copilot templates */ TemplateIdConstants["ResearchWithCopilotTemplateId"] = "TM11953924";
    TemplateIdConstants["CompetitiveAnalysisWithCopilotTemplateId"] = "TM77819305";
    TemplateIdConstants["SWOTAnalysisWithCopilotTemplateId"] = "TM34086013";
    TemplateIdConstants["MarketStudyWithCopilotTemplateId"] = "TM89612528";
    TemplateIdConstants["ContentCreationWithCopilotTemplateId"] = "TM45427482";
    TemplateIdConstants["CampaignLaunchWithCopilotTemplateId"] = "TM33728249";
    TemplateIdConstants["LearningPlanWithCopilotTemplateId"] = "TM33373652";
    TemplateIdConstants["WorkbackPlanWithCopilotTemplateId"] = "TM66937081";
})(TemplateIdConstants || (TemplateIdConstants = {}));
export var TemplateCategory;
(function(TemplateCategory) {
    TemplateCategory["Blank"] = "blank";
    TemplateCategory["Simple"] = "simple";
    TemplateCategory["Business"] = "business";
    TemplateCategory["EmployeeOnboarding"] = "employeeonboarding";
    TemplateCategory["ProjectManagement"] = "projectmanagement";
    TemplateCategory["SoftwareDevelopment"] = "softwaredevelopment";
    TemplateCategory["CommercialConstruction"] = "commercialconstruction";
    TemplateCategory["MarketingCampaign"] = "marketingcampaign";
    TemplateCategory["SprintPlanning"] = "sprintplanning";
    TemplateCategory["GoalsAndObjectives"] = "goalsandobjectives";
    TemplateCategory["SprintRetrospective"] = "sprintretrospective";
    TemplateCategory["EventPlanning"] = "eventplanning";
    TemplateCategory["ProjectRetrospective"] = "projectretrospective";
    TemplateCategory["TrainingPlan"] = "trainingplan";
    TemplateCategory["ProjectBudget"] = "projectbudget";
    TemplateCategory["CrmPipeline"] = "crmpipeline";
    TemplateCategory["HelpDeskTickets"] = "helpdesktickets";
})(TemplateCategory || (TemplateCategory = {}));
/**
 * List of all the copilot/ ProjectManager templates
 * Planner Copilot has been re-branded to ProjectManager so the code can refer to the same thing using 2 different terms
 */ export const CopilotTemplateIds = [
    "TM11953924",
    "TM77819305",
    "TM34086013",
    "TM89612528",
    "TM45427482",
    "TM33728249",
    "TM33373652",
    "TM66937081"
];
export var TemplateListType;
(function(TemplateListType) {
    /**
     * Used for querying basic plan/planner built-in templates
     */ TemplateListType["BasicPlanProduction"] = "officetemplatesplanner";
    /**
     * Used for querying premium plan/project built-in templates
     */ TemplateListType["PremiumPlanProduction"] = "officetemplatesprojectfortheweb";
    /**
     * Used for querying test premium plan/project built-in templates
     */ TemplateListType["PremiumPlanTest"] = "officetemplatesprojectforthewebppe";
    /**
     * Used for integration testing with tCMS
     */ TemplateListType["IntegrationTest"] = "officetemplatesdesktoprojectstart";
    /**
     * Used for local unit testing, not hosted on tCMS
     */ TemplateListType["UnitTest"] = "fakeList";
})(TemplateListType || (TemplateListType = {}));
/**
 * Different regex patterns used to identify and manipulate text highlighting in ProjectManager page prompt
 * Text in a string (goal) is identified by the pattern `{{%prompt_text%}}`
 */ export const ProjectManagerPromptHighlightTextRegex = {
    /** Regex to strip a goal string off the identifiers */ IDENTIFIER_REGEX: /\{\{%(.+?)%\}\}/g,
    /** Regex to identify individual substrings */ HIGHLIGHT_REGEX: /\{\{%.*?%\}\}/g,
    /** Regex to check/remove for identifiers  */ CHECK_REGEX: /\{\{%\s*[^%]+?\s*%\}\}/,
    /** Regex to replace all identifiers */ REPLACE_IDENTIFIERS_REGEX: /\{\{%\s*[^%]+?\s*%\}\}/g
};
