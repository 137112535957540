// Constants
import { PlannerMobileLinks } from "@ms/uno-constants/lib/local/PlannerMobileConstants";
// Logos
import plannerSvg from "@ms/uno-resources/lib/local/images/logos/planner2.svg";
// Styls
import { getClassNames, getStyles } from "./LandingPage.styles";
// Utilities
import React from "react";
import { getTeamsLightV8Theme } from "@ms/uno-shell/lib/local/themes/v8/TeamsLight";
import { safelyOpenExternalUrl } from "@ms/uno-utilities/lib/local/BrowserUtilities";
import { Strings } from "@ms/uno-shell/lib/local/utilities/CultureUtilities";
/**
 * Web Login Page UI and login button click handler
 */
export function LandingPage(props) {
    const { onClick, enableExternalUrlRegexCheck, loggers } = props;
    const theme = getTeamsLightV8Theme();
    const classNames = getClassNames(getStyles(theme));
    const [showLoading, setShowLoading] = React.useState(false);
    /**
     * Handle the click event for the login button
     */
    const handleSignInClick = async () => {
        setShowLoading(true);
        await onClick();
    };
    /**
     * Handle the click event for the planner for iOS button
     */
    const handlePlannerForIos = () => {
        safelyOpenExternalUrl(PlannerMobileLinks.plannerForIos, loggers.traceLogger, enableExternalUrlRegexCheck);
    };
    /**
     * Handle the click event for the planner for Android button
     */
    const handlePlannerForAndroid = () => {
        safelyOpenExternalUrl(PlannerMobileLinks.plannerForAndroid, loggers.traceLogger, enableExternalUrlRegexCheck);
    };
    if (showLoading) {
        return (React.createElement("div", { className: classNames.loadingScreen },
            React.createElement("img", { className: classNames.loadingImage, src: plannerSvg })));
    }
    return (React.createElement("div", { className: classNames.root },
        React.createElement("div", { className: classNames.gradient }),
        React.createElement("div", { className: classNames.ellipse }),
        React.createElement("div", { className: classNames.infoBox },
            React.createElement("div", { className: classNames.plannerTitle },
                React.createElement("img", { className: classNames.plannerLogo, src: plannerSvg, alt: Strings.LandingPage_PlannerIconAltText }),
                React.createElement("span", null, Strings.LandingPage_PlannerTitle)),
            React.createElement("span", { className: classNames.plannerSlogan }, Strings.LandingPage_PlannerSlogan),
            React.createElement("button", { className: classNames.signInButton, onClick: handleSignInClick }, Strings.LandingPage_SignInText),
            React.createElement("span", { className: classNames.plannerMobileLabel }, Strings.LandingPage_PlannerMobileText),
            React.createElement("div", { className: classNames.appButtons },
                React.createElement("button", { className: classNames.mobileButton, onClick: handlePlannerForIos }, Strings.LandingPage_PlannerForIosText),
                React.createElement("button", { className: classNames.mobileButton, onClick: handlePlannerForAndroid }, Strings.LandingPage_PlannerForAndroidText)))));
}
