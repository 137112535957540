export class FormsCompletionRequirement {
    toGraphSerializable() {
        return {
            requiredForms: this.requiredForms
        };
    }
    static fromResource(resource) {
        return new FormsCompletionRequirement(resource.requiredForms);
    }
    setProperty(key, value) {
        const clone = new FormsCompletionRequirement([
            ...this.requiredForms
        ]);
        clone[key] = value;
        return clone;
    }
    constructor(requiredForms = []){
        this.requiredForms = requiredForms;
    }
}
