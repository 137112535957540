import { DailyMotion } from "./providers/DailyMotion";
import { Stream } from "./providers/Stream";
import { Vimeo } from "./providers/Vimeo";
import { Youtube } from "./providers/Youtube";
/**
 * Utility for parsing urls as video urls and generating embeddable urls from them
 */ export class VideoUrlParser {
    static TryParseVideoUrl(url) {
        // Assume no match
        const result = {
            canPlay: false,
            embedUrl: "",
            provider: "Unknown"
        };
        if (Stream.tryParseVideoUrl(url, result)) {
            return result;
        } else if (Vimeo.tryParseVideoUrl(url, result)) {
            return result;
        } else if (DailyMotion.tryParseVideoUrl(url, result)) {
            return result;
        } else if (Youtube.tryParseVideoUrl(url, result)) {
            return result;
        }
        return result;
    }
}
