// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../../ActionType";
import { FailureAction } from "../../FailureAction";
export class FetchBasicTaskAction extends TypedAction(ActionType.FetchBasicTaskAction) {
    loggingData() {
        return {
            taskId: this.taskId
        };
    }
    constructor(taskId){
        super(), this.taskId = taskId;
    }
}
export class FetchBasicTaskFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicTaskFailedAction, response, textStatus, errorThrown), this.taskId = taskId;
    }
}
export class FetchBasicTaskSucceededAction extends TypedAction(ActionType.FetchBasicTaskSucceededAction) {
    loggingData() {
        return {
            taskId: this.taskEntitySet.id,
            taskVersion: this.taskEntitySet.task.itemVersion
        };
    }
    constructor(taskEntitySet){
        super(), this.taskEntitySet = taskEntitySet;
    }
}
export class FetchBasicTasksForPlanAction extends TypedAction(ActionType.FetchBasicTasksForPlanAction) {
    loggingData() {
        return {
            planId: this.planId
        };
    }
    constructor(planId){
        super(), this.planId = planId;
    }
}
export class FetchBasicTasksForPlanFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            planId: this.planId
        };
    }
    constructor(planId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicTasksForPlanFailedAction, response, textStatus, errorThrown), this.planId = planId;
    }
}
export class FetchBasicTasksForPlanSucceededAction extends TypedAction(ActionType.FetchBasicTasksForPlanSucceededAction) {
    loggingData() {
        return {
            planId: this.planId,
            isFirstPage: String(this.isFirstPage),
            isLastPage: String(this.isLastPage)
        };
    }
    constructor(planId, taskEntitySets, nextLink, isFirstPage = false, accumulatedTaskIds = null){
        super(), this.planId = planId, this.taskEntitySets = taskEntitySets, this.nextLink = nextLink, this.isFirstPage = isFirstPage, this.accumulatedTaskIds = accumulatedTaskIds;
        this.isLastPage = nextLink == null;
    }
}
export class FetchBasicTasksForTodoPlanFinalizedAction extends TypedAction(ActionType.FetchBasicTasksForTodoPlanFinalizedAction) {
    loggingData() {
        return {
            planId: this.planId,
            succeeded: String(this.succeeded)
        };
    }
    constructor(planId, succeeded){
        super(), this.planId = planId, this.succeeded = succeeded;
    }
}
export class FetchBasicTasksForAssigneeAction extends TypedAction(ActionType.FetchBasicTasksForAssigneeAction) {
    loggingData() {
        return {
            userId: this.userId
        };
    }
    constructor(userId){
        super(), this.userId = userId;
    }
}
export class FetchBasicTasksForAssigneeFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            userId: this.userId
        };
    }
    constructor(userId, response, textStatus, errorThrown){
        super(ActionType.FetchBasicTasksForAssigneeFailedAction, response, textStatus, errorThrown), this.userId = userId;
    }
}
export class FetchBasicTasksForAssigneeSucceededAction extends TypedAction(ActionType.FetchBasicTasksForAssigneeSucceededAction) {
    loggingData() {
        return {
            userId: this.userId,
            isFirstPage: String(this.isFirstPage),
            isLastPage: String(this.isLastPage)
        };
    }
    constructor(userId, taskEntitySets, nextLink, isFirstPage = false, accumulatedTaskIds = null){
        super(), this.userId = userId, this.taskEntitySets = taskEntitySets, this.nextLink = nextLink, this.isFirstPage = isFirstPage, this.accumulatedTaskIds = accumulatedTaskIds;
        this.isLastPage = nextLink == null;
    }
}
export class FetchBasicTasksForMyDayAction extends TypedAction(ActionType.FetchBasicTasksForMyDayAction) {
}
export class FetchBasicTasksForMyDayFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData()
        };
    }
    constructor(response, textStatus, errorThrown){
        super(ActionType.FetchBasicTasksForMyDayFailedAction, response, textStatus, errorThrown);
    }
}
export class FetchBasicTasksForMyDaySucceededAction extends TypedAction(ActionType.FetchBasicTasksForMyDaySucceededAction) {
    loggingData() {
        return {
            isFirstPage: String(this.isFirstPage),
            isLastPage: String(this.isLastPage)
        };
    }
    constructor(taskEntitySets, nextLink, isFirstPage = false, accumulatedTaskIds = null){
        super(), this.taskEntitySets = taskEntitySets, this.nextLink = nextLink, this.isFirstPage = isFirstPage, this.accumulatedTaskIds = accumulatedTaskIds;
        this.isLastPage = nextLink == null;
    }
}
export class FetchApprovalDetailsAction extends TypedAction(ActionType.FetchApprovalDetailsAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId){
        super(), this.taskId = taskId;
    }
}
export class FetchApprovalDetailsSucceededAction extends TypedAction(ActionType.FetchApprovalDetailsSucceededAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId, approvalDetailResponse){
        super(), this.taskId = taskId, this.approvalDetailResponse = approvalDetailResponse;
    }
}
export class FetchApprovalDetailsFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId, response, textStatus, errorThrown){
        super(ActionType.FetchApprovalDetailsFailedAction, response, textStatus, errorThrown), this.taskId = taskId;
    }
}
export class CancelApprovalDetailsAction extends TypedAction(ActionType.CancelApprovalDetailsAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId){
        super(), this.taskId = taskId;
    }
}
export class CancelApprovalDetailsFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId, response, textStatus, errorThrown){
        super(ActionType.CancelApprovalDetailsFailedAction, response, textStatus, errorThrown), this.taskId = taskId;
    }
}
export class UpdateApprovalDetailsAction extends TypedAction(ActionType.UpdateApprovalDetailsAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId){
        super(), this.taskId = taskId;
    }
}
export class UpdateApprovalDetailsFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId, response, textStatus, errorThrown){
        super(ActionType.UpdateApprovalDetailsFailedAction, response, textStatus, errorThrown), this.taskId = taskId;
    }
}
export class CreateApprovalDetailsAction extends TypedAction(ActionType.CreateApprovalDetailsAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId){
        super(), this.taskId = taskId;
    }
}
export class CreateApprovalDetailsFailedAction extends FailureAction {
    loggingData() {
        return {
            ...super.loggingData(),
            taskId: this.taskId
        };
    }
    constructor(taskId, response, textStatus, errorThrown){
        super(ActionType.CreateApprovalDetailsFailedAction, response, textStatus, errorThrown), this.taskId = taskId;
    }
}
