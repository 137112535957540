// Constants
import { ConfigNotFoundError } from "@ms/uno-errors/lib/local/errors/ConfigNotFoundError";
import { ClientFlavor, ClientType, Environment, TenantRegion, allowedRolloutStagesForEnvironment } from "../AppConstants";
import { IrisSurfacePlacementIds } from "../IrisConstants";
import { OneDSEnvironment } from "../LoggingConstants";
import { RolloutStage } from "../RolloutStage";
import { SettingsConstants } from "../SettingsConstants";
import { TemplateListType } from "../TemplateConstants";
import { AudienceGroup, TraceLevel } from "./ISettings";
// Utilities
import merge from "lodash/merge";
/**
 * Class to hold static data about the app. Data here should never mutate during the life of our app.
 */ export class AppContext {
    /**
     * Initialize the app based on given app context settings
     * @param settings The app context settings to be used
     */ static initializeFromSettings(settings) {
        this._appConfiguration = settings.appConfiguration;
    }
    /**
     * Get the change gates with the default values.
     * @deprecated Use getDefaultChangeGates instead
     */ static getDefaultOldChangeGates() {
        return {
            DeprecateProjectTokens: true,
            RemoveHomePageReadOnlyProp: true,
            CopilotRequestHangFix: true,
            FilterPortfolioMruItems: true,
            CopilotNoSummaryTasksToGoalsFix: true,
            ProjectTagTooltipFix: true,
            TurnOffTimelineZoomFlightMonth: true,
            RenderKeyDateWithinRowForRoadmap: true,
            DelayLoadingTopHeader: true,
            DatePickerVoiceAccess: true
        };
    }
    /**
     * Get the change gates with the default values.
     */ static getDefaultChangeGates() {
        return {
            DisableRandomTemplateImages: false,
            DisableEnableExportToExcelForInactiveProjects: false,
            DisableUnoNotifications: false,
            DisableTaskHistoryUserInfoFetch: false,
            DisableGsdRestrictedReadWriteAccess: false,
            DisableCopilotForGsdUsers: false,
            DisableForceAuthRefreshOnInactivity: false,
            DisableAuthInteractionRequiredUsingPopup: false,
            DisableBootstrapAuthPrefetchAndCAEvaluation: false,
            DisableChoiceOptionGetterDefaultValues: false,
            DisableLicenseCheckForGetPortfoliosCall: false
        }; // any new CG should be false by default
    }
    /**
     * Get the flights with the default values.
     */ static getDefaultFlights() {
        return {
            EnableMandatoryForms: false,
            EnableNLPInTaskCreation: false,
            EnableMandatoryApprovals: false,
            EnableMandatoryChecklist: false,
            EnableUpsellConversion: false,
            EnableEnhancedPalauTelemetry: false,
            EnableNativeBasicPlanViews: false,
            EnableTaskEachAssignee: false,
            EnableCopilotUpsellInBasicPlan: false,
            EnableFileIntelligence: false,
            EnableFirstRunExperience: false,
            EnableRecurringTasks: true,
            EnableUnoLevelProjectProvisioning: false,
            EnableRecurrenceForTaskEachAssignee: false,
            EnableRecurrenceList: false,
            EnableRecurrenceListEditRecipients: false,
            EnablePortfolioExperience: false,
            CopilotSidecar: false,
            EnableCopilotUpsellInPremiumPlan: false,
            EnableCopilotInUpsellString: false,
            EnableWhiteBoardInPremiumPlan: false,
            EnableReportPageInPremiumPlan: false,
            EnableTeamsTabPlanContextRefresh: false,
            EnableDefaultActiveTasksFilterInBasicPlan: true,
            EnablePersistenceForTodoTasks: false,
            EnableGSDInPremiumPlan: false,
            EnableBasicPlanParallelConversion: false,
            EnableAppPoweredTasks: false,
            EnableTaskEditorRouting: false,
            EnableGSDHome: false,
            EnableGSDHomeResources: false,
            EnableGSDHomeResourcesEsdkPicker: false,
            EnableGroundingOnPDFFiles: false,
            EnableIncrementalLoadInPremiumPlans: false,
            EnablePublishingNewListSelector: false,
            EnablePerfImprovementPeopleBoardPage: false,
            EnableCopilotMyDayMyTasks: false,
            EnablePlannerServicePatchRetries: false,
            EnableCDSInstancePersistedState: false,
            EnablePremiumPlansInCreateTaskFromMessage: false,
            EnableCapabilitiesCheck: false,
            EnableNewPlanPickerExperience: false,
            EnableCommentRefresh: false,
            EnableUpsellOverflowMenuUpdate: false,
            EnableRemovePexAppContextCall: false,
            EnablePwaInUno: false,
            EnableProjectOperationsPlanInUno: false,
            EnableGridViewLabels: false,
            EnableProjectTasksForPlannerWeb: false,
            EnableSuggestedAssignee: false,
            EnableBaselineWave2: false,
            EnableCopyPlan: false,
            EnableUnoTeamsTabExperience: false,
            EnableExportPlanToExcel: false,
            EnableGSDHomePublicPreview: false,
            EnableEtcc: false,
            EnableTaskValuesEditForEnterpriseColumns: false,
            EnablePremiumPlanStartTrialEntryPoints: false,
            EnableDeletePortfolioExperience: false,
            EnableFlowAuthenticationWithTeamsSDK: false,
            EnableGccHInP4W: false,
            ClientAFDRollOut: false,
            EnableCaseSensitiveUserId: false,
            EnableSinceRevisionToken: false,
            EnableLoopPlans: false,
            EnableMruSyncToPlanner: false,
            EnablePalauErrorHandling: false,
            EnableExpandCollapseSummaryTask: false,
            EnablePMCorrectionExperience: false,
            EnableProjectTasksInPlanner: false,
            EnableRefreshButtonForNamedOrgProjects: false,
            EnableNewProjectDialogAndSurveyForUsersRoutedFromPJOBanners: false,
            EnableProjectFetchedCheckInCriticalData: false,
            EnableGsdAsyncTaskExecution: false,
            EnableCopilotInUnoWeb: false,
            EnableOpenFilePreview: false,
            EnablePlannerUserSettingsInProject: false,
            EnablePersonalPlanConversionUX: false,
            EnableGsdInBasicPlans: false,
            EnableICalendar: false,
            EnableIrisCampaignCallouts: false,
            EnableWebHeaderChanges: false,
            EnableModernPerformanceMetrics: false,
            EnableExternalUrlRegexCheck: false,
            EnableGraphV1ForDriveItemShareInvitation: false,
            EnableEnforceModernGroupForGsdPlans: false,
            EnablePremiumPlansCreatedByUserInMyPlans: false,
            EnableOptimisticPremiumPlanCreation: false,
            EnableTasksLimitReadFromConfigForPremiumPlan: false,
            EnableDefaultTaskFilterIrisCallout: false,
            EnableGroundingForAsyncTaskExecution: false,
            EnableNestedAppAuth: false,
            EnableMyTasksBoardView: false,
            EnableCreateTaskFromMessageShell: false,
            EnableGridColumnReorderInUserViews: false,
            EnableMyDayBoardView: false,
            EnablePublishingV2: false,
            EnableAIGeneratedTaskAttribution: false,
            EnableUnifiedDiscoveryEndpointForProjectAndRoadmap: false,
            EnableDataFetchWorker: false,
            EnableDataPersistence: false,
            EnableLazyLoadingOfPlanDetails: false,
            EnableUsingAppIdForAuthInPexTeamsApp: false,
            EnableDataPreFetcher: false,
            EnablePersistentFiltersInPremiumPlans: false,
            EnableUndoDeleteBasicTask: false,
            EnableDefaultPolicy: false,
            EnableMadlibsInProjectManagerPrompt: false,
            EnableGroupBySprintViewOptions: false
        };
    }
    static getDefaultSettings() {
        return {
            ConfigIDs: "configId",
            ETag: "eTag",
            rolloutStage: RolloutStage.ProdAll,
            clientBasicLimits: {
                maxAttachmentsOnTask: 10,
                maxDisplayablePersonasTaskCard: 3,
                maxDisplayablePersonasAddTaskCard: 4,
                maxDisplayablePersonasTaskDetails: 8,
                maxTaskAssignees: 20,
                maxFileSuggestions: 20,
                maxUserContainerPlansLimit: 200,
                maxRequiredFormsOnTask: 2,
                maxRequiredApprovalsOnTask: 1
            },
            auth: {
                authTokenExpiryBufferSeconds: 300,
                msalSettings: null
            },
            instantiation: {
                maxRetryCount: 10,
                retryIntervalMs: 500
            },
            audienceGroup: AudienceGroup.Production,
            serviceConfigurations: {
                auth: {
                    hostname: "https://tasks.teams.microsoft.com",
                    authResourceURI: "https://tasks.teams.microsoft.com"
                },
                augloop: {
                    hostname: "https://augloop.office.com",
                    authResourceURI: "https://augloop.office.com/v2"
                },
                centro: {
                    hostname: "https://admin-ignite.microsoft.com",
                    authResourceURI: "00000006-0000-0ff1-ce00-000000000000"
                },
                chatsvcagg: {
                    hostname: "https://chatsvcagg.teams.microsoft.com",
                    authResourceURI: "https://chatsvcagg.teams.microsoft.com"
                },
                forms: {
                    hostname: "https://forms.office.com",
                    authResourceURI: "https://forms.office.com"
                },
                flow: {
                    hostname: "https://api.flow.microsoft.com",
                    authResourceURI: "https://service.flow.microsoft.com",
                    widgetBaseUri: "https://make.powerautomate.com",
                    portfolioTemplateId: "698e0dd9a2dc4ae19b30b73ee5074303"
                },
                graph: {
                    hostname: "https://graph.microsoft.com",
                    authResourceURI: "https://graph.microsoft.com",
                    batchAll: false,
                    maxBatchSize: 20,
                    batchInterval: 100
                },
                iris: {
                    hostname: "https://arc.msn.com",
                    authResourceURI: "https://arc.msn.com/v4",
                    placementId: IrisSurfacePlacementIds.PlannerExternal,
                    batchCount: 3
                },
                mars: {
                    hostname: "https://taskmars.microsoft.com",
                    authResourceURI: "3b3a0f1f-2a1c-4805-9e05-43a40c630002",
                    timeout: 240000
                },
                loop: {
                    hostname: "https://prod.api.loop.cloud.microsoft/v0.1",
                    hostnameEu: "https://eu.prod.api.loop.cloud.microsoft/v0.1",
                    authResourceURI: "https://api.loop.cloud.microsoft",
                    timeout: 45000
                },
                loopMts: {
                    hostname: "https://substrate.office.com",
                    authResourceURI: "https://substrate.office.com"
                },
                mru: {
                    hostname: "https://ocws.officeapps.live.com",
                    hostnameEu: "https://ocws-eu.officeapps.live.com",
                    officeApplicationNumber: "153",
                    authResourceURI: "https://api.office.net"
                },
                planner: {
                    hostname: "https://tasks.office.com",
                    authResourceURI: ""
                },
                project: {
                    hostname: "https://project.microsoft.com",
                    authResourceURI: "https://project.microsoft.com",
                    cdsEndpointTimeout: 30000,
                    planConversionTimeout: 120000
                },
                ocps: {
                    hostname: "https://clients.config.office.net",
                    authResourceURI: "https://clients.config.office.net"
                },
                oneshell: {
                    hostname: "https://webshell.suite.office.com",
                    authResourceURI: "https://webshell.suite.office.com",
                    shellBootHost: "https://res-1.cdn.office.net",
                    shellBootPath: "/shellux/api/shellbootstrapper/business/oneshell"
                },
                roaming: {
                    hostname: "https://roaming.officeapps.live.com",
                    hostnameEu: "https://roaming-eu.officeapps.live.com",
                    officeApplicationNumber: "153",
                    authResourceURI: "https://api.office.net"
                },
                tcms: {
                    hostname: "https://metadata.templates.cdn.office.net",
                    authResourceURI: ""
                },
                retailservices: {
                    hostname: "https://tasks.teams.microsoft.com",
                    authResourceURI: "https://retailservices.teams.microsoft.com",
                    appFlavor: "planner"
                },
                stapi: {
                    hostname: "https://substrate.office.com",
                    authResourceURI: "https://substrate.office.com"
                },
                todo: {
                    hostname: "https://to-do.office.com",
                    authResourceURI: ""
                }
            },
            retrySettings: {
                globalAjaxTimeout: 15000,
                maxRetryCount: 3,
                defaultRetryDelay: 1000,
                maxRetryTimeout: 20000
            },
            premiumPlanCreationStatusPollSettings: {
                maxPollCount: 10,
                baseInterval: 1000
            },
            batchJobSizes: {
                fetchUsers: 10,
                fetchPhotos: 20,
                addUsersToContainer: 15,
                fetchUserGroupPlansPageSize: 30
            },
            deltaSyncConfiguration: {
                baseInterval: 6,
                cutoff: 60,
                backoffThreshold: 5,
                retryInterval: 300
            },
            fileUpload: {
                maxFileSize: 10485760,
                maxUploadRetries: 3,
                uploadChunkSize: 327680
            },
            localDevelopmentSettings: {
                showHiddenToasts: false
            },
            templateSettings: {
                // Default to production lists, as these are a subset of all possible built-in templates. Pre-prod environments will
                // be configured to show the PPE list of templates, as these templates might have localization issues or be broken when
                // first added to the template management service (and want to avoid breaking creation flows where possible).
                basicPlanTemplateList: TemplateListType.BasicPlanProduction,
                premiumPlanTemplateList: TemplateListType.PremiumPlanProduction
            },
            sharedContainers: {
                updateDriveItemLinksToOpenOnWeb: false
            },
            oneDSSinkEnvironment: OneDSEnvironment.Public,
            disableFileRecommendations: false,
            filterGuestUsersOnTenantSearch: false,
            userSearchResultsExpiryTimeInSeconds: 30,
            showFirstRunExperienceAfter: new Date("2024-01-01T00:00:00.000Z"),
            applicationIds: {
                project: "f53895d3-095d-408f-8e93-8f94b391404e"
            },
            disableFileUploads: false,
            basicPlanConversionSettings: {
                overallTimeoutDuration: 90000,
                loopInterval: 2000,
                maxCount: 50
            },
            forceLogsToEU: false,
            disableStoragePersistence: false,
            disableAppCaching: false,
            taskPublishingSettings: {
                disablePublishingFreCallouts: false
            },
            routePersistenceEnabled: false,
            irisCampaignSettings: {
                disableIrisCampaignCallouts: false,
                campaignCacheExpiryDuration: 14400000
            },
            maxConversationCreationPeriod: 300,
            AugloopFlights: "",
            CopilotAugloopFlights: "",
            PortfolioMinCDSVersionSupported: "0.8.8.60",
            persistedStorage: {
                limits: {
                    maxTaskFilters: 20,
                    maxSortCriteria: 20,
                    maxGridColumnWidths: 20,
                    maxTaskGroupings: 20
                },
                expiryTimes: {
                    cdsInstanceData: 300,
                    cdsPackageInfoData: 300
                }
            },
            traceLogThreshold: TraceLevel.Info,
            workerSettings: {
                cdsProvisioningStatusPollingWorker: {
                    maxPollCount: 10,
                    baseInterval: 30000
                },
                recommendedPlansWorker: {
                    disabled: false,
                    recommendedPlansUpdateInterval: 1000 * 60 * 20,
                    recommendedPlansLimit: 10
                },
                operationTrackingWorker: {
                    disabled: false,
                    copyPlan: {
                        processingInterval: 5,
                        operationExpiryDuration: 60 * 60 * 24 * 1
                    }
                }
            },
            enableResourceTelemetryCollection: false,
            performanceSettings: {
                minimumTasksForVisualReadiness: 100,
                minimumPlansForVisualReadiness: 100,
                longTaskDurationLoggingThreshold: 1000,
                longAnimationFrameDurationLoggingThreshold: 1000
            },
            copilotExecutionSettings: {
                maxActiveTasks: 25,
                activeTaskExpiryTimeInSeconds: 300
            },
            MaxTaskLimitForPremiumPlans: 3000
        };
    }
    /**
     * Builds IAppContextSettings based on given settings getter delegate function.
     * @param settingsGetter delegate function that returns settings value given a settings key string
     * @returns The instance of IAppContextSettings that is built from given settingsGetter
     */ static BuildContextSettingsFromSettingsGetter(settingsGetter) {
        const ecsConfig = settingsGetter(SettingsConstants.Config);
        const [ecsFlights, ecsChangeGates, oldChangeGates, ecsSettings] = AppContext.getFlightsCGsAndSettings(ecsConfig);
        const serverSessionMetaData = settingsGetter(SettingsConstants.SessionMetaData);
        if (!serverSessionMetaData) {
            throw new ConfigNotFoundError(SettingsConstants.SessionMetaData);
        }
        const sessionMetaData = AppContext.getSessionMetaData(serverSessionMetaData);
        const appSettings = {
            appConfiguration: {
                flights: ecsFlights,
                changeGates: ecsChangeGates,
                oldChangeGates: oldChangeGates,
                settings: ecsSettings,
                sessionMetaData: sessionMetaData
            }
        };
        return appSettings;
    }
    /** Current App Configuration */ static get appConfiguration() {
        return AppContext._appConfiguration;
    }
    /** Method to override the client flavor. Used for cached app load in Teams */ static overrideClientFlavor(clientFlavor) {
        // Right now we'll block on Teams. Important note is other MOS hosts will be able to override this.
        if (AppContext._appConfiguration.sessionMetaData.appMetadata.clientType !== ClientType.Teams) {
            throw new Error("Client flavor can only be overridden for Teams client type");
        }
        AppContext._appConfiguration.sessionMetaData.appMetadata.clientFlavor = clientFlavor;
    }
    /**
     * Returns true if the environment is a PPE one. Returns false otherwise.
     */ static isPreProductionEnvironment() {
        return AppContext.appConfiguration.sessionMetaData.environment === Environment.Ppe;
    }
    /**
     * Returns true if the environment is gov environment. Returns false otherwise.
     */ static isGovEnvironment() {
        return AppContext.appConfiguration.sessionMetaData.environment === Environment.Gcc || AppContext.appConfiguration.sessionMetaData.environment === Environment.Gcch || AppContext.appConfiguration.sessionMetaData.environment === Environment.Dod || AppContext.appConfiguration.sessionMetaData.environment === Environment.Agc;
    }
    /** Checks if the combination of Rollout Stage and Environment is unexpected, and logs unexpected entries. */ static isRolloutStageAndEnvironmentCombinationUnexpected() {
        const rolloutStage = AppContext.appConfiguration.settings.rolloutStage;
        const environment = AppContext.appConfiguration.sessionMetaData.environment;
        const allowedRolloutStages = allowedRolloutStagesForEnvironment.get(environment);
        return !allowedRolloutStages?.includes(rolloutStage);
    }
    /** Returns an IFlight object and an ISettings object from the config object returned from ECS. */ static getFlightsCGsAndSettings(ecsConfig) {
        const flights = AppContext.getDefaultFlights();
        const flightKeys = Object.keys(flights);
        for (const flightKey of flightKeys){
            if (ecsConfig?.[flightKey] != null) {
                flights[flightKey] = ecsConfig[flightKey];
            }
        }
        const oldChangeGates = AppContext.getDefaultOldChangeGates();
        const oldChangeGatesKeys = Object.keys(oldChangeGates);
        for (const cgKey of oldChangeGatesKeys){
            if (ecsConfig?.[cgKey] != null) {
                oldChangeGates[cgKey] = ecsConfig[cgKey];
            }
        }
        const changeGates = AppContext.getDefaultChangeGates();
        const changeGatesKeys = Object.keys(changeGates);
        for (const cgKey of changeGatesKeys){
            if (ecsConfig?.[cgKey] != null) {
                changeGates[cgKey] = ecsConfig[cgKey];
            }
        }
        const settings = AppContext.getDefaultSettings();
        const settingsKeys = Object.keys(settings);
        for (const settingsKey of settingsKeys){
            if (ecsConfig?.[settingsKey] != null) {
                if (typeof settings[settingsKey] === "object") {
                    settings[settingsKey] = merge(settings[settingsKey], ecsConfig[settingsKey]);
                } else {
                    settings[settingsKey] = ecsConfig[settingsKey];
                }
            }
        }
        // Construct the AugloopFlights string by concatenated JSON config values with a key of AugloopFlights
        // NOTE: The SDK appears to remove duplicate flights and semicolons when it makes the network call to set up the session
        // so extra semicolons and duplicate flight values are OK
        settings.AugloopFlights = Object.values(ecsConfig || {}).filter(isAugloopFlight).map((val)=>val.AugloopFlights).concat(settings.CopilotAugloopFlights) // Remove this line when graduating CopilotAugloopFlights
        .join(";");
        return [
            flights,
            changeGates,
            oldChangeGates,
            settings
        ];
    }
    /**
     * Checks for missing parameters in the session metadata object returned from service and sends back the same object
     * if no missing parameters are found. Throws ConfigNotFoundError if any parameter is missing.
     */ static getSessionMetaData(serverSessionMetaData) {
        const sessionMetaData = {
            appMetadata: {
                clientFlavor: ClientFlavor.App,
                clientType: ClientType.Teams
            },
            appLoadCorrelationId: "sampleAppLoadCorrelationId",
            clientBuild: "sampleClientBuild",
            serverBuild: "sampleServerBuild",
            environment: Environment.Ppe,
            sessionId: "sampleSessionId",
            tenantRegion: TenantRegion.Row
        };
        const sessionMetaDataKeys = Object.keys(sessionMetaData);
        for (const sessionMetaDataKey of sessionMetaDataKeys){
            if (!serverSessionMetaData[sessionMetaDataKey]) {
                throw new ConfigNotFoundError(sessionMetaDataKey);
            }
        }
        return serverSessionMetaData;
    }
}
/** Returns true if the input fulfills the IAugloopFlightConfig interface */ const isAugloopFlight = (config)=>typeof config?.AugloopFlights === "string";
