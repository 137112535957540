// Models
import { RecurrenceCalculator } from "./RecurrenceCalculator";
/**
 * Represents a daily recurrence pattern calculator
 * @example Every 3rd day.
 */ export class DailyRecurrenceCalculator extends RecurrenceCalculator {
    calculateOccurrencesBetween(start, end) {
        return this.getPeriodsBetween(this.offsetStart(this.normalizeDate(start)), this.normalizeDate(end)).filter((period)=>this.getDurationBetweenDateAndPatternStartDate(period) % this.interval === 0); // Get only periods that fall on the interval
    }
    calculatePatternStartDate() {
        // Daily can only be interval based. Thus whatever the current pattern start date is.
        return this.patternStartDate.clone();
    }
    calculateNextOccurrenceDate() {
        // Daily can only be interval based. Thus take the start date and add the interval number of days
        return this.patternStartDate.clone().add(this.interval, this.periodPattern);
    }
    offsetStart(start) {
        let startOffset;
        /** OPTIMIZATION BELOW: Remove all days before pattern start date. */ if (start.isSameOrBefore(this.patternStartDate)) {
            startOffset = this.patternStartDate.clone();
        } else {
            // If start is after then offset start to the next period interval
            const periodsToNextInterval = this.interval - start.diff(this.patternStartDate, this.periodPattern) % this.interval;
            startOffset = start.clone().add(periodsToNextInterval, this.periodPattern);
        }
        return startOffset;
    }
    isSameOrBefore(start, end) {
        return start.isSameOrBefore(end);
    }
    getDurationBetweenDateAndPatternStartDate(date) {
        return date.diff(this.patternStartDate, this.periodPattern);
    }
    constructor(pattern, patternStartDate){
        super(pattern.interval, patternStartDate, "day");
    }
}
