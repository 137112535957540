// Models
import { CreationProcessInfo } from "./CreationProcessInfo";
import { PlanCreationProcessType } from "./ICreationProcessInfo";
/**
 * Configuration and building of creation process info objects
 */ export class CreationProcessInfoBuilder {
    /**
     * Build from provided property bag
     */ withPropertyBag(propertyBag) {
        this.sourceId = propertyBag.creationSourceId ?? "";
        this.processType = propertyBag.creationProcessType ?? PlanCreationProcessType.None;
        if (propertyBag.creationProcessType === PlanCreationProcessType.PublishTarget || propertyBag.creationProcessType === PlanCreationProcessType.OfficeDocument) {
            this.processId = null;
            this.processHasFinished = true;
        } else {
            this.processHasFinished = propertyBag.creationHasFinished ?? true;
            this.processId = propertyBag.creationProcessId ?? "";
        }
        return this;
    }
    /**
     * Build out the ICreationProcessInfo
     */ build() {
        return new CreationProcessInfo(this);
    }
    /**
     * Initializes a new instance of the CreationProcessInfoBuilder with default params.
     */ constructor(){
        this.processHasFinished = true;
        this.processId = "";
        this.processType = PlanCreationProcessType.None;
        this.sourceId = "";
    }
}
