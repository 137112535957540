/** Relates to number of CSS color classes defined in @ms/uno-controls/.../common/GroupsColors */ export const NumGroupColors = 14;
export var Colors;
(function(Colors) {
    Colors.White = "#FFFFFF";
    Colors.LightRed = "#e73550";
    Colors.DarkRed = "#a80000";
    Colors.Red = "#C4314B";
    Colors.Black = "#000000";
    Colors.Grey4 = "#0A0A0A";
    Colors.Grey14 = "#242424";
    Colors.Grey16 = "#292929";
    Colors.Grey26 = "#424242";
    Colors.Grey38 = "#616161";
    Colors.Grey78 = "#C7C7C7";
    Colors.Grey82 = "#D1D1D1";
    Colors.Grey84 = "#D6D6D6";
    Colors.Grey92 = "#EBEBEB";
    Colors.Grey94 = "#F0F0F0";
    Colors.Grey96 = "#F5F5F5";
    Colors.Grey98 = "#FAFAFA";
    Colors.DarkGrey24 = "#3D3D3D";
    Colors.DarkGrey68 = "#ADADAD";
    Colors.Teams12 = "#bdbde6";
    Colors.Teams60 = "#444791";
    Colors.Teams70 = "#4F52B2";
    Colors.Teams80 = "#5B5FC7";
    Colors.Teams160 = "#E8EBFA";
    //#region Default Theme
    /** NeutralDark for default theme */ Colors.NeutralDark = "#201f1e";
    /** NeutralPrimary for default theme */ Colors.NeutralPrimary = "#323130";
    /** NeutralPrimaryAlt for default theme */ Colors.NeutralPrimaryAlt = "#3b3a39";
    /** NeutralSecondary for default theme */ Colors.NeutralSecondary = "#605e5c";
    /** NeutralSecondaryAlt for default theme */ Colors.NeutralSecondaryAlt = "#8a8886";
    /** NeutralTertiary for default theme */ Colors.NeutralTertiary = "#a19f9d";
    /** NeutralTertiaryAlt for default theme */ Colors.NeutralTertiaryAlt = "#c8c6c4";
    /** NeutralLight for default theme */ Colors.NeutralLight = "#edebe9";
    /** NeutralLighter for default theme */ Colors.NeutralLighter = "#f3f2f1";
    /** NeutralLighterAlt for default theme */ Colors.NeutralLighterAlt = "#faf9f8";
    /** NeutralQuaternaryAlt for default theme */ Colors.NeutralQuaternaryAlt = "#e1dfdd";
    /** ThemePrimary for default theme */ Colors.ThemePrimary = "#217346";
    /** ThemeSecondary for default theme */ Colors.ThemeSecondary = "#318456";
    /** ThemeSecondaryAlt for default theme */ Colors.ThemeSecondaryAlt = "#4a986d";
    /** ThemeTertiary for default theme */ Colors.ThemeTertiary = "#62ab83";
    /** ThemeLighter for default theme */ Colors.ThemeLighter = "#cee9da";
    /** Fluent Greens - Light theme */ /** Planner Primary. This is the primary Planner brand color, used in elements like the complete task button */ Colors.PlannerPrimary = "#107c41";
    /** Planner Secondary */ Colors.PlannerSecondary = "#218D51";
    /** Planner DarkAlt */ Colors.PlannerDarkAlt = "#0F703B";
    /** Planner Darker. This is the "darker" product color for Planner. Can be used in text, as a darker complement to other brand colors, etc. */ Colors.PlannerDarker = "#094624";
    /** Planner Darker */ Colors.PlannerDarkDarker = "#60BD82";
    /** Fluent Greens - Dark theme */ /** Planner Primary */ Colors.PlannerDarkPrimary = "#10893C";
    /** Planner Secondary */ Colors.PlannerDarkSecondary = "#0F7937";
    /** DarkTheme AppBackgroundColor */ Colors.DarkThemeAppBackgroundColor = "#1b1a19";
    //#endregion
    //#region High contrast colors
    Colors.HighContrastYellow = "#ffff00";
    Colors.HighContrastCyan = "#00ffff";
    Colors.HighContrastBlack = "#000000";
    Colors.HighContrastSelectedTextBackground = "#008000";
    Colors.HighContrastWhiteSelectedTextBackground = "#37006e";
    Colors.HighContrastBlackSelectedTextBackground = "#1aebff";
    //#endregion
    //#region documentPreviewIcon colors
    Colors.ExcelIconColor = "#107c10";
    Colors.PowerPointIconColor = "#d24726";
    Colors.WordIconColor = "#2b579a";
    Colors.OneNoteIconColor = "#80397b";
    Colors.PdfIconColor = "#de3d41";
    Colors.ProjectIconColor = "#31752f";
    Colors.VisioIconColor = "#3955a3";
    Colors.FormsIconColor = "#038387";
    Colors.GenericIconColor = "#5e727c";
    //#endregion
    /** Meeting notes main theme color */ Colors.MeetingNotesColorLight = "#7719aa";
    Colors.MeetingNotesColorDark = "#C0AAE4";
    /** Word document main theme color */ Colors.WordColorLight = "#185abd";
    Colors.WordColorDark = "#296FE6";
    /** Excel document main theme color */ Colors.ExcelColorLight = "#107C41";
    Colors.ExcelColorDark = "#10893C";
    /** PowerPoint document main theme color */ Colors.PowerPointColorLight = "#C43E1C";
    Colors.PowerPointColorDark = "#CA5010";
    /** Project main theme color */ Colors.ProjectColorLight = "#107C41";
    Colors.ProjectColorDark = "#0a4e28";
    //#endregion
    //#region Teams Theme
    /** The TeamsPurple color */ Colors.TeamsPurple = "#5b5fc6";
    /** The TeamsDarkPurple color */ Colors.TeamsDarkPurple = "#7579eb";
    /** The TeamsDark background color */ Colors.TeamsDarkBackground = "#252423";
    /** The TeamsDark neutralPrimary color */ Colors.TeamsDarkNeutralPrimary = "#323130";
    /** TeamsAppBrand00 color */ Colors.TeamsAppBrand00 = "#5b5fc6";
    /** TeamsAppBrand04 color */ Colors.TeamsAppBrand04 = "#464775";
    /** TeamsAppBrand08 color */ Colors.TeamsAppBrand08 = "#7579eb";
    /** TeamsAppBrand12 color */ Colors.TeamsAppBrand12 = "#bdbde6";
    /** TeamsAppBrand14 color */ Colors.TeamsAppBrand14 = "#e2e2f6";
    /** TeamsContrastSelectedColor color */ Colors.TeamsContrastSelectedColor = "#00ebff";
    /** TeamsContrastHyperlinkColor color */ Colors.TeamsContrastHyperlinkColor = "#e6e600";
    /** Teams background hover color */ Colors.TeamsHoverBackgroundColor = "#f5f5f5";
    /** Teams Light background color */ Colors.TeamsLightBackgroundColor = "#f3f2f1";
    /** Teams Dark background color */ Colors.TeamsDarkBackgroundColor = "#201f1f";
    /** TeamsContrastPlaceHolderOrDisabledColor color */ Colors.TeamsContrastPlaceHolderOrDisabledColor = "#3ef23e";
    /** TeamsTitleBackgroundColor color */ Colors.TeamsTitleBackgroundColor = "#f9f8f7";
    /** TeamsButtonHoverColor color */ Colors.TeamsButtonHoverColor = "#7679bf";
    /** TeamsLightPivotDownColor color */ Colors.TeamsLightPivotDownColor = "#bdbde6";
    /** TeamsMessage Dark color */ Colors.TeamsMessageDarkColor = "#7a7fea";
    /** Teams progress NotStarted color */ Colors.TeamsProgressNotStartedColor = "#605e5c"; // Grey
    /** Teams progress InProgress color */ Colors.TeamsProgressInProgressColor = "#327eaa"; // Blue
    /** Teams progress Complete color */ Colors.TeamsProgressCompleteColor = "#418040"; // Green
    /** Teams progress Late color */ Colors.TeamsProgressLateColor = "#d13438"; // Salmon
    /** Teams progress NotStarted color */ Colors.TeamsDarkProgressNotStartedColor = "#979693"; // Grey
    /** Teams progress InProgress color */ Colors.TeamsDarkProgressInProgressColor = "#5ca9e5"; // Blue
    /** Teams progress Completed color */ Colors.TeamsDarkProgressCompleteColor = "#62ab83"; // Green
    /** Teams progress Late color */ Colors.TeamsDarkProgressLateColor = "#d13438"; // Salmon
    /** Teams priority urgent color*/ Colors.TeamsPriorityUrgentColor = "#D13438"; // Red
    /** Teams priority important color*/ Colors.TeamsPriorityImportantColor = "#D13438"; // Red
    /** Teams priority medium color*/ Colors.TeamsPriorityMediumColor = "#0B6A0B"; // Green
    /** Teams priority low color*/ Colors.TeamsPriorityLowColor = "#0078D4"; // Blue
    /** Teams priority urgent color*/ Colors.TeamsDarkPriorityUrgentColor = "#F28C8C"; // Red
    /** Teams priority important color*/ Colors.TeamsDarkPriorityImportantColor = "#F28C8C"; // Red
    /** Teams priority medium color*/ Colors.TeamsDarkPriorityMediumColor = "#67D48B"; // Green
    /** Teams priority low color*/ Colors.TeamsDarkPriorityLowColor = "#6CB8F6"; // Blue
    //#endregion
    /** Loop Theme */ Colors.LoopBrand50 = "#560FBF";
    Colors.LoopBrand140 = "#D0BEFE";
    /** Brand Color - Loop 70 */ Colors.LoopBrand70 = "#7520F5";
    /** Brand Color - Loop (Primary light) 80 */ Colors.LoopBrand80 = "#8230FF";
    /** Brand Color - Loop (Primary dark) 100 */ Colors.LoopBrand100 = "#A375FF";
    /** Brand Color - Loop (dark) 110 */ Colors.LoopBrand110 = "#AB85FF";
    //#endregion
    //#region Font Color
    /** FontColorThemeDark  */ Colors.FontColorThemeDark = "#195735";
    /** FontColorThemeDarkAlt  */ Colors.FontColorThemeDarkAlt = "#1e673f";
    /** FontColorThemeDarker  */ Colors.FontColorThemeDarker = "#134027";
    /** FontColorThemeLight  */ Colors.FontColorThemeLight = "#a8d5bc";
    //#endregion
    //#region Border Color
    /** BorderColorNeutralPrimary */ Colors.BorderColorNeutralPrimary = "#364147";
    /** BorderColorNeutralTertiaryAlt */ Colors.BorderColorNeutralTertiaryAlt = "#c7cde1";
    /** BorderColorNeutralTertiary */ Colors.BorderColorNeutralTertiary = "#afb8bd";
    //#endregion
    //#region Sharepoint Theme
    Colors.SharepointTitleFontColor = ()=>window["__themeState__"]?.["theme"]?.black ?? Colors.Black;
    Colors.SharepointAddTaskButtonBackgroundColor = ()=>window["__themeState__"]?.["theme"]?.neutralLighter ?? Colors.NeutralLighter;
    Colors.SharepointBackgroundColor = ()=>window["__themeState__"]?.["theme"]?.white ?? Colors.White;
    Colors.SharepointTextFontColor = ()=>window["__themeState__"]?.["theme"]?.primaryText ?? "#333333";
    Colors.SharepointPlaceholderFontColor = ()=>window["__themeState__"]?.["theme"]?.neutralTertiary ?? Colors.NeutralTertiary;
    Colors.SharepointLightBackgroundColor = ()=>window["__themeState__"]?.["theme"]?.neutralLight ?? Colors.NeutralLight;
    Colors.SharepointNeutralTextFontColor = ()=>window["__themeState__"]?.["theme"]?.neutralSecondary ?? "#666666";
    Colors.SharepointButtonHoverColor = ()=>window["__themeState__"]?.["theme"]?.themePrimary ?? "#407855";
    Colors.SharepointCardSeparator = ()=>window["__themeState__"]?.["theme"]?.neutralLight ?? Colors.NeutralLight;
    Colors.SharepointBGColorForNonProcessableStyles = ()=>window["__themeState__"]?.["theme"]?.PageBackground ?? Colors.White;
    Colors.SharepointTextColorForNonProcessableStyles = ()=>window["__themeState__"]?.["theme"]?.bodyText ?? Colors.NeutralPrimary;
    Colors.SharepointBlueIconColor = ()=>window["__themeState__"]?.["theme"]?.blue ?? "#0078d4";
    Colors.SharepointCompleteColor = ()=>window["__themeState__"]?.["theme"]?.excel ?? Colors.ThemePrimary;
    Colors.SharepointHighPriIconColor = ()=>window["__themeState__"]?.["theme"]?.redDark ?? "#a4262c";
    Colors.SharepointCheckboxCheckedColor = ()=>window["__themeState__"]?.["theme"]?.themePrimary ?? "#6264A7";
    //#endregion
    //#region Partner team colors
    /** Loop Theme */ Colors.LoopLightP0 = "#712DFF";
    /** Fluent vNext Neutrals */ Colors.NeutralLightGrey14 = "#242424";
    Colors.NeutralLightGrey26 = "#424242";
    Colors.NeutralLightGrey30 = "#4D4D4D";
    Colors.NeutralLightGrey38 = "#616161";
    Colors.NeutralLightGrey44 = "#707070";
    Colors.NeutralLightGrey74 = "#BDBDBD";
    Colors.NeutralLightGrey82 = "#D1D1D1";
    Colors.NeutralLightGrey86 = "#DBDBDB";
    Colors.NeutralLightGrey88 = "#E0E0E0";
    Colors.NeutralLightGrey92 = "#EBEBEB";
    Colors.NeutralLightGrey94 = "#F0F0F0";
    Colors.NeutralLightGrey96 = "#F5F5F5";
    Colors.NeutralDarkGrey8 = "#141414";
    Colors.NeutralDarkGrey12 = "#1F1F1F";
    Colors.NeutralDarkGrey16 = "#292929";
    Colors.NeutralDarkGrey20 = "#333333";
    Colors.NeutralDarkGrey24 = "#3D3D3D";
    Colors.NeutralDarkGrey28 = "#474747";
    Colors.NeutralDarkGrey32 = "#525252";
    Colors.NeutralDarkGrey36 = "#5C5C5C";
    Colors.NeutralDarkGrey40 = "#666666";
    Colors.NeutralDarkGrey60 = "#999999";
    Colors.NeutralDarkGrey68 = "#ADADAD";
    Colors.NeutralDarkGrey84 = "#D6D6D6";
    //#endregion
    //#region Complete Button Animation Constants
    Colors.CompleteButtonHoverColor = "#195735";
    //#endregion
    //#region Confetti colors
    Colors.ConfettiMagenta = "#e3008c";
    Colors.ConfettiRed = "#da3b00";
    Colors.ConfettiOrange = "#feab43";
    Colors.ConfettiGreen = "#02ad56";
    Colors.ConfettiBlue = "#0078d4";
    Colors.ConfettiPurple = "#8763b8";
    Colors.TeamsDarkThemeConfettiDarkPurple = "#7d84c4";
    //#endregion
    //#region Progress state colors
    /** The not-started color */ Colors.NotStarted = "#605e5c"; // Grey
    /** The in-progress color */ Colors.InProgress = "#327eaa"; // Blue
    /** The completed color */ Colors.Completed = "#418040"; // Green
    /** The late color */ Colors.Late = "#d13438"; // NOT Salmon
    /** The late color on hover */ Colors.LateHover = "#bc2f34"; // Dark NOT Salmon
    /** The not-started color for dark theme */ Colors.DarkNotStarted = "#979693"; // Grey
    /** The in-progress color for dark theme */ Colors.DarkInProgress = "#5ca9e5"; // Blue
    /** The completed color for dark theme */ Colors.DarkCompleted = "#62ab83"; // Green
    /** The late color for dark theme */ Colors.DarkLate = "#d13438"; // NOT Salmon
    //#endregion
    //#region Priority state colors
    /** Priority Urgent color for dark theme */ Colors.Urgent = "#d13438"; // Red
    /** Priority Important color for dark theme */ Colors.Important = "#d13438"; // Red
    /** Priority Medium color for dark theme */ Colors.Medium = "#0B6A0B"; // Green
    /** Priority Low color for dark theme */ Colors.Low = "#0078D4"; // Blue
    /** Priority Urgent color */ Colors.DarkUrgent = "#F28C8C"; // Red
    /** Priority Important color */ Colors.DarkImportant = "#F28C8C"; // Red
    /** Priority Medium color */ Colors.DarkMedium = "#67D48B"; // Green
    /** Priority Low color */ Colors.DarkLow = "#6CB8F6"; // Blue
    //#endregion
    //#CompletedSolid Icon Color for Create Task
    /** CompletedSolid Icon Color for Light theme */ Colors.LightCompletedSolidIcon = "#4A9F30";
    /** CompletedSolid Icon Color for Dark theme */ Colors.DarkCompletedSolidIcon = "#63B64C";
    /** CompletedSolid Icon Color for Contrast theme */ Colors.ContrastCompletedSolidIcon = "#000000";
    //#endregion
    //#region Portfolio Status colors
    /** Priority Default color for light theme */ Colors.PortfolioItemStatusDefault = "#767676"; // Gray
    /** Priority NotStarted color for light theme */ Colors.PortfolioItemStatusNotStarted = "#767676"; // Dark Gray
    /** Priority OnTrack color for light theme */ Colors.PortfolioItemStatusOnTrack = "#107C10"; // Green
    /** Priority AtRisk color for light theme */ Colors.PortfolioItemStatusAtRisk = "#DA3B01"; // Orange
    /** Priority OffTrack color for light theme */ Colors.PortfolioItemStatusOffTrack = "#C50F1F"; // Red
    /** Priority Closed color for light theme */ Colors.PortfolioItemStatusClosed = "#0078D4"; // Blue
    // Dark theme colors
    /** Priority Default color for dark theme */ Colors.DarkPortfolioItemStatusDefault = "#B3B3B3"; // Gray
    /** Priority NotStarted color for dark theme */ Colors.DarkPortfolioItemStatusNotStarted = "#B3B3B3"; // Gray
    /** Priority OnTrack color for dark theme */ Colors.DarkPortfolioItemStatusOnTrack = "#27AC22"; // Green
    /** Priority AtRisk color for dark theme */ Colors.DarkPortfolioItemStatusAtRisk = "#F87528"; // Orange
    /** Priority OffTrack color for dark theme */ Colors.DarkPortfolioItemStatusOffTrack = "#CC2635"; // Red
    /** Priority Closed color for dark theme */ Colors.DarkPortfolioItemStatusClosed = "#4FA1E1"; // Blue
    // contrast theme colors
    /** Priority Default color for contrast theme */ Colors.ContrastPortfolioItemStatusDefault = "#A6A6A6"; // Gray
    /** Priority NotStarted color for contrast theme */ Colors.ContrastPortfolioItemStatusNotStarted = "#A6A6A6"; // Gray
    /** Priority OnTrack color for contrast theme */ Colors.ContrastPortfolioItemStatusOnTrack = "#FFFFFF"; // Green
    /** Priority AtRisk color for contrast theme */ Colors.ContrastPortfolioItemStatusAtRisk = "#8EE3F0"; // Orange
    /** Priority OffTrack color for contrast theme */ Colors.ContrastPortfolioItemStatusOffTrack = "#8EE3F0"; // Red
    /** Priority Closed color for contrast theme */ Colors.ContrastPortfolioItemStatusClosed = "#A6A6A6"; // Blue
    //#endregion
    //#region Delink icon colors
    Colors.DelinkIconLight = "#c50f1f";
    Colors.DelinkIconDark = "#f1707b";
    Colors.DelinkIconHighContrast = "#0086f0";
//#endregion
})(Colors || (Colors = {}));
