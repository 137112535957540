/**
 * Error codes parsed from GroupNamesValidation API call's response
 */ export var GroupNameValidationErrorCode;
(function(GroupNameValidationErrorCode) {
    /**
     * DisplayName and Mail Nickname passed all validations. Ready for use.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["OK"] = 0] = "OK";
    /**
     * DisplayName matches one of the Default Reserved Words.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["ContainsDefaultReservedWords"] = 1] = "ContainsDefaultReservedWords";
    /**
     * DisplayName or Mail Nickname do not meet Prefix and/or Suffix's requirement(s).
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["MissingPrefixSuffix"] = 2] = "MissingPrefixSuffix";
    /**
     * DisplayName matches one of customer defined blocked words.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["ContainsCustomBlockedWords"] = 3] = "ContainsCustomBlockedWords";
    /**
     * DisplayName matches one of Microsoft standard blocked words.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["ContainsMSStandardBlockedWords"] = 4] = "ContainsMSStandardBlockedWords";
    /**
     * Mail Nickname already exists, matches default reserved word or matches blocked word.
     * Combining these 3 cases together since solution is the same - append some random number and retry.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["MailNicknameNotOK"] = 5] = "MailNicknameNotOK";
    /**
     * This is the default case if we don't have a clear error code.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["Unknown"] = 6] = "Unknown";
})(GroupNameValidationErrorCode || (GroupNameValidationErrorCode = {}));
export var ServerInitialErrorMsg;
(function(ServerInitialErrorMsg) {
    /**
     * Error Id used when user is not allowed to access the requested entity.
     */ ServerInitialErrorMsg["Forbidden"] = "Forbidden";
    /**
     * Error Id used when the requested entity is not found.
     */ ServerInitialErrorMsg["NotFound"] = "NotFound";
    /**
     * Error id used when user tries to visit an empty group yet plan creation failed.
     */ ServerInitialErrorMsg["CreatePlanForGroupFailed"] = "CreatePlanForGroupFailed";
    /**
     * Error id used when user tries to visit an empty group yet service can create no more plans for this user.
     */ ServerInitialErrorMsg["MaximumPlansOwnedByUserReached"] = "MaximumPlansOwnedByUserReached";
    /**
     * Default error id when the error is unexpected.
     */ ServerInitialErrorMsg["UnknownError"] = "UnknownError";
})(ServerInitialErrorMsg || (ServerInitialErrorMsg = {}));
export var NetworkConnectivityError;
(function(NetworkConnectivityError) {
    NetworkConnectivityError["Offline"] = "Offline";
})(NetworkConnectivityError || (NetworkConnectivityError = {}));
export var AppErrorCode;
(function(AppErrorCode) {
    AppErrorCode["DynamicImportError"] = "DynamicImportError";
})(AppErrorCode || (AppErrorCode = {}));
export var PlannerServiceLimitErrorTypes;
(function(PlannerServiceLimitErrorTypes) {
    /** The maximum number of plans contained by a group limit was exceeded. The container property of the plannerPlan resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumProjectsOwnedByUser"] = "MaximumProjectsOwnedByUser";
    /** The maximum number of plans shared with a user limit was exceeded. The sharedWith property on the plannerPlanDetails resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumProjectsSharedWithUser"] = "MaximumProjectsSharedWithUser";
    /** The maximum number of tasks created by a user limit was exceeded. The createdBy property on the plannerTask resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumTasksCreatedByUser"] = "MaximumTasksCreatedByUser";
    /** The maximum number of tasks assigned to a user limit was exceeded. The assignments property on the plannerTask resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumTasksAssignedToUser"] = "MaximumTasksAssignedToUser";
    /** The maximum number of tasks in a plan limit was exceeded. The planId property on the plannerTask resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumTasksInProject"] = "MaximumTasksInProject";
    /** The maximum number of tasks that aren't completed in a plan limit was exceeded. The planId and percentComplete properties on the plannerTask resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumActiveTasksInProject"] = "MaximumActiveTasksInProject";
    /** The maximum number of buckets in a plan limit was exceeded. The planId property on the plannerBucket resource determines this limit. */ PlannerServiceLimitErrorTypes["MaximumBucketsInProject"] = "MaximumBucketsInProject";
    /** The sharedWith property on the plannerPlanDetails resource contains too many values.*/ PlannerServiceLimitErrorTypes["MaximumUsersSharedWithProject"] = "MaximumUsersSharedWithProject";
    /** The references property on the plannerTaskDetails resource contains too many values.*/ PlannerServiceLimitErrorTypes["MaximumReferencesOnTask"] = " MaximumReferencesOnTask";
    /** The checklist property on the plannerTaskDetails resource contains too many values.*/ PlannerServiceLimitErrorTypes["MaximumChecklistItemsOnTask"] = "MaximumChecklistItemsOnTask";
    /** The assignments property on the plannerTask resource contains too many values.*/ PlannerServiceLimitErrorTypes["MaximumAssigneesInTasks"] = "MaximumAssigneesInTasks";
    /** The favoritePlanReferences property on the plannerUser resource contains too many values.*/ PlannerServiceLimitErrorTypes["MaximumFavoritePlansForUser"] = "MaximumFavoriteProjectsForUser";
    /** The recentPlanReferences property on the plannerUser resource contains too many values.*/ PlannerServiceLimitErrorTypes["MaximumRecentPlansForUser"] = "MaximumRecentProjectsForUser";
    /** The contexts property on the plannerPlan resource contains too many values. */ PlannerServiceLimitErrorTypes["MaximumContextsOnPlan"] = "MaximumContextsOnPlan";
})(PlannerServiceLimitErrorTypes || (PlannerServiceLimitErrorTypes = {}));
/**
 * Error name for updating archived entity
 */ export const UpdateArchivedEntityError = "ArchivedEntityCanNotBeUpdated";
