// Model
import { DeltaTranslationMap } from "../../deltaSync/DeltaTranslationMap";
import { TaskServiceEntityType } from "../../ITaskServiceEntity";
import { ProgressTaskboardTaskFormatting } from "./ProgressTaskboardTaskFormatting";
// Utilities
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Configuration and building of progress task format objects
 */ export class ProgressTaskboardTaskFormattingBuilder {
    /**
     * Build out the task formatting data for "Bucket" taskboard type
     */ build() {
        return new ProgressTaskboardTaskFormatting(this);
    }
    /**
     * Build from a Graph task format resource entity as initialization data.
     * @param taskFormatResource Task format resource entity to use for initializing the task format builder
     */ fromGraphResource(taskFormatResource) {
        return this.withPropertyBag({
            version: taskFormatResource["@odata.etag"],
            id: taskFormatResource.id,
            orderHint: taskFormatResource.orderHint
        });
    }
    /**
     * Create the task from diff sync
     * @param update Changes from diff sync to apply
     */ newFromDifferentialUpdate(update) {
        if (!(update.entityType === TaskServiceEntityType.Task || update.entityType === TaskServiceEntityType.TaskboardStatusTaskFormatting)) {
            throw new Error("ArgumentException: invalid entity");
        }
        const baseline = ProgressTaskboardTaskFormatting.builder.withPropertyBag({
            id: update.id,
            version: update.itemVersion
        }).build();
        const diffData = update.getCreateDiffData(TaskServiceEntityType.TaskboardStatusTaskFormatting);
        const progressFormat = baseline.applyDiffs(diffData);
        return this.forClone(progressFormat);
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(progressFormat) {
        return this.withPropertyBag({
            id: progressFormat.id,
            orderHint: progressFormat.orderHint,
            version: progressFormat.itemVersion
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.id = propertyBag.id;
        this.orderHint = propertyBag.orderHint || this.orderHint;
        this.version = propertyBag.version || this.version;
        return this;
    }
    /**
     * A test method to build a default valued task format object
     */ withDefaultValues() {
        return this.withPropertyBag({
            id: "TaskID",
            orderHint: "orderhint1",
            version: "1"
        });
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        if (update == null) {
            throw new Error("ArgumentNullException: update");
        }
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("orderHint", "orderHint");
        return graphTranslationMap.translate(update);
    }
    /**
     * Test method to add a specific id to the ProgressTaskboardTaskFormattingBuilder
     * @param id Id to assign to the ProgressTaskboardTaskFormattingBuilder
     */ withId(id) {
        this.id = id;
        return this;
    }
    /**
     * Initialize an instance of the task format builder
     */ constructor(){
        this.id = LocalIdGenerator.generate();
        this.orderHint = " !";
        this.version = "";
    }
}
