/**
 * Constants related to engagement telemetry logging. If you find yourself reusing the same string over and over in engagement telemetry logs it should be defined here instead.
 * This is also convenient for experiments that have to define which engagement telemetry logs they want to track in IExperimentDefinition.
 */ export const EngagementTelemetryConstants = {
    /** Action Creators */ RoutingActionCreator: "RoutingActionCreator",
    PortfolioActionCreator: "PortfolioActionCreator",
    /** Modules */ TeamsApp: "TeamsApp",
    WebApp: "WebApp",
    BasicBucketModule: "BasicBucketModule",
    BasicPlanModule: "BasicPlanModule",
    PlannerTaskModule: "PlannerTaskModule",
    PremiumPlanModule: "PremiumPlanModule",
    PortfolioModule: "PortfolioModule",
    GroupsModule: "GroupsModule",
    /** Goals */ CreateGoal: "CreateGoal",
    /** Task */ AddToMyDay: "AddToMyDay",
    RemoveFromMyDay: "RemoveFromMyDay",
    CreateTask: "CreateTask",
    UpdateTask: "UpdateTask",
    DeleteTask: "DeleteTask",
    CreateBucket: "CreateBucket",
    DeleteBucket: "DeleteBucket",
    CreatePlan: "CreatePlan",
    CreatePlanFromTemplate: "CreatePlanFromTemplate",
    MovePlanToExistingContainerSucceeded: "MovePlanToExistingContainerSucceeded",
    CreatePortfolio: "CreatePortfolio",
    TaskDetailsExternalContextLinkClicked: "TaskDetailsExternalContextLinkClicked",
    ExternalContextLink: "ExternalContextLink",
    BucketTitleUpdated: "BucketTitleUpdated",
    BucketOrderHintUpdated: "BucketOrderHintUpdated",
    DeleteBucketWithPublishedTasks: "DeleteBucketWithPublishedTasks",
    AddAttachment: "AddAttachment",
    EditAttachment: "EditAttachment",
    AttachmentClicked: "AttachmentClicked",
    TaskDeepLinkClicked: "TaskDeepLinkClicked",
    TaskLinkCopied: "TaskLinkCopied",
    AddLabelToTask: "AddLabelToTask",
    RemoveLabelFromTask: "RemoveLabelFromTask",
    TaskReactivated: "TaskReactivated",
    TaskCompleted: "TaskCompleted",
    TaskProgressChanged: "TaskProgressChanged",
    TaskAssigned: "TaskAssigned",
    TaskUpdated: "TaskUpdated",
    FileSuggestionToAttachment: "FileSuggestionToAttachment",
    TaskDetailsUpdated: "TaskDetailsUpdated",
    RecurrenceCanceledDueDate: "RecurrenceCanceledDueDate",
    RecurrenceOptionSelected: "RecurrenceOptionSelected",
    TaskCard: "TaskCard",
    TaskCardContextMenu: "TaskCardContextMenu",
    TaskCardContextMenuClicked: "TaskCardContextMenuClicked",
    ChecklistItemCompleted: "ChecklistItemCompleted",
    ReceivedFileRecommendations: "ReceivedFileRecommendations",
    /** Group */ CreateGroup: "CreateGroup",
    /** Task Board */ BoardColumnGroup: "BoardColumnGroup",
    DeleteUncategorizedBucket: "DeleteUncategorizedBucket",
    ToggleColumnGroupSection: "ToggleColumnGroupSection",
    TaskBoardDnd: "TaskBoardDnd",
    // Authentication
    ClaimChallengeReceived: "ClaimChallengeReceived",
    /* App Lifecycle */ /** When the app bootstraps */ Bootstrap: "Bootstrap",
    /** Group Picker */ GroupPicker: "GroupPicker",
    GroupLinkClicked: "GroupLinkClicked",
    /** Plan Settings */ PlanSettings: "PlanSettings",
    /** Delta Sync */ DeltaSyncCookieExpiredError: "DeltaSyncCookieExpiredError",
    /** Filters */ AssignmentFilterSection: "AssignmentFilterSection",
    BucketFilterSection: "BucketFilterSection",
    CategoryFilterSection: "CategoryFilterSection",
    DueDateFilterSection: "DueDateFilterSection",
    PriorityFilterSection: "PriorityFilterSection",
    ProgressFilterSection: "ProgressFilterSection",
    TaskFilterMenu: "TaskFilterMenu",
    ClearFilters: "ClearFilters",
    FilterClicked: "FilterClicked",
    FilterByKeyword: "FilterByKeyword",
    // MyPlans
    MyPlansHub: "MyPlansHub",
    PlanClicked: "PlanClicked",
    KeywordFilterUsed: "KeywordFilterUsed",
    // Notifications
    NotificationBar: "NotificationBar",
    ErrorBarShown: "ErrorBarShown",
    WarningBarShown: "WarningBarShown",
    UserDismissedToast: "UserDismissedToast",
    TimeoutDismissedToast: "TimeoutDismissedToast",
    BasicPlanConversionToast: "BasicPlanConversionToast",
    // Upsell
    FetchPremiumSubscriptionStatus: "FetchPremiumSubscriptionStatus",
    AcquirePremiumTrialSucceeded: "AcquirePremiumTrialSucceeded",
    TrialConversionSucceeded: "TrialConversionSucceeded",
    BasicPlanConversionSucceeded: "BasicPlanConversionSucceeded",
    SessionShowsTryProject: "SessionShowsTryProject",
    SessionExpiredTrial: "SessionExpiredTrial",
    // Upsell - RequestPremiumLicense
    RequestPremiumPlanLicenseDialog: "RequestPremiumPlanLicenseDialog",
    RequestPremiumPlanLicenseDialogOpened: "RequestPremiumPlanLicenseDialogOpened",
    RequestPremiumLicenseSucceeded: "RequestPremiumLicenseSucceeded",
    // Upsell - ConvertPlan
    ConvertPlanDialog: "ConvertPlanDialog",
    ConvertPlanDialogOpened: "ConvertPlanDialogOpened",
    ConvertPlanDialogCompareButtonClicked: "ConvertPlanDialogCompareButtonClicked",
    ConvertPlanDialogCreatePremiumPlanButtonClicked: "ConvertPlanDialogCreatePremiumPlanButtonClicked",
    ConvertPlanDialogStartTrialAndConvertPlanButtonClicked: "ConvertPlanDialogStartTrialAndConvertPlanButtonClicked",
    ConvertPlanToastNotificationViewButtonClicked: "ConvertPlanToastNotificationViewButtonClicked",
    // Upsell - TryPremiumModal
    TryPremiumModal: "TryPremiumModal",
    TryPremiumModalOpened: "TryPremiumModalOpened",
    TryPremiumModalStartTrialClicked: "TryPremiumModalStartTrialClicked",
    TryPremiumModalCompareButtonClicked: "TryPremiumModalCompareButtonClicked",
    TryPremiumModalLearnMoreClicked: "TryPremiumModalLearnMoreClicked",
    // Upsell - CopilotUpsellModal
    CopilotUpsellModal: "CopilotUpsellModal",
    CopilotUpsellModalStartTrialClicked: "CopilotUpsellModalStartTrialClicked",
    CopilotUpsellModalExplorePremiumClicked: "CopilotUpsellModalExplorePremiumClicked",
    CopilotUpsellModalCompareButtonClicked: "CopilotUpsellModalCompareButtonClicked",
    CopilotUpsellModalLearnMoreClicked: "CopilotUpsellModalLearnMoreClicked",
    // Upsell - UpsellStatusModal
    UpsellStatusModal: "UpsellStatusModal",
    UpsellStatusModalBuyNowClicked: "UpsellStatusModalBuyNowClicked",
    UpsellStatusModalRequestLicenseClicked: "UpsellStatusModalRequestLicenseClicked",
    UpsellStatusModalManageSubscriptionClicked: "UpsellStatusModalManageSubscriptionClicked",
    UpsellStatusModalLearnMoreClicked: "UpsellStatusModalLearnMoreClicked",
    // Upsell - PlanUpgraded
    PlanUpgradedDialog: "PlanUpgradedDialog",
    PlanUpgradedDialogOpened: "PlanUpgradedDialogOpened",
    PlanUpgradedDialogdDismissed: "PlanUpgradedDialogdDismissed",
    PlanUpgradedDialogCompareButtonClicked: "PlanUpgradedDialogCompareButtonClicked",
    PlanUpgradedDialogGoToPlanButtonClicked: "PlanUpgradedDialogGoToPlanButtonClicked",
    // Usage Logging Constants
    TaskReactivateButtonClicked: "TaskReactivateButtonClicked",
    TaskCompleteButtonClicked: "TaskCompleteButtonClicked",
    DeleteAttachment: "DeleteAttachment",
    TaskProgressUpdated: "TaskProgressUpdated",
    RecurrenceDueDateUpdated: "RecurrenceDueDateUpdated",
    TaskDetailsContextMenuClicked: "TaskDetailsContextMenuClicked",
    OpenTaskEditor: "OpenTaskEditor",
    ProjectTaskDetails: "ProjectTaskDetails",
    CopyTaskDialogOpened: "CopyTaskDialogOpened",
    MoveTaskDialogOpened: "MoveTaskDialogOpened",
    DeleteRecurringTaskDialogOpened: "DeleteRecurringTaskDialogOpened",
    DeleteRecurringTaskDialogClosed: "DeleteRecurringTaskDialogClosed",
    TaskRowContextMenuClicked: "TaskRowContextMenuClicked",
    NewPlanButtonClicked: "NewPlanButtonClicked",
    ExistingPlanButtonClicked: "ExistingPlanButtonClicked",
    NewPortfolioButtonClicked: "NewPortfolioButtonClicked",
    PlanPinned: "PlanPinned",
    // Task Forms
    FormsRequirementsIFrameDialogOpened: "FormsRequirementsIFrameDialogOpened",
    FormsRequirementsIFrameDialogClosed: "FormsRequirementsIFrameDialogClosed",
    FormsRequirementsIFrameDialogSubmit: "FormsRequirementsIFrameDialogSubmit",
    FormsRequirementsIFrameDialogFormReady: "FormsRequirementsIFrameDialogFormReady",
    FormsRequirementsIFrameFormClicked: "FormsRequirementsIFrameFormClicked",
    FormsRequirementsIFrameDialogError: "FormsRequirementsIFrameDialogError",
    FormsRequirementIFrameDialogTokenFetched: "FormsRequirementIFrameDialogTokenFetched",
    FormsRequirementIFrameDialogTokenFetchFailed: "FormsRequirementIFrameDialogTokenFetchFailed",
    FormsRequirementIFrameDialogFormSubmitted: "FormsRequirementIFrameDialogFormSubmitted",
    // Shared containers
    AssignTaskSuccessful: "AssignTaskSuccessful",
    AssignTaskBlockedByShareFile: "AssignTaskBlockedByShareFile",
    AssignTaskBlockedByAddNonMember: "AssignTaskBlockedByAddNonMember",
    // Left Nav
    LeftNav: "LeftNav",
    OptionClicked: "OptionClicked",
    SwitchView: "SwitchView",
    // Open plan logging
    OpenPlanFromPersistedRoute: "OpenPlanFromPersistedRoute",
    OpenPlan: "OpenPlan",
    // Open portfolio logging
    OpenPortfolio: "OpenPortfolio",
    // Create task from message
    CreateTaskFromMessage: "CreateTaskFromMessage",
    // Remove teams tab
    RemoveTeamsTabForBasicPlan: "RemoveTeamsTabForBasicPlan",
    RemoveTeamsTabForPremiumPlan: "RemoveTeamsTabForPremiumPlan",
    RemoveTeamsTabForNonLinkedPlan: "RemoveTeamsTabForNonLinkedPlan",
    // Create teams tab
    CreateBlankTab: "CreateBlankTab",
    LinkPlanToTab: "LinkPlanToTab",
    CreateTabWithNewPlan: "CreateTabWithNewPlan",
    CreateTabWithExistingPlan: "CreateTabWithExistingPlan",
    // Teams Tab Landing Page Experience
    TeamsTabLandingExperience: "TeamsTabLandingExperience",
    // GSD
    GsdPlanOpened: "GsdPlanOpened",
    TaskAssignedToCopilot: "TaskAssignedToCopilot",
    TaskUnassignedFromCopilot: "TaskUnassignedFromCopilot",
    CopilotResultUpdateSelected: "CopilotResultUpdateSelected",
    CopilotResultStopSelected: "CopilotResultStopSelected",
    CopilotResultLiked: "CopilotResultLiked",
    CopilotResultDisliked: "CopilotResultDisliked",
    GenerateCopilotTasks: "GenerateCopilotTasks",
    RegenerateCopilotTasks: "RegenerateCopilotTasks",
    CopilotTaskCreationCancelled: "CopilotTaskCreationCancelled",
    EditGoalsSection: "EditGoalsSection",
    ProjectHomeResourcesEditButtonClicked: "ProjectHomeResourcesEditButtonClicked",
    AssignToCopilotButtonClicked: "AssignToCopilotButtonClicked",
    AttachResourceItem: "AttachResourceItem",
    DeleteResourceItem: "DeleteResourceItem",
    LoopPageCreated: "LoopPageCreated",
    UpdatedTaskWithExecutionResponse: "UpdatedTaskWithExecutionResponse",
    //Baseline Usage engagement constants for logging
    BaselineDetailsView: "BaselineDetailsView",
    BaselineProjectSummaryView: "BaselineProjectSummaryView",
    SpotLightTab: "SpotLightTab",
    GridViewCommandBar: "GridViewCommandBar",
    HideBaselineColumnButtonClicked: "HideBaselineColumnButtonClicked",
    CompareAllTasksButtonClicked: "CompareAllTasksButtonClicked",
    SelectedBaselineDetailsView: "SelectedBaselineDetailsView",
    InsightCardClicked: "InsightCardClicked",
    // Share plan
    AddMember: "AddMember",
    RemoveMember: "RemoveMember",
    // Campaign
    CampaignCalloutContainer: "CampaignCalloutContainer",
    CampaignShownToUser: "CampaignShownToUser",
    CampaignCtaButtonClicked: "CampaignCtaButtonClicked",
    CampaignDismissed: "CampaignDismissed",
    // iCalendar
    ICalendarDialog: "ICalendarDialog",
    ICalendarPublishEnabled: "ICalendarPublishEnabled",
    ICalendarPublishDisabled: "ICalendarPublishDisabled",
    PublishPlanTasksToOutlookCalendarClicked: "PublishTasksToOutlookCalendarClicked",
    // Grid customizations
    ReorderGridColumn: "ReorderGridColumn",
    ResizeGridColumn: "ResizeGridColumn"
};
/**
 * List of usage events that are considered "core actions" for analytics purposes.
 * It is defined as all create/update actions on task/bucket/plan objects.
 */ export const UsageCoreActions = [
    // Core task actions
    EngagementTelemetryConstants.CreateTask,
    // Core bucket actions
    EngagementTelemetryConstants.CreateBucket,
    // Core plan actions
    EngagementTelemetryConstants.CreatePlan,
    EngagementTelemetryConstants.CreatePlanFromTemplate
];
