/**
 * Enumeration of the possible preview types for a task
 */ export var PreviewType;
(function(PreviewType) {
    /**
     * Automatic selection of preview
     */ PreviewType[PreviewType["Automatic"] = 0] = "Automatic";
    /**
     * No preview set
     */ PreviewType[PreviewType["NoPreview"] = 1] = "NoPreview";
    /**
     * Checklist preview
     */ PreviewType[PreviewType["CheckList"] = 2] = "CheckList";
    /**
     * Task description preview
     */ PreviewType[PreviewType["Description"] = 3] = "Description";
    /**
     * Task reference link preview
     */ PreviewType[PreviewType["Reference"] = 4] = "Reference";
})(PreviewType || (PreviewType = {}));
/**
 * Convert preview types
 */ export class PreviewTypeConverter {
    /**
     * Map the preview type string returned by graph to the PreviewType enum
     * @param graphPreviewType Graph preview type
     */ static getPreviewTypeFromGraph(graphPreviewType) {
        if (graphPreviewType == null) {
            return 0;
        }
        switch(graphPreviewType){
            case "checklist":
                return 2;
            case "description":
                return 3;
            case "noPreview":
                return 1;
            case "reference":
                return 4;
            case "automatic":
            default:
                return 0;
        }
    }
    /**
     * Map the PreviewType enum to the graph string
     * @param previewType PreviewType
     */ static getGraphPreviewType(previewType) {
        if (previewType == null) {
            return "automatic";
        }
        switch(previewType){
            case 2:
                return "checklist";
            case 3:
                return "description";
            case 1:
                return "noPreview";
            case 4:
                return "reference";
            case 0:
            default:
                return "automatic";
        }
    }
}
